/*eslint-disable*/
import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Label,
  FormGroup,
  Row,
  Input,
  CardHeader,
  CardFooter,
  Col,
  Button,
  Container,
  CardImg,
  CardImgOverlay,
  CardTitle,
  CardText,
  ListGroupItem,
  ListGroup,
} from "reactstrap";
import { Link , useHistory } from "react-router-dom";
import axios from "axios";
import SimpleHeader from "components/Headers/SimpleHeader.js";

export default function MigrasiData() {
    const allInfo = JSON.parse(localStorage.allinfo);
    const invoicePO = String(allInfo.privileges.filter((i) => i.privilege_name === "Tab Invoice PO").map((p) => p.read_access));
	const validasiAdminFinance = String(allInfo.privileges.filter((i) => i.privilege_name === "Tab Validasi Invoice PO Admin").map((p) => p.read_access));
	const validasiDirektur = String(allInfo.privileges.filter((i) => i.privilege_name === "Tab Validasi Invoice PO Direktur").map((p) => p.read_access));
	const cetak = String(allInfo.privileges.filter((i) => i.privilege_name === "Tab Cetak Invoice PO").map((p) => p.read_access));

  return (
    <>
      <div>
      <SimpleHeader name="Migrasi Data" parentName="Report" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
                <CardBody>
                    <Row>
                        <div className="card-columns">
                        {invoicePO && invoicePO === "1" && (
                            <Card>
                                <Link to="/admin/migrasi-data/gambar">
                                    <CardImg
                                        alt="..."
                                        src={require("assets/img/theme/master.png").default}
                                        top
                                    />
                                </Link>
                                GAMBAR
                            </Card>
                        )}
                        <Card></Card>
                        </div>
					</Row>
                </CardBody>
          </div>
        </Row>
      </Container>
      </div>
    </>
  );
}