/*eslint-disable*/
import React, { useEffect, useState } from "react";
import {
  Card,
  Button,
  Row,
  Col,
  CardBody,
  CardFooter,
  FormGroup,
  Label,
  Input,
  Container,
  CardHeader
} from "reactstrap";

import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import SweetAlert from "react-bootstrap-sweetalert";
import Halaman404 from "views/404";

const JangkaWaktu = () => {
  const token = localStorage.token;
  const warehouse = localStorage.warehouse;
  const username = localStorage.username;
  const history = useHistory();
  const [name,setName] = useState("");
  const [durasi,setDurasi] = useState(0);
  const [keterangan,setKeterangan] = useState("");
  const [alert, setAlert] = React.useState(null);
  const allInfo = JSON.parse(localStorage.allinfo);
  const JatuhTempo = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Durasi Tempo").map((p) => p.read_access));
  
  const CreateData = () => {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    
    let data = {
        warehouse_id : parseInt(warehouse),
        username : username,
        name: name,
        durasi: parseInt(durasi), 
        keterangan: keterangan ,
      };
      axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/jwkredit/save`, data, { headers })
      .then(function (response) {
        setSuccessAlert(response.data.message);
        setTimeout(() => (history.push("/admin/jangka-waktu")), 2000);
        })
        .catch(function (error) {
          setGagalAlert(error.response.data.message);
        });
  };

  const setSuccessAlert = (id) => {
    setAlert(
      <SweetAlert
        success
        showConfirm
        confirmBtnText="Ok"
        title={id}
        onCancel={hideAlert}
        onConfirm={hideAlert}
      />
    )
  }

const setGagalAlert = (id) => {
setAlert(
  <SweetAlert
  danger
  showConfirm
  confirmBtnText="Ok"
  title={id}
  onCancel={hideAlert}
  onConfirm={hideAlert}
  />
);
};

const setQuestionAlert = () => {
setAlert(
  <SweetAlert
  warning
  showCancel
  confirmBtnText="Lanjutkan"
  confirmBtnBsStyle="danger"
  title="Apakah Kamu Yakin?"
  onConfirm={CreateData}
  onCancel={hideAlert}
  focusCancelBtn
  />
);
};

  const hideAlert = () => {
    setAlert(null);
  }


  return (
    <div>
      {alert}
      {JatuhTempo && JatuhTempo === "1" ? (
      <div>
      <SimpleHeader name="Tambah Jatuh Tempo" parentName="Master" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                    <h3>Tambah Jatuh Tempo</h3>
                </CardHeader>
                <CardBody>
                  <Row md="12">
                      <Col md="6">
                        <FormGroup row>
                          <Label
                            for="exampleEmail"
                            sm={4}
                          >
                            Kode
                            {/* <span className="text-danger">*</span> */}
                          </Label>
                          <Col sm={7}>
                            <Input
                            autoComplete="off"
                              type="text"
                              name="Nama"
                              placeholder="Masukan Kode"
                              value={name}
                              onChange={(e) => {
                                setName(e.target.value);
                              }}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label
                            for="exampleEmail"
                            sm={4}
                          >
                            Durasi
                            {/* <span className="text-danger">*</span> */}
                          </Label>
                          <Col sm={5}>
                            <Input
                              autoComplete="off"
                              type="number"
                              name="Alamat"
                              placeholder="Masukan Durasi"
                              value={durasi}
                              onChange={(e) => {
                                setDurasi(e.target.value);
                              }}
                            />
                          </Col>
                          <Label
                            for="exampleEmail"
                            sm={2}
                          >
                            <b>
                            Hari
                            </b>
                          </Label>
                        </FormGroup>
                        <FormGroup row>
                          <Label
                            for="exampleEmail"
                            sm={4}
                          >
                            Deskripsi
                            {/* <span className="text-danger">*</span> */}
                          </Label>
                          <Col sm={7}>
                            <Input
                            autoComplete="off"
                              type="text"
                              name="Longitude"
                              placeholder="Masukan Deskripsi"
                              value={keterangan}
                              onChange={(e) => {
                                setKeterangan(e.target.value);
                              }}
                            />
                          </Col>
                        </FormGroup>
                      </Col>
                      <Col md="6">
                      </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                    <Link className="btn btn-info" to="/admin/jangka-waktu">
                      Kembali
                    </Link>
                    <Button color="danger" onClick={setQuestionAlert}>
                        Simpan
                    </Button>
                </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
      </div>
      ):(
        <Halaman404 />
      )}
    </div>
    
  );
};

export default JangkaWaktu;