


/*eslint-disable*/
import React, { useEffect, useState } from "react";
import {
  
  Card,
  Button,
  Row,
  Col,
  CardBody,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  
} from "reactstrap";
import { Link,useHistory } from "react-router-dom";
import axios from "axios";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import Select2 from "react-select2-wrapper";
import SweetAlert from "react-bootstrap-sweetalert";
import Halaman404 from "views/404";

const EditFinanceBBMAddendum = (props) => {
    const [alert, setAlert] = React.useState(null);
    const token = localStorage.token;
    const [code, setCode] = useState("")
    const username = localStorage.username;
    const warehouse = localStorage.warehouse;
    let history = useHistory();
    const headers = { Authorization: `Bearer ${token}`};
    const [codebbm, setCodeBbm] = useState("")
    const [user, setUser] = useState("")
    const [persons, setPersons] = useState([]);
    const [person, setPerson] = useState("");
    const [keterangan, setKeterangan] = useState("");
    const [savedItems, setSavedItems] = useState([]);
    const [bank, setBank] = useState("");
    const [banks, setBanks] = useState([]);
    const [isSearchShoww, setIsSearchShoww] = useState(false);
    const [allItemm, setAllItemm] = useState([]);
    const [queryy, setQueryy] = useState("");
    const [qtyTotal, setTotalQty] = useState(0);
    const [qtyError, setQtyError] = useState(null);
    
    const validateForm = () => {
        let error = false;
        savedItems.map((savedItem, key) => { 
          if (savedItem.bayar > savedItem.qtysisa) {
            setQtyError("invalid");
            error = true;
          }
        })
        return error;
    };
  
    const handleSubmit2 = (e) => {
      e.preventDefault();
      if (!validateForm()) {
          setQuestionAlert()
      }
    }

  useEffect(() => {
    getById();
  }, []);

  const getById = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/bbm-addendum/get/${props.match.params.id}`,
        { headers }
      )
      .then((data) => {
        setCodeBbm(data.data.response.bbm_code);
        setUser(data.data.response.user_name);
        setCode(data.data.response.user_code);
        // getPerson(data.data.response.customer_id);
        getbank(data.data.response.payment_method);
        setKeterangan(data.data.response.keterangan1);
        getItemDataSaved();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  
const getItemDataSaved = () => {
axios.post(`${process.env.REACT_APP_API_BASE_URL}/bbm-addendum/invoice`, {

    bbm_id: props.match.params.id

}, {headers : {Authorization: `Bearer ${token}`}}).then(async response => {
    let stateItem = [];

    await Promise.all(response.data.response.map(async (data) => {
        stateItem = [...stateItem, {
            warehouse_id: data.warehouse_id,
            code_invoice : data.code_invoice,
            payment_total : data.payment_total,
            keterangan2 : data.keterangan2,
            potongan: data.potongan,
            qtysisa : data.payment_total,
            bayar : data.bayar,
            data: {
                warehouse_id: data.warehouse_id,
                code_invoice : data.code_invoice,
                payment_total : data.payment_total,
                keterangan2 : data.keterangan2,
                potongan: data.potongan,
                qtysisa : data.payment_total,
                bayar : data.bayar,
            },
        }];
    }));
    setSavedItems(stateItem);
})
}

      useEffect(() => {
        const getData = setTimeout(async() => {
            if(queryy != ''){
                axios
                .post(`${process.env.REACT_APP_API_BASE_URL}/invoice-sjadd/page`, { invoice_code: queryy , per_page: 10,warehouse_id: parseInt(warehouse) , active_flag:1, user_code:code},{ headers })
                .then((res) => {
                    setAllItemm(res.data);
                    setIsSearchShoww(true);
                })
                .catch(function (error) {
                });
            }else{
          setAllItemm(null);
            }
          }, 1000)
          return () => clearTimeout(getData)
      }, [queryy]);
    
    
      const handlePotongan = (index, value) => {
        let updateList = savedItems;
        let aqtyTotal = parseInt(updateList[index].harga) + value;
        if(!isNaN(value) && value.length > -1){
          updateList[index] = {...updateList[index], potongan: value};
          setSavedItems(updateList);
          setTotalQty(qtyTotal + aqtyTotal);
        }else{return false}
      }
    
      const handleBayar = (index, value) => {
        let updateList = savedItems;
        let aqtyTotal = parseInt(updateList[index].potongan) + value;
        if(!isNaN(value) && value.length > -1){
          updateList[index] = {...updateList[index], bayar: value};
          setSavedItems(updateList);
          setTotalQty(qtyTotal + aqtyTotal);
        }else{return false}
      }
    
        // save
        const saveItemOrUpdate = (item) => {
            let oldobj = savedItems.find(o => o.code_invoice === item.invoice_code);
            if(oldobj===undefined){
                setSavedItems([...savedItems,{
                    warehouse_id : item.warehouse_id,
                    code_invoice: item.invoice_code,
                    payment_total : item.payment_total,
                    keterangan2 : item.keterangan,
                    qtysisa : item.payment_total,
                    potongan: 0,
                    bayar : item.payment_total
                  }]);
                  return setTotalQty(qtyTotal * 1 )
            }else{
                let index = savedItems.findIndex(e=>e.code_invoice===item.invoice_code);
                let updateList = savedItems;
          let qtyupdate = parseInt(updateList[index].bayar);
                updateList[index] = {...updateList[index], bayar: qtyupdate};
                setSavedItems(updateList);
                return setTotalQty(parseInt(updateList[index].payment_total) * qtyupdate)
            };
        }
      
    
      const handleSubmit = async (e) => {
        e.preventDefault();
        {
          setQueryy("");
          setIsSearchShoww("");
          return true
        }
      };
  
    const deleteItem = (id) => {
      let array = [...savedItems];
  
      let index = array.findIndex(object => {
        return object.code_invoice === id;
      });
  
      if (index !== -1) {
        setTotalQty(qtyTotal - (array[index].payment_total))  ;
        array.splice(index, 1);
        setSavedItems(array);
      }
    }

//   const getPerson = (id) => {
//     const headers = {
//       "Content-Type": "application/json",
//       Authorization: `Bearer ${token}`,
//     };
//     axios
//       .get(
//         `${process.env.REACT_APP_API_BASE_URL}/customer/list`,
//         { headers }
//       )
//       .then((data) => {
//         setPersons(data.data.response);
//         setPerson(id)
//       })
//       .catch(function (error) {
//         console.log(error);
//       });
//   };

  const getbank = (id) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/bank/list-cabang/${warehouse}`,
        { headers }
      )
      .then((data) => {
        setBanks(data.data.response);
        setBank(id)
      })
      .catch(function (error) {
        console.log(error);
      });
  };

 

  function EditBBM() {
    let dataItems = [];
            savedItems.map((dataItem) => dataItems = [...dataItems, 
                { 
                    warehouse_id: dataItem.warehouse_id,
                    code_invoice : dataItem.code_invoice,
                    payment_total : dataItem.payment_total,
                    keterangan2 : dataItem.keterangan2,
                    potongan: dataItem.potongan,
                    bayar : dataItem.bayar,
                }]);
        let data = {
        warehouse_id : parseInt(warehouse),
        user_code : code,
        payment_method : parseInt(bank),
        keterangan1 : keterangan,
        status_af :3,
        status_d :3,
        username : username,
        list : dataItems
        };
    axios
        .post(
          `${process.env.REACT_APP_API_BASE_URL}/bbm-addendum/update/${props.match.params.id}`,
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(function (response) {
          setSuccessAlert(response.data.message);
          setTimeout(() => (history.push("/admin/bukti-bank-masuk-addendum/page")), 1000);
        })
        .catch(function (error) {
              setGagalAlert(error.response.data.message);
        });
  }

  const setSuccessAlert = (id) => {
    setAlert(
      <SweetAlert
        success
        showConfirm
        confirmBtnText="Ok"
        title={id}
        onCancel={hideAlert}
        onConfirm={hideAlert}
      />
    )
  }

const setGagalAlert = (id) => {
setAlert(
  <SweetAlert
  danger
  showConfirm
  confirmBtnText="Ok"
  title={id}
  onCancel={hideAlert}
  onConfirm={hideAlert}
  />
);
};

const setQuestionAlert = () => {
setAlert(
  <SweetAlert
  warning
  showCancel
  confirmBtnText="Lanjutkan"
  confirmBtnBsStyle="danger"
  title="Apakah Kamu Yakin?"
  onConfirm={EditBBM}
  onCancel={hideAlert}
  focusCancelBtn
  />
);
};

  const hideAlert = () => {
    setAlert(null);
  };



const formatRupiah = (money) => {
  return new Intl.NumberFormat('id-ID',
      { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }
  ).format(money);
}
  
  return (
    <>
    {alert}
    <SimpleHeader name="Edit BBM Addendum" parentName="PO" />
    <Container className="mt--6" fluid>
        <Row>
          <div className="col"> 
                <CardBody>
                <Card className="bg-secondary shadow">
                          <CardHeader className="bg-white border-0">
                              <h3>Edit BBM</h3>
                          </CardHeader>
                    <CardBody>
                      <Row md="12">
                          <Col md="6">
                          <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={4}
                              >
                                Kode
                              </Label>
                              <Col sm={7}>
                                <Input
                                className="form-control-alternative"
                                disabled
                                  type="text"
                                  name="desc"
                                  placeholder="Masukan Keterangan"
                                  value={codebbm}
                                  onChange={(e) => {
                                    setCodeBbm(e.target.value);
                                  }}
                                />
                              </Col>                             
                            </FormGroup>
                             <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={4}
                              >
                                User
                              </Label>
                              <Col sm={7}>
                                <Input
                                    className="form-control-alternative"
                                    disabled
                                    type="text"
                                    name="desc"
                                    placeholder="Masukan Keterangan"
                                    value={user}
                                    onChange={(e) => {
                                        setUser(e.target.value);
                                    }}
                                    />
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label
                                className="form-control-label"
                                    sm={4}
                                >
                                    Bank
                                </Label>
                                <Col sm={7}>
                                <Input
                                autoComplete="off"
                                    name="coa"
                                    type="select"
                                    value={bank}
                                    onChange={(e) => {
                                    setBank(e.target.value);
                                    }}
                                >
                                    <option value="" disabled selected hidden>Pilih Bank</option>
                                    {banks.map((coa, key) => {
                                    return (
                                        <option key={key} value={coa.id}>
                                        {coa.bank_name} {coa.account_number} {coa.account_name}
                                        </option>
                                    );
                                    })}
                                </Input>
                                </Col>
                            </FormGroup>
                          </Col>
                          <Col md="6">
                             <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={4}
                              >
                                Keterangan
                              </Label>
                              <Col sm={7}>
                                <Input
                                className="form-control-alternative"
                                
                                  type="textarea"
                                  name="desc"
                                  rows = "4"
                                  placeholder="Masukan Keterangan"
                                  value={keterangan}
                                  onChange={(e) => {
                                    setKeterangan(e.target.value);
                                  }}
                                />
                              </Col>                             
                            </FormGroup>
                          </Col>
                      </Row>
                    </CardBody>
                </Card>
                <Form onSubmit={handleSubmit2}>
                <Card className="bg-secondary shadow">
                    <CardHeader className="bg-white border-0">
                      <h3>Invoice</h3>
                    </CardHeader>
                        <CardBody>
                          <Table size="sm" responsive>
                            <thead>
                              <tr>
                                <th><b>Kode Invoice</b></th>
                                <th><b>Tagihan</b></th>
                                <th><b>Keterangan</b></th>
                                <th><b>Potongan</b></th>
                                <th><b>Bayar</b></th>
                              </tr>
                            </thead>
                              <tbody>
                              {
                                savedItems.map((savedItem, key) => {
                                  return (
                                    <tr key={key}>
                                      <td>{savedItem.code_invoice}</td>
                                      <td>{formatRupiah(savedItem.payment_total)}</td>
                                      <td>{savedItem.keterangan2}</td>
                                      <td>
                                        <Input
                                          className="form-control-alternative"
                                          placeholder="Rp 0"
                                          type="number"
                                          value={savedItem.potongan}
                                          onChange={(e) => {
                                              handlePotongan(key, e.target.value);
                                          }}/>
                                        </td>
                                      <td>
                                      <FormGroup>
                                                  <Input
                                                      autoComplete="off"
                                                      placeholder="Rp 0"
                                                      invalid={qtyError === "invalid"}
                                                      type="number"
                                                      value={savedItem.bayar}
                                                      onChange={(e) => {
                                                        handleBayar(key, e.target.value);
                                                        if (savedItem.bayar > savedItem.qtysisa) {
                                                          setQtyError("");
                                                        }
                                                      }}
                                                  />
                                                  <FormFeedback>
                                                      Max Bayar {savedItem.qtysisa} 
                                                  </FormFeedback>
                                                </FormGroup>
                                        </td>
                                      <td>
                                        <Button color="danger" onClick={() => deleteItem(savedItem.code_invoice)}><i className="fas fa-trash" /></Button>
                                      </td>
                                    </tr>
                                  )
                                })
                              }
                              <tr>
                                <td>
                                  <><Form onSubmit={handleSubmit}>
                                          <Input
                                            autoComplete="off"
                                            placeholder="Masukan Kode Invoice"
                                            type="search"
                                            value={queryy}
                                            onChange={(e) => setQueryy(e.target.value)}
                                          />
                                        {isSearchShoww && queryy && (
                                        <Card className="position-sticky boxShadow" style={{ maxHeight: "15.5rem", overflowY: "auto", paddingTop: "1rem", position: "relative" }}>
                                          <div style={{ position: "absolute", top: "2.5px", right: "1rem", cursor: "pointer", fontSize: "1rem" }}>
                                            <i className="fas fa-window-close text-danger" onClick={() => setIsSearchShoww(false)}></i>
                                          </div>
                                          {allItemm?.response ? (
                                            allItemm.response.map((item) => (
                                              <CardBody key={item.id} style={{ minHeight: "5rem", padding: "1rem" }} className="bgSearch" onClick={() => {saveItemOrUpdate(item);setQueryy('');setIsSearchShoww(false);}}>
                                                <div>
                                                  <b>Kode:</b> {item.invoice_code}
                                                </div>
                                              </CardBody>
                                            ))
                                          ) : (
                                            <div className="text-center mb-3 text-danger">Akun "{queryy}" tidak ada bosku!</div>
                                          )}
                                        </Card>
                                      )}
                                  </Form></>
                                  </td>
                                 </tr>
                              </tbody>
                          </Table>
                        </CardBody>
                <CardFooter>
                      <Link className="btn btn-info" to="/admin/bukti-bank-masuk-addendum/page">
                        Kembali
                      </Link>
                      <Button color="danger" type="submit">
                          Simpan
                      </Button>
                </CardFooter>
                </Card>
              </Form>
            </CardBody>
          </div>
        </Row>
    </Container>
    </>
  );
}

export default EditFinanceBBMAddendum;