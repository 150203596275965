/*eslint-disable*/
import React, { useEffect, useState } from "react";
import {
  Card,
  Button,
  Row,
  Col,
  CardBody,
  CardHeader,
  CardFooter,
  FormGroup,
  Label,
  Input,
  Container,
} from "reactstrap";

import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import SweetAlert from "react-bootstrap-sweetalert";
import SelectFile from "components/SelectedFile/index";
import CurrencyInput from 'react-currency-input-field';

export default function EditKurir(props) {
  const token = localStorage.token;
  const history = useHistory();
  const [name, setName] = useState("");
  const [perkg, setPerKg] = useState("");
  const [perkm, setPerKm] = useState("");
  const [packingcairan, setPackingCairan] = useState("");
  const [packingpecah, setPackingPecah] = useState("");
  const [status, setStatus] = useState("");
  const [alert, setAlert] = React.useState(null);

  useEffect(() => {
    getById();
  }, []);

  const getById = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/kurir/get/${props.match.params.id}`,
        { headers }
      )
      .then((data) => {
        setName(data.data.response.name);
        setPerKg(data.data.response.per_kg);
        setPerKm(data.data.response.per_km);
        setPackingCairan(data.data.response.packing_cairan);
        setPackingPecah(data.data.response.packing_pecah);
        setStatus(data.data.response.active_flag);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const updateKurir = () => {
    const headers = {
			"Content-Type": "application/json",
			Authorization: `Bearer ${token}`,
		};

    const data = {
      name,
      per_kg : perkg,
      per_km: perkm,
      packing_cairan : packingcairan,
      packing_pecah : packingpecah,
      active_flag: status,
    }
    
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/kurir/update/${props.match.params.id}`, data, { headers })
      .then(function (response) {
        setSuccessAlert(response.data.message);
        setTimeout(() => (history.push("/admin/master/kurir")), 1000);
      })
      .catch(function (error) {
          setGagalAlert(error.response.data.message);
      });
  };
  const setSuccessAlert = (id) => {
    setAlert(
      <SweetAlert
        success
        showConfirm
        confirmBtnText="Ok"
        title={id}
        onCancel={hideAlert}
        onConfirm={hideAlert}
      />
    )
  }

  const setGagalAlert = (id) => {
    setAlert(
      <SweetAlert
        danger
        showConfirm
        confirmBtnText="Ok"
        title={id}
        onCancel={hideAlert}
        onConfirm={hideAlert}
      />
    );
  };

  const setQuestionAlert = () => {
    setAlert(
      <SweetAlert
        warning
        showCancel
        confirmBtnText="Lanjutkan"
        confirmBtnBsStyle="danger"
        title="Apakah Kamu Yakin?"
        onConfirm={updateKurir}
        onCancel={hideAlert}
        focusCancelBtn
      />
    );
  };

  const hideAlert = () => {
    setAlert(null);
  }

  const priceperkg = (e) => {
    const value = e === undefined ? 'undefined' : e;
    setPerKg(value || ' ');
                                                            
  };

  const priceperkm = (e) => {
    const value = e === undefined ? 'undefined' : e;
    setPerKm(value || ' ');
                                                            
  };

  const pricepackingcairan = (e) => {
    const value = e === undefined ? 'undefined' : e;
    setPackingCairan(value || ' ');
                                                            
  };

  const pricepackingpecah = (e) => {
    const value = e === undefined ? 'undefined' : e;
    setPackingPecah(value || ' ');
                                                            
  };

  return (
    <div>
      {alert}
      <SimpleHeader name="Edit Kurir" parentName="Master" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <CardBody>
              <Card className="bg-secondary shadow">
                  <CardHeader className="bg-white border-0">
                    <h3>Edit Kurir</h3>
                  </CardHeader>
                  <CardBody>
                    <Row md="12">
                      <Col md="6">
                        <FormGroup row>
                          <Label
                          className="form-control-label"
                            for="exampleEmail"
                            sm={4}
                          >
                            Nama
                          </Label>
                          <Col sm={7}>
                            <Input
                              autoComplete="off"
                              className="form-control-alternative"
                              type="text"
                              name="Nama"
                              placeholder="Masukan Nama"
                              value={name}
                              onChange={(e) => {
                                setName(e.target.value);
                              }}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label
                          className="form-control-label"
                            for="exampleEmail"
                            sm={4}
                          >
                            Per KG
                          </Label>
                          <Col sm={7}>
                            <CurrencyInput
                                
                                id="validation-example-2-field"
                                placeholder="Rp0"
                                allowDecimals={false}
                                value={perkg}
                                onValueChange={priceperkg}
                                className={`form-control`}
                                prefix={'Rp'}
                                step={10}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label
                          className="form-control-label"
                            for="exampleEmail"
                            sm={4}
                          >
                            Per KM
                          </Label>
                          <Col sm={7}>
                          <CurrencyInput
                                
                                id="validation-example-2-field"
                                placeholder="Rp0"
                                allowDecimals={false}
                                value={perkm}
                                onValueChange={priceperkm}
                                className={`form-control`}
                                prefix={'Rp'}
                                step={10}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label
                          className="form-control-label"
                            for="exampleEmail"
                            sm={4}
                          >
                            Packing Cairan
                          </Label>
                          <Col sm={7}>
                          <CurrencyInput
                                
                                id="validation-example-2-field"
                                placeholder="Rp0"
                                allowDecimals={false}
                                value={packingcairan}
                                onValueChange={pricepackingcairan}
                                className={`form-control`}
                                prefix={'Rp'}
                                step={10}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label
                          className="form-control-label"
                            for="exampleEmail"
                            sm={4}
                          >
                            Packing Pecah
                          </Label>
                          <Col sm={7}>
                          <CurrencyInput
                                
                                id="validation-example-2-field"
                                placeholder="Rp0"
                                allowDecimals={false}
                                value={packingpecah}
                                onValueChange={pricepackingpecah}
                                className={`form-control`}
                                prefix={'Rp'}
                                step={10}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label
                          className="form-control-label"
                            for="exampleEmail"
                            sm={4}
                          >
                            Status
                          </Label>
                          <Col sm={7}>
                            <Input
                              autoComplete="off"
                              className="form-control-alternative"
                              type="select"
                              name="Alamat"
                              placeholder="Masukan Alamat"
                              value={status}
                              onChange={(e) => {
                                setStatus(e.target.value);
                              }}
                            >
                                <option value="">Pilih Status</option>
                                <option value={1}>Aktif</option>
                                <option value={2}>Tidak Aktif</option>
                            </Input>
                          </Col>
                        </FormGroup>
                      </Col>
                      <Col md="6">
                      
                      </Col>
                    </Row>
                  </CardBody>
                  <CardFooter>
                    <Link className="btn btn-info" to="/admin/master/kurir">
                      Kembali
                    </Link>
                    <Button color="danger" onClick={setQuestionAlert}>
                      Simpan
                    </Button>
                  </CardFooter>
              </Card>
            </CardBody>
          </div>
        </Row>
      </Container>
    </div>

  );
};
