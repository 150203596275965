/*eslint-disable*/
import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Label,
  FormGroup,
  Row,
  Input,
  Container,
  CardHeader,
  CardFooter,
  Button, FormFeedback,
} from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import axios from 'axios';
import SimpleHeader from "components/Headers/SimpleHeader.js";
import moment from "moment";
import SweetAlert from "react-bootstrap-sweetalert";
import Halaman404 from "views/404";

export default function EditAsset(props) {
  const token = localStorage.token;
  const warehouse = parseInt(localStorage.warehouse);
  let history = useHistory();
  const [codeAsset, setCodeAsset] = useState("");
  const [nameAsset, setNameAsset] = useState("");
  const [typeAsset, setTypeAsset] = useState("");
  const [total, setTotal] = useState("");
  const [umurasset, setUmurAsset] = useState("");
  const [depresiasi, setDepresiasi] = useState("")
  const [residu, setResidu] = useState("");
  const [note, setNote] = useState("");
  const [valueAsset, setValueAsset] = useState("");
  const [buyingDate, setBuyingDate] = useState("");
  const [types, setTypes] = useState([]);
  const [coa, setCoa] = useState("");
  const [allCoa, setAllCoa] = useState([]);
  const [alert, setAlert] = React.useState(null);
  const allInfo = JSON.parse(localStorage.allinfo);
  const asset = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Asset").map((p) => p.read_access));
  
  useEffect(() => {
    getAssetById();
  }, []);

  const getAssetById = () => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    axios.get(`${process.env.REACT_APP_API_BASE_URL}/asset/get/${props.match.params.id}`, { headers
    })
    .then(data => {
        setNameAsset(data.data.response.asset_name);
        setCodeAsset(data.data.response.asset_code);
        setTotal(data.data.response.qty);
        setUmurAsset(data.data.response.umur_asset);
        setValueAsset(data.data.response.harga);
        setNote(data.data.response.keterangan);
        setResidu(data.data.response.residu);
        setDepresiasi(data.data.response.depresiasi_state);
        setBuyingDate(moment(data.data.response.buying_date, "DD/MM/YYYY").format("YYYY-MM-DD"));
        getCoa(data.data.response.akun_penyusutan);
        getType(data.data.response.tipe_penyusutan);
    })
      .catch(function (error) {
        console.log(error)
      })
  }
  
  const getType = (id) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    axios.get(`${process.env.REACT_APP_API_BASE_URL}/asset-type/list`, { headers
    })
    .then(data => {
        setTypes(data.data.response)
        setTypeAsset(id)
    })
      .catch(function (error) {
        console.log(error)
      })
  }

  const getCoa = (id) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/account/list/${warehouse}`, { headers })
      .then((data) => {
        setAllCoa(data.data.response);
        setCoa(id);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  function EditData() {
    let data = {
      asset_code: codeAsset,
      asset_name: nameAsset,
      qty: parseInt(total),
      tipe_penyusutan: typeAsset,
      umur_asset : parseInt(umurasset),
      depresiasi_state : parseInt(depresiasi),
      harga: parseInt(valueAsset),
      residu : parseInt(residu),
      buying_date: moment(buyingDate).format("DD-MM-YYYY"),
      warehouse_id: parseInt(warehouse),
      akun_penyusutan: parseInt(coa),
      keterangan: note,
    }
    axios.post(`${process.env.REACT_APP_API_BASE_URL}/asset/update/${props.match.params.id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    })
    .then(function (response) {
      setSuccessAlert(response.data.message);
      setTimeout(() => (history.push("/admin/asset")), 1000);
      })
      .catch(function (error) {
        setGagalAlert(error.response.data.message);
      });
}

const setSuccessAlert = (id) => {
  setAlert(
    <SweetAlert
      success
      showConfirm
      confirmBtnText="Ok"
      title={id}
      onCancel={hideAlert}
      onConfirm={hideAlert}
    />
  )
}

const setGagalAlert = (id) => {
setAlert(
<SweetAlert
danger
showConfirm
confirmBtnText="Ok"
title={id}
onCancel={hideAlert}
onConfirm={hideAlert}
/>
);
};

const setQuestionAlert = () => {
setAlert(
<SweetAlert
warning
showCancel
confirmBtnText="Lanjutkan"
confirmBtnBsStyle="danger"
title="Apakah Kamu Yakin?"
onConfirm={EditData}
onCancel={hideAlert}
focusCancelBtn
/>
);
};

const hideAlert = () => {
  setAlert(null);
}

  
  return (
    <>
    {alert}
    {asset && asset === "1" ? (
      <div>
      <SimpleHeader name="Edit Asset" parentName="Admin" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <CardBody>
              <Card className="bg-secondary shadow">
                    <CardHeader className="bg-white border-0">
                      <h3>Edit Asset</h3>
                    </CardHeader>
                    <CardBody>
                      <FormGroup>
                        <Label
                          className="form-control-label"
                          htmlFor="exampleFormControlInput1"
                        >
                          Nama Asset
                        </Label>
                        <Input
                          autoComplete="off"
                          type="text"
                          name="nama"
                          placeholder="Masukan Nama Asset"
                          value={nameAsset}
                          onChange={(e) => {
                            setNameAsset(e.target.value);
                          }}
                        />
                        <FormFeedback>Nama Asset tidak boleh kosong</FormFeedback>
                      </FormGroup>
                      <FormGroup>
                        <Label htmlFor="exampleFormControlSelect3">
                          Tanggal Pembelian
                        </Label>
                        <Input
                          disabled 
                          readOnly
                          autoComplete="off"
                          id="example-date-input"
                          type="date"
                          value={buyingDate}
                          onChange={(e) => {
                            setBuyingDate(e.target.value);
                          }}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label
                          className="form-control-label"
                          htmlFor="exampleFormControlInput1"
                        >
                          Umur Asset
                        </Label>
                        <Input
                          autoComplete="off"
                          type="number"
                          name="nilaiAsset"
                          placeholder="Masukan Umur Asset"
                          value={umurasset}
                          onChange={(e) => {
                            setUmurAsset(e.target.value);
                          }}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label
                          className="form-control-label"
                          htmlFor="exampleFormControlInput1"
                        >
                          Jumlah Asset
                        </Label>
                        <Input
                          autoComplete="off"
                          type="number"
                          name="total"
                          placeholder="0"
                          value={total}
                          onChange={(e) => {
                            setTotal(e.target.value);
                          }}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label
                          className="form-control-label"
                          htmlFor="exampleFormControlInput1"
                        >
                          Nilai Total Asset
                        </Label>
                        <Input
                          autoComplete="off"
                          type="number"
                          name="nilaiAsset"
                          placeholder="Rp 0.00"
                          value={valueAsset}
                          onChange={(e) => {
                            setValueAsset(e.target.value);
                          }}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label
                          className="form-control-label"
                          htmlFor="exampleFormControlInput1"
                        >
                          Residu
                        </Label>
                        <Input
                          autoComplete="off"
                          type="text"
                          name="nilaiAsset"
                          pattern='[0-9]{0,5}'
                          maxLength="12"
                          placeholder="Rp 0.00"
                          value={residu}
                          onChange={(e) => {
                            setResidu(e.target.value);
                          }}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label
                          className="form-control-label"
                          htmlFor="exampleFormControlInput1"
                        >
                          Keterangan
                        </Label>
                        <Input
                          autoComplete="off"
                          type="textarea"
                          rows ="4"
                          name="note"
                          placeholder="Masukan Keterangan"
                          value={note}
                          onChange={(e) => {
                            setNote(e.target.value);
                          }}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label
                          className="form-control-label"
                          htmlFor="exampleFormControlSelect3"
                        >
                          Depresiasi
                        </Label>
                        <Input
                          autoComplete="off"
                          name="type"
                          type="select"
                          value={depresiasi}
                          onChange={(e) => {
                            setDepresiasi(e.target.value);
                          }}
                        >
                          <option value="" selected hidden>Pilih Depresiasi</option>
                          <option value={1}>Penyusutan</option>
                          <option value={2}>Tidak Ada Penyusutan</option>
                          
                        </Input>
                        <FormFeedback>Depresiasi tidak boleh kosong</FormFeedback>
                      </FormGroup>
                      <FormGroup>
                        <Label
                          className="form-control-label"
                          htmlFor="exampleFormControlSelect3"
                        >
                          Tipe Penyusutan
                        </Label>
                        <Input
                        autoComplete="off"
                        disabled
                        readOnly
                          name="type"
                          type="select"
                          value={typeAsset}
                          onChange={(e) => {
                            setTypeAsset(e.target.value);
                          }}
                        >
                          <option value="">Pilih Tipe Penyusutan</option>
                          {types.map((type, key) => {
                            return (
                              <option key={key} value={type.id}>
                                {type.name}
                              </option>
                            );
                          })}
                        </Input>
                        <FormFeedback>Type Asset tidak boleh kosong</FormFeedback>
                      </FormGroup>
                      <FormGroup>
                        <Label
                          className="form-control-label"
                          htmlFor="exampleFormControlSelect3"
                        >
                          Akun Penyusutan
                        </Label>
                        <Input
                        autoComplete="off"
                          name="coa"
                          type="select"
                          value={coa}
                          onChange={(e) => {
                            setCoa(e.target.value);
                          }}
                        >
                          <option value="" disabled selected hidden>Pilih Akun Penyusutan</option>
                          {allCoa.map((coa, key) => {
                            return (
                              <option key={key} value={coa.id}>
                                {coa.account_code} {coa.account_name}
                              </option>
                            );
                          })}
                        </Input>
                      </FormGroup>
                    </CardBody>
                    <CardFooter>
                      <Link className="btn btn-info" to="/admin/asset">
                        Kembali
                      </Link>
                      <Button color="danger" onClick={setQuestionAlert}>
                          Simpan
                      </Button>
                    </CardFooter>
              </Card>
            </CardBody>
          </div>
        </Row>
      </Container>
      </div>
    ):(
      <Halaman404 />
    )}
    </>
  );
}
