/*!

=========================================================
* Argon Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import classnames from "classnames";

// reactstrap components
import { Breadcrumb, BreadcrumbItem, Container, Row, Col,Navbar } from "reactstrap";

function TimelineHeader({ name, parentName }) {
  return (
    <>
      <div className="header header-dark  pb-4 content__title content__title--calendar" style={{backgroundColor:"#1c0ab9"}}>
        <Container fluid>
          <div className="header-body">
            <Row className="align-items-center py-4">
              <Col lg="6" xs="7">
                <h6 className="fullcalendar-title h2 text-white d-inline-block mb-0">
                  {name}
                </h6>{" "}
                <Breadcrumb
                  className="d-none d-md-inline-block ml-lg-4"
                  listClassName="breadcrumb-links breadcrumb-dark"
                >
                  <BreadcrumbItem>
                    <a href="../../admin/dashboard" >
                      <i className="fas fa-home" />
                    </a>
                  </BreadcrumbItem>
                  <BreadcrumbItem aria-current="page" className="active">
                    {name}
                  </BreadcrumbItem>
                </Breadcrumb>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      <Navbar
				className={classnames(
					"navbar-top bg-color-cok navbar-expand",
					// { "navbar-dark bg-secondary" : theme === "dark" },
					// { "navbar-light bg-secondary": theme === "light" }
				)} style={{backgroundColor:"rgb(250, 233, 2)"}}>
				<Container fluid>
				</Container>
			</Navbar>
    </>
  );
}

TimelineHeader.propTypes = {
  name: PropTypes.string,
  parentName: PropTypes.string,
};

export default TimelineHeader;