/*eslint-disable*/
import React, { useEffect, useState } from "react";
import {
  Card,
  Button,
  Row,
  Col,
  CardBody,
  CardHeader,
  CardFooter,
  FormGroup,
  Label,
  Input,
  Container,
} from "reactstrap";

import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import SweetAlert from "react-bootstrap-sweetalert";

const CreateIklan = () => {
  const token = localStorage.token;
  const history = useHistory();
  const [videowebsite, setVideoWebsite] = useState("");
  const [videomobile, setVideoMobile] = useState("");
  const [posterwebsite1, setPosterWebsite1] = useState("");
  const [postermobile1, setPosterMobile1] = useState("");
  const [posterwebsite2, setPosterWebsite2] = useState("");
  const [postermobile2, setPosterMobile2] = useState("");
  const [alert, setAlert] = React.useState(null);

  const CreatePusat = () => {
    const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
    };

    const data = {
        video_web : videowebsite, 
        video_mobile: videomobile, 
        poster1_web: posterwebsite1, 
        poster1_mobile: postermobile1, 
        poster2_web: posterwebsite2, 
        poster2_mobile: postermobile2
    }
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/iklan-ecommerce/save`, data, { headers })
      .then(function (response) {
        setSuccessAlert(response.data.message);
        setTimeout(() => (history.push("/admin/iklan/master/iklan/page")), 1000);
      })
      .catch(function (error) {
          setGagalAlert(error.response.data.message);
      });
  };
  const setSuccessAlert = (id) => {
    setAlert(
      <SweetAlert
        success
        showConfirm
        confirmBtnText="Ok"
        title={id}
        onCancel={hideAlert}
        onConfirm={hideAlert}
      />
    )
  }

  const setGagalAlert = (id) => {
    setAlert(
      <SweetAlert
        danger
        showConfirm
        confirmBtnText="Ok"
        title={id}
        onCancel={hideAlert}
        onConfirm={hideAlert}
      />
    );
  };

  const setQuestionAlert = () => {
    setAlert(
      <SweetAlert
        warning
        showCancel
        confirmBtnText="Lanjutkan"
        confirmBtnBsStyle="danger"
        title="Apakah Kamu Yakin?"
        onConfirm={CreatePusat}
        onCancel={hideAlert}
        focusCancelBtn
      />
    );
  };

  const hideAlert = () => {
    setAlert(null);
  }

  return (
    <div>
      {alert}
      <SimpleHeader name="Tambah Iklan" parentName="Master" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <CardBody>
              <Card className="bg-secondary shadow">
                  <CardHeader className="bg-white border-0">
                    <h3>Tambah Iklan</h3>
                  </CardHeader>
                  <CardBody>
                    <Row md="12">
                      <Col md="6">
                        <FormGroup row>
                          <Label
                          className="form-control-label"
                            for="exampleEmail"
                            sm={4}
                          >
                            Video Website
                          </Label>
                          <Col sm={7}>
                            <Input
                              autoComplete="off"
                              className="form-control-alternative"
                              type="text"
                              name="Nama"
                              placeholder="Masukan Link Video Website"
                              value={videowebsite}
                              onChange={(e) => {
                                setVideoWebsite(e.target.value);
                              }}
                            />
                          </Col>
                        </FormGroup>
                       
                        <FormGroup row>
                          <Label
                          className="form-control-label"
                            for="exampleEmail"
                            sm={4}
                          >
                            Poster Website 1
                          </Label>
                          <Col sm={7}>
                          <Input
                              autoComplete="off"
                              className="form-control-alternative"
                              type="text"
                              name="Nama"
                              placeholder="Masukan Link Poster Website 1"
                              value={posterwebsite1}
                              onChange={(e) => {
                                setPosterWebsite1(e.target.value);
                              }}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label
                          className="form-control-label"
                            for="exampleEmail"
                            sm={4}
                          >
                            Poster Website 2
                          </Label>
                          <Col sm={7}>
                          <Input
                              autoComplete="off"
                              className="form-control-alternative"
                              type="text"
                              name="Nama"
                              placeholder="Masukan Link Poster Website 2"
                              value={posterwebsite2}
                              onChange={(e) => {
                                setPosterWebsite2(e.target.value);
                              }}
                            />
                          </Col>
                        </FormGroup>
                        
                      </Col>
                      <Col md="6">
                      <FormGroup row>
                          <Label
                          className="form-control-label"
                            for="exampleEmail"
                            sm={4}
                          >
                            Video Mobile
                          </Label>
                          <Col sm={7}>
                          <Input
                              autoComplete="off"
                              className="form-control-alternative"
                              type="text"
                              name="Nama"
                              placeholder="Masukan Link Video Mobile"
                              value={videomobile}
                              onChange={(e) => {
                                setVideoMobile(e.target.value);
                              }}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label
                          className="form-control-label"
                            for="exampleEmail"
                            sm={4}
                          >
                            Poster Mobile 1
                          </Label>
                          <Col sm={7}>
                          <Input
                              autoComplete="off"
                              className="form-control-alternative"
                              type="text"
                              name="Nama"
                              placeholder="Masukan Link Poster Mobile 1"
                              value={postermobile1}
                              onChange={(e) => {
                                setPosterMobile1(e.target.value);
                              }}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label
                          className="form-control-label"
                            for="exampleEmail"
                            sm={4}
                          >
                            Poster Mobile 2
                          </Label>
                          <Col sm={7}>
                          <Input
                              autoComplete="off"
                              className="form-control-alternative"
                              type="text"
                              name="Nama"
                              placeholder="Masukan Link Poster Mobile 2"
                              value={postermobile2}
                              onChange={(e) => {
                                setPosterMobile2(e.target.value);
                              }}
                            />
                          </Col>
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                  <CardFooter>
                    <Link className="btn btn-info" to="/admin/iklan/master/iklan/page">
                      Kembali
                    </Link>
                    <Button color="danger" onClick={setQuestionAlert}>
                      Simpan
                    </Button>
                  </CardFooter>
              </Card>
            </CardBody>
          </div>
        </Row>
      </Container>
    </div>

  );
};

export default CreateIklan;