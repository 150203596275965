
/*!

=========================================================
* Argon Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Login from "views/pages/login/Index.js";
import Dashboard from "views/pages/dashboard/Index.js";

import karyawanbaru from "views/pages/karyawanbaru/Index"
import CreateKaryawanBaru from "views/pages/karyawanbaru/Create";

import Asset from "views/pages/asset/Index.js";
import CreateAsset from "views/pages/asset/Create.js";
import EditAsset from "views/pages/asset/Edit.js";

import TypeAsset from "views/pages/asset/type/Index.js";
import CreateTypeAsset from "views/pages/asset/type/Create.js";
import EditTypeAsset from "views/pages/asset/type/Edit.js";

import EditKaryawan from "views/pages/karyawanbaru/Edit.js";

import MainJangka  from "views/pages/jangkaWaktu/Index";
import CreateJangkaWaktu from "views/pages/jangkaWaktu/Create";
import EditJangkaWaktu from "views/pages/jangkaWaktu/Edit";
// import CetakCetakSuratJalanSo from "views/pages/so/SuratJalanSo/SuratJalanSo/Cetak";
// import DetailSuratJalanSo from "views/pages/so/SuratJalanSo/SuratJalanSo/Detail.js";
// import CetakSuratJalan from "views/pages/so/SuratJalanSo/CetakSuratJalan/Cetak.js";
import SoRetur from "views/pages/so/SoRetur/Index1";
import CreateSoReturProyek from "views/pages/so/SoRetur/SoReturProyek/Create";
import ValidasiReturSoKepalaToko from "views/pages/so/SoRetur/ValidasiAdminSo/Edit";
import EditSoKasurProyek from "views/pages/so/SoRetur/SoReturProyek/Edit";
import SoReturValidasiManajerOffice from "views/pages/so/SoRetur/ValidasiManajerOffice/Edit";
import ValidasiDirekturSoRetur from "views/pages/so/SoRetur/ValidasiDirektur/Edit";
import ValidasiDirekturSoRetur1 from "views/pages/so/SoRetur/ValidasiDirektur/Index";
import ValidasiCetakRetur from "views/pages/so/SoRetur/CetakReturSo/BkkBaru";

// import BkmInvoiceSo from "views/pages/so/InvoiceSo/CetakInvoice/Bkm";
import IndexIzinHrd from "views/pages/Hrd/Izin/Index";
import ValidasiHrdIzinValidasi from "views/pages/Hrd/Izin/Izin/Edit";
import IndexAbsensiKaryawan from "views/pages/Hrd/Absensi/Index1";
import SoReturProyekPage from "views/pages/so/SoRetur/SoReturProyek/Index";
import ValidasiSoReturManajerOffice from "views/pages/so/SoRetur/ValidasiManajerOffice/Index";
import HistoriSoRetur from "views/pages/so/SoRetur/CetakReturSo/Index";
import MigrasiData from "views/pages/MigrasiData/Index";
import PageLaporanMigrasiDataGambar from "views/pages/MigrasiData/MigrasiDataGambar/Index";
import DetailHrdIzin from "views/pages/Hrd/Izin/RiwayatIzin/Detail";
// import DetailSuratJalanSOOOOO from "views/pages/so/SuratJalanSo/CetakSuratJalan/Detail";
import DetailReturSo from "views/pages/so/SoRetur/CetakReturSo/Detail";
import IndexDinasHrd from "views/pages/Hrd/Dinas/Index";
import ValidasiHrdIzinDinasValidasi from "views/pages/Hrd/Dinas/RiwayatDinas/Edit";
import DetailHrdIzinDinas from "views/pages/Hrd/Dinas/Histori/Detail";
import EditGambarKaryawan from "views/pages/karyawanbaru/EditGambar";
import KurirPage from "views/pages/master/Kurir/Index";
import CreateKurir from "views/pages/master/Kurir/Create";
import EditKurir from "views/pages/master/Kurir/Edit";
import IklanPage from "views/pages/master/Iklan/Index";
import iklanPagination from "views/pages/master/Iklan/Iklan/Index";
import Carousel1Pagination from "views/pages/master/Iklan/Carousel1/Index";
import Carousel2Pagination from "views/pages/master/Iklan/Carousel2/Index";
import Carousel3Pagination from "views/pages/master/Iklan/Carousel3/Index";
import CreateIklan from "views/pages/master/Iklan/Iklan/Create";
import PajakPage from "views/pages/master/Pajak/Index";
import AplikatorPage from "views/pages/master/Aplikator/Index";
import CreateAplikator from "views/pages/master/Aplikator/Create";
import EditAplikator from "views/pages/master/Aplikator/Edit";
import MGMPage from "views/pages/master/Mgm/Index";
import MgmPagination from "views/pages/master/Mgm/Mgm/Index";
import Validasi1MGM from "views/pages/master/Mgm/Mgm/Validasi";
import validasi1MgmPagination from "views/pages/master/Mgm/Validasi1/Index";
import Validasi2MGM from "views/pages/master/Mgm/Validasi1/Validasi";
import HistoriMgmPagination from "views/pages/master/Mgm/History/Index";
import DetailMgm from "views/pages/master/Mgm/History/Detail";
import PenjualanProduk from "views/pages/penjualan/PenjualanProduk/Index";
import penjualanProdukPagination from "views/pages/penjualan/PenjualanProduk/PenjualanProduk/Index";
import ValidasiAdminCabangPesanan from "views/pages/penjualan/PenjualanProduk/PenjualanProduk/Validasi";
import ValidasipenjualanProdukPagination from "views/pages/penjualan/PenjualanProduk/Validasi1/Index";
import ValidasiAdminPusatPesanan from "views/pages/penjualan/PenjualanProduk/Validasi1/Validasi";
import HistoripenjualanProdukPagination from "views/pages/penjualan/PenjualanProduk/History/Index";
import DetailHistoriPesanan from "views/pages/penjualan/PenjualanProduk/History/Detail";
import CreateCarousel1 from "views/pages/master/Iklan/Carousel1/Create";
import CreateCarousel3 from "views/pages/master/Iklan/Carousel3/Create";
import CreateCarousel2 from "views/pages/master/Iklan/Carousel2/Create";
import AfiliasiPage from "views/pages/master/Affiliasi/Index";
import OrderAplikatorPage from "views/pages/penjualan/PenjualanAplikator/Index";
import UpdateTanggalSurvey from "views/pages/penjualan/PenjualanAplikator/tanggalsurvey";
import UpdateHasilKunjunganCuak from "views/pages/penjualan/PenjualanAplikator/hasilsurvey";
import SuratJalanPemesananProduk from "views/pages/penjualan/SuratJalanPesananProduk/Index";
import SuratJalanPesananProdukPage from "views/pages/penjualan/SuratJalanPesananProduk/SuratJalanPesananProduk/Index";
import CreateSuratJalanPesananProduk from "views/pages/penjualan/SuratJalanPesananProduk/SuratJalanPesananProduk/Create";
import EditSuratJalanPesananProduk from "views/pages/penjualan/SuratJalanPesananProduk/SuratJalanPesananProduk/Edit";
import SuratJalanPesananProdukValidasiKepalaGudang from "views/pages/penjualan/SuratJalanPesananProduk/ValidasiKepalaGudang/Index";
import ValidasiSuratJalanPesananProdukKepalaGudang from "views/pages/penjualan/SuratJalanPesananProduk/ValidasiKepalaGudang/Validasi";
import HistoriSuratJalanPesananProdukPage from "views/pages/penjualan/SuratJalanPesananProduk/Histori/Index";
import InvoicePesananProdukPage from "views/pages/penjualan/InvoicePesananProduk/Index";
import InvoicePesananProdukPagination from "views/pages/penjualan/InvoicePesananProduk/Invoice/Index";
import CreateInvoicePesananProduk from "views/pages/penjualan/InvoicePesananProduk/Invoice/Create";
import ValidasiKepalafinanceInvoicePesananProduk from "views/pages/penjualan/InvoicePesananProduk/ValidasiFinance/Index";
import ValidasiInvoicePesananProdukCokFinance from "views/pages/penjualan/InvoicePesananProduk/ValidasiFinance/Validasi";
import ValidasiDirekturInvoicePesananProduk from "views/pages/penjualan/InvoicePesananProduk/ValidasiDirektur/Index";
import ValidasiInvoicePesananProdukCokDirektur from "views/pages/penjualan/InvoicePesananProduk/ValidasiDirektur/Validasi";
import HistoriInvoicePesananProduk from "views/pages/penjualan/InvoicePesananProduk/Histori/Index";
import BuktiBayarPesananProdukPage from "views/pages/penjualan/BuktiBayar/Index";
import BBMPesananProdukPagination from "views/pages/penjualan/BuktiBayar/BuktiBayarPesananProduk/Index";
import CreateFinanceBbmPesananProduk from "views/pages/penjualan/BuktiBayar/BuktiBayarPesananProduk/Create";
import EditFinanceBBMPesananProduk from "views/pages/penjualan/BuktiBayar/BuktiBayarPesananProduk/Edit";
import ValidasiKepalaFinanceBBMPesananProdukPagination from "views/pages/penjualan/BuktiBayar/ValidasiKepalaFinance/Index";
import ValidasiFinanceBBMPesananProdukCok from "views/pages/penjualan/BuktiBayar/ValidasiKepalaFinance/Validasi";
import ValidasiDirekturBBMPesananProdukPagination from "views/pages/penjualan/BuktiBayar/ValidasiDirektur/Index";
import ValidasiFinanceBBMPesananProdukDirekturCok from "views/pages/penjualan/BuktiBayar/ValidasiDirektur/Validasi";
import HistoriBuktiBankMasukPesananProduk from "views/pages/penjualan/BuktiBayar/Histori/Index";
import ValidasiDpGambar from "views/pages/penjualan/PenjualanAplikator/ValidasiDp";
import InputLinkRab from "views/pages/penjualan/PenjualanAplikator/InputLinkRab";
import ValidasiPelunasanGambar from "views/pages/penjualan/PenjualanAplikator/ValidasiPelunasan";
import DetailPesananAplikator from "views/pages/penjualan/PenjualanAplikator/Detail";
import SuratJalanPemesananAplikator from "views/pages/penjualan/SuratJalanPesananAplikator/Index";
import SuratJalanPesananAplikatorPage from "views/pages/penjualan/SuratJalanPesananAplikator/SuratJalanPesananAplikator/Index";
import CreateSuratJalanPesananAplikator from "views/pages/penjualan/SuratJalanPesananAplikator/SuratJalanPesananAplikator/Create";
import EditSuratJalanPesananAplikator from "views/pages/penjualan/SuratJalanPesananAplikator/SuratJalanPesananAplikator/Edit";
import SuratJalanPesananAplikatorValidasiKepalaGudang from "views/pages/penjualan/SuratJalanPesananAplikator/ValidasiKepalaGudang/Index";
import ValidasiSuratJalanPesananAplikatorKepalaGudang from "views/pages/penjualan/SuratJalanPesananAplikator/ValidasiKepalaGudang/Validasi";
import HistoriSuratJalanPesananAplikatorPage from "views/pages/penjualan/SuratJalanPesananAplikator/Histori/Index";
import InvoicePesananAplikatorPage from "views/pages/penjualan/InvoicePesananAplikator/Index";
import InvoicePesananAplikatorPagination from "views/pages/penjualan/InvoicePesananAplikator/Invoice/Index";
import CreateInvoicePesananAplikator from "views/pages/penjualan/InvoicePesananAplikator/Invoice/Create";
import ValidasiKepalafinanceInvoicePesananAplikator from "views/pages/penjualan/InvoicePesananAplikator/ValidasiFinance/Index";
import ValidasiInvoicePesananAplikatorCokFinance from "views/pages/penjualan/InvoicePesananAplikator/ValidasiFinance/Validasi";
import ValidasiDirekturInvoicePesananAplikator from "views/pages/penjualan/InvoicePesananAplikator/ValidasiDirektur/Index";
import ValidasiInvoicePesananAplikatorCokDirektur from "views/pages/penjualan/InvoicePesananAplikator/ValidasiDirektur/Validasi";
import HistoriInvoicePesananAplikator from "views/pages/penjualan/InvoicePesananAplikator/Histori/Index";
import BuktiBayarPesananAplikatorPage from "views/pages/penjualan/BuktiBayarAplikator/Index";
import BBMPesananAplikatorPagination from "views/pages/penjualan/BuktiBayarAplikator/BuktiBayarPesananAplikator/Index";
import CreateFinanceBbmPesananAplikator from "views/pages/penjualan/BuktiBayarAplikator/BuktiBayarPesananAplikator/Create";
import EditFinanceBBMPesananAplikator from "views/pages/penjualan/BuktiBayarAplikator/BuktiBayarPesananAplikator/Edit";
import ValidasiKepalaFinanceBBMPesananAplikatorPagination from "views/pages/penjualan/BuktiBayarAplikator/ValidasiKepalaFinance/Index";
import ValidasiFinanceBBMPesananAplikatorCok from "views/pages/penjualan/BuktiBayarAplikator/ValidasiKepalaFinance/Validasi";
import ValidasiDirekturBBMPesananAplikatorPagination from "views/pages/penjualan/BuktiBayarAplikator/ValidasiDirektur/Index";
import ValidasiFinanceBBMPesananAplikatorDirekturCok from "views/pages/penjualan/BuktiBayarAplikator/ValidasiDirektur/Validasi";
import HistoriBuktiBankMasukPesananAplikator from "views/pages/penjualan/BuktiBayarAplikator/Histori/Index";
import OrderAddendumPage from "views/pages/penjualan/Addendum/Addendum/Index";
import InputAddendum from "views/pages/penjualan/Addendum/Addendum/InputAddendum";
import ValidasiBuktiTransferAddendum from "views/pages/penjualan/Addendum/Addendum/ValidasiBuktiBayar";
import DetailAddendum from "views/pages/penjualan/Addendum/Addendum/Detail";
import SuratJalanAddendum from "views/pages/penjualan/Addendum/SuratJalanAddendum/Index";
import SuratJalanAddendumPage from "views/pages/penjualan/Addendum/SuratJalanAddendum/SuratJalanAddendum/Index";
import EditSuratJalanAddendum from "views/pages/penjualan/Addendum/SuratJalanAddendum/SuratJalanAddendum/Edit";
import CreateSuratJalanAddendum from "views/pages/penjualan/Addendum/SuratJalanAddendum/SuratJalanAddendum/Create";
import SuratJalanAddendumValidasiKepalaGudang from "views/pages/penjualan/Addendum/SuratJalanAddendum/ValidasiKepalaGudang/Index";
import ValidasiSuratJalanAddendumKepalaGudang from "views/pages/penjualan/Addendum/SuratJalanAddendum/ValidasiKepalaGudang/Validasi";
import HistoriSuratJalanAddendumPage from "views/pages/penjualan/Addendum/SuratJalanAddendum/Histori/Index";
import InvoiceAddendumPage from "views/pages/penjualan/Addendum/InvoiceAddendum/Index";
import InvoiceAddendumPagination from "views/pages/penjualan/Addendum/InvoiceAddendum/Invoice/Index";
import CreateInvoiceAddendum from "views/pages/penjualan/Addendum/InvoiceAddendum/Invoice/Create";
import ValidasiKepalafinanceInvoiceAddendum from "views/pages/penjualan/Addendum/InvoiceAddendum/ValidasiFinance/Index";
import ValidasiInvoiceAddendumCokFinance from "views/pages/penjualan/Addendum/InvoiceAddendum/ValidasiFinance/Validasi";
import ValidasiDirekturInvoiceAddendum from "views/pages/penjualan/Addendum/InvoiceAddendum/ValidasiDIrektur/Index";
import ValidasiInvoiceAddendumCokDirektur from "views/pages/penjualan/Addendum/InvoiceAddendum/ValidasiDIrektur/Validasi";
import HistoriInvoiceAddendum from "views/pages/penjualan/Addendum/InvoiceAddendum/Histori/Index";
import BuktiBayarAddendumPage from "views/pages/penjualan/Addendum/BuktiBayarAddendum/Index";
import BBMAddendumPagination from "views/pages/penjualan/Addendum/BuktiBayarAddendum/BuktiBayar/Index";
import CreateFinanceBbmAddendum from "views/pages/penjualan/Addendum/BuktiBayarAddendum/BuktiBayar/Create";
import EditFinanceBBMAddendum from "views/pages/penjualan/Addendum/BuktiBayarAddendum/BuktiBayar/Edit";
import ValidasiKepalaFinanceBBMAddendumPagination from "views/pages/penjualan/Addendum/BuktiBayarAddendum/ValidasiKepalaFinance/Index";
import ValidasiFinanceBBMAddendumCok from "views/pages/penjualan/Addendum/BuktiBayarAddendum/ValidasiKepalaFinance/Validasi";
import ValidasiDirekturBBMAddendumPagination from "views/pages/penjualan/Addendum/BuktiBayarAddendum/ValidasiDirektur/Index";
import ValidasiFinanceBBMAddendumDirekturCok from "views/pages/penjualan/Addendum/BuktiBayarAddendum/ValidasiDirektur/Validasi";
import HistoriBuktiBankMasukAddendum from "views/pages/penjualan/Addendum/BuktiBayarAddendum/Histori/Index";

const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
    layout: "/auth",
    hidden: true,
  },
  {
    icon: "ni ni-shop text-blue",
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    layout: "/admin",
    // hidden: true,
  },
  {
    collapse: true,
    name: "Master",
    icon: "ni ni-folder-17 text-yellow",
    state: "MasterCollapse",
    roles: [
      "ROLE_SUPERADMIN",
      "ROLE_ADMIN",
      "ROLE_KARYAWAN",
      "ROLE_USER",
      "ROLE_OWNER",
    ],
    views: [
      {
        path: "/iklan/master/iklan/carousel-3/create",
        name: "Create Carousel 1",
        miniName: "",
        component: CreateCarousel3,
        hidden: true,
        layout: "/admin",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/iklan/master/iklan/carousel-3/page",
        name: "Iklan",
        miniName: "",
        component: Carousel3Pagination,
        hidden: true,
        layout: "/admin",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/iklan/master/iklan/carousel-2/create",
        name: "Create Carousel 1",
        miniName: "",
        component: CreateCarousel2,
        hidden: true,
        layout: "/admin",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/iklan/master/iklan/carousel-2/page",
        name: "Iklan",
        miniName: "",
        component: Carousel2Pagination,
        hidden: true,
        layout: "/admin",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/iklan/master/iklan/carousel-1/create",
        name: "Create Carousel 1",
        miniName: "",
        component: CreateCarousel1,
        hidden: true,
        layout: "/admin",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/iklan/master/iklan/carousel-1/page",
        name: "Iklan",
        miniName: "",
        component: Carousel1Pagination,
        hidden: true,
        layout: "/admin",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/iklan/master/iklan/create",
        name: "Create Iklan",
        miniName: "",
        component: CreateIklan,
        hidden: true,
        layout: "/admin",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/iklan/master/iklan/page",
        name: "Iklan",
        miniName: "",
        component: iklanPagination,
        hidden: true,
        layout: "/admin",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/iklan/master",
        name: "Iklan",
        miniName: "",
        component: IklanPage,
        layout: "/admin",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/master/pajak",
        name: "PPN",
        miniName: "",
        component: PajakPage,
        layout: "/admin",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/master/aplikator/edit/:id",
        name: "Edit Aplikator",
        miniName: "",
        component: EditAplikator,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/master/aplikator/create",
        name: "Create Aplikator",
        miniName: "",
        component: CreateAplikator,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/master/aplikator",
        name: "Aplikator",
        miniName: "",
        component: AplikatorPage,
        layout: "/admin",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/master/kurir/edit/:id",
        name: "Edit Kurir",
        miniName: "",
        component: EditKurir,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/master/kurir/create",
        name: "Create Kurir",
        miniName: "",
        component: CreateKurir,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/master/kurir",
        name: "Kurir",
        miniName: "",
        component: KurirPage,
        layout: "/admin",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/master/afiliasi",
        name: "Afiliasi ",
        miniName: "",
        component: AfiliasiPage,
        layout: "/admin",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/master/mgm/histori/detail/:id",
        name: "MGM ",
        miniName: "",
        component: DetailMgm,
        hidden: true,
        layout: "/admin",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/master/mgm/histori/page",
        name: "MGM ",
        miniName: "",
        component: HistoriMgmPagination,
        hidden: true,
        layout: "/admin",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/master/mgm/finance/validasi/:id",
        name: "MGM ",
        miniName: "",
        component: Validasi2MGM,
        hidden: true,
        layout: "/admin",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/master/mgm/finance/page",
        name: "MGM ",
        miniName: "",
        component: validasi1MgmPagination,
        hidden: true,
        layout: "/admin",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/master/mgm/mgm/validasi/:id",
        name: "MGM ",
        miniName: "",
        component: Validasi1MGM,
        hidden: true,
        layout: "/admin",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/master/mgm/mgm/page",
        name: "MGM ",
        miniName: "",
        component: MgmPagination,
        hidden: true,
        layout: "/admin",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/master/mgm",
        name: "Penarikan Komisi ",
        miniName: "",
        component: MGMPage,
        layout: "/admin",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/jangka-waktu/update/:id",
        name: "Update Jangka Waktu",
        miniName: "",
        component: "",
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER ",
        ],
      },
      {
        path: "/jangka-waktu/edit/:id",
        name: "Edit Jangka Waktu",
        miniName: "",
        component: EditJangkaWaktu,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/jangka-waktu/create",
        name: "Create Jangka Waktu",
        miniName: "",
        component: CreateJangkaWaktu,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/jangka-waktu",
        name: "Jatuh Tempo",
        miniName: "",
        component: MainJangka,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },     
      {
        path: "/type-asset/edit/:id",
        name: "Edit Type Asset",
        miniName: "",
        component: EditTypeAsset,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/type-asset/create",
        name: "Create Type Asset",
        miniName: "",
        component: CreateTypeAsset,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/type-asset",
        name: "Type Asset",
        miniName: "",
                component: TypeAsset,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/asset/edit/:id",
        name: "Edit Asset",
        miniName: "",
        component: EditAsset,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/asset/create",
        name: "Create Asset",
        miniName: "",
        component: CreateAsset,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/asset",
        name: "Asset",
        miniName: "",
        component: Asset,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
    ],
  },
  {
    collapse: true,
    name: "Pesanan Produk",
    icon: "ni ni-cart text-red",
    // icon: "ni ni-archive-2 text-orange",
    state: "PesananProdukCollapse",
    roles: [
      "ROLE_SUPERADMIN",
      "ROLE_ADMIN",
      "ROLE_KARYAWAN",
      "ROLE_USER",
      "ROLE_OWNER",
    ],
    views: [
      {
        path: "/penjualan/so/penjualan/histori/detail/:id",
        name: "histori Admin Cabang Pesanan",
        component: DetailHistoriPesanan,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/penjualan/so/penjualan/histori",
        name: "Validasi Penjualan Produk",
        component: HistoripenjualanProdukPagination,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/penjualan/so/penjualan/admin/pusat/validasi/:id",
        name: "Validasi Admin Cabang Pesanan",
        component: ValidasiAdminPusatPesanan,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/penjualan/so/penjualan/admin/pusat",
        name: "Validasi Penjualan Produk",
        component: ValidasipenjualanProdukPagination,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/penjualan/so/penjualan/validasi/:id",
        name: "Validasi Admin Cabang Pesanan",
        component: ValidasiAdminCabangPesanan,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/penjualan/so/penjualan/page",
        name: "Penjualan Produk",
        component: penjualanProdukPagination,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/penjualan/so/penjualan",
        name: "Pesanan Produk",
        component: PenjualanProduk,
        layout: "/admin",
        miniName: "",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      
      // {
      //   path: "/surat-jalan-ecommerce/cetak/:id",
      //   name: "Edit Penawaran So",

      //   component: CetakSuratJalan,
      //   layout: "/cetak",
      //   hidden: true,
      //   roles: [
      //     "ROLE_SUPERADMIN",
      //     "ROLE_ADMIN",
      //     "ROLE_KARYAWAN",
      //     "ROLE_USER",
      //     "ROLE_OWNER",
      //   ],
      // },
      // {
      //   path: "/surat-jalan-ecommerce/histori/detail/:id",
      //   name: "Create Penawaran So",
      //   component: DetailSuratJalanSOOOOO,
      //   layout: "/admin",
      //   hidden: true,
      //   roles: [
      //     "ROLE_SUPERADMIN",
      //     "ROLE_ADMIN",
      //     "ROLE_KARYAWAN",
      //     "ROLE_USER",
      //     "ROLE_OWNER",
      //   ],
      // },
      {
        path: "/surat-jalan-ecommerce/histori",
        name: "Create Penawaran So",
        component: HistoriSuratJalanPesananProdukPage,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/surat-jalan-ecommerce/validasi-kepala-gudang/validasi/:id",
        name: "Edit Penawaran So",

        component: ValidasiSuratJalanPesananProdukKepalaGudang,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/surat-jalan-ecommerce/validasi-kepala-gudang",
        name: "Create Penawaran So",
        component: SuratJalanPesananProdukValidasiKepalaGudang,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      // {
      //   path: "/surat-jalan-ecommerce/detail/:id",
      //   name: "Detail Penawaran So",

      //   component: DetailSuratJalanSo,
      //   layout: "/admin",
      //   hidden: true,
      //   roles: [
      //     "ROLE_SUPERADMIN",
      //     "ROLE_ADMIN",
      //     "ROLE_KARYAWAN",
      //     "ROLE_USER",
      //     "ROLE_OWNER",
      //   ],
      // },
      // {
      //   path: "/surat-jalan-ecommerce/cetak-sj/:id",
      //   name: "Edit Penawaran So",

      //   component: CetakCetakSuratJalanSo,
      //   layout: "/cetak",
      //   hidden: true,
      //   roles: [
      //     "ROLE_SUPERADMIN",
      //     "ROLE_ADMIN",
      //     "ROLE_KARYAWAN",
      //     "ROLE_USER",
      //     "ROLE_OWNER",
      //   ],
      // },
      {
        path: "/surat-jalan-ecommerce/edit/:id",
        name: "Edit Penawaran So",

        component: EditSuratJalanPesananProduk,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/surat-jalan-ecommerce/create",
        name: "Create Penawaran So",
        component: CreateSuratJalanPesananProduk,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/surat-jalan-ecommerce/page",
        name: "Create Penawaran So",
        component: SuratJalanPesananProdukPage,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/surat-jalan-ecommerce",
        name: "Surat Jalan Pesanan Produk",
        miniName: "",
        component: SuratJalanPemesananProduk,
        layout: "/admin",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      // {
      //   path: "/invoice-so/bkm/:id",
      //   name: "Validasi Receiving Po",
      //   component: BkmInvoiceSo,
      //   layout: "/admin",
      //   hidden: true,
      //   roles: [
      //     "ROLE_SUPERADMIN",
      //     "ROLE_ADMIN",
      //     "ROLE_KARYAWAN",
      //     "ROLE_USER",
      //     "ROLE_OWNER",
      //   ],
      // },
      {
        path: "/invoice-sjp/detail-invoice/:id",
        name: "Create Invoice So",

        component: "",
        layout: "/cetak",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/invoice-sjp/histori/detail/:id",
        name: "Invoice",
        miniName: "",
        hidden: true,
        component: "",
        layout: "/admin",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/invoice-sjp/histori",
        name: "Invoice",
        miniName: "",
        hidden: true,
        component: HistoriInvoicePesananProduk,
        layout: "/admin",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/invoice-sjp/validasi-direktur/validasi/:id",
        name: "Create Invoice So",

        component: ValidasiInvoicePesananProdukCokDirektur,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/invoice-sjp/validasi-direktur",
        name: "Invoice",
        miniName: "",
        component: ValidasiDirekturInvoicePesananProduk,
        hidden: true,
        layout: "/admin",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/validasi-invoice-sjp/validasi/:id",
        name: "Create Invoice So",
        component: ValidasiInvoicePesananProdukCokFinance,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/invoice-sjp/validasi-kepala-finance",
        name: "Invoice",
        miniName: "",
        component: ValidasiKepalafinanceInvoicePesananProduk,
        hidden: true,
        layout: "/admin",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/invoice-sjp/create",
        name: "Create Invoice So",
        component: CreateInvoicePesananProduk,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/invoice-sjp/page",
        name: "Invoice",
        miniName: "",
        component: InvoicePesananProdukPagination,
        hidden: true,
        layout: "/admin",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/invoice-sjp",
        name: "Faktur Pesanan Produk",
        miniName: "",
        component: InvoicePesananProdukPage,
        layout: "/admin",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bukti-bank-masuk-pesanan-produk/detail/:id",
        name: "Edit Stock Opname",
        component: "",
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bukti-bank-masuk-pesanan-produk/histori",
        name: "Edit Stock Opname",
        component: HistoriBuktiBankMasukPesananProduk,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bukti-bank-masuk-pesanan-produk/direktur/validasi/:id",
        name: "Edit Stock Opname",
        component: ValidasiFinanceBBMPesananProdukDirekturCok,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bukti-bank-masuk-pesanan-produk/direktur",
        name: "",
        component: ValidasiDirekturBBMPesananProdukPagination,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bukti-bank-masuk-pesanan-produk/kepala-finance/validasi/:id",
        name: "",
        component: ValidasiFinanceBBMPesananProdukCok,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bukti-bank-masuk-pesanan-produk/kepala-finance",
        name: "",
        component: ValidasiKepalaFinanceBBMPesananProdukPagination,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bukti-bank-masuk-pesanan-produk/edit/:id",
        name: "",
        component: EditFinanceBBMPesananProduk,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bukti-bank-masuk-pesanan-produk/create",
        name: "",
        component: CreateFinanceBbmPesananProduk,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bukti-bank-masuk-pesanan-produk/page",
        name: "",
        component: BBMPesananProdukPagination,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bukti-bank-masuk-pesanan-produk/",
        name: "BBM Pesanan Produk",
        miniName: "",
        component: BuktiBayarPesananProdukPage,
        layout: "/admin",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/so-retur/payment/:id",
        name: "Validasi Retur Kepala Toko",
        component: ValidasiCetakRetur,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/so-retur/histori/detail/:id",
        name: "Create Po Retur",
        component: DetailReturSo,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/so-retur/histori",
        name: "Create Po Retur",
        component: HistoriSoRetur,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/so-retur/validasi-direktur/:id",
        name: "Validasi Retur Kepala Toko",
        component: ValidasiDirekturSoRetur,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/so-retur/validasi-direktur",
        name: "Create Po Retur",
        component: ValidasiDirekturSoRetur1,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/so-retur/validasi-manager-office/:id",
        name: "Validasi Retur Kepala Toko",
        component: SoReturValidasiManajerOffice,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/so-retur/validasi-manajer-office",
        name: "Create Po Retur",
        component: ValidasiSoReturManajerOffice,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/so-retur/validasi-kepala-toko/:id",
        name: "Validasi Retur Kepala Toko",
        component: ValidasiReturSoKepalaToko,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/so-retur/edit/:id",
        name: "Validasi Retur Kepala Toko",
        component: EditSoKasurProyek,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/so-retur/proyek/create",
        name: "Create Po Retur",
        component: CreateSoReturProyek,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/so-retur/page",
        name: "Create Po Retur",
        component: SoReturProyekPage,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/so-retur",
        name: "Retur Penjualan",
        miniName: "",
        component: SoRetur,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      }
    ],
  },
  {
    collapse: true,
    name: "Pesanan Aplikator",
    icon: "ni ni-cart text-red",
    // icon: "ni ni-archive-2 text-orange",
    state: "PesananAplikatorCollapse",
    roles: [
      "ROLE_SUPERADMIN",
      "ROLE_ADMIN",
      "ROLE_KARYAWAN",
      "ROLE_USER",
      "ROLE_OWNER",
    ],
    views: [
      {
        path: "/pemesanan/so/aplikator/pelunasan-gambar/:id",
        name: "Update Tanggal Survey",
        component: ValidasiPelunasanGambar,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/pemesanan/so/aplikator/input-gambar-rab/:id",
        name: "Update Tanggal Survey",
        component: InputLinkRab,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/pemesanan/so/aplikator/dp-gambar/:id",
        name: "Update Tanggal Survey",
        component: ValidasiDpGambar,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/pemesanan/so/aplikator/hasil-survey/:id",
        name: "Update Tanggal Survey",
        component: UpdateHasilKunjunganCuak,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/pemesanan/so/aplikator/tanggal-survey/:id",
        name: "Update Tanggal Survey",
        component: UpdateTanggalSurvey,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/pemesanan/so/aplikator/detail/:id",
        name: "Update Tanggal Survey",
        component: DetailPesananAplikator,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/pemesanan/so/aplikator",
        name: "Order Aplikator",
        component: OrderAplikatorPage,
        layout: "/admin",
        miniName: "",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      // {
      //   path: "/surat-jalan-aplikator/cetak/:id",
      //   name: "Edit Penawaran So",

      //   component: CetakSuratJalan,
      //   layout: "/cetak",
      //   hidden: true,
      //   roles: [
      //     "ROLE_SUPERADMIN",
      //     "ROLE_ADMIN",
      //     "ROLE_KARYAWAN",
      //     "ROLE_USER",
      //     "ROLE_OWNER",
      //   ],
      // },
      // {
      //   path: "/surat-jalan-aplikator/histori/detail/:id",
      //   name: "Create Penawaran So",
      //   component: DetailSuratJalanSOOOOO,
      //   layout: "/admin",
      //   hidden: true,
      //   roles: [
      //     "ROLE_SUPERADMIN",
      //     "ROLE_ADMIN",
      //     "ROLE_KARYAWAN",
      //     "ROLE_USER",
      //     "ROLE_OWNER",
      //   ],
      // },
      {
        path: "/surat-jalan-aplikator/histori",
        name: "Create Penawaran So",
        component: HistoriSuratJalanPesananAplikatorPage,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/surat-jalan-aplikator/validasi-kepala-gudang/validasi/:id",
        name: "Edit Penawaran So",

        component: ValidasiSuratJalanPesananAplikatorKepalaGudang,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/surat-jalan-aplikator/validasi-kepala-gudang",
        name: "Create Penawaran So",
        component: SuratJalanPesananAplikatorValidasiKepalaGudang,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      // {
      //   path: "/surat-jalan-aplikator/detail/:id",
      //   name: "Detail Penawaran So",

      //   component: DetailSuratJalanSo,
      //   layout: "/admin",
      //   hidden: true,
      //   roles: [
      //     "ROLE_SUPERADMIN",
      //     "ROLE_ADMIN",
      //     "ROLE_KARYAWAN",
      //     "ROLE_USER",
      //     "ROLE_OWNER",
      //   ],
      // },
      // {
      //   path: "/surat-jalan-aplikator/cetak-sj/:id",
      //   name: "Edit Penawaran So",

      //   component: CetakCetakSuratJalanSo,
      //   layout: "/cetak",
      //   hidden: true,
      //   roles: [
      //     "ROLE_SUPERADMIN",
      //     "ROLE_ADMIN",
      //     "ROLE_KARYAWAN",
      //     "ROLE_USER",
      //     "ROLE_OWNER",
      //   ],
      // },
      {
        path: "/surat-jalan-aplikator/edit/:id",
        name: "Edit Penawaran So",

        component: EditSuratJalanPesananAplikator,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/surat-jalan-aplikator/create",
        name: "Create Penawaran So",
        component: CreateSuratJalanPesananAplikator,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/surat-jalan-aplikator/page",
        name: "Create Penawaran So",
        component: SuratJalanPesananAplikatorPage,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/surat-jalan-aplikator",
        name: "Surat Jalan Order Aplikator",
        miniName: "",
        component: SuratJalanPemesananAplikator,
        layout: "/admin",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/invoice-sjs/histori",
        name: "Invoice",
        miniName: "",
        hidden: true,
        component: HistoriInvoicePesananAplikator,
        layout: "/admin",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/invoice-sjs/validasi-direktur/validasi/:id",
        name: "Create Invoice So",

        component: ValidasiInvoicePesananAplikatorCokDirektur,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/invoice-sjs/validasi-direktur",
        name: "Invoice",
        miniName: "",
        component: ValidasiDirekturInvoicePesananAplikator,
        hidden: true,
        layout: "/admin",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/validasi-invoice-sjs/validasi/:id",
        name: "Create Invoice So",
        component: ValidasiInvoicePesananAplikatorCokFinance,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/invoice-sjs/validasi-kepala-finance",
        name: "Invoice",
        miniName: "",
        component: ValidasiKepalafinanceInvoicePesananAplikator,
        hidden: true,
        layout: "/admin",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/invoice-sjs/create",
        name: "Create Invoice So",
        component: CreateInvoicePesananAplikator,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/invoice-sjs/page",
        name: "Invoice",
        miniName: "",
        component: InvoicePesananAplikatorPagination,
        hidden: true,
        layout: "/admin",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/invoice-sjs",
        name: "Faktur Order Aplikator",
        miniName: "",
        component: InvoicePesananAplikatorPage,
        layout: "/admin",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bukti-bank-masuk-pesanan-aplikator/detail/:id",
        name: "Edit Stock Opname",
        component: "",
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bukti-bank-masuk-pesanan-aplikator/histori",
        name: "Edit Stock Opname",
        component: HistoriBuktiBankMasukPesananAplikator,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bukti-bank-masuk-pesanan-aplikator/direktur/validasi/:id",
        name: "Edit Stock Opname",
        component: ValidasiFinanceBBMPesananAplikatorDirekturCok,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bukti-bank-masuk-pesanan-aplikator/direktur",
        name: "",
        component: ValidasiDirekturBBMPesananAplikatorPagination,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bukti-bank-masuk-pesanan-aplikator/kepala-finance/validasi/:id",
        name: "",
        component: ValidasiFinanceBBMPesananAplikatorCok,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bukti-bank-masuk-pesanan-aplikator/kepala-finance",
        name: "",
        component: ValidasiKepalaFinanceBBMPesananAplikatorPagination,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bukti-bank-masuk-pesanan-aplikator/edit/:id",
        name: "",
        component: EditFinanceBBMPesananAplikator,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bukti-bank-masuk-pesanan-aplikator/create",
        name: "",
        component: CreateFinanceBbmPesananAplikator,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bukti-bank-masuk-pesanan-aplikator/page",
        name: "",
        component: BBMPesananAplikatorPagination,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bukti-bank-masuk-pesanan-aplikator/",
        name: "BBM Order Aplikator",
        miniName: "",
        component: BuktiBayarPesananAplikatorPage,
        layout: "/admin",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/so-retur/payment/:id",
        name: "Validasi Retur Kepala Toko",
        component: ValidasiCetakRetur,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/so-retur/histori/detail/:id",
        name: "Create Po Retur",
        component: DetailReturSo,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/so-retur/histori",
        name: "Create Po Retur",
        component: HistoriSoRetur,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/so-retur/validasi-direktur/:id",
        name: "Validasi Retur Kepala Toko",
        component: ValidasiDirekturSoRetur,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/so-retur/validasi-direktur",
        name: "Create Po Retur",
        component: ValidasiDirekturSoRetur1,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/so-retur/validasi-manager-office/:id",
        name: "Validasi Retur Kepala Toko",
        component: SoReturValidasiManajerOffice,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/so-retur/validasi-manajer-office",
        name: "Create Po Retur",
        component: ValidasiSoReturManajerOffice,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/so-retur/validasi-kepala-toko/:id",
        name: "Validasi Retur Kepala Toko",
        component: ValidasiReturSoKepalaToko,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/so-retur/edit/:id",
        name: "Validasi Retur Kepala Toko",
        component: EditSoKasurProyek,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/so-retur/proyek/create",
        name: "Create Po Retur",
        component: CreateSoReturProyek,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/so-retur/page",
        name: "Create Po Retur",
        component: SoReturProyekPage,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/so-retur",
        name: "Retur Penjualan",
        miniName: "",
        component: SoRetur,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/addendum/aplikator/pelunasan-gambar/:id",
        name: "Update Tanggal Survey",
        component: ValidasiPelunasanGambar,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/addendum/aplikator/input-gambar-rab/:id",
        name: "Update Tanggal Survey",
        component: InputLinkRab,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/addendum/aplikator/dp-gambar/:id",
        name: "Update Tanggal Survey",
        component: ValidasiDpGambar,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/addendum/aplikator/hasil-survey/:id",
        name: "Update Tanggal Survey",
        component: UpdateHasilKunjunganCuak,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/addendum/aplikator/validasi-transfer/:id",
        name: "Update Tanggal Survey",
        component: ValidasiBuktiTransferAddendum,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/addendum/aplikator/input-addendum/:id",
        name: "Update Tanggal Survey",
        component: InputAddendum,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/addendum/aplikator/detail/:id",
        name: "Update Tanggal Survey",
        component: DetailAddendum,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/addendum/aplikator",
        name: "Addendum",
        component: OrderAddendumPage,
        layout: "/admin",
        miniName: "",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/surat-jalan-addendum/histori",
        name: "Create Penawaran So",
        component: HistoriSuratJalanAddendumPage,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/surat-jalan-addendum/validasi-kepala-gudang/validasi/:id",
        name: "Edit Penawaran So",

        component: ValidasiSuratJalanAddendumKepalaGudang,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/surat-jalan-addendum/validasi-kepala-gudang",
        name: "Create Penawaran So",
        component: SuratJalanAddendumValidasiKepalaGudang,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      // {
      //   path: "/surat-jalan-aplikator/detail/:id",
      //   name: "Detail Penawaran So",

      //   component: DetailSuratJalanSo,
      //   layout: "/admin",
      //   hidden: true,
      //   roles: [
      //     "ROLE_SUPERADMIN",
      //     "ROLE_ADMIN",
      //     "ROLE_KARYAWAN",
      //     "ROLE_USER",
      //     "ROLE_OWNER",
      //   ],
      // },
      // {
      //   path: "/surat-jalan-aplikator/cetak-sj/:id",
      //   name: "Edit Penawaran So",

      //   component: CetakCetakSuratJalanSo,
      //   layout: "/cetak",
      //   hidden: true,
      //   roles: [
      //     "ROLE_SUPERADMIN",
      //     "ROLE_ADMIN",
      //     "ROLE_KARYAWAN",
      //     "ROLE_USER",
      //     "ROLE_OWNER",
      //   ],
      // },
      {
        path: "/surat-jalan-addendum/edit/:id",
        name: "Edit Penawaran So",

        component: EditSuratJalanAddendum,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/surat-jalan-addendum/create",
        name: "Create Penawaran So",
        component: CreateSuratJalanAddendum,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/surat-jalan-addendum/page",
        name: "Create Penawaran So",
        component: SuratJalanAddendumPage,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/surat-jalan-addendum",
        name: "Surat Jalan Addendum",
        miniName: "",
        component: SuratJalanAddendum,
        layout: "/admin",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/invoice-sjadd/histori",
        name: "Invoice",
        miniName: "",
        hidden: true,
        component: HistoriInvoiceAddendum,
        layout: "/admin",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/invoice-sjadd/validasi-direktur/validasi/:id",
        name: "Create Invoice So",

        component: ValidasiInvoiceAddendumCokDirektur,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/invoice-sjadd/validasi-direktur",
        name: "Invoice",
        miniName: "",
        component: ValidasiDirekturInvoiceAddendum,
        hidden: true,
        layout: "/admin",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/validasi-invoice-sjadd/validasi/:id",
        name: "Create Invoice So",
        component: ValidasiInvoiceAddendumCokFinance,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/invoice-sjadd/validasi-kepala-finance",
        name: "Invoice",
        miniName: "",
        component: ValidasiKepalafinanceInvoiceAddendum,
        hidden: true,
        layout: "/admin",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/invoice-sjadd/create",
        name: "Create Invoice So",
        component: CreateInvoiceAddendum,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/invoice-sjadd/page",
        name: "Invoice",
        miniName: "",
        component: InvoiceAddendumPagination,
        hidden: true,
        layout: "/admin",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/invoice-sjadd",
        name: "Faktur Addendum",
        miniName: "",
        component: InvoiceAddendumPage,
        layout: "/admin",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bukti-bank-masuk-addendum/detail/:id",
        name: "Edit Stock Opname",
        component: "",
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bukti-bank-masuk-addendum/histori",
        name: "Edit Stock Opname",
        component: HistoriBuktiBankMasukAddendum,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bukti-bank-masuk-addendum/direktur/validasi/:id",
        name: "Edit Stock Opname",
        component: ValidasiFinanceBBMAddendumDirekturCok,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bukti-bank-masuk-addendum/direktur",
        name: "",
        component: ValidasiDirekturBBMAddendumPagination,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bukti-bank-masuk-addendum/kepala-finance/validasi/:id",
        name: "",
        component: ValidasiFinanceBBMAddendumCok,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bukti-bank-masuk-addendum/kepala-finance",
        name: "",
        component: ValidasiKepalaFinanceBBMAddendumPagination,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bukti-bank-masuk-addendum/edit/:id",
        name: "",
        component: EditFinanceBBMAddendum,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bukti-bank-masuk-addendum/create",
        name: "",
        component: CreateFinanceBbmAddendum,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bukti-bank-masuk-addendum/page",
        name: "",
        component: BBMAddendumPagination,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/bukti-bank-masuk-addendum/",
        name: "BBM Addendum",
        miniName: "",
        component: BuktiBayarAddendumPage,
        layout: "/admin",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
    ],
  },
  {
    collapse: true,
    name: "HRD",
    hidden: true,
    icon: "ni ni-circle-08 text-green",
    state: "HRDCollapse",
    roles: [
      "ROLE_SUPERADMIN",
      "ROLE_ADMIN",
      "ROLE_KARYAWAN",
      "ROLE_USER",
      "ROLE_OWNER",
    ],
    views: [
      {
        path: "/karyawan/edit/gambar/:id",
        name: "Edit Warehouse Pusat",
        miniName: "",
        component: EditGambarKaryawan,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/karyawan/edit/:id",
        name: "Edit Durasi Operasional",
        miniName: "",
        component: EditKaryawan,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/karyawan/create",
        name: "Create karyawan",
        miniName: "",
        component: CreateKaryawanBaru,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/karyawan",
        name: "Daftar Karyawan ",
        miniName: "",
        component: karyawanbaru,
        layout: "/admin",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/absen",
        name: "Daftar Absen",
        miniName: "",
        component: IndexAbsensiKaryawan,
        layout: "/admin",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/employee/izin/detail/:id",
        name: "Edit User",
        component: DetailHrdIzin,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/employee/izin/validasi/:id",
        name: "Edit User",
        component: ValidasiHrdIzinValidasi,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      }, 
      {
        path: "/employee/izin",
        name: "Daftar Izin",
        miniName: "",
        component: IndexIzinHrd,
        layout: "/admin",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/hrd/dinas/detail/:id",
        name: "Edit User",
        component: DetailHrdIzinDinas,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/hrd/dinas/validasi/:id",
        name: "Edit User",
        component: ValidasiHrdIzinDinasValidasi,
        layout: "/admin",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/hrd/dinas",
        name: "Daftar Dinas",
        miniName: "",
        component: IndexDinasHrd,
        layout: "/admin",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
    ],
  },
  {
    path: "/migrasi-data/gambar",
    name: "Laporan",
    miniName: "",
    component: PageLaporanMigrasiDataGambar,
    layout: "/admin",
    hidden: true,
    roles: [
      "ROLE_SUPERADMIN",
      "ROLE_ADMIN",
      "ROLE_KARYAWAN",
      "ROLE_USER",
      "ROLE_OWNER",
    ],
  },
  {
    path: "/migrasi-data",
    name: "Migrasi Data",
    miniName: "",
    icon: "ni ni-folder-17 text-red",
    component: MigrasiData,
    layout: "/admin",
    hidden: true,
    roles: [
      "ROLE_SUPERADMIN",
      "ROLE_ADMIN",
      "ROLE_KARYAWAN",
      "ROLE_USER",
      "ROLE_OWNER",
    ],
  },
];

export default routes;
