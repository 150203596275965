/*eslint-disable*/
import React, { useEffect, useState } from "react";
import {
  
  Card,
  Button,
  Row,
  Col,
  CardBody,
  CardHeader,
  CardFooter,
  Container,
  FormGroup,
  Label,
  Input,
  
} from "reactstrap";
import { Link,useHistory } from "react-router-dom";
import axios from "axios";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import SweetAlert from "react-bootstrap-sweetalert";
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import CurrencyInput from 'react-currency-input-field';

export default function ValidasiDpGambar(props)  {
    const username = localStorage.username
    const [tanggal,setTanggal] = useState("")
    const [code, setCode] = useState("")
    const [codetr, setCodeTr] = useState("")
    const [totalbayar,setTotalBayar] = useState("")
    const [uangmuka,setUangMuka] = useState("")
    const [gambar,setGambar] = useState("")
    const [bankname,setBankName] = useState("")
    const [rekening,setRekening] = useState("")
    const [pemilik,setPemilik] = useState("")
    const [status,setStatus] = useState("")
    const [alert, setAlert] = React.useState(null);
    const token = localStorage.token;
    let history = useHistory();


  useEffect(() => {
    getByIdss();
  }, []);

  const getByIdss = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/order-aplikator/payment/${props.match.params.id}`,
        { headers }
      )
      .then((data) => {
        setTanggal(data.data.response.transaction_date)
        setCode(data.data.response.transaction_code)
        setTotalBayar(data.data.response.transaction_total)
        setUangMuka(data.data.response.transaction_um)
        setGambar(data.data.response.bukti_um)
        setBankName(data.data.response.bank_name)
        setRekening(data.data.response.account_number)
        setPemilik(data.data.response.account_name)
        setCodeTr(data.data.response.id)
        // getItemDataSaved();
        // getKurirList(data.data.response.kurir_id)
        // setTotalQty(data.data.response.price_total);
        // setTypeReq(data.data.response.type);
        // setPPN(data.data.response.persen_pajak);
        // setAddress(data.data.response.address_id)
        // setBerat(data.data.response.berat)
        // setBukti(data.data.response.bukti)
        // setCodeTransaction(data.data.response.code_transaction)
        // setJarak(data.data.response.jarak)
        // setKeterangan(data.data.response.keterangan)
        // setManualAddress(data.data.response.manual_address)
        // setNominalPajak(data.data.response.nominal_pajak)
        // setOngkir(data.data.response.ongkir)
        // setPajakId(data.data.response.pajak_id)
        // setPartialCode(data.data.response.partial_code)
        // setPaymentTotal(data.data.response.payment_total)
        // setPengiriman(data.data.response.pengiriman)
        // setPersenPajak(data.data.response.persen_pajak)
        // setPic1Name(data.data.response.pic1_name)
        // setPic1State(data.data.response.pic1_state)
        // setPic2Name(data.data.response.pic2_name)
        // setStatus(data.data.response.pic2_state)
        // setPriceTotal(data.data.response.price_total)
        // setQtyTotal(data.data.response.qty_total)
        // setScCode(data.data.response.sc_code)
        // setScCounter(data.data.response.sc_counter)
        // setTransactionDate(data.data.response.transaction_date)
        // setType(data.data.response.type)
        // setUserCode(data.data.response.user_code)
        // setWarehouseId(data.data.response.warehouse_id)
       
      })
      .catch(function (error) {
        console.log(error);
      });
  };


  function EditData() {
    let data = {
        active_flag : status

    };
    axios
        .post(
          `${process.env.REACT_APP_API_BASE_URL}/transaction/validasi-bukti-um/${codetr}`,
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(function (response) {
          setSuccessAlert(response.data.message);
          setTimeout(() => (history.push("/admin/pemesanan/so/aplikator")), 1000);
        })
        .catch(function (error) {
              setGagalAlert(error.response.data.message);
        });
  }

  const setSuccessAlert = (id) => {
    setAlert(
      <SweetAlert
        success
        showConfirm
        confirmBtnText="Ok"
        title={id}
        onCancel={hideAlert}
        onConfirm={hideAlert}
      />
    )
  }

const setGagalAlert = (id) => {
setAlert(
  <SweetAlert
  danger
  showConfirm
  confirmBtnText="Ok"
  title={id}
  onCancel={hideAlert}
  onConfirm={hideAlert}
  />
);
};

const setQuestionAlert = () => {
setAlert(
  <SweetAlert
  warning
  showCancel
  confirmBtnText="Lanjutkan"
  confirmBtnBsStyle="danger"
  title="Apakah Kamu Yakin?"
  onConfirm={EditData}
  onCancel={hideAlert}
  focusCancelBtn
  />
);
};

  const hideAlert = () => {
    setAlert(null);
  };


const formatRupiah = (money) => {
  return new Intl.NumberFormat('id-ID',
      { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }
  ).format(money);
}  
  

  return (
    <>
    {alert}
    <SimpleHeader name="Validasi Dp Klien" parentName="SO" />
    <Container className="mt--6" fluid>
        <Row>
        <div className="col">
            <CardBody>
            <Row md="12">
                <Col md="6">
                    <Card className="bg-secondary shadow">
                        <CardHeader className="bg-white border-0">
                        <h3>Validasi Dp Klien</h3>
                        </CardHeader>
                        <CardBody>
                            
                                <FormGroup row>
                                    <Label
                                    className="form-control-label"
                                    for="exampleEmail"
                                    sm={3}
                                    >
                                    Kode
                                    </Label>
                                    <Col md="6">
                                        <Input
                                        disabled
                                            className="form-control-alternative"
                                            type="text"
                                            name="desc"
                                            placeholder="Masukan Keterangan"
                                            value={code}
                                            onChange={(e) => {
                                            setCode(e.target.value);
                                            }}
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label
                                    className="form-control-label"
                                    for="exampleEmail"
                                    sm={3}
                                    >
                                    Tanggal
                                    </Label>
                                    <Col md="6">
                                        <Input
                                        disabled
                                            className="form-control-alternative"
                                            type="text"
                                            name="desc"
                                            placeholder="Masukan Keterangan"
                                            value={tanggal}
                                            onChange={(e) => {
                                            setTanggal(e.target.value);
                                            }}
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                <Label
                                className="form-control-label"
                                    for="exampleEmail"
                                    sm={3}
                                >
                                    Bank
                                </Label>
                                <Col sm={6}>
                                    <Input
                                    disabled
                                    className="form-control-alternative"
                                    type="text"
                                    name="keterangan"
                                    placeholder="Masukan Alamat"
                                    value={bankname}
                                    onChange={(e) => {
                                        setBankName(e.target.value);
                                    }}
                                    />
                                </Col>
                                </FormGroup>
                                <FormGroup row>
                                <Label
                                className="form-control-label" for="exampleEmail" sm={3}>
                                    Rekening
                                </Label>
                                <Col sm={6}>
                                    <Input
                                    disabled
                                    className="form-control-alternative"
                                        type="text"
                                        value={rekening}
                                        onChange={(e) => {
                                        setRekening(e.target.value);
                                        }}>
                                    
                                    </Input>
                                </Col>
                                </FormGroup>
                                <FormGroup row>
                                <Label
                                className="form-control-label"
                                    for="exampleEmail"
                                    sm={3}
                                >
                                    Pemilik
                                </Label>
                                <Col sm={6}>
                                    <Input
                                    disabled
                                    className="form-control-alternative"
                                    type="text"
                                    name="desc"
                                    placeholder="Masukan Ongkir"
                                    value={pemilik}
                                    onChange={(e) => {
                                        setPemilik(e.target.value);
                                    }}
                                    />
                                </Col>                             
                                </FormGroup>
                                <FormGroup row>
                                <Label
                                className="form-control-label"
                                    for="exampleEmail"
                                    sm={3}
                                >
                                    Dp
                                </Label>
                                <Col sm={6}>
                                     <CurrencyInput
                                     disabled
                                        id="validation-example-2-field"
                                        placeholder="Rp0"
                                        allowDecimals={false}
                                        value={uangmuka}
                                        className={`form-control`}
                                        prefix={'Rp'}
                                        step={10}
                                    />
                                </Col>                             
                                </FormGroup>
                                <FormGroup row>
                                <Label
                                className="form-control-label"
                                    for="exampleEmail"
                                    sm={3}
                                >
                                    Total Tagihan
                                </Label>
                                <Col sm={6}>
                                      <CurrencyInput
                                      disabled
                                        id="validation-example-2-field"
                                        placeholder="Rp0"
                                        allowDecimals={false}
                                        value={totalbayar}
                                        className={`form-control`}
                                        prefix={'Rp'}
                                        step={10}
                                    />
                                </Col>                             
                                </FormGroup>
                        </CardBody>
                        <CardFooter>
                            <Link className="btn btn-info" to="/admin/pemesanan/so/aplikator">
                                Kembali
                            </Link>
                            <Button color="danger" onClick={()=> setQuestionAlert()}>
                                Simpan
                            </Button>
                        </CardFooter>
                    </Card>
                </Col>
                <Col md="6">
                    <Card className="bg-secondary shadow">
                        <CardHeader className="bg-white border-0">
                        <h3>Bukti Transfer DP</h3>
                        </CardHeader>
                        <CardBody>
                                {/* <FormGroup row>
                                    <Label
                                    className="form-control-label" for="exampleEmail" sm={3}>
                                    </Label>
                                    <Col sm={2}>
                                    {gambar === "" ?(
                                        <img
                                        alt="..."
                                        src={"https://i.ibb.co/j5qfxy8/NO-IMAGE-AVAILABLE-2.png"}
                                        width="100%"
                                        />
                                    ):(
                                    <img
                                        alt=""
                                        src={gambar}
                                        width="100%"
                                    />
                                    )}
                                    </Col>
                                </FormGroup> */}
                                <div align="center">
                                        {gambar === "" ?(
                                            <img
                                            alt="..."
                                            src={"https://i.ibb.co/j5qfxy8/NO-IMAGE-AVAILABLE-2.png"}
                                            width="45%"
                                            align="center"
                                            />
                                        ):(
                                            <Zoom>
                                                <img
                                                    alt=""
                                                    src={gambar}
                                                    width="45%"
                                                    align="center"
                                                />
                                            </Zoom>
                                        )}
                                    </div>
                                    <br></br>
                                    <FormGroup row>
                                <Col
                                    for="exampleEmail"
                                    sm={3}
                                >
                                    <Label 
                                    className="form-control-label">Validasi</Label>
                                    
                                </Col>
                                    <Col sm={6}>
                                    <div style={{ display: "flex" }}>
                                        <div className="custom-control custom-radio mb-3">
                                        <Input
                                            className="custom-control-input"
                                            id="customRadio12"
                                            name="custom-radio-5"
                                            type="radio"
                                            value={3}
                                            checked={status === 3}
                                            onChange={() => setStatus(3)}
                                        />
                                        <Label
                                            className="custom-control-label"
                                            htmlFor="customRadio12"
                                        >
                                        </Label>
                                        <b>SESUAI</b>
                                        </div>
                                        <div
                                        className="custom-control custom-radio mb-3"
                                        style={{ marginLeft: "20px" }}
                                        >
                                        <Input
                                            className="custom-control-input"
                                            id="customRadio13"
                                            name="custom-radio-5"
                                            type="radio"
                                            value={4}
                                            checked={status === 4}
                                            onChange={() => setStatus(4)}
                                        />
                                        <Label
                                            className="custom-control-label"
                                            htmlFor="customRadio13"
                                        >
                                        </Label>
                                        <b>TIDAK SESUAI</b>
                                        </div>
                                    </div>
                                    </Col>
                                </FormGroup>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
              {/* <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                      <h3>Item</h3>
                    </CardHeader>
                    <CardBody>
                        </CardBody>
                
              </Card> */}
            </CardBody>
          </div>
        </Row>
    </Container>
    </>
  );
}