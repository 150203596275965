/*eslint-disable*/
import React from "react";
// reactstrap components
import { Button, Card, CardBody, Container, Row, Col } from "reactstrap";


function Halaman404() {

	const ButtonKeluarCok = async () => {window.location.href = "/admin/dashboard"};

	return (
		<div>
            <Container className="mt--7 pb-10 login-container">
                <Card>
                    <Row md="8">
                        <Col lg="5" md="3">
                            <CardBody>
                            <br></br><br></br>
                            <div className="text-left d-none d-lg-block" style={{
                                      fontSize: 43,
                                      resize: "none"
                                    }} >     
                                <b>Oops!<br></br></b>
                            </div>
                            <div className="text-left d-none d-lg-block" style={{
                                      fontSize: 28,
                                      resize: "none"
                                    }} >    
                            <b>Halaman Yang Anda Cari Tidak Di Temukan</b>
                            </div>
                            <div className="text-center d-lg-none d-xl-none" style={{
                                      fontSize: 40,
                                      resize: "none"
                                    }} >     
                                <b>Oops!<br></br></b>
                            </div>
                            <div className="text-center d-lg-none d-xl-none" style={{
                                      fontSize: 25,
                                      resize: "none"
                                    }} >    
                            <b>Halaman Yang Anda Cari Tidak Di Temukan</b>
                            </div>
                            <div className="text-left d-none d-lg-block">
                                <br></br>
                                <br></br>
                                <br></br>
                                <br></br>
                                <br></br>
                                <Button style={{
                                    fontSize: 26,
                                // borderRadius: "20%",
                                }}
                                color="info" onClick={ButtonKeluarCok}>
                                    Kembali ke Dashboard
                                </Button>
                            </div>
                        </CardBody>
                        </Col>
                        <Col lg="7" md="4">
                            <div className="text-right">
                                <img alt="" style={{ width: "100%" }} src={require("assets/img/theme/404-2.png").default} />
                            </div>
                            <div className="text-center d-lg-none d-xl-none">
                            <CardBody>
                                <Button color="info" onClick={ButtonKeluarCok}>
                                    Kembali
                                </Button>
                            </CardBody>
                            </div>
                        </Col>
                    </Row>
                </Card>
            </Container>
		</div>
	);
}

export default Halaman404;