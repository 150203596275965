/*eslint-disable*/
import React from "react";
import {
  Card,
  CardBody,
  Row,
  Container,
  CardImg,
} from "reactstrap";
import { Link  } from "react-router-dom";
import SimpleHeader from "components/Headers/SimpleHeader.js";

export default function BuktiBayarPesananAplikatorPage() {
   
 return (
    <>
      <SimpleHeader name="BBM Pesanan Aplikator" parentName="Report" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
                <CardBody>
                    <Row>
                        <div className="card-columns">
                            <Card>
                                <Link to="/admin/bukti-bank-masuk-pesanan-aplikator/page">
                                    <CardImg
                                        alt="..."
                                        src={require("assets/img/theme/bbm-penjualan.png").default}
                                        top
                                    />
                                </Link>
                            </Card>
                            <Card>
                                <Link to="/admin/bukti-bank-masuk-pesanan-aplikator/kepala-finance">
                                    <CardImg
                                        alt="..."
                                        src={require("assets/img/theme/validasi-kepala-finance.png").default}
                                        top
                                    />
                                </Link>
                            </Card>
                            <Card>
                                <Link to="/admin/bukti-bank-masuk-pesanan-aplikator/direktur">
                                    <CardImg
                                        alt="..."
                                        src={require("assets/img/theme/validasi-direktur.png").default}
                                        top
                                    />
                                </Link>
                            </Card>
                            <Card>
                                <Link to="/admin/bukti-bank-masuk-pesanan-aplikator/histori">
                                    <CardImg
                                        alt="..."
                                        src={require("assets/img/theme/history.png").default}
                                        top
                                    />
                                </Link>
                            </Card>
                        </div>
					</Row>
                </CardBody>
          </div>
        </Row>
      </Container>
    </>
  );
}