/*eslint-disable*/
import React, { useEffect, useState } from "react";
import {
  Card,
  Button,
  Row,
  Col,
  CardBody,
  CardHeader,
  CardFooter,
  FormGroup,
  Label,
  Input,
  Container,
} from "reactstrap";

import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import SweetAlert from "react-bootstrap-sweetalert";
import CurrencyInput from 'react-currency-input-field';
import ItemGambar1 from "components/SelectedFile/itemgambar1";

export default function InputLinkRab(props) {
  const token = localStorage.token;
  const username = localStorage.username;
  const history = useHistory();
  const [link, setLink] = useState("");
  const [durasipengerjaan, setDurasiPengerjaan] = useState("")
  const [alert, setAlert] = React.useState(null);

  const EditData = () => {
        const headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        };
		let data = {
            link_akses : link,
            durasi_pengerjaan : durasipengerjaan
        }
		axios
			.post(`${process.env.REACT_APP_API_BASE_URL}/order-aplikator/update-akses-gambar/${props.match.params.id}`, data, { headers })
			.then(function (response) {
				setSuccessAlert(response.data.message);
                setTimeout(() => (history.push("/admin/pemesanan/so/aplikator")), 1000);

				})
				.catch(function (error) {
				  setGagalAlert(error.response.data.message);
				});
		  }

  const setSuccessAlert = (id) => {
    setAlert(
      <SweetAlert
        success
        showConfirm
        confirmBtnText="Ok"
        title={id}
        onCancel={hideAlert}
        onConfirm={hideAlert}
      />
    )
  }

  const setGagalAlert = (id) => {
    setAlert(
      <SweetAlert
        danger
        showConfirm
        confirmBtnText="Ok"
        title={id}
        onCancel={hideAlert}
        onConfirm={hideAlert}
      />
    );
  };

  const setQuestionAlert = () => {
    setAlert(
      <SweetAlert
        warning
        showCancel
        confirmBtnText="Lanjutkan"
        confirmBtnBsStyle="danger"
        title="Apakah Kamu Yakin?"
        onConfirm={EditData}
        onCancel={hideAlert}
        focusCancelBtn
      />
    );
  };

  const hideAlert = () => {
    setAlert(null);
  }

  return (
    <div>
      {alert}
      <SimpleHeader name="Kirim Link RAB" parentName="Master" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <CardBody>
              <Card className="bg-secondary shadow">
                  <CardHeader className="bg-white border-0">
                    <h3>Kirim Link RAB</h3>
                  </CardHeader>
                  <CardBody>
                    <Row md="12">
                      <Col md="6">
                        <FormGroup row>
                          <Label
                          className="form-control-label"
                            for="exampleEmail"
                            sm={4}
                          >
                            Link Rab
                          </Label>
                          <Col sm={7}>
                            <Input
                              autoComplete="off"
                              className="form-control-alternative"
                              type="text"
                              name="Nama"
                              placeholder="Masukan Link RAB"
                              value={link}
                              onChange={(e) => {
                                setLink(e.target.value);
                              }}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label
                          className="form-control-label"
                            for="exampleEmail"
                            sm={4}
                          >
                            Durasi Pengerjaan
                          </Label>
                          <Col sm={7}>
                            <Input
                              autoComplete="off"
                              className="form-control-alternative"
                              type="number"
                              name="Nama"
                              placeholder="Masukan Durasi Pengerjaan "
                              value={durasipengerjaan}
                              onChange={(e) => {
                                setDurasiPengerjaan(e.target.value);
                              }}
                            />
                          </Col>
                        </FormGroup>
                      </Col>
                      <Col md="6">
                      </Col>
                    </Row>
                  </CardBody>
                  <CardFooter>
                    <Link className="btn btn-info" to="/admin/pemesanan/so/aplikator">
                      Kembali
                    </Link>
                    <Button color="danger" onClick={setQuestionAlert}>
                      Simpan
                    </Button>
                  </CardFooter>
              </Card>
            </CardBody>
          </div>
        </Row>
      </Container>
    </div>

  );
};
