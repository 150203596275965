/*eslint-disable*/
import React, { useEffect, useState } from "react";
import {
  
  Card,
  Button,
  Row,
  Col,
  CardBody,
  CardHeader,
  CardFooter,
  Container,
  FormGroup,
  Label,
  Input,
  
} from "reactstrap";
import { Link,useHistory } from "react-router-dom";
import axios from "axios";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import SweetAlert from "react-bootstrap-sweetalert";
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import CurrencyInput from 'react-currency-input-field';

export default function DetailPesananAplikator(props)  {
    const username = localStorage.username
    const [tanggal,setTanggal] = useState("")
    const [code, setCode] = useState("")
    const [codetr, setCodeTr] = useState("")
    const [totalbayar,setTotalBayar] = useState("")
    const [uangmuka,setUangMuka] = useState("")
    const [gambar,setGambar] = useState("")
    const [gambar1,setGambar1] = useState("")
    const [bankname,setBankName] = useState("")
    const [rekening,setRekening] = useState("")
    const [pemilik,setPemilik] = useState("")
    const [status,setStatus] = useState("")
    const [alert, setAlert] = React.useState(null);
    const token = localStorage.token;
    let history = useHistory();

    const [activeflag,setActiveFlag] = useState("")
    const [addressid,setAddressId] = useState("")
    const [akunakses,setAkunAkses] = useState("")
    const [berat,setBerat] = useState("")
    const [codeaplikator,setCodeAplikator] = useState("")
    const [codesc,setCodeSc] = useState("")
    const [codetransaction,setCodeTransaction] = useState("")
    const [durasipengerjaan,setDurasiPengerjaan] = useState("")
    const [filldate,setFillDate] = useState("")
    const [gvdate,setGvDate] = useState("")
    const [ gvstate,setGvState] = useState("")
    const [ hasilsurvey,setHasilSurvey] = useState("")
    const [id,setId] = useState("")
    const [jarak,setJarak] = useState("")
    const [ kuririd,setKurirId] = useState("")
    const [linkakses,setLinkAkses] = useState("")
    const [manualaddress,setManualAddress] = useState("")
    const [nominalpajak,setNominalPajak] = useState("")
    const [oacode,setOaCode] = useState("")
    const [oacounter,setOaCounter] = useState("")
    const [oafile,setOaFile] = useState("")
    const [ongkir,setOngkir] = useState("")
    const [partialcode,setPartialCode] = useState("")
    const [paymenttotal,setPaymentTotal] = useState("")
    const [ pengiriman,setPengeriman] = useState("")
    const [persenpajak,setPersenPajak] = useState("")
    const [pricetotal,setPriceTotal] = useState("")
    const [qtytotal,setQtyTotal] = useState("")
    const [satuan,setSatuan] = useState("")
    const [ surveyor,setSurveyor] = useState("")
    const [tglsurvey,setTglSurvey] = useState("")
    const [transactiondate,setTransactionDate] = useState("")
    const [ukdate,setUkDate] = useState("")
    const [ ukstate,setUkState] = useState("")
    const [ upahgambar,setUpahGambar] = useState("")
    const [upahjasa,setUpahJasa] = useState("")
    const [upahkunjungan,setUpahKunjungan] = useState("")
    const [usercode,setUserCode] = useState("")
    const [ uvdate,setUvDate] = useState("")
    const [ uvstate,setUvState] = useState("")
    const [warehouseid,setWarehouseId] = useState("")


  useEffect(() => {
    getByIdss();
    getByIdsss();
  }, []);

  const getByIdsss = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/order-aplikator/payment/${props.match.params.id}`,
        { headers }
      )
      .then((data) => {
        setTanggal(data.data.response.transaction_date)
        setCode(data.data.response.transaction_code)
        setTotalBayar(data.data.response.transaction_total)
        setUangMuka(data.data.response.transaction_um)
        setGambar(data.data.response.bukti_um)
        setGambar1(data.data.response.bukti)
        setBankName(data.data.response.bank_name)
        setRekening(data.data.response.account_number)
        setPemilik(data.data.response.account_name)
        setCodeTr(data.data.response.id)
       
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getByIdss = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/order-aplikator/get/${props.match.params.id}`,
        { headers }
      )
      .then((data) => {
        setActiveFlag(data.data.response.active_flag)
        setAddressId(data.data.response.address_id)
        setAkunAkses(data.data.response.akun_akses)
        setBerat(data.data.response.berat)
        setCodeAplikator(data.data.response.code_aplikator)
        setCodeSc(data.data.response.code_sc)
        setCodeTransaction(data.data.response.code_transaction)
        setDurasiPengerjaan(data.data.response.durasi_pengerjaan)
        setFillDate(data.data.response.fill_date)
        setGvDate(data.data.response.gv_date)
        setGvState(data.data.response.gv_state)
        setHasilSurvey(data.data.response.hasil_survey)
        setId(data.data.response.id)
        setJarak(data.data.response.jarak)
        setKurirId(data.data.response.kurir_id)
        setLinkAkses(data.data.response.link_akses)
        setManualAddress(data.data.response.manual_address)
        setNominalPajak(data.data.response.nominal_pajak)
        setOaCode(data.data.response.oa_code)
        setOaCounter(data.data.response.oa_counter)
        setOaFile(data.data.response.oa_file)
        setOngkir(data.data.response.ongkir)
        setPartialCode(data.data.response.partial_code)
        setPaymentTotal(data.data.response.payment_total)
        setPengeriman(data.data.response.pengiriman)
        setPersenPajak(data.data.response.persen_pajak)
        setPriceTotal(data.data.response.price_total)
        setSatuan(data.data.response.satuan)
        setSurveyor(data.data.response.surveyor)
        setTglSurvey(data.data.response.tgl_survey)
        setTransactionDate(data.data.response.transaction_date)
        setUkDate(data.data.response.uk_date)
        setUkState(data.data.response.uk_state)
        setUpahGambar(data.data.response.upah_gambar)
        setUpahJasa(data.data.response.upah_jasa)
        setUpahKunjungan(data.data.response.upah_kunjungan)
        setUserCode(data.data.response.user_code)
        setUvDate(data.data.response.uv_date)
        setUvState(data.data.response.uv_state)
        setWarehouseId(data.data.response.warehouse_id)
        // setTanggal(data.data.response.transaction_date)
        // setCode(data.data.response.transaction_code)
        // setTotalBayar(data.data.response.transaction_total)
        // setUangMuka(data.data.response.transaction_um)
        // setGambar(data.data.response.bukti_um)
        // setBankName(data.data.response.bank_name)
        // setRekening(data.data.response.account_number)
        // setPemilik(data.data.response.account_name)
        // setCodeTr(data.data.response.id)
        // getItemDataSaved();
        // getKurirList(data.data.response.kurir_id)
        // setTotalQty(data.data.response.price_total);
        // setTypeReq(data.data.response.type);
        // setPPN(data.data.response.persen_pajak);
        // setAddress(data.data.response.address_id)
        // setBerat(data.data.response.berat)
        // setBukti(data.data.response.bukti)
        // setCodeTransaction(data.data.response.code_transaction)
        // setJarak(data.data.response.jarak)
        // setKeterangan(data.data.response.keterangan)
        // setManualAddress(data.data.response.manual_address)
        // setNominalPajak(data.data.response.nominal_pajak)
        // setOngkir(data.data.response.ongkir)
        // setPajakId(data.data.response.pajak_id)
        // setPartialCode(data.data.response.partial_code)
        // setPaymentTotal(data.data.response.payment_total)
        // setPengiriman(data.data.response.pengiriman)
        // setPersenPajak(data.data.response.persen_pajak)
        // setPic1Name(data.data.response.pic1_name)
        // setPic1State(data.data.response.pic1_state)
        // setPic2Name(data.data.response.pic2_name)
        // setStatus(data.data.response.pic2_state)
        // setPriceTotal(data.data.response.price_total)
        // setQtyTotal(data.data.response.qty_total)
        // setScCode(data.data.response.sc_code)
        // setScCounter(data.data.response.sc_counter)
        // setTransactionDate(data.data.response.transaction_date)
        // setType(data.data.response.type)
        // setUserCode(data.data.response.user_code)
        // setWarehouseId(data.data.response.warehouse_id)
       
      })
      .catch(function (error) {
        console.log(error);
      });
  };


  const setSuccessAlert = (id) => {
    setAlert(
      <SweetAlert
        success
        showConfirm
        confirmBtnText="Ok"
        title={id}
        onCancel={hideAlert}
        onConfirm={hideAlert}
      />
    )
  }

const setGagalAlert = (id) => {
setAlert(
  <SweetAlert
  danger
  showConfirm
  confirmBtnText="Ok"
  title={id}
  onCancel={hideAlert}
  onConfirm={hideAlert}
  />
);
};

const setQuestionAlert = () => {
setAlert(
  <SweetAlert
  warning
  showCancel
  confirmBtnText="Lanjutkan"
  confirmBtnBsStyle="danger"
  title="Apakah Kamu Yakin?"
  onConfirm={EditData}
  onCancel={hideAlert}
  focusCancelBtn
  />
);
};

  const hideAlert = () => {
    setAlert(null);
  };


const formatRupiah = (money) => {
  return new Intl.NumberFormat('id-ID',
      { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }
  ).format(money);
}  
  

  return (
    <>
    {alert}
    <SimpleHeader name="Detail" parentName="SO" />
    <Container className="mt--6" fluid>
        <Row>
        <div className="col">
            <CardBody>
            {/* <Row md="12">
                <Col md="6">
                    <Card className="bg-secondary shadow">
                        <CardHeader className="bg-white border-0">
                        <h3>Detail</h3>
                        </CardHeader>
                        <CardBody>
                            
                                <FormGroup row>
                                    <Label
                                    className="form-control-label"
                                    for="exampleEmail"
                                    sm={3}
                                    >
                                    Kode
                                    </Label>
                                    <Col md="6">
                                        <Input
                                        disabled
                                            className="form-control-alternative"
                                            type="text"
                                            name="desc"
                                            placeholder="Masukan Keterangan"
                                            value={code}
                                            onChange={(e) => {
                                            setCode(e.target.value);
                                            }}
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label
                                    className="form-control-label"
                                    for="exampleEmail"
                                    sm={3}
                                    >
                                    Tanggal
                                    </Label>
                                    <Col md="6">
                                        <Input
                                        disabled
                                            className="form-control-alternative"
                                            type="text"
                                            name="desc"
                                            placeholder="Masukan Keterangan"
                                            value={tanggal}
                                            onChange={(e) => {
                                            setTanggal(e.target.value);
                                            }}
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                <Label
                                className="form-control-label"
                                    for="exampleEmail"
                                    sm={3}
                                >
                                    Bank
                                </Label>
                                <Col sm={6}>
                                    <Input
                                    disabled
                                    className="form-control-alternative"
                                    type="text"
                                    name="keterangan"
                                    placeholder="Masukan Alamat"
                                    value={bankname}
                                    onChange={(e) => {
                                        setBankName(e.target.value);
                                    }}
                                    />
                                </Col>
                                </FormGroup>
                                <FormGroup row>
                                <Label
                                className="form-control-label" for="exampleEmail" sm={3}>
                                    Rekening
                                </Label>
                                <Col sm={6}>
                                    <Input
                                    disabled
                                    className="form-control-alternative"
                                        type="text"
                                        value={rekening}
                                        onChange={(e) => {
                                        setRekening(e.target.value);
                                        }}>
                                    
                                    </Input>
                                </Col>
                                </FormGroup>
                                <FormGroup row>
                                <Label
                                className="form-control-label"
                                    for="exampleEmail"
                                    sm={3}
                                >
                                    Pemilik
                                </Label>
                                <Col sm={6}>
                                    <Input
                                    disabled
                                    className="form-control-alternative"
                                    type="text"
                                    name="desc"
                                    placeholder="Masukan Ongkir"
                                    value={pemilik}
                                    onChange={(e) => {
                                        setPemilik(e.target.value);
                                    }}
                                    />
                                </Col>                             
                                </FormGroup>
                                <FormGroup row>
                                <Label
                                className="form-control-label"
                                    for="exampleEmail"
                                    sm={3}
                                >
                                    Dp
                                </Label>
                                <Col sm={6}>
                                     <CurrencyInput
                                     disabled
                                        id="validation-example-2-field"
                                        placeholder="Rp0"
                                        allowDecimals={false}
                                        value={uangmuka}
                                        className={`form-control`}
                                        prefix={'Rp'}
                                        step={10}
                                    />
                                </Col>                             
                                </FormGroup>
                                <FormGroup row>
                                <Label
                                className="form-control-label"
                                    for="exampleEmail"
                                    sm={3}
                                >
                                    Total Tagihan
                                </Label>
                                <Col sm={6}>
                                      <CurrencyInput
                                      disabled
                                        id="validation-example-2-field"
                                        placeholder="Rp0"
                                        allowDecimals={false}
                                        value={totalbayar}
                                        className={`form-control`}
                                        prefix={'Rp'}
                                        step={10}
                                    />
                                </Col>                             
                                </FormGroup>
                        </CardBody>
                    </Card>
                </Col>
                <Col md="6">
                    <Card className="bg-secondary shadow">
                        <CardHeader className="bg-white border-0">
                        <h3>Bukti Transfer DP</h3>
                        </CardHeader>
                        <CardBody>
                                <div align="center">
                                        {gambar === "" ?(
                                            <img
                                            alt="..."
                                            src={"https://i.ibb.co/j5qfxy8/NO-IMAGE-AVAILABLE-2.png"}
                                            width="45%"
                                            align="center"
                                            />
                                        ):(
                                            <Zoom>
                                                <img
                                                    alt=""
                                                    src={gambar}
                                                    width="45%"
                                                    align="center"
                                                />
                                            </Zoom>
                                        )}
                                    </div>
                                    <br></br>
                                    <FormGroup row>
                                <Col
                                    for="exampleEmail"
                                    sm={3}
                                >
                                    Validasi
                                </Col>
                                    <Col sm={6}>
                                    <div style={{ display: "flex" }}>
                                        <div className="custom-control custom-radio mb-3">
                                        <Input
                                            className="custom-control-input"
                                            id="customRadio12"
                                            name="custom-radio-5"
                                            type="radio"
                                            value={3}
                                            checked={status === 3}
                                            onChange={() => setStatus(3)}
                                        />
                                        <Label
                                        className="form-control-label"
                                            className="custom-control-label"
                                            htmlFor="customRadio12"
                                        >
                                        </Label>
                                        Sesuai
                                        </div>
                                        <div
                                        className="custom-control custom-radio mb-3"
                                        style={{ marginLeft: "20px" }}
                                        >
                                        <Input
                                            className="custom-control-input"
                                            id="customRadio13"
                                            name="custom-radio-5"
                                            type="radio"
                                            value={4}
                                            checked={status === 4}
                                            onChange={() => setStatus(4)}
                                        />
                                        <Label
                                        className="form-control-label"
                                            className="custom-control-label"
                                            htmlFor="customRadio13"
                                        >
                                        </Label>
                                        Tidak Sesuai
                                        </div>
                                    </div>
                                    </Col>
                                </FormGroup>
                        </CardBody>
                    </Card>
                </Col>
            </Row> */}
             <Card className="bg-secondary shadow">
                        <CardHeader className="bg-white border-0">
                        <h3>Transaksi</h3>
                        </CardHeader>
                        <CardBody>
                            <FormGroup row>
                                <Label
                                className="form-control-label"
                                for="exampleEmail"
                                sm={3}
                                >
                                Kode Transaksi
                                </Label>
                                <Col md="6">
                                    <Input
                                    disabled
                                        className="form-control-alternative"
                                        type="text"
                                        name="desc"
                                        placeholder="Masukan Keterangan"
                                        value={codetransaction}
                                        // onChange={(e) => {
                                        // setCode(e.target.value);
                                        // }}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label
                                className="form-control-label"
                                for="exampleEmail"
                                sm={3}
                                >
                                Tanggal
                                </Label>
                                <Col md="6">
                                    <Input
                                    disabled
                                        className="form-control-alternative"
                                        type="text"
                                        name="desc"
                                        placeholder="Masukan Keterangan"
                                        value={transactiondate}
                                        // onChange={(e) => {
                                        // setTanggal(e.target.value);
                                        // }}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label
                                className="form-control-label"
                                for="exampleEmail"
                                sm={3}
                                >
                                Tanggal Survey
                                </Label>
                                <Col md="6">
                                    <Input
                                    disabled
                                        className="form-control-alternative"
                                        type="text"
                                        name="desc"
                                        placeholder="Masukan Keterangan"
                                        value={tglsurvey}
                                        // onChange={(e) => {
                                        // setTanggal(e.target.value);
                                        // }}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                            <Label
                            className="form-control-label"
                                for="exampleEmail"
                                sm={3}
                            >
                                Kode Aplikator
                            </Label>
                            <Col sm={6}>
                                <Input
                                disabled
                                className="form-control-alternative"
                                type="text"
                                name="keterangan"
                                placeholder="Masukan Alamat"
                                value={codeaplikator}
                                onChange={(e) => {
                                    setBankName(e.target.value);
                                }}
                                />
                            </Col>
                            </FormGroup>
                            <FormGroup row>
                            <Label
                            className="form-control-label" for="exampleEmail" sm={3}>
                                Berat
                            </Label>
                            <Col sm={6}>
                                <Input
                                disabled
                                className="form-control-alternative"
                                    type="text"
                                    value={berat}
                                    onChange={(e) => {
                                    setRekening(e.target.value);
                                    }}>
                                
                                </Input>
                            </Col>
                            </FormGroup>
                            <FormGroup row>
                            <Label
                            className="form-control-label"
                                for="exampleEmail"
                                sm={3}
                            >
                                Kurir
                            </Label>
                            <Col sm={6}>
                                <Input
                                disabled
                                className="form-control-alternative"
                                type="text"
                                name="desc"
                                placeholder="Masukan Ongkir"
                                value={kuririd}
                                onChange={(e) => {
                                    setPemilik(e.target.value);
                                }}
                                />
                            </Col>                             
                            </FormGroup>
                            <FormGroup row>
                            <Label
                            className="form-control-label"
                                for="exampleEmail"
                                sm={3}
                            >
                                Surveyor
                            </Label>
                            <Col sm={6}>
                                <Input
                                disabled
                                className="form-control-alternative"
                                type="text"
                                name="desc"
                                placeholder="Masukan Ongkir"
                                value={surveyor}
                                onChange={(e) => {
                                    setPemilik(e.target.value);
                                }}
                                />
                            </Col>                             
                            </FormGroup>
                            <FormGroup row>
                            <Label
                            className="form-control-label"
                                for="exampleEmail"
                                sm={3}
                            >
                                Pengiriman
                            </Label>
                            <Col sm={6}>
                                <Input
                                disabled
                                className="form-control-alternative"
                                type="text"
                                name="desc"
                                placeholder="Masukan Ongkir"
                                value={pengiriman}
                                onChange={(e) => {
                                    setPemilik(e.target.value);
                                }}
                                />
                            </Col>                             
                            </FormGroup>
                            <FormGroup row>
                            <Label
                            className="form-control-label"
                                for="exampleEmail"
                                sm={3}
                            >
                                Ongkir
                            </Label>
                            <Col sm={6}>
                            <CurrencyInput
                                    disabled
                                    id="validation-example-2-field"
                                    placeholder="Rp0"
                                    allowDecimals={false}
                                    value={ongkir}
                                    className={`form-control`}
                                    prefix={'Rp'}
                                    step={10}
                                />
                            </Col>                             
                            </FormGroup>
                            <FormGroup row>
                            <Label
                            className="form-control-label"
                                for="exampleEmail"
                                sm={3}
                            >
                                Pajak
                            </Label>
                            <Col sm={6}>
                                <Input
                                disabled
                                className="form-control-alternative"
                                type="text"
                                name="desc"
                                placeholder="Masukan Ongkir"
                                value={nominalpajak}
                                onChange={(e) => {
                                    setPemilik(e.target.value);
                                }}
                                />
                            </Col>                             
                            </FormGroup>
                            <FormGroup row>
                            <Label
                            className="form-control-label"
                                for="exampleEmail"
                                sm={3}
                            >
                                Durasi Pengerjaan
                            </Label>
                            <Col sm={3}>
                            <Input
                                disabled
                                className="form-control-alternative"
                                type="number"
                                name="desc"
                                placeholder="Masukan Ongkir"
                                value={durasipengerjaan}
                                />
                            </Col>      
                            <Label
                            className="form-control-label"
                                for="exampleEmail"
                                sm={3}
                            >
                                Hari
                            </Label>                       
                            </FormGroup>
                            <FormGroup row>
                            <Label
                            className="form-control-label"
                                for="exampleEmail"
                                sm={3}
                            >
                                Upah Jasa
                            </Label>
                            <Col sm={6}>
                            <CurrencyInput
                                    disabled
                                    id="validation-example-2-field"
                                    placeholder="Rp0"
                                    allowDecimals={false}
                                    value={upahjasa}
                                    className={`form-control`}
                                    prefix={'Rp'}
                                    step={10}
                                />
                            </Col>                             
                            </FormGroup>
                            <FormGroup row>
                            <Label
                            className="form-control-label"
                                for="exampleEmail"
                                sm={3}
                            >
                                Upah Gambar
                            </Label>
                            <Col sm={6}>
                            <CurrencyInput
                                    disabled
                                    id="validation-example-2-field"
                                    placeholder="Rp0"
                                    allowDecimals={false}
                                    value={upahgambar}
                                    className={`form-control`}
                                    prefix={'Rp'}
                                    step={10}
                                />
                            </Col>                             
                            </FormGroup>
                            <FormGroup row>
                            <Label
                            className="form-control-label"
                                for="exampleEmail"
                                sm={3}
                            >
                                Upah Kunjungan
                            </Label>
                            <Col sm={6}>
                            <CurrencyInput
                                    disabled
                                    id="validation-example-2-field"
                                    placeholder="Rp0"
                                    allowDecimals={false}
                                    value={upahkunjungan}
                                    className={`form-control`}
                                    prefix={'Rp'}
                                    step={10}
                                />
                            </Col>                             
                            </FormGroup>
                            <FormGroup row>
                            <Label
                            className="form-control-label"
                                for="exampleEmail"
                                sm={3}
                            >
                                Harga Total
                            </Label>
                            <Col sm={6}>
                            <CurrencyInput
                                    disabled
                                    id="validation-example-2-field"
                                    placeholder="Rp0"
                                    allowDecimals={false}
                                    value={pricetotal}
                                    className={`form-control`}
                                    prefix={'Rp'}
                                    step={10}
                                />
                            </Col>                             
                            </FormGroup>
                            <FormGroup row>
                            <Label
                            className="form-control-label"
                                for="exampleEmail"
                                sm={3}
                            >
                                Pembayaran
                            </Label>
                            <Col sm={6}>
                            <CurrencyInput
                                    disabled
                                    id="validation-example-2-field"
                                    placeholder="Rp0"
                                    allowDecimals={false}
                                    value={paymenttotal}
                                    className={`form-control`}
                                    prefix={'Rp'}
                                    step={10}
                                />
                            </Col>                             
                            </FormGroup>
                            <FormGroup row>
                            <Label
                            className="form-control-label"
                                for="exampleEmail"
                                sm={3}
                            >
                                Dp
                            </Label>
                            <Col sm={6}>
                            <CurrencyInput
                                    disabled
                                    id="validation-example-2-field"
                                    placeholder="Rp0"
                                    allowDecimals={false}
                                    value={uangmuka}
                                    className={`form-control`}
                                    prefix={'Rp'}
                                    step={10}
                                />
                            </Col>                             
                            </FormGroup>
                            {/* <FormGroup row>
                            <Label
                            className="form-control-label"
                                for="exampleEmail"
                                sm={3}
                            >
                                Surveyor
                            </Label>
                            <Col sm={6}>
                                    <CurrencyInput
                                    disabled
                                    id="validation-example-2-field"
                                    placeholder="Rp0"
                                    allowDecimals={false}
                                    value={uangmuka}
                                    className={`form-control`}
                                    prefix={'Rp'}
                                    step={10}
                                />
                            </Col>                             
                            </FormGroup> */}
                            <FormGroup row>
                            <Label
                            className="form-control-label"
                                for="exampleEmail"
                                sm={3}
                            >
                                Total Tagihan
                            </Label>
                            <Col sm={6}>
                                    <CurrencyInput
                                    disabled
                                    id="validation-example-2-field"
                                    placeholder="Rp0"
                                    allowDecimals={false}
                                    value={totalbayar}
                                    className={`form-control`}
                                    prefix={'Rp'}
                                    step={10}
                                />
                            </Col>                             
                            </FormGroup>
                        </CardBody>
                </Card>
                <Card className="bg-secondary shadow">
                        <CardHeader className="bg-white border-0">
                        <h3>Pembayaran Customer</h3>
                        </CardHeader>
                        <CardBody>
                            <FormGroup row>
                                <Label
                                className="form-control-label"
                                for="exampleEmail"
                                sm={3}
                                >
                                Kode
                                </Label>
                                <Col md="6">
                                    <Input
                                    disabled
                                        className="form-control-alternative"
                                        type="text"
                                        name="desc"
                                        placeholder="Masukan Keterangan"
                                        value={code}
                                        onChange={(e) => {
                                        setCode(e.target.value);
                                        }}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label
                                className="form-control-label"
                                for="exampleEmail"
                                sm={3}
                                >
                                Tanggal
                                </Label>
                                <Col md="6">
                                    <Input
                                    disabled
                                        className="form-control-alternative"
                                        type="text"
                                        name="desc"
                                        placeholder="Masukan Keterangan"
                                        value={tanggal}
                                        onChange={(e) => {
                                        setTanggal(e.target.value);
                                        }}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                            <Label
                            className="form-control-label"
                                for="exampleEmail"
                                sm={3}
                            >
                                Bank
                            </Label>
                            <Col sm={6}>
                                <Input
                                disabled
                                className="form-control-alternative"
                                type="text"
                                name="keterangan"
                                placeholder="Masukan Alamat"
                                value={bankname}
                                onChange={(e) => {
                                    setBankName(e.target.value);
                                }}
                                />
                            </Col>
                            </FormGroup>
                            <FormGroup row>
                            <Label
                            className="form-control-label" for="exampleEmail" sm={3}>
                                Rekening
                            </Label>
                            <Col sm={6}>
                                <Input
                                disabled
                                className="form-control-alternative"
                                    type="text"
                                    value={rekening}
                                    onChange={(e) => {
                                    setRekening(e.target.value);
                                    }}>
                                
                                </Input>
                            </Col>
                            </FormGroup>
                            <FormGroup row>
                            <Label
                            className="form-control-label"
                                for="exampleEmail"
                                sm={3}
                            >
                                Pemilik
                            </Label>
                            <Col sm={6}>
                                <Input
                                disabled
                                className="form-control-alternative"
                                type="text"
                                name="desc"
                                placeholder="Masukan Ongkir"
                                value={pemilik}
                                onChange={(e) => {
                                    setPemilik(e.target.value);
                                }}
                                />
                            </Col>                             
                            </FormGroup>
                            <FormGroup row>
                            <Label
                            className="form-control-label"
                                for="exampleEmail"
                                sm={3}
                            >
                                Dp
                            </Label>
                            <Col sm={6}>
                                    <CurrencyInput
                                    disabled
                                    id="validation-example-2-field"
                                    placeholder="Rp0"
                                    allowDecimals={false}
                                    value={uangmuka}
                                    className={`form-control`}
                                    prefix={'Rp'}
                                    step={10}
                                />
                            </Col>                             
                            </FormGroup>
                            <FormGroup row>
                            <Label
                            className="form-control-label"
                                for="exampleEmail"
                                sm={3}
                            >
                                Total Tagihan
                            </Label>
                            <Col sm={6}>
                                    <CurrencyInput
                                    disabled
                                    id="validation-example-2-field"
                                    placeholder="Rp0"
                                    allowDecimals={false}
                                    value={totalbayar}
                                    className={`form-control`}
                                    prefix={'Rp'}
                                    step={10}
                                />
                            </Col>                             
                            </FormGroup>
                        </CardBody>
                </Card>
                <Card className="bg-secondary shadow">
									<CardHeader className="bg-white border-0">
										<div style={{ display: "flex", justifyContent: "space-between" }}>
											<h3 >Bukti Gambar</h3>
										</div>
									</CardHeader>
									<CardBody>
										<Row md="12">
											<Col md="10">
												<FormGroup row>
													{/* <Label
                                                    className="form-control-label" for="exampleEmail" sm={3}>
														<b>Foto Produk</b><br></br>
														Format gambar .jpg .jpeg .png dan ukuran minimum <b>300px X 300px</b>.
													</Label> */}
													<Col sm={2}>
													{gambar === "" ?(
														<img
														alt="..."
														src={"https://i.ibb.co/j5qfxy8/NO-IMAGE-AVAILABLE-2.png"}
														width="100%"
														/>
													):(
                                                    <Zoom>
                                                        <img
                                                            alt=""
                                                            src={gambar}
                                                            width="100%"
                                                        />
                                                    </Zoom>
													)}
													</Col>
													<Col sm={2}>
													{gambar1 === "" ?(
														<img
														alt="..."
														src={"https://i.ibb.co/j5qfxy8/NO-IMAGE-AVAILABLE-2.png"}
														width="100%"
														/>
													):(
                                                    <Zoom>
                                                        <img
                                                            alt=""
                                                            src={gambar1}
                                                            width="100%"
                                                        />
                                                    </Zoom>
													)}
													</Col>
												</FormGroup>
											</Col>
										</Row>
									</CardBody>
                                    <CardFooter>
                                        <Link className="btn btn-info" to="/admin/pemesanan/so/aplikator">
                                            Kembali
                                        </Link>
                                    </CardFooter>
									</Card>
              {/* <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                      <h3>Bukti Gambar</h3>
                    </CardHeader>
                    <CardBody>
                        <FormGroup row>
                            <Label
                            className="form-control-label"
                            for="exampleEmail"
                            sm={3}
                            >
                            Kode
                            </Label>
                            <Col md="6">
                                <Input
                                disabled
                                    className="form-control-alternative"
                                    type="text"
                                    name="desc"
                                    placeholder="Masukan Keterangan"
                                    value={code}
                                    onChange={(e) => {
                                    setCode(e.target.value);
                                    }}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label
                            className="form-control-label"
                            for="exampleEmail"
                            sm={3}
                            >
                            Tanggal
                            </Label>
                            <Col md="6">
                                <Input
                                disabled
                                    className="form-control-alternative"
                                    type="text"
                                    name="desc"
                                    placeholder="Masukan Keterangan"
                                    value={tanggal}
                                    onChange={(e) => {
                                    setTanggal(e.target.value);
                                    }}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                        <Label
                        className="form-control-label"
                            for="exampleEmail"
                            sm={3}
                        >
                            Bank
                        </Label>
                        <Col sm={6}>
                            <Input
                            disabled
                            className="form-control-alternative"
                            type="text"
                            name="keterangan"
                            placeholder="Masukan Alamat"
                            value={bankname}
                            onChange={(e) => {
                                setBankName(e.target.value);
                            }}
                            />
                        </Col>
                        </FormGroup>
                        <FormGroup row>
                        <Label
                        className="form-control-label" for="exampleEmail" sm={3}>
                            Rekening
                        </Label>
                        <Col sm={6}>
                            <Input
                            disabled
                            className="form-control-alternative"
                                type="text"
                                value={rekening}
                                onChange={(e) => {
                                setRekening(e.target.value);
                                }}>
                            
                            </Input>
                        </Col>
                        </FormGroup>
                        <FormGroup row>
                        <Label
                        className="form-control-label"
                            for="exampleEmail"
                            sm={3}
                        >
                            Pemilik
                        </Label>
                        <Col sm={6}>
                            <Input
                            disabled
                            className="form-control-alternative"
                            type="text"
                            name="desc"
                            placeholder="Masukan Ongkir"
                            value={pemilik}
                            onChange={(e) => {
                                setPemilik(e.target.value);
                            }}
                            />
                        </Col>                             
                        </FormGroup>
                        <FormGroup row>
                        <Label
                        className="form-control-label"
                            for="exampleEmail"
                            sm={3}
                        >
                            Dp
                        </Label>
                        <Col sm={6}>
                                <CurrencyInput
                                disabled
                                id="validation-example-2-field"
                                placeholder="Rp0"
                                allowDecimals={false}
                                value={uangmuka}
                                className={`form-control`}
                                prefix={'Rp'}
                                step={10}
                            />
                        </Col>                             
                        </FormGroup>
                        <FormGroup row>
                        <Label
                        className="form-control-label"
                            for="exampleEmail"
                            sm={3}
                        >
                            Total Tagihan
                        </Label>
                        <Col sm={6}>
                                <CurrencyInput
                                disabled
                                id="validation-example-2-field"
                                placeholder="Rp0"
                                allowDecimals={false}
                                value={totalbayar}
                                className={`form-control`}
                                prefix={'Rp'}
                                step={10}
                            />
                        </Col>                             
                        </FormGroup>
                    </CardBody>
                    
              </Card> */}
            </CardBody>
          </div>
        </Row>
    </Container>
    </>
  );
}