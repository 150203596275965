


/*eslint-disable*/
import React, { useEffect, useState } from "react";
import {
  
  Card,
  Button,
  Row,
  Col,
  CardBody,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  
} from "reactstrap";
import { Link,useHistory } from "react-router-dom";
import axios from "axios";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import Select2 from "react-select2-wrapper";
import SweetAlert from "react-bootstrap-sweetalert";
import Halaman404 from "views/404";

const ValidasiFinanceBBMPesananProdukDirekturCok = (props) => {
    const [alert, setAlert] = React.useState(null);
    const token = localStorage.token;
    const [code, setCode] = useState("")
    const username = localStorage.username;
    const warehouse = localStorage.warehouse;
    let history = useHistory();
    const headers = { Authorization: `Bearer ${token}`};
    const [codebbm, setCodeBbm] = useState("")
    const [user, setUser] = useState("")
    const [persons, setPersons] = useState([]);
    const [person, setPerson] = useState("");
    const [status, setStatus] = useState("")
    const [status1, setStatus1] = useState("")
    const [keterangan, setKeterangan] = useState("");
    const [savedItems, setSavedItems] = useState([]);
    const [bank, setBank] = useState("");
    const [banks, setBanks] = useState([]);
    const [isSearchShoww, setIsSearchShoww] = useState(false);
    const [allItemm, setAllItemm] = useState([]);
    const [queryy, setQueryy] = useState("");
    const [qtyTotal, setTotalQty] = useState(0);
    const [qtyError, setQtyError] = useState(null);
    
    const validateForm = () => {
        let error = false;
        savedItems.map((savedItem, key) => { 
          if (savedItem.bayar > savedItem.qtysisa) {
            setQtyError("invalid");
            error = true;
          }
        })
        return error;
    };
  
    const handleSubmit2 = (e) => {
      e.preventDefault();
      if (!validateForm()) {
          setQuestionAlert()
      }
    }

  useEffect(() => {
    getById();
  }, []);

  const getById = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/bbm-commerce/get/${props.match.params.id}`,
        { headers }
      )
      .then((data) => {
        setCodeBbm(data.data.response.bbm_code);
        setUser(data.data.response.user_name);
        setCode(data.data.response.user_code);
        setStatus(data.data.response.status_af)
        setStatus1(data.data.response.status_d)
        // getPerson(data.data.response.customer_id);
        getbank(data.data.response.payment_method);
        setKeterangan(data.data.response.keterangan1);
        getItemDataSaved();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  
const getItemDataSaved = () => {
axios.post(`${process.env.REACT_APP_API_BASE_URL}/bbm-commerce/invoice`, {

    bbm_id: props.match.params.id

}, {headers : {Authorization: `Bearer ${token}`}}).then(async response => {
    let stateItem = [];

    await Promise.all(response.data.response.map(async (data) => {
        stateItem = [...stateItem, {
            warehouse_id: data.warehouse_id,
            code_invoice : data.code_invoice,
            payment_total : data.payment_total,
            keterangan2 : data.keterangan2,
            potongan: data.potongan,
            qtysisa : data.payment_total,
            bayar : data.bayar,
            data: {
                warehouse_id: data.warehouse_id,
                code_invoice : data.code_invoice,
                payment_total : data.payment_total,
                keterangan2 : data.keterangan2,
                potongan: data.potongan,
                qtysisa : data.payment_total,
                bayar : data.bayar,
            },
        }];
    }));
    setSavedItems(stateItem);
})
}


  const getbank = (id) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/bank/list-cabang/${warehouse}`,
        { headers }
      )
      .then((data) => {
        setBanks(data.data.response);
        setBank(id)
      })
      .catch(function (error) {
        console.log(error);
      });
  };

 

  function EditBBM() {
    let dataItems = [];
            savedItems.map((dataItem) => dataItems = [...dataItems, 
                { 
                    warehouse_id: dataItem.warehouse_id,
                    code_invoice : dataItem.code_invoice,
                    payment_total : dataItem.payment_total,
                    keterangan2 : dataItem.keterangan2,
                    potongan: dataItem.potongan,
                    bayar : dataItem.bayar,
                }]);
        let data = {
        warehouse_id : parseInt(warehouse),
        user_code : code,
        payment_method : parseInt(bank),
        keterangan1 : keterangan,
        status_af :status,
        status_d : status1,
        direktur : username,
        list : dataItems
        };
    axios
        .post(
          `${process.env.REACT_APP_API_BASE_URL}/bbm-commerce/update/${props.match.params.id}`,
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(function (response) {
          setSuccessAlert(response.data.message);
          setTimeout(() => (history.push("/admin/bukti-bank-masuk-pesanan-produk/direktur")), 1000);
        })
        .catch(function (error) {
              setGagalAlert(error.response.data.message);
        });
  }

  const setSuccessAlert = (id) => {
    setAlert(
      <SweetAlert
        success
        showConfirm
        confirmBtnText="Ok"
        title={id}
        onCancel={hideAlert}
        onConfirm={hideAlert}
      />
    )
  }

const setGagalAlert = (id) => {
setAlert(
  <SweetAlert
  danger
  showConfirm
  confirmBtnText="Ok"
  title={id}
  onCancel={hideAlert}
  onConfirm={hideAlert}
  />
);
};

const setQuestionAlert = () => {
setAlert(
  <SweetAlert
  warning
  showCancel
  confirmBtnText="Lanjutkan"
  confirmBtnBsStyle="danger"
  title="Apakah Kamu Yakin?"
  onConfirm={EditBBM}
  onCancel={hideAlert}
  focusCancelBtn
  />
);
};

  const hideAlert = () => {
    setAlert(null);
  };



const formatRupiah = (money) => {
  return new Intl.NumberFormat('id-ID',
      { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }
  ).format(money);
}
  
  return (
    <>
    {alert}
    <SimpleHeader name="Validasi Direktur" parentName="PO" />
    <Container className="mt--6" fluid>
        <Row>
          <div className="col"> 
                <CardBody>
                <Card className="bg-secondary shadow">
                          <CardHeader className="bg-white border-0">
                              <h3>Validasi Direktur</h3>
                          </CardHeader>
                    <CardBody>
                      <Row md="12">
                          <Col md="6">
                          <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={4}
                              >
                                Kode
                              </Label>
                              <Col sm={7}>
                                <Input
                                className="form-control-alternative"
                                disabled
                                  type="text"
                                  name="desc"
                                  placeholder="Masukan Keterangan"
                                  value={codebbm}
                                  onChange={(e) => {
                                    setCodeBbm(e.target.value);
                                  }}
                                />
                              </Col>                             
                            </FormGroup>
                             <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={4}
                              >
                                User
                              </Label>
                              <Col sm={7}>
                                <Input
                                    className="form-control-alternative"
                                    disabled
                                    type="text"
                                    name="desc"
                                    placeholder="Masukan Keterangan"
                                    value={user}
                                    onChange={(e) => {
                                        setUser(e.target.value);
                                    }}
                                    />
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label
                                className="form-control-label"
                                    sm={4}
                                >
                                    Bank
                                </Label>
                                <Col sm={7}>
                                <Input
                                disabled
                                autoComplete="off"
                                    name="coa"
                                    type="select"
                                    value={bank}
                                    onChange={(e) => {
                                    setBank(e.target.value);
                                    }}
                                >
                                    <option value="" disabled selected hidden>Pilih Bank</option>
                                    {banks.map((coa, key) => {
                                    return (
                                        <option key={key} value={coa.id}>
                                        {coa.bank_name} {coa.account_number} {coa.account_name}
                                        </option>
                                    );
                                    })}
                                </Input>
                                </Col>
                            </FormGroup>
                          </Col>
                          <Col md="6">
                             <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={4}
                              >
                                Keterangan
                              </Label>
                              <Col sm={7}>
                                <Input
                                className="form-control-alternative"
                                disabled
                                  type="textarea"
                                  name="desc"
                                  rows = "4"
                                  placeholder="Masukan Keterangan"
                                  value={keterangan}
                                  onChange={(e) => {
                                    setKeterangan(e.target.value);
                                  }}
                                />
                              </Col>                             
                            </FormGroup>
                            <FormGroup row>
                            <Col
                              for="exampleEmail"
                              sm={4}
                            >
                              <Label
                              className="form-control-label">Validasi</Label>
                            </Col>
                              <Col sm={7}>
                                <div style={{ display: "flex" }}>
                                  <div className="custom-control custom-radio mb-3">
                                    <Input
                                      className="custom-control-input"
                                      id="customRadio8"
                                      name="custom-radio-3"
                                      type="radio"
                                      value={5}
                                      checked={status1 === 5}
                                      onChange={() => setStatus1(5)}
                                    />
                                    <Label
                                      className="custom-control-label"
                                      htmlFor="customRadio8"
                                    >
                                      
                                    </Label>
                                    <b>SETUJU</b>
                                  </div>
                                  <div
                                    className="custom-control custom-radio mb-3"
                                    style={{ marginLeft: "20px" }}
                                  >
                                    <Input
                                      className="custom-control-input"
                                      id="customRadio9"
                                      name="custom-radio-3"
                                      type="radio"
                                      value={4}
                                      checked={status1 === 4}
                                      onChange={() => setStatus1(4)}
                                    />
                                    <Label
                                      className="custom-control-label"
                                      htmlFor="customRadio9"
                                    >
                                    </Label>
                                    <b>TOLAK</b>
                                  </div>
                                </div>
                              </Col>
                            </FormGroup>
                          </Col>
                      </Row>
                    </CardBody>
                </Card>
                <Form onSubmit={handleSubmit2}>
                <Card className="bg-secondary shadow">
                    <CardHeader className="bg-white border-0">
                      <h3>Invoice</h3>
                    </CardHeader>
                        <CardBody>
                          <Table size="sm" responsive>
                            <thead>
                              <tr>
                                <th><b>Kode Invoice</b></th>
                                <th><b>Tagihan</b></th>
                                <th><b>Keterangan</b></th>
                                <th><b>Potongan</b></th>
                                <th><b>Bayar</b></th>
                              </tr>
                            </thead>
                              <tbody>
                              {
                                savedItems.map((savedItem, key) => {
                                  return (
                                    <tr key={key}>
                                      <td>{savedItem.code_invoice}</td>
                                      <td>{formatRupiah(savedItem.payment_total)}</td>
                                      <td>{savedItem.keterangan2}</td>
                                      <td>{formatRupiah(savedItem.potongan)}</td>
                                      <td>{formatRupiah(savedItem.bayar)}</td>
                                    </tr>
                                  )
                                })
                              }
                              </tbody>
                          </Table>
                        </CardBody>
                <CardFooter>
                      <Link className="btn btn-info" to="/admin/bukti-bank-masuk-pesanan-produk/direktur">
                        Kembali
                      </Link>
                      <Button color="danger" type="submit">
                          Simpan
                      </Button>
                </CardFooter>
                </Card>
              </Form>
            </CardBody>
          </div>
        </Row>
    </Container>
    </>
  );
}

export default ValidasiFinanceBBMPesananProdukDirekturCok;