/*eslint-disable*/
import React, { useEffect, useState } from "react";
import {
  Card,
  Button,
  Row,
  Col,
  CardBody,
  CardHeader,
  CardFooter,
  FormGroup,
  Label,
  Input,
  Container,
} from "reactstrap";

import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import SweetAlert from "react-bootstrap-sweetalert";
import CurrencyInput from 'react-currency-input-field';
import ItemGambar1 from "components/SelectedFile/itemgambar1";

export default function UpdateHasilKunjunganCuak(props) {
  const token = localStorage.token;
  const username = localStorage.username;
  const history = useHistory();
  const [code, setCode] = useState("")
	const [image1, setImage1] = useState("");
  const [kurir, setKurir] = useState("");
  const [kurirs, setKurirs] = useState([]);
  const [keterangan, setKeterangan] = useState("");
  const [hasilsurvey, setHasilSurvey] = useState("");
  const [upahkunjungan, setUpahKunjungan] = useState("");
  const [alert, setAlert] = React.useState(null);

  useEffect(() => {
    getKurir()
    getByIdss()
  }, [])

  const getByIdss = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/order-aplikator/get/${props.match.params.id}`,
        { headers }
      )
      .then((data) => {
        setCode(data.data.response.oa_code)
       
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  

  const getKurir = () =>{
    axios({
     method: "GET",
     url: `${process.env.REACT_APP_API_BASE_URL}/kurir/aktif`,
     headers: {
       Authorization: `Bearer ${token}`,
     },
   })
   .then(data => {
    setKurirs(data.data.response)
 })
   .catch(function (error) {
   })
   
}


  const updateHasilKunjungan = () => {
    const headers = {
			"Content-Type": "application/json",
			Authorization: `Bearer ${token}`,
		};

    const data = {
        // surveyor : username,
        kurir_id : kurir,
        hasil_survey : hasilsurvey,
        upah_kunjungan: upahkunjungan,
    }
    
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/order-aplikator/update-hasil-survey/${props.match.params.id}`, data, { headers })
      .then(function (response) {
        setSuccessAlert(response.data.message);
        setTimeout(() => (history.push("/admin/pemesanan/so/aplikator")),2000);
      })
      .catch(function (error) {
          setGagalAlert(error.response.data.message);
      });
  };

  const EditData = () => {
		const headers = {
			"Content-Type": "multipart/form-data",
			Authorization: `Bearer ${token}`,
		};
    const myjson = JSON.stringify({
			code : code,
      keterangan : keterangan
		});
		let data = new FormData();
		data.append("media", image1);
		data.append("body", myjson);
		axios
			.post(`${process.env.REACT_APP_API_BASE_URL}/tampung-file/lokasi-pengerjaan-by-surveyor`, data, { headers })
			.then(function (response) {
        updateHasilKunjungan()
				// setSuccessAlert(response.data.message);
				// setTimeout(() => (history.push("/admin/item")), 2000);
        // setTimeout(() => (history.push("/admin/pemesanan/so/aplikator")),2000);
				})
				.catch(function (error) {
				  // setGagalAlert(error.response.data.message);
				});
		  }

  const setSuccessAlert = (id) => {
    setAlert(
      <SweetAlert
        success
        showConfirm
        confirmBtnText="Ok"
        title={id}
        onCancel={hideAlert}
        onConfirm={hideAlert}
      />
    )
  }

  const setGagalAlert = (id) => {
    setAlert(
      <SweetAlert
        danger
        showConfirm
        confirmBtnText="Ok"
        title={id}
        onCancel={hideAlert}
        onConfirm={hideAlert}
      />
    );
  };

  const setQuestionAlert = () => {
    setAlert(
      <SweetAlert
        warning
        showCancel
        confirmBtnText="Lanjutkan"
        confirmBtnBsStyle="danger"
        title="Apakah Kamu Yakin?"
        onConfirm={EditData}
        onCancel={hideAlert}
        focusCancelBtn
      />
    );
  };

  const hideAlert = () => {
    setAlert(null);
  }

  const upahkunjungancok = (e) => {
    const value = e === undefined ? 'undefined' : e;
    setUpahKunjungan(value || ' ');
                                                            
  };

  return (
    <div>
      {alert}
      <SimpleHeader name="Hasil Survey" parentName="Master" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <CardBody>
              <Card className="bg-secondary shadow">
                  <CardHeader className="bg-white border-0">
                    <h3>Hasil Survey</h3>
                  </CardHeader>
                  <CardBody>
                    <Row md="12">
                      <Col md="6">
                        <FormGroup row>
                          <Label
                          className="form-control-label"
                            for="exampleEmail"
                            sm={4}
                          >
                            Hasil Survey
                          </Label>
                          <Col sm={7}>
                            <Input
                              autoComplete="off"
                              className="form-control-alternative"
                              type="number"
                              name="Nama"
                              placeholder="Masukan Berapa Meter Persegi"
                              value={hasilsurvey}
                              onChange={(e) => {
                                setHasilSurvey(e.target.value);
                              }}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label
                          className="form-control-label"
                            for="exampleEmail"
                            sm={4}
                          >
                            Upah Kunjungan
                          </Label>
                          <Col sm={7}>
                            <CurrencyInput
                                id="validation-example-2-field"
                                placeholder="Rp0"
                                allowDecimals={false}
                                value={upahkunjungan}
                                onValueChange={upahkunjungancok}
                                className={`form-control`}
                                prefix={'Rp'}
                                step={10}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label
                          className="form-control-label"
                            for="exampleEmail"
                            sm={4}
                          >
                            Kurir
                          </Label>
                          <Col sm={7}>
                          <Input
                                id="profile-last-name"
                                type="select"
                                className="form-control"
                                placeholder="Kurir"
                                value={kurir}
                                onChange={e => {
                                  setKurir(e.target.value);
                                }
                              }
                            >
                                 <option value=""  selected>Pilih Kurir</option>
                                  {kurirs.map((toko, key) => {
                                    return (
                                        <option key={key} value={toko.id}>
                                          {toko.name} 
                                        </option>
                                    );
                                  })}
                            </Input>
                          </Col>
                        </FormGroup>
                        {/* <FormGroup row>
                          <Label
                          className="form-control-label"
                            for="exampleEmail"
                            sm={4}
                          >
                            Per KG
                          </Label>
                          <Col sm={7}>
                            <CurrencyInput
                                
                                id="validation-example-2-field"
                                placeholder="Rp0"
                                allowDecimals={false}
                                value={perkg}
                                onValueChange={priceperkg}
                                className={`form-control`}
                                prefix={'Rp'}
                                step={10}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label
                          className="form-control-label"
                            for="exampleEmail"
                            sm={4}
                          >
                            Per KM
                          </Label>
                          <Col sm={7}>
                          <CurrencyInput
                                
                                id="validation-example-2-field"
                                placeholder="Rp0"
                                allowDecimals={false}
                                value={perkm}
                                onValueChange={priceperkm}
                                className={`form-control`}
                                prefix={'Rp'}
                                step={10}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label
                          className="form-control-label"
                            for="exampleEmail"
                            sm={4}
                          >
                            Packing Cairan
                          </Label>
                          <Col sm={7}>
                          <CurrencyInput
                                
                                id="validation-example-2-field"
                                placeholder="Rp0"
                                allowDecimals={false}
                                value={packingcairan}
                                onValueChange={pricepackingcairan}
                                className={`form-control`}
                                prefix={'Rp'}
                                step={10}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label
                          className="form-control-label"
                            for="exampleEmail"
                            sm={4}
                          >
                            Packing Pecah
                          </Label>
                          <Col sm={7}>
                          <CurrencyInput
                                
                                id="validation-example-2-field"
                                placeholder="Rp0"
                                allowDecimals={false}
                                value={packingpecah}
                                onValueChange={pricepackingpecah}
                                className={`form-control`}
                                prefix={'Rp'}
                                step={10}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label
                          className="form-control-label"
                            for="exampleEmail"
                            sm={4}
                          >
                            Status
                          </Label>
                          <Col sm={7}>
                            <Input
                              autoComplete="off"
                              className="form-control-alternative"
                              type="select"
                              name="Alamat"
                              placeholder="Masukan Alamat"
                              value={status}
                              onChange={(e) => {
                                setStatus(e.target.value);
                              }}
                            >
                                <option value="">Pilih Status</option>
                                <option value={1}>Aktif</option>
                                <option value={2}>Tidak Aktif</option>
                            </Input>
                          </Col>
                        </FormGroup> */}
                      </Col>
                      <Col md="6">
                      
                      </Col>
                    </Row>
                  </CardBody>
                  
              </Card>
              <Card className="bg-secondary shadow">
                <CardHeader>
                  <h3>Upload Gambar</h3>
                </CardHeader>
                <CardBody>
                  <Row md="12">
                    <Col md="10">
												<FormGroup row>
													<Label
                          className="form-control-label" for="exampleEmail" sm={3}>
														<b>Foto Produk</b><br></br>
														Format gambar .jpg .jpeg .png dan ukuran minimum <b>300px X 300px</b>.
													</Label>
														<Col sm={2}>
														<label
                            className="form-control-label" htmlFor1="formId1" >
															<input
															hidden
															id="formId1"
															name="file"
															type="file"
                              accept="image/*"
															onChange={(event) => {
																setImage1(event.target.files[0]);
															}}
															/>
															<ItemGambar1
															onclick={() => { }}
															imageSrc1={image1 != "" && image1 != undefined ? URL.createObjectURL(image1) : null}
															/>
														</label>
														</Col>
											</FormGroup>
										</Col>
                  </Row>
                </CardBody>
                <CardFooter>
                    <Link className="btn btn-info" to="/admin/pemesanan/so/aplikator">
                      Kembali
                    </Link>
                    <Button color="danger" onClick={setQuestionAlert}>
                      Simpan
                    </Button>
                  </CardFooter>   
                </Card>
            </CardBody>
          </div>
        </Row>
      </Container>
    </div>

  );
};
