/*eslint-disable*/
import React, { useEffect, useState } from "react";
import {
  Card,
  Button,
  Row,
  Col,
  CardBody,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Form,
  FormGroup,
  Label,
  Input
} from "reactstrap";
import { Link,useHistory } from "react-router-dom";
import axios from "axios";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import Select2 from "react-select2-wrapper";
import SweetAlert from "react-bootstrap-sweetalert";
import CurrencyInput from 'react-currency-input-field';
import Halaman404 from "views/404";

const CreateAplikator = () => {
  const token = localStorage.token;
  const username = localStorage.username;
  const warehouse = localStorage.warehouse;
  let   history = useHistory();
  const [fungsis, setFungsis] = useState([])
  const [fungsi, setFungsi] = useState("")
  const [name, setName] = useState("")
  const [upahjasa,setUpahJasa] = useState("")
  const [upahum,setUpahUm] = useState("")
  const [upahgambar,setUpahGambar] = useState("")
  const [keterangan,setKeterangan] = useState("");
  const [status, setStatus] = useState("")
  const [satuan, setSatuan] = useState("")
  const [satuans, setSatuans] = useState([])
  const [savedItems, setSavedItems] = useState([]);
  const [isSearchShoww, setIsSearchShoww] = useState(false);
	const [allItemm, setAllItemm] = useState([]);
	const [queryy, setQueryy] = useState("");
  const headers = { Authorization: `Bearer ${token}` };
  const [totalPrice, setTotalPrice] = useState(0);
  const [alert, setAlert] = React.useState(null);

  useEffect(() => {
    getFungsi()
    getSatuan()
  }, [])
  
  const getFungsi = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/function/list`, { headers })
      .then((data) => {
        setFungsis(data.data.response);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getSatuan = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/satuan/list`, { headers })
      .then((data) => {
        setSatuans(data.data.response);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const PriceUpahJasa = (e) => {
    const value = e === undefined ? 'undefined' : e;
    setUpahJasa(value || ' ');
                                                            
  };

  const PriceUpahUm = (e) => {
    const value = e === undefined ? 'undefined' : e;
    setUpahUm(value || ' ');
                                                            
  };

  const PriceUpahGambar = (e) => {
    const value = e === undefined ? 'undefined' : e;
    setUpahGambar(value || ' ');
                                                            
  };


useEffect(() => {
  const getData = setTimeout(async() => {
      if(queryy != ''){
          axios
          .post(`${process.env.REACT_APP_API_BASE_URL}/items-searching/internal-page`, { item_info: queryy ,page:1, per_page: 10},{ headers })
          .then((res) => {
              setAllItemm(res.data);
              setIsSearchShoww(true);
          })
          .catch(function (error) {
            setGagalAlert(error.response.data.message);
          });
      }else{
    setAllItemm(null);
      }
    }, 1000)
    return () => clearTimeout(getData)
}, [queryy]);




// const handleEditQty = (index, value) => {
//   let updateList = savedItems;
  
//   if(!isNaN(value)&&value.length>0){
//     updateList[index] = {...updateList[index], qty: value};
//     setSavedItems(updateList);
//   }else{return false}
// }

const handleEditQty = (index, value) => {
  let updateList = savedItems;
  let minTotal = (parseInt(updateList[index].harga)) * parseInt(updateList[index].qty) ;
  let plusTotal = (parseInt(updateList[index].harga))* value ;
  
  if(!isNaN(value)&&value.length>0){
    updateList[index] = {...updateList[index], qty: value};
    setSavedItems(updateList);
    setTotalPrice(totalPrice + plusTotal - minTotal);
    // setTotalQty(qtyTotal + aqtyTotala - aqtyTotal);
    // setDiskonTotal(diskontotal + diskonnominal1 - diskonnominal2)
  }else{return false}
}

const handleEditPersenFraud = (index, value) => {
  let updateList = savedItems;
  let minTotal = (parseInt(updateList[index].harga)) * parseInt(updateList[index].persen_fraud) ;
  let plusTotal = (parseInt(updateList[index].harga))* value ;
  
  if(!isNaN(value)&&value.length>0){
    updateList[index] = {...updateList[index], persen_fraud: value};
    setSavedItems(updateList);
    setTotalPrice(totalPrice + plusTotal - minTotal);
    // setTotalQty(qtyTotal + aqtyTotala - aqtyTotal);
    // setDiskonTotal(diskontotal + diskonnominal1 - diskonnominal2)
  }else{return false}
}

// save
const saveItemOrUpdate = (item) => {
  let oldobj = savedItems.find(o => o.item_id === item.id);
  if(oldobj===undefined){
    setSavedItems([...savedItems,{
      item_id: item.id,
      item_name: item.item_name,
      item_code:item.item_code,
      satuan : item.satuan,
      persen_fraud : 0,
      qty : 1, 
      harga: 1,
    }]);
    return setTotalPrice(totalPrice + 1 * 1)
  }else{
    let index = savedItems.findIndex(e=>e.item_id===item.id);
    let updateList = savedItems;
    let minTotal = parseInt(updateList[index].harga) * parseInt(updateList[index].qty) ;
    let qtyupdate = parseInt(updateList[index].qty)+parseInt(1);
    updateList[index] = {...updateList[index], qty: qtyupdate};
    setSavedItems(updateList);
    let fixPrice = totalPrice - minTotal;
    return setTotalPrice(fixPrice + (parseInt(updateList[index].harga)) * qtyupdate)
    };
  }


const handleSubmit = async (e) => {
  e.preventDefault();
  {
    setQueryy("");
    setIsSearchShoww("");
    return true
  }
};

const deleteItem = (id) => {
  let array = [...savedItems];

  let index = array.findIndex(object => {
    return object.item_id === id;
  });

  if (index !== -1) {
    array.splice(index, 1);
    setSavedItems(array);
  }
}

  function CreateData() {
    let dataItems = [];
        savedItems.map((dataItem) => dataItems = [...dataItems, 
            { 
                item_id: dataItem.item_id, 
                qty: dataItem.qty, 
                persen_fraud : dataItem.persen_fraud
            }]);
    let data = {
      function_id : parseInt(fungsi),
      name : name,
      upah_jasa : upahjasa,
      upah_gambar : upahgambar,
      persen_um : upahum,
      active_flag: 1,
      keterangan: keterangan,
      satuan : satuan, 
      items : dataItems
    };
    axios
        .post(
          `${process.env.REACT_APP_API_BASE_URL}/aplikator/save`,
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(function (response) {
          setSuccessAlert(response.data.message);
				setTimeout(() => (history.push("/admin/master/aplikator")), 1000);

        })
        .catch(function (error) {
              setGagalAlert(error.response.data.message);
        });
    }
  
    const setSuccessAlert = (id) => {
      setAlert(
        <SweetAlert
          success
          showConfirm
          confirmBtnText="Ok"
          title={id}
          onCancel={hideAlert}
          onConfirm={hideAlert}
        />
      )
    }

  const setGagalAlert = (id) => {
  setAlert(
    <SweetAlert
    danger
    showConfirm
    confirmBtnText="Ok"
    title={id}
    onCancel={hideAlert}
    onConfirm={hideAlert}
    />
  );
  };

  const setQuestionAlert = () => {
  setAlert(
    <SweetAlert
    warning
    showCancel
    confirmBtnText="Lanjutkan"
    confirmBtnBsStyle="danger"
    title="Apakah Kamu Yakin?"
    onConfirm={CreateData}
    onCancel={hideAlert}
    focusCancelBtn
    />
  );
  };
  
    const hideAlert = () => {
      setAlert(null);
    };


const formatRupiah = (money) => {
    return new Intl.NumberFormat('id-ID',
        { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }
    ).format(money);
  }
    

  return (
    <>
    {alert}
    <SimpleHeader name="Tambah Aplikator" parentName="SO" />
    <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <CardBody>
              <Card className="bg-secondary shadow">
                  <CardHeader className="bg-white border-0">
                      <h3>Tambah Aplikator</h3>
                  </CardHeader>
                    <CardBody>
                      <Row md="12">
                          <Col md="6">
                            <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={3}
                              >
                                Nama
                              </Label>
                              <Col sm={6}>
                                <Input
                                className="form-control-alternative"
                                  type="text"
                                  rows = "4"
                                  placeholder="Masukan Nama"
                                  value={name}
                                  onChange={(e) => {
                                    setName(e.target.value);
                                  }}
                                />
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={3}
                              >
                                Fungsi
                              </Label>
                              <Col sm={6}>
                                <Select2
                                      className="form-control-alternative"
                                      defaultValue="1"
                                      value={fungsi}
                                      onChange={(e) => {
                                        setFungsi(e.target.value);
                                      }}
                                      options={{
                                        placeholder: "Pilih Fungsi"
                                      }}
                                      data={fungsis.map((fungsi) => {
                                        return (
                                        { id: fungsi.id, text: fungsi.name}
                                        );
                                      })}
                                    />
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={3}
                              >
                                Satuan
                              </Label>
                              <Col sm={6}>
                                <Select2
                                      className="form-control-alternative"
                                      defaultValue="1"
                                      value={satuan}
                                      onChange={(e) => {
                                        setSatuan(e.target.value);
                                      }}
                                      options={{
                                        placeholder: "Pilih Satuan"
                                      }}
                                      data={satuans.map((satuan) => {
                                        return (
                                        { id: satuan.id, text: satuan.description}
                                        );
                                      })}
                                    />
                              </Col>
                            </FormGroup>
                          </Col>
                          <Col md="6">
                          <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={3}
                              >
                                Harga Gambar
                              </Label>
                              <Col sm={6}>
                                <CurrencyInput
                                    id="validation-example-2-field"
                                    placeholder="Rp0"
                                    allowDecimals={false}
                                    value={upahgambar}
                                    onValueChange={PriceUpahGambar}
                                    className={`form-control`}
                                    prefix={'Rp'}
                                    step={10}
                                />
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={3}
                              >
                                Biaya Jasa
                              </Label>
                              <Col sm={6}>
                                <CurrencyInput
                                    id="validation-example-2-field"
                                    placeholder="Rp0"
                                    allowDecimals={false}
                                    value={upahjasa}
                                    onValueChange={PriceUpahJasa}
                                    className={`form-control`}
                                    prefix={'Rp'}
                                    step={10}
                                />
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={3}
                              >
                                Uang Muka
                              </Label>
                              <Col sm={6}>
                                <CurrencyInput
                                    id="validation-example-2-field"
                                    placeholder="0"
                                    allowDecimals={false}
                                    value={upahum}
                                    onValueChange={PriceUpahUm}
                                    className={`form-control`}
                                    // prefix={'Rp'}
                                    suffix={'%'}
                                    step={10}
                                />
                              </Col>
                            </FormGroup>
                          <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={3}
                              >
                                Keterangan
                              </Label>
                              <Col sm={6}>
                                <Input
                                className="form-control-alternative"
                                  type="textarea"
                                  rows = "4"
                                  placeholder="Masukan Keterangan"
                                  value={keterangan}
                                  onChange={(e) => {
                                    setKeterangan(e.target.value);
                                  }}
                                />
                              </Col>
                            </FormGroup>
                          {/* <FormGroup row>
                                <Label
                                className="form-control-label"
                                    sm={3}
                                    className="form-control-label"
                                    htmlFor="exampleFormControlSelect3"
                                >Status
                                </Label>
                                <Col sm={6}>
                                    <Input
                                    className="form-control-alternative"
                                    type="select"
                                    placeholder="Masukan Customer"
                                    value={status}
                                    onChange={e => setStatus(e.target.value)}
                                    >
                                        <option value="">Pilih Status</option>
                                        <option value={1}>Aktif</option>
                                        <option value={2}>Tidak Aktif</option>
                                    </Input>
                                </Col>
                              </FormGroup> */}
                          </Col>
                      </Row>
                    </CardBody>
              </Card>
              <Card className="bg-secondary shadow">
                  <CardHeader className="bg-white border-0">
                      <h3>Item</h3>
                  </CardHeader>
                        <CardBody>
                          <Table size="sm" responsive>
                            <thead>
                              <tr>
                                <th><b>Nama Item</b></th>
                                <th><b>Kode Item</b></th>
                                <th><b>Qty&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b></th>
                                <th><b>Satuan</b></th>
                                <th><b>Sisa Penggunaan (%)</b></th>
                              </tr>
                            </thead>
                              <tbody>
                              {
                                savedItems.map((savedItem, key) => {
                                  return (
                                    <tr key={key}>
                                      <td>{savedItem.item_name}</td>
                                      <td>{savedItem.item_code}</td>
                                      {/* <td>
                                      
                                      {editable ? (
                                          <Input
                                          className="form-control-alternative"
                                            placeholder="Diskon Persen"
                                            type="number"
                                            value={savedItem.harga}
                                            onChange={(e) => {
                                              handleEditHarga(key, e.target.value);
                                            }}
                                          />
                                        ) : (
                                            <>{formatRupiah(savedItem.harga)}</>
                                        )}
                                      
                                      </td> */}
                                      <td>
                                        <Input
                                          className="form-control-alternative"
                                          placeholder="qty"
                                          type="number"
                                          value={savedItem.qty}
                                          onChange={(e) => {
                                              handleEditQty(key, e.target.value);
                                          }}/>
                                      </td>
                                      
                                      <td>{savedItem.satuan}</td>
                                      <td>
                                        <Input
                                          className="form-control-alternative"
                                          placeholder="qty"
                                          type="number"
                                          value={savedItem.persen_fraud}
                                          onChange={(e) => {
                                              handleEditPersenFraud(key, e.target.value);
                                          }}/>
                                      </td>
                                      {/* <td>
                                        {formatRupiah((savedItem.harga - savedItem.diskon_nominal) * savedItem.qty)}
                                      </td> */}
                                      <td>
                                        <Button color="danger" onClick={() => deleteItem(savedItem.item_id)}><i className="fas fa-trash" /></Button>
                                      </td>
                                    </tr>
                                  )
                                })
                              }
                              <tr>
                                  <td colSpan="2">
                                  <><Form onSubmit={handleSubmit}>
                                          <Input
                                            autoComplete="off"
                                            placeholder="Masukan Item Manual"
                                            type="search"
                                            value={queryy}
                                            onChange={(e) => setQueryy(e.target.value)}
                                          />
                                        {isSearchShoww && queryy && (
                                        <Card className="position-sticky boxShadow" style={{ maxHeight: "15.5rem", overflowY: "auto", paddingTop: "1rem", position: "relative" }}>
                                          <div style={{ position: "absolute", top: "2.5px", right: "1rem", cursor: "pointer", fontSize: "1rem" }}>
                                            <i className="fas fa-window-close text-danger" onClick={() => setIsSearchShoww(false)}></i>
                                          </div>
                                          {allItemm?.response ? (
                                            allItemm.response.map((item) => (
                                              <CardBody key={item.id} style={{ minHeight: "5rem", padding: "1rem" }} className="bgSearch" onClick={() => {saveItemOrUpdate(item);setQueryy('');setIsSearchShoww(false);}}>
                                                <div>
                                                  <b>Nama item:</b> {item.item_name}
                                                </div>
                                              </CardBody>
                                            ))
                                          ) : (
                                            <div className="text-center mb-3 text-danger">Item "{queryy}" tidak ada bosku!</div>
                                          )}
                                        </Card>
                                      )}
                                  </Form></>
                                  </td>
                                 </tr>
                              </tbody>
                          </Table>
                        </CardBody>
                <CardFooter>
                      <Link className="btn btn-info" to="/admin/master/aplikator">
                        Kembali
                      </Link>
                      <Button color="danger" onClick={()=>setQuestionAlert()}> 
                          Simpan
                      </Button>
                </CardFooter>
                {/* modal */}
              </Card>
            </CardBody>
          </div>
        </Row>
    </Container>  
    </>
  );
}

export default CreateAplikator;