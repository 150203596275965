import React, { useEffect, useState } from "react";
import {
  Button,
  Row,
  Col,
  CardBody,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import KopSurat from "views/components/KopSurat";
import axios from "axios";
import * as FileSaver from "file-saver";
import SweetAlert from "react-bootstrap-sweetalert";
const ModalCetakSuratIzinDinasHRD = ({ open, toggle, data }) => {
    const token = localStorage.token;
    const username = localStorage.username;
    const [savedItems, setSavedItems] = useState([]);
    const [download, setDownload] = useState("")
    const [alert, setAlert] = React.useState(null);
    const [tanggal,setTanggal] = useState("")
    const [tanggalizin,setTanggalIzin] = useState("")
    const [creator,setCreator] = useState("")
    const [keterangan,setKeterangan] = useState("")
    const [cabang,setCabang] = useState("")
    const [person,setPerson] = useState("")
    const [validator1,setValidator1] = useState("")
    const [validator2,setValidator2] = useState("")


  useEffect(() => {
    getById();
    // getItemDataSaved();
  }, []);

  const getById = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/absensi-dinas/cetak/${data.id}`, {
        headers,
      })
      .then((data) => {
        setTanggal(data.data.response.created);
        setTanggalIzin(data.data.response.tanggal_dinas);
        setCreator(data.data.response.creator);
        setKeterangan(data.data.response.keterangan);
        setCabang(data.data.response.warehouse);
        setPerson(data.data.response.nama);
        setValidator1(data.data.response.validator1);
        setValidator2(data.data.response.validator2);
        
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  function print() {
    var printContents = document.getElementById("targetContent").innerHTML;
    var originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
  }
  
  return (
    <Modal isOpen={open} toggle={toggle} style={{ minWidth: "70%", top:"-15%" }}>
      <ModalHeader toggle={toggle} align="center">
      <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div style={{ textAlign: 'left' }}>
                    </div>
                    <div style={{ textAlign: 'right' }}>
                        <Button color="info" onClick={() => print()}>Print</Button>
                        {/* <Button color="success" onClick={()=> (downloadExcel())}>Excel</Button> */}
                    </div>
                </div>
      </ModalHeader>
      <ModalBody>
        <CardBody
          className="border"
          id="targetContent"
          style={{ width: "100%" }}
        >
          <div className="col">
            <KopSurat 
            warehouseName={cabang}
            // user={username}
             />
          </div>
          <div className="w-100" style={{ border: "1px solid #b3b3b3" }}></div>
                        <Row md="12">
                            <Col md="12">
                                <h3><b><center>FORM IZIN DINAS</center></b></h3>
                            </Col>
                        </Row>
                        <div className="w-100" style={{ border: "1px solid #b3b3b3" }}></div>
                        <br></br>
                        <Row md="12">
                            <Col md="10">
                            <div className="row align-items-center mb-1">
                                <div className="col-3">Tanggal Pengajuan</div>
                                <div className="col-1 text-center">:</div>
                                <div className="col-3 ">
                                    <b>{tanggal}</b>
                                </div>
                            </div>
                            <div className="row align-items-center mb-1">
                                <div className="col-3">Tanggal Dinas</div>
                                <div className="col-1 text-center">:</div>
                                <div className="col-5 ">
                                    <b>{tanggalizin}</b>
                                </div>
                            </div>
                            <div className="row align-items-center mb-1">
                                <div className="col-3">Nama</div>
                                <div className="col-1 text-center">:</div>
                                <div className="col-5 ">
                                    <b>{person}</b>
                                </div>
                            </div>
                            <div className="row align-items-center mb-1">
                                <div className="col-2">Keterangan</div>
                                <div className="col-1 text-center">:</div>
                                <div className="col-5 ">
                                    <b>{keterangan}</b>
                                </div>
                            </div>
                            </Col>
                        </Row>
                    <div className="w-100" style={{ border: "0.5px solid #b3b3b3" }}></div>
                        <center>Terms of Price, delivery & shipping required</center>
                        <br></br>
                                <Row md="20">
                                    <Col md="4">
                                    <div className="row align-items-center mb-3">
                                        <div className="col-5">Di Buat</div>
                                        <div className="col-1 text-center">:</div>
                                        <div className="col-5 ">
                                            <b>{creator}</b>
                                        </div>
                                    </div>
                                    <div className="row align-items-center mb-3">
                                        <div className="col-5">Signature</div>
                                        <div className="col-1 text-center">:</div>
                                        <div className="col-4 ">
                                            <b>------------</b>
                                        </div>
                                    </div>
                                    </Col>
                                    <Col md="4">
                                    <div className="row align-items-center mb-3">
                                        <div className="col-4">HRD</div>
                                        <div className="col-1 text-center">:</div>
                                        <div className="col-4 ">
                                            <b>Bu Nurul</b>
                                        </div>
                                    </div>
                                    <div className="row align-items-center mb-3">
                                        <div className="col-4">Signature</div>
                                        <div className="col-1 text-center">:</div>
                                        <div className="col-4 ">
                                            <b>------------</b>
                                        </div>
                                    </div>
                                    </Col>
                                    <Col md="4">
                                    <div className="row align-items-center mb-3">
                                        <div className="col-4">Mengetahui</div>
                                        <div className="col-1 text-center">:</div>
                                        <div className="col-4 ">
                                            <b>Bu. JESSIE</b>
                                        </div>
                                    </div>
                                    <div className="row align-items-center mb-3">
                                        <div className="col-4">Signature</div>
                                        <div className="col-1 text-center">:</div>
                                        <div className="col-4 ">
                                            <b>------------</b>
                                        </div>
                                    </div>
                                    </Col>
                                </Row>
                                <div className="w-100" style={{ border: "0.5px solid #b3b3b3" }}></div>
                                {/* <div className="row align-items-center mb-3">
                                    <div className="col-4"><i>NB : PENGAJUAN MIN 7 HARI DAN MAKSIMAL 2 HARI SEBELUMNYA</i></div>
                                    </div> */}
        </CardBody>
      </ModalBody>
    </Modal>
  );
};

export default ModalCetakSuratIzinDinasHRD;
