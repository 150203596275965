/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import { 
    Card, 
    Button, 
    Row, 
    Col, 
    CardBody, 
    CardHeader,
    Form, 
    Badge,
    FormGroup, 
    Container,
    Label, 
    Input ,
    DropdownItem,DropdownMenu, UncontrolledDropdown, DropdownToggle
} from 'reactstrap';
import { Link } from "react-router-dom";
import axios from 'axios';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import SweetAlert from "react-bootstrap-sweetalert";
import Halaman404 from 'views/404';

const OrderAplikatorPage = () => {
  const [alert, setAlert] = React.useState(null);
  const token = localStorage.token;
  const username = localStorage.username;
  const warehouse = parseInt(localStorage.warehouse);
  const redirectPrefix = `/admin/pemesanan/so/aplikator/tanggal-survey/`;
  const redirectPrefix2 = `/admin/pemesanan/so/aplikator/hasil-survey/`
  const redirectPrefix3 = `/admin/pemesanan/so/aplikator/dp-gambar/`
  const redirectPrefix4 = `/admin/pemesanan/so/aplikator/input-gambar-rab/`
  const redirectPrefix5 = `/admin/pemesanan/so/aplikator/pelunasan-gambar/`
  const redirectPrefix6 = `/admin/pemesanan/so/aplikator/detail/`
  
  const [rowIndex, setRowIndex] = useState(0);
  const [allAplikator, setAllAplikator] = useState([]);
  const [name, setName] = useState("");
  const [code, setCode] = useState("");
  const [page, setPage] = useState(1);
  const [perPage, setPerpage] = useState(10);
  const [totalItem, setTotalItem] = useState(0);
  const [currentSort, setCurrentSort] = useState("");

  let paginationOption = {
    page: page,
    alwaysShowAllBtns: true,
    override: true,
    showTotal: true,
    withFirstAndLast: false,
    sizePerPage: perPage,
    totalSize: totalItem,
    onPageChange: (page) => {
      updateDataTable(page, perPage, currentSort, code, name);
    },
    sizePerPageRenderer: () => (
      <div className="dataTables_length" id="datatable-basic_length">
        <label>
          Show{" "}
          {
            <select
              name="datatable-basic_length"
              aria-controls="datatable-basic"
              className="form-control form-control-sm"
              onChange={(e) => {
                updateDataTable(page, e.target.value, currentSort)
              }}
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="25">25</option>
              <option value="50">50</option>
            </select>
          }{" "}
          entries.
        </label>
      </div>
    ),
  }

  const updateDataTable = (page, perPage, sort, code, name ) => {
    getAplikator(page, perPage, sort,code, name );
    setPage(page);
    setPerpage(perPage);
    setRowIndex((page - 1) * perPage);
    setCurrentSort(sort);
    setCode(code);
    setName(name);
  }

  const handleTableChange = (type, { sortField, sortOrder }) => {
    if (type === "sort") {
      let sort = `${sortField} ${sortOrder}`
      updateDataTable(page, perPage, sort, code, name)
    }
  }
  
  useEffect(() => {
    getAplikator(page, perPage, currentSort);
  }, []);

  // fungsi dari ambil data
  const getAplikator = async (page, perPage, currentSort, user_code = null, oa_code = null,function_id = null,surveyor = null,uk_state = null,uv_state = null,active_flag = null) => {
    
    let filter = { 
      
      page: page, 
      per_page: perPage,
      warehouse_id : warehouse,

    };
    if (user_code !== null) {
      filter = Object.assign(filter, { user_code: user_code })
    }
    if (oa_code !== null) {
        filter = Object.assign(filter, { oa_code: oa_code })
    }
    if (function_id !== null) {
        filter = Object.assign(filter, { function_id: function_id })
    }
    if (surveyor !== null) {
        filter = Object.assign(filter, { surveyor: surveyor })
    }
    if (uk_state !== null) {
        filter = Object.assign(filter, { uk_state: uk_state })
    }
    if (uv_state !== null) {
        filter = Object.assign(filter, { uv_state: uv_state })
    }
    if (active_flag !== null) {
        filter = Object.assign(filter, { active_flag: active_flag })
    }
    const data = filter;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/order-aplikator/page`, data, {
        headers,
      })
      .then((data) => {
        setAllAplikator(data.data.response);
        setPage(data.data.current_page + 1);
        setPerpage(data.data.per_page);
        setTotalItem(data.data.total_item);
      })
      .catch(function (error) {
        setAllAplikator(error.response.data.response);
      });
  }

  const reset = () => {
    setCode("");
    setName("");
    updateDataTable(1, perPage, currentSort, "", "");
  }

  const updatePengerjaanProses = (id) => {
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/order-aplikator/update-pengerjaan-proses/${id}`, null, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        setSuccessAlert(response.data.message);
        getAplikator();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const updatePengerjaanSelesai = (id) => {
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/order-aplikator/update-pengerjaan-selesai/${id}`, null, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        setSuccessAlert(response.data.message);
        getAplikator();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const setSuccessAlert = (id) => {
    setAlert(
      <SweetAlert
        success
        showConfirm
        confirmBtnText="Ok"
        title={id}
        onCancel={hideAlert}
        onConfirm={hideAlert}
      />
    )
  }

  const hideAlert = () => {
    setAlert(null);
  };

  const formatRupiah = (money) => {
        return new Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR", minimumFractionDigits: 0 }).format(money);
    };

  return (
    <div>
      {alert}
        <SimpleHeader name="Aplikator" parentName="" />
          <Container className="mt--6" fluid>
          <Row>
            <div className="col">
            <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div style={{ textAlign: 'left' }}>
                    </div>
                    {/* <div style={{ textAlign: 'right' }}>
                      <Link className="btn btn-danger" to="/admin/master/aplikator/create">
                      <i className="fas fa-plus" /> Tambah
                      </Link>
                    </div> */}
                  </div>
                </CardHeader>
                <CardBody>
                        <Form>
                          <Row md="12">
                              <Col md="3">
                              <FormGroup>
                                <Label
                                  className="form-control-label"
                                  htmlFor="exampleFormControlSelect3"
                              >Kode</Label>
                                <Input
                                className="form-control-alternative"
                                  type="text"
                                  placeholder="Masukan Kode"
                                  value={code}
                                  onChange={e => updateDataTable(1, perPage, currentSort, e.target.value, name)}
                                />
                              </FormGroup>
                            </Col>
                            <Col md="3">
                              <FormGroup>
                                <Label
                                  className="form-control-label"
                                  htmlFor="exampleFormControlSelect3"
                              >Nama</Label>
                                <Input
                                className="form-control-alternative"
                                  type="text"
                                  placeholder="Masukan Nama"
                                  value={name}
                                  onChange={e => updateDataTable(1, perPage, currentSort,code, e.target.value)}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <Button type="button" onClick={reset} color="secondary">Reset</Button>
                            </Col>
                          </Row>
                        </Form>
                        {allAplikator === null ? (
                        <div>
                          <ToolkitProvider
                                  rowNumber={rowIndex}
                                  data={""}
                                  keyField="id"
                                  columns={[
                                  {
                                    dataField: "no",
                                    text: "#",
                                    sort: true,
                                    page: 1,
                                    formatter: (cell, row, index) => {
                                    let currentRow = ++index;
                                    return currentRow + rowIndex;
                                    },
                                },
                                {
                                    dataField: "",
                                    text: "Kode",
                                    sort: true,
                                    },
                                {
                                  dataField: "",
                                  text: "Aplikator",
                                  sort: true,
                                  },
                                  {
                                    dataField: "",
                                    text: "Harga",
                                    sort: true,
                                    }
                                  ]}
                              >
                                  {(props) => (
                                  <div className="py-4 table-responsive">
                                      <BootstrapTable
                                      remote
                                      {...props.baseProps}
                                      bootstrap4={true}
                                      bordered={false}
                                      hover={true}
                                      onTableChange={handleTableChange}
                                      />
                                  </div>
                                  )}
                          </ToolkitProvider>
                          <CardHeader>
                              <center><h3>DATA NOT FOUND</h3></center>
                          </CardHeader>
                        </div>
                        ):(
                      <ToolkitProvider
                              rowNumber={rowIndex}
                              data={allAplikator}
                              keyField="id"
                              columns={[
                              {
                                  dataField: "no",
                                  text: "#",
                                  sort: true,
                                  page: 1,
                                  formatter: (cell, row, index) => {
                                  let currentRow = ++index;
                                  return currentRow + rowIndex;
                                  },
                              },
                              {
                                dataField: "transaction_date",
                                text: "Tanggal",
                                sort: true,
                                },
                                {
                                    dataField: "oa_code",
                                    text: "Kode",
                                    sort: true,
                                    formatter : (cell , row) => {
                                      return row.oa_code === ""
                                      ? ""
                                      :
                                      <Link  to={redirectPrefix6 + row.id}
                                                id={"tooltip_" + row.id}>
                                                <span>{row.oa_code}</span>
                                      </Link>
                                    }
                                    },
                            {
                              dataField: "aplikator_name",
                              text: "Aplikator",
                              sort: true,
                              },
                              {
                                dataField: "payment_total",
                                text: "Harga",
                                sort: true,
                                formatter: (value) => formatRupiah(value)
                                },
                              {
                                dataField: "",
                                text: "Status",
                                sort: true,
                                formatter: (cell, row) => {
                                  return row.status === 1
                                    ? <Badge color="" className="badge-dot mr-4">
                                        <i className="bg-danger" />
                                        Menunggu Respon User
                                      </Badge>
                                    : row.status === 2
                                    ? <Badge color="" className="badge-dot mr-4">
                                        <i className="bg-danger" />
                                        Input Jadwal Survey
                                      </Badge>
                                    :row.status === 3
                                    ? <Badge color="" className="badge-dot mr-4">
                                        <i className="bg-danger" />
                                        Input Observasi
                                      </Badge>
                                    :row.status === 4
                                    ? <Badge color="" className="badge-dot mr-4">
                                        <i className="bg-damger" />
                                        Menunggu validasi 
                                      </Badge>
                                    :row.status === 5
                                    ? <Badge color="" className="badge-dot mr-4">
                                        <i className="bg-danger" />
                                        Menunggu validasi Tagihan
                                      </Badge>
                                    :row.status === 6
                                    ? <Badge color="" className="badge-dot mr-4">
                                        <i className="bg-danger" />
                                        Menunggu Upload Gambar
                                      </Badge> 
                                    : row.status === 7
                                    ? <Badge color="" className="badge-dot mr-4">
                                        <i className="bg-danger" />
                                        Menunggu Email User
                                      </Badge> 
                                      :row.status === 8
                                      ? <Badge color="" className="badge-dot mr-4">
                                          <i className="bg-danger" />
                                          Validasi DP
                                        </Badge>
                                      :row.status === 9
                                      ? <Badge color="" className="badge-dot mr-4">
                                          <i className="bg-info" />
                                          Input Gambar dan Durasi Pengerjaan
                                        </Badge>
                                      :row.status === 10
                                      ? <Badge color="" className="badge-dot mr-4">
                                          <i className="bg-danger" />
                                          Menunggu Respon User
                                        </Badge>
                                      :row.status === 11
                                      ? <Badge color="" className="badge-dot mr-4">
                                          <i className="bg-danger" />
                                          Menunggu Pengerjaan
                                        </Badge>
                                      :row.status === 12
                                      ? <Badge color="" className="badge-dot mr-4">
                                          <i className="bg-danger" />
                                          Validasi Pengerjaan Proses
                                        </Badge>
                                      :row.status === 13
                                      ? <Badge color="" className="badge-dot mr-4">
                                          <i className="bg-danger" />
                                          Menunggu Pelunasan
                                        </Badge>
                                      :row.status === 14
                                      ? <Badge color="" className="badge-dot mr-4">
                                          <i className="bg-info" />
                                          Validasi Pelunasan
                                        </Badge>
                                      :
                                      <Badge color="" className="badge-dot mr-4">
                                        <i className="bg-success" />
                                        Lunas
                                      </Badge>;
                                },
                            },
                            {
                              dataField: "",
                              text: "Status",
                              sort: true,
                              formatter: (cell, row) => {
                                return row.status === 1
                                  ? ""
                                  : row.status === 2
                                  ? <UncontrolledDropdown nav>
                                        <DropdownToggle className="btn btn-danger" color="" tag="a">
                                            Input Tanggal Survey
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <Link  to={redirectPrefix + row.id}
                                                id={"tooltip_" + row.id}>
                                            <DropdownItem>
                                                <i className="fas fa-user-edit" /><span>Tanggal Survei</span>
                                            </DropdownItem>
                                            </Link>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                  :row.status === 3
                                  ?  <UncontrolledDropdown nav>
                                        <DropdownToggle className="btn btn-danger" color="" tag="a">
                                            Input Observasi
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <Link  to={redirectPrefix2 + row.id}
                                                id={"tooltip_" + row.id}>
                                            <DropdownItem>
                                                <i className="fas fa-user-edit" /><span>Input Hasil Survey</span>
                                            </DropdownItem>
                                            </Link>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                  :row.status === 4
                                  ? ""
                                  :row.status === 5
                                  ? ""
                                  :row.status === 6
                                  ? ""
                                  :row.status === 7
                                  ? ""
                                  :row.status === 8
                                  ?  <UncontrolledDropdown nav>
                                        <DropdownToggle className="btn btn-danger" color="" tag="a">
                                            Validasi DP
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <Link  to={redirectPrefix3 + row.id}
                                                id={"tooltip_" + row.id}>
                                            <DropdownItem>
                                                <i className="fas fa-user-edit" /><span>Validasi DP</span>
                                            </DropdownItem>
                                            </Link>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                  :row.status === 9
                                  ?  <UncontrolledDropdown nav>
                                        <DropdownToggle className="btn btn-danger" color="" tag="a">
                                            Input Gambar RAB
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <Link  to={redirectPrefix4 + row.id}
                                                id={"tooltip_" + row.id}>
                                            <DropdownItem>
                                                <i className="fas fa-user-edit" /><span>Input Gambar RAB</span>
                                            </DropdownItem>
                                            </Link>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                  :row.status === 10
                                  ? ""
                                  :row.status === 11
                                  ? 
                                    // <UncontrolledDropdown nav>
                                    //     <DropdownToggle className="btn btn-danger" color="" tag="a">
                                    //       Validasi Pengerjaan Proses
                                    //     </DropdownToggle>
                                    //     <DropdownMenu>
                                    //         <Link  to={redirectPrefix2 + row.id}
                                    //             id={"tooltip_" + row.id}>
                                    //         <DropdownItem>
                                    //             <i className="fas fa-user-edit" /><span>Input Hasil Survey</span>
                                    //         </DropdownItem>
                                    //         </Link>
                                    //     </DropdownMenu>
                                    // </UncontrolledDropdown>
                                        <Button color="danger"  onClick={() => updatePengerjaanProses(row.id)} ><i className="fa fa-check-circle"  aria-hidden="true"/> Update Proses Pengerjaan</Button>
                                       
                                  :row.status === 12
                                  ?  <Button color="danger"  onClick={() => updatePengerjaanSelesai(row.id)} ><i className="fa fa-check-circle"  aria-hidden="true"/> Pengerjaan Selesai</Button>

                                  :row.status === 13
                                  ? ""
                                  :row.status === 14
                                  ? <UncontrolledDropdown nav>
                                      <DropdownToggle className="btn btn-danger" color="" tag="a">
                                        Validasi Pelunasan
                                      </DropdownToggle>
                                      <DropdownMenu>
                                          <Link  to={redirectPrefix5 + row.id}
                                              id={"tooltip_" + row.id}>
                                          <DropdownItem>
                                              <i className="fas fa-user-edit" /><span>Validasi Pelunasan</span>
                                          </DropdownItem>
                                          </Link>
                                      </DropdownMenu>
                                    </UncontrolledDropdown>
                                  :
                                  <Badge color="" className="badge-dot mr-4">
                                    <i className="bg-danger" />
                                    Lunas
                                  </Badge>
                              },
                          },
                            // {
                            //     dataField: "",
                            //     text: "",
                            //     sort: true,
                            //     formatter: (cell, row) => {
                            //       return row.status === 1
                            //             ? <UncontrolledDropdown nav>
                            //                 <DropdownToggle className="btn btn-danger" color="" tag="a">
                            //                     Tindakan
                            //                 </DropdownToggle>
                            //                 <DropdownMenu>
                            //                     <Link  to={redirectPrefix + row.id}
                            //                         id={"tooltip_" + row.id}>
                            //                     <DropdownItem>
                            //                         <i className="fas fa-user-edit" /><span>Tanggal Survei</span>
                            //                     </DropdownItem>
                            //                     </Link>
                            //                 </DropdownMenu>
                            //             </UncontrolledDropdown>
                            //         : row.status === 2
                            //         ? <UncontrolledDropdown nav>
                            //         <DropdownToggle className="btn btn-danger" color="" tag="a">
                            //             Tindakan
                            //         </DropdownToggle>
                            //         <DropdownMenu>
                            //             <Link  to={redirectPrefix2 + row.id}
                            //                 id={"tooltip_" + row.id}>
                            //             <DropdownItem>
                            //                 <i className="fas fa-user-edit" /><span>Input Hasil Survey</span>
                            //             </DropdownItem>
                            //             </Link>
                            //         </DropdownMenu>
                            //     </UncontrolledDropdown>
                            //         :row.status === 3
                            //         ? ""
                            //         :row.status === 4
                            //         ? ""
                            //         :row.status === 5
                            //         ?""
                            //         :<UncontrolledDropdown nav>
                            //         <DropdownToggle className="btn btn-danger" color="" tag="a">
                            //             Tindakan
                            //         </DropdownToggle>
                            //         <DropdownMenu>
                            //             <Link  to={redirectPrefix + row.id}
                            //                 id={"tooltip_" + row.id}>
                            //             <DropdownItem>
                            //                 <i className="fas fa-user-edit" /><span>Detail</span>
                            //             </DropdownItem>
                            //             </Link>
                            //         </DropdownMenu>
                            //     </UncontrolledDropdown>
                            //     },
                            // },
                            //   {
                            //       dataField: "", text: "", formatter: (cell, row, index) => {
                            //       return (
                            //         <UncontrolledDropdown nav>
                            //         <DropdownToggle className="btn btn-danger" color="" tag="a">
                            //               Tindakan
                            //         </DropdownToggle>
                            //         <DropdownMenu center>
                            //               <Link  to={redirectPrefix + row.id}
                            //                 id={"tooltip_" + row.id}>
                            //               <DropdownItem>
                            //                 <i className="fas fa-user-edit" /><span>Edit</span>
                            //               </DropdownItem>
                            //               </Link>
                            //         </DropdownMenu>
                            //     </UncontrolledDropdown>
                            //         )
                            //       }
                            //     },
                              ]}
                          >
                              {(props) => (
                              <div className="py-4 table-responsive">
                                  <BootstrapTable
                                  remote
                                  {...props.baseProps}
                                  bootstrap4={true}
                                  bordered={false}
                                  hover={true}
                                  pagination={paginationFactory({ ...paginationOption })}
                                  onTableChange={handleTableChange}
                                  />
                              </div>
                            )}
                      </ToolkitProvider>
                        )}
                </CardBody>
              </Card>
            </div>
          </Row>
          </Container>
    </div>
  );
}

export default OrderAplikatorPage;