/*eslint-disable*/
import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Card,
  CardBody,
  Row,
  Col,
  Container,
  CardHeader,
  CardTitle,
  // Label,
  FormGroup,
  Input
} from "reactstrap";
import { Link } from "react-router-dom";
import SimpleHeader from "components/Headers/SimpleHeader.js";
// import Chart from "chart.js";
import {Bar } from "react-chartjs-2";
import  Notifications  from 'react-push-notification';

export default function dashboard() {
  const token = localStorage.token
  const [itemTotal, setItemTotal] = useState(0);
  const [item, setItem] = useState(0);
  const [cabang, setCabang] = useState(0);
  const [pemasukan, setPemasukan] = useState(0);
  const [pengeluaran, setPengeluaran] = useState(0);
  const [hutang, setHutang] = useState(0);
  const [piutang, setPiutang] = useState(0);
  
  const [pemasukan1, setPemasukan1] = useState([]);


  const [warehouse, setWarehouse] = useState("");
  const [warehousess, setWarehousess] = useState([]);
  const headers = { Authorization: `Bearer ${token}` };

  const buttonClick = () => {
    Notifications({
        title: 'Absensi Gaes',
        subtitle: 'Manok',
        message: 'Jangan Lupa Absen Ya Gaes',
        theme: 'darkblue',
        native: true // when using native, your OS will handle theming.
    });
};

  const colors = {
    gray: {
      100: "#f6f9fc",
      200: "#e9ecef",
      300: "#dee2e6",
      400: "#ced4da",
      500: "#adb5bd",
      600: "#8898aa",
      700: "#525f7f",
      800: "#32325d",
      900: "#212529",
    },
    theme: {
      default: "#172b4d",
      primary: "#5e72e4",
      secondary: "#f4f5f7",
      info: "#11cdef",
      success: "#2dce89",
      danger: "#f5365c",
      warning: "#fb6340",
    },
    black: "#12263F",
    white: "#FFFFFF",
    transparent: "transparent",
  };

  const bulans = pemasukan1.map((i) => i.date_check)
  const pemasukancabang = pemasukan1.map((a) => a.pemasukan_cabang)
  const pengeluarancabang = pemasukan1.map((a) => a.pengeluaran_cabang)
  const hutangcabang = pemasukan1.map((a) => a.hutang_cabang)
  const piutangcabang = pemasukan1.map((a) => a.piutang_cabang)


  // console.log(pemasukancabang);
  // console.log(bulans)

  let chartExample21 = {
    options: {
      scales: {
        yAxes: [
          {
            gridLines: {
              color: colors.gray[200],
              zeroLineColor: colors.gray[200],
            },
            ticks: {
              callback: function (value) {
                if (!(value % 10)) {
                  //return '$' + value + 'k'
                  return value;
                }
              },
            },
          },
        ],
      },
      tooltips: {
        callbacks: {
          label: function (item, data) {
            var label = data.datasets[item.datasetIndex].label || "";
            var yLabel = item.yLabel;
            var content = "";
            if (data.datasets.length > 1) {
              content += label;
            }
            content += yLabel;
            return content;
          },
        },
      },
    },
    data: {
      labels: pemasukan1.map((i) => i.date_check),
      datasets: [
        {
          label: "Pemasukan",
          // data: [25, 20],
          data: pemasukan1.map((a) => (a.pemasukan_cabang)),
          maxBarThickness: 10,
        }
      ],
    },
    // data: {
    //   labels: [],
    //   datasets: [
    //     {
    //       label: "Pemasukan",
    //       data: 
    //         pemasukan1.map((person) => {person.pemasukan_cabang
    //           // return [person.pemasukan_cabang]
    //           })
    //       ,
    //       maxBarThickness: 10,
    //     }
       
    //   ],
    // },
  };

  let chartExample2 = {
    options: {
      scales: {
        yAxes: [
          {
            gridLines: {
              color: colors.gray[200],
              zeroLineColor: colors.gray[200],
            },
            ticks: {
              callback: function (value) {
                if (!(value % 10)) {
                  //return '$' + value + 'k'
                  return value;
                }
              },
            },
          },
        ],
      },
      tooltips: {
        callbacks: {
          label: function (item, data) {
            var label = data.datasets[item.datasetIndex].label || "";
            var yLabel = item.yLabel;
            var content = "";
            if (data.datasets.length > 1) {
              content += label;
            }
            content += yLabel;
            return content;
          },
        },
      },
    },
    data: {
      labels: bulans,
      datasets: [
        {
          label: "Pemasukan",
          data: pemasukancabang,
          maxBarThickness: 10,
          backgroundColor: 'rgba(5, 203, 38, 0.8)',
        },
        {
          label: "Pengeluaran",
          data: pengeluarancabang,
          maxBarThickness: 10,
          backgroundColor: 'rgba(223, 12, 12, 1)',
        },
        {
          label: "Hutang",
          data: hutangcabang,
          maxBarThickness: 10,
          backgroundColor: 'rgba(9, 41, 246, 1)',
        },
        {
          label: "Piutang",
          data: piutangcabang,
          maxBarThickness: 10,
          backgroundColor: 'rgba(14, 211, 211, 1)',
        },
      ],
    },
  };
  
  
  const updateDataTable = (warehouse) => {
    getAllKalulasi(warehouse);
    setWarehouse(warehouse);
    getAllKalkulasiDiagram(warehouse)
  }
  
  // useEffect(() => {
  //   getAllKalulasi();
  //   getWarehouse();
  //   getAllKalkulasiDiagram()
  //   }, []);

  const getAllKalulasi = async (warehouse_id="",) => {
		let filter = {
			page: 1,
			per_page: 10
		};
    if (warehouse_id !== "") {
      filter = Object.assign(filter, { warehouse_id: warehouse_id });
    }
		const data = filter;

		await axios.post(`${process.env.REACT_APP_API_BASE_URL}/laporan/kalkulasi`, data, { headers })
    .then((data) => {
      setItemTotal(data.data.response.karyawan_cabang);
      setItem(data.data.response.item_cabang);
      setCabang(data.data.response.jumlah_cabang);
      setPemasukan(data.data.response.pemasukan_cabang);
      setPengeluaran(data.data.response.pengeluaran_cabang);
      setHutang(data.data.response.hutang_cabang);
      setPiutang(data.data.response.piutang_cabang);
    })
    .catch(function (error) {
    });
	};

  const getAllKalkulasiDiagram = async (warehouse_id="",) => {
		let filter = {
			page: 1,
			per_page: 10,
		};
    if (warehouse_id !== "") {
      filter = Object.assign(filter, { warehouse_id: warehouse_id });
    }
		const data = filter;

		await axios.post(`${process.env.REACT_APP_API_BASE_URL}/laporan/kalkulasi-diagram`, data, { headers })
    .then((data) => {
      setPemasukan1(data.data.response);
    })
    .catch(function (error) {
    });
	};

  const getWarehouse = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/warehouse/list/cabang`,
        { headers }
      )
      .then((data) => {
        setWarehousess(data.data.response);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const formatRupiah = (money) => {
		return new Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR", minimumFractionDigits: 0 }).format(money);
	};
  
  return (
    <div> 
      <SimpleHeader name="Dashboard" parentName="Admin" />
      <Container className="mt--5" fluid>
        <Row>
          <div className="col">
          <div>
              <CardBody>
                  <Row md="12">
                    <Col md="3">
                    </Col>
                    <Col md="3">
                    </Col>
                    <Col md="3">
                    </Col>
                    <Col md="3">
                    </Col>
                  </Row>
              </CardBody>
              </div>
              {/* <button onClick={buttonClick} className="button">
              Hello world.
              </button> */}
              {/* <Notifications /> */}
              <CardBody>
                  <Row className="mb-4">
                    <Col lg="4">
                      <div>
                      <Card className="bg-gradient-red">
                        <CardBody>
                          <Row>
                            <div className="col">
                              <CardTitle className="text-uppercase text-muted mb-0 text-white">
                                <b>Total Afiliasi</b>
                              </CardTitle>
                              <span className="h2 font-weight-bold mb-0 text-white">
                                {itemTotal} Orang
                              </span>
                            </div>
                            <Col className="col-auto">
                              <div className="icon icon-shape bg-white text-dark rounded-circle shadow">
                                <i className="fa fa-users" />
                              </div>
                            </Col>
                          </Row>
                          <p className="mt-3 mb-0 text-sm">
                            <span className="text-white mr-2">
                              <i className="fa fa-arrow-up" />
                            </span>
                            <span className="text-nowrap text-light">
                            </span>
                          </p>
                        </CardBody>
                      </Card>
                      </div>
                    </Col>
                    {/* <Col lg="4">
                      <div>
                      <Card className="bg-gradient-primary">
                        <CardBody>
                          <Row>
                            <div className="col">
                              <CardTitle className="text-uppercase text-muted mb-0 text-white">
                              <b>Total Afiliasi</b>
                              </CardTitle>
                              <span className="h2 font-weight-bold mb-0 text-white">
                                {item} Orang
                              </span>
                            </div>
                            <Col className="col-auto">
                              <div className="icon icon-shape bg-white text-dark rounded-circle shadow">
                                <i className="ni ni-book-bookmark" />
                              </div>
                            </Col>
                          </Row>
                          <p className="mt-3 mb-0 text-sm">
                            <span className="text-white mr-2">
                              <i className="fa fa-arrow-up" />
                            </span>
                            <span className="text-nowrap text-light">
                            </span>
                          </p>
                        </CardBody>
                      </Card>
                      </div>
                    </Col> */}
                    <Col lg="4">
                      <div>
                        <Card className="bg-gradient-info">
                          <CardBody>
                            <Row>
                              <div className="col">
                                <CardTitle className="text-uppercase text-muted mb-0 text-white">
                                <b>Total Pesanan</b>
                                </CardTitle>
                                <span className="h2 font-weight-bold mb-0 text-white">
                                  {cabang} Pesanan
                                </span>
                              </div>
                              <Col className="col-auto">
                                <div className="icon icon-shape bg-white text-dark rounded-circle shadow">
                                  <i className="ni ni-shop" />
                                </div>
                              </Col>
                            </Row>
                            <p className="mt-3 mb-0 text-sm">
                              <span className="text-white mr-2">
                                <i className="fa fa-arrow-up" />
                              </span>
                              <span className="text-nowrap text-light">
                              </span>
                            </p>
                          </CardBody>
                        </Card>
                      </div>
                    </Col>
                  </Row>
              </CardBody>
              <div>
                <CardBody>
                    <Row md="12">
                      <Col md="7">
                      <Card className="bg-secondary shadow">
												<CardHeader className="bg-white border-0">
                          <h5 className="h3 mb-0">Finance</h5>
                          </CardHeader>
                          <CardBody>
                            <Row className="mb-4">
                              <Col lg="6">
                                <div>
                                  <Card className="bg-gradient-success">
                                    <CardBody>
                                      <Row>
                                        <div className="col">
                                          <CardTitle className="text-uppercase text-muted mb-0 text-white">
                                          <b>Pemasukan</b>
                                          </CardTitle>
                                          <span className="h2 font-weight-bold mb-0 text-white">
                                            {formatRupiah(pemasukan)}
                                          </span>
                                        </div>
                                        <Col className="col-auto">
                                          <div className="icon icon-shape bg-white text-dark rounded-circle shadow">
                                            <i className="ni ni-money-coins" />
                                          </div>
                                        </Col>
                                      </Row>
                                      <p className="mt-3 mb-0 text-sm">
                                        <span className="text-white mr-2">
                                          <i className="fa fa-arrow-up" />
                                        </span>
                                        <span className="text-nowrap text-light">
                                        </span>
                                      </p>
                                    </CardBody>
                                  </Card>
                                </div>
                              </Col>
                              <Col lg="6">
                                <div>
                                  <Card className="bg-gradient-info">
                                    <CardBody>
                                      <Row>
                                        <div className="col">
                                          <CardTitle className="text-uppercase text-muted mb-0 text-white">
                                          <b>Piutang</b>
                                          </CardTitle>
                                          <span className="h2 font-weight-bold mb-0 text-white">
                                            {formatRupiah(piutang)}
                                          </span>
                                        </div>
                                        <Col className="col-auto">
                                          <div className="icon icon-shape bg-white text-dark rounded-circle shadow">
                                            <i className="ni ni-money-coins" />
                                          </div>
                                        </Col>
                                      </Row>
                                      <p className="mt-3 mb-0 text-sm">
                                        <span className="text-white mr-2">
                                          <i className="fa fa-arrow-up" />
                                        </span>
                                        <span className="text-nowrap text-light">
                                        </span>
                                      </p>
                                    </CardBody>
                                  </Card>
                                </div>
                              </Col>
                              <Col lg="6">
                                <div>
                                    <Card className="bg-gradient-danger">
                                      <CardBody>
                                        <Row>
                                          <div className="col">
                                            <CardTitle className="text-uppercase text-muted mb-0 text-white">
                                            <b>Pengeluaran</b>
                                            </CardTitle>
                                            <span className="h2 font-weight-bold mb-0 text-white">
                                              {formatRupiah(pengeluaran)}
                                            </span>
                                          </div>
                                          <Col className="col-auto">
                                            <div className="icon icon-shape bg-white text-dark rounded-circle shadow">
                                              <i className="ni ni-money-coins" />
                                            </div>
                                          </Col>
                                        </Row>
                                        <p className="mt-3 mb-0 text-sm">
                                          <span className="text-white mr-2">
                                            <i className="fa fa-arrow-up" />
                                          </span>
                                          <span className="text-nowrap text-light">
                                          </span>
                                        </p>
                                      </CardBody>
                                    </Card>
                                </div>
                              </Col>
                              <Col lg="6">
                                <div>
                                    <Card className="bg-gradient-primary">
                                      <CardBody>
                                        <Row>
                                          <div className="col">
                                            <CardTitle className="text-uppercase text-muted mb-0 text-white">
                                            <b>Hutang</b>
                                            </CardTitle>
                                            <span className="h2 font-weight-bold mb-0 text-white">
                                              {formatRupiah(hutang)}
                                            </span>
                                          </div>
                                          <Col className="col-auto">
                                            <div className="icon icon-shape bg-white text-dark rounded-circle shadow">
                                              <i className="ni ni-money-coins" />
                                            </div>
                                          </Col>
                                        </Row>
                                        <p className="mt-3 mb-0 text-sm">
                                          <span className="text-white mr-2">
                                            <i className="fa fa-arrow-up" />
                                          </span>
                                          <span className="text-nowrap text-light">
                                          </span>
                                        </p>
                                      </CardBody>
                                    </Card>
                                </div>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col md="5">
                        <Card className="bg-secondary shadow">
                          <CardHeader className="bg-white border-0">
                            <h5 className="h3 mb-0">Diagram Pendapatan</h5>
                          </CardHeader>
                          <CardBody>
                            <div>
                              <Bar
                                data={chartExample2.data}
                                options={chartExample2.options}
                                className="chart-canvas"
                                id="chart-bars"
                              />
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                </CardBody>
                </div>
          </div>
        </Row>
        </Container>
    </div>
  );
}
