/*eslint-disable*/
import React, { useEffect, useState } from "react";
import {
  
  Card,
  Button,
  Row,
  Col,
  CardBody,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback
  
} from "reactstrap";
import { Link,useHistory } from "react-router-dom";
import axios from "axios";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import Select2 from "react-select2-wrapper";
import SweetAlert from "react-bootstrap-sweetalert";
import Halaman404 from "views/404";

const CreateFinanceBbmPesananAplikator = () => {
  const [alert, setAlert] = React.useState(null);
//   const headers = { Authorization: `Bearer ${token}`};
  const token = localStorage.token;
  const username = localStorage.username;
  const warehouse = localStorage.warehouse;
  let history = useHistory();
  const [persons, setPersons] = useState([]);
  const [person, setPerson] = useState("");
  const [name, setName] = useState("")
  const [code, setCode] = useState("")
  const [isSearchShoww, setIsSearchShoww] = useState(false);
  const [keterangan, setKeterangan] = useState("");
  const [savedItems, setSavedItems] = useState([]);
  const [bank, setBank] = useState("");
  const [banks, setBanks] = useState([]);
	const [qtyTotal, setTotalQty] = useState(0);
  const [qtyError, setQtyError] = useState(null);
  
  const validateForm = () => {
      let error = false;
      savedItems.map((savedItem, key) => { 
        if (savedItem.bayar > savedItem.qtysisa) {
          setQtyError("invalid");
          error = true;
        }
      })
      return error;
  };

  const handlePembayaran = (e) => {
    e.preventDefault();
    if (!validateForm()) {
        setQuestionAlert()
    }
  }

    const getUserCode = (id) => {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      axios
        .get(
          `${process.env.REACT_APP_API_BASE_URL}/users/get/${id}`,
          { headers }
        )
        .then((data) => {
            setCode(data.data.response.user_code)
          setName(data.data.response.name)
        //   setTimeout(() => {
          getItemDataSaved(data.data.response.user_code);
        //   }, 1000);
        })
        .catch(function (error) {
          console.log(error);
        });
    };
  
    // console.log(name);

    const getItemDataSaved = (id) => {
      axios.post(`${process.env.REACT_APP_API_BASE_URL}/invoice-sjs/list-invoice`, {
  
          warehouse_id: warehouse,
        //   name : id,
          user_code: id
  
      },{headers : {
        Authorization: `Bearer ${token}`,
      }}).then(async response => {
          let stateItem = [];
          let stateEditing = [];
          await Promise.all(response.data.response.map(async (data) => {
              stateItem = [...stateItem, {
                warehouse_id: data.warehouse_id,
                invoice_code: data.invoice_code,
                payment_total : data.payment_total,
                keterangan2 : data.keterangan2,
                potongan: 0,
                bayar : data.payment_total,
                qtysisa : data.payment_total,
                  data: {
                    invoice_code: data.invoice_code,
                    payment_total : data.payment_total,
                    keterangan2 : data.keterangan2,
                    potongan: 0,
                    bayar : data.payment_total,
                    qtysisa : data.payment_total

                  },
              }];
              stateEditing = [...stateEditing, {
                  editing: false
              }];
          }));
          setSavedItems(stateItem);
      })
  }


  const handlePotongan = (index, value) => {
    let updateList = savedItems;
    let aqtyTotal = parseInt(updateList[index].harga) + value;
    if(!isNaN(value) && value.length > -1){
      updateList[index] = {...updateList[index], potongan: value};
      setSavedItems(updateList);
      setTotalQty(qtyTotal + aqtyTotal);
    }else{return false}
  }

  const handleBayar = (index, value) => {
    let updateList = savedItems;
    let aqtyTotal = parseInt(updateList[index].potongan) + value;
    if(!isNaN(value) && value.length > -1){
      updateList[index] = {...updateList[index], bayar: value};
      setSavedItems(updateList);
      setTotalQty(qtyTotal + aqtyTotal);
    }else{return false}
  }
  
    const deleteItem = (id) => {
      let array = [...savedItems];
  
      let index = array.findIndex(object => {
        return object.invoice_code === id;
      });
  
      if (index !== -1) {
        setTotalQty(qtyTotal - (array[index].payment_total))  ;
        array.splice(index, 1);
        setSavedItems(array);
      }
    }

  useEffect(() => {
    // getPerson();
    getbank();

  }, []);

  useEffect(() => {
    const getData = setTimeout(async() => {
        if(person != ''){
            const headers = {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              };
            axios
            .post(`${process.env.REACT_APP_API_BASE_URL}/users/page`, { name: person , per_page: 10},{ headers })
            .then((res) => {
                setPersons(res.data);
                setIsSearchShoww(true);
            })
            .catch(function (error) {
            });
        }else{
            setPersons(null);
        }
      }, 1000)
      return () => clearTimeout(getData)
  }, [person]);

  const getPerson = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/users/page`,{per_page : 10},
        { headers }
      )
      .then((data) => {
        setPersons(data.data.response);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getbank = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/bank/list-cabang/${warehouse}`,
        { headers }
      )
      .then((data) => {
        setBanks(data.data.response);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

 

  function CreateData() {
    let dataItems = [];
        savedItems.map((dataItem) => dataItems = [...dataItems, 
            { 
                warehouse_id: dataItem.warehouse_id,
                code_invoice : dataItem.invoice_code,
                payment_total : dataItem.payment_total,
                keterangan2 : dataItem.keterangan2,
                potongan: dataItem.potongan,
                bayar : dataItem.bayar,
            }]);
    let data = {
      warehouse_id : parseInt(warehouse),
      user_code : code,
      payment_method : parseInt(bank),
      keterangan1 : keterangan,
      status_af :3,
      status_d :3,
      username : username,
      list : dataItems
    };
    axios
        .post(
          `${process.env.REACT_APP_API_BASE_URL}/bbm-apk/save`,
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(function (response) {
          setSuccessAlert(response.data.message);
          setTimeout(() => (history.push("/admin/bukti-bank-masuk-pesanan-aplikator/page")), 1000);
        })
        .catch(function (error) {
              setGagalAlert(error.response.data.message);
        });
  }

  const setSuccessAlert = (id) => {
    setAlert(
      <SweetAlert
        success
        showConfirm
        confirmBtnText="Ok"
        title={id}
        onCancel={hideAlert}
        onConfirm={hideAlert}
      />
    )
  }

const setGagalAlert = (id) => {
setAlert(
  <SweetAlert
  danger
  showConfirm
  confirmBtnText="Ok"
  title={id}
  onCancel={hideAlert}
  onConfirm={hideAlert}
  />
);
};

const setQuestionAlert = () => {
setAlert(
  <SweetAlert
  warning
  showCancel
  confirmBtnText="Lanjutkan"
  confirmBtnBsStyle="danger"
  title="Apakah Kamu Yakin?"
  onConfirm={CreateData}
  onCancel={hideAlert}
  focusCancelBtn
  />
);
};

  const hideAlert = () => {
    setAlert(null);
  };

  const searchShow = (item) => {
    // setName(item.name)

    setTimeout(() => {
    getUserCode(item.id);
    }, 1000);
    setIsSearchShoww(false);
  };


const formatRupiah = (money) => {
  return new Intl.NumberFormat('id-ID',
      { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }
  ).format(money);
}
  
  return (
    <>
    {alert}
    <SimpleHeader name="Buat BBM Pesanan Produk" parentName="PO" />
    <Container className="mt--6" fluid>
        <Row>
          <div className="col"> 
                <CardBody>
                <Card className="position-sticky boxShadow" style={{ top: 0, zIndex: "5" }}>
									<CardBody className="ml-3 pb-0">
										<Row md="12">
											<Col md="5 d-lg-block d-none">
											</Col>
											<Col md="3">
												<FormGroup row>
													<Col sm={10}>
                                                        <Input
                                                            autoComplete="off"
                                                            placeholder="Masukan Nama User"
                                                            type="search"
                                                            value={person}
                                                            onChange={(e) => setPerson(e.target.value)}
                                                        />
													</Col>
												</FormGroup>
											</Col>
											<Col md="4 d-lg-block d-none">
											</Col>
										</Row>
									</CardBody>
								</Card>
                                            {isSearchShoww && person && (
                                                <Card className="position-sticky boxShadow" style={{ maxHeight: "15.5rem", overflowY: "auto", paddingTop: "1rem", position: "relative" }}>
                                                <div style={{ position: "absolute", top: "2.5px", right: "1rem", cursor: "pointer", fontSize: "1rem" }}>
                                                    <i className="fas fa-window-close text-danger" onClick={() => setIsSearchShoww(false)}></i>
                                                </div>
                                                {persons?.response ? (
                                                    persons.response.map((item) => (
                                                    <CardBody key={item.id} style={{ minHeight: "5rem", padding: "1rem" }} className="bgSearch" onClick={() => {searchShow(item);setPerson("");setIsSearchShoww(false);}}>
                                                        <div>
                                                        <b>User:</b> {item.name}
                                                        </div>
                                                    </CardBody>
                                                    ))
                                                ) : (
                                                    <div className="text-center mb-3 text-danger">User "{person}" tidak ada bosku!</div>
                                                )}
                                                </Card>
                                            )}
                <Card className="bg-secondary shadow">
                          <CardHeader className="bg-white border-0">
                              <h3>Buat BBM</h3>
                          </CardHeader>
                    <CardBody>
                      <Row md="12">
                          <Col md="6">
                             <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={4}
                              >
                                Customer
                              </Label>
                              <Col sm={7}>
                                {/* <Select2
                                      className="form-control-alternative"
                                      defaultValue="0"
                                      value={person}
                                      onChange={(e) => {
                                        setPerson(e.target.value);
                                        getUserCode(e.target.value)
                                      }}
                                      options={{
                                        placeholder: "Pilih Customer"
                                      }}
                                      data={persons.map((person) => {
                                        return (
                                        { id: person.id, text: person.name}
                                        );
                                      })}
                                    /> */}
                                          <Input
                                          disabled
                                            autoComplete="off"
                                            placeholder="Masukan Nama User"
                                            type="text"
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                          />
                                        
                                        {/* {isSearchShoww && person && (
                                        <Card className="position-sticky boxShadow" style={{ maxHeight: "15.5rem", overflowY: "auto", paddingTop: "1rem", position: "relative" }}>
                                          <div style={{ position: "absolute", top: "2.5px", right: "1rem", cursor: "pointer", fontSize: "1rem" }}>
                                            <i className="fas fa-window-close text-danger" onClick={() => setIsSearchShoww(false)}></i>
                                          </div>
                                          {persons?.response ? (
                                            persons.response.map((item) => (
                                              <CardBody key={item.id} style={{ minHeight: "5rem", padding: "1rem" }} className="bgSearch" onClick={() => {searchShow(item);setIsSearchShoww(false);}}>
                                                <div>
                                                  <b>User:</b> {item.name}
                                                </div>
                                              </CardBody>
                                            ))
                                          ) : (
                                            <div className="text-center mb-3 text-danger">User "{person}" tidak ada bosku!</div>
                                          )}
                                        </Card>
                                      )} */}
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label
                                className="form-control-label"
                                    sm={4}
                                >
                                    Bank
                                </Label>
                                <Col sm={7}>
                                <Input
                                autoComplete="off"
                                    name="coa"
                                    type="select"
                                    value={bank}
                                    onChange={(e) => {
                                    setBank(e.target.value);
                                    }}
                                >
                                    <option value="" disabled selected hidden>Pilih Bank</option>
                                    {banks.map((coa, key) => {
                                    return (
                                        <option key={key} value={coa.id}>
                                        {coa.bank_name} {coa.account_number} {coa.account_name}
                                        </option>
                                    );
                                    })}
                                </Input>
                                </Col>
                            </FormGroup>
                          </Col>
                          <Col md="6">
                             <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={4}
                              >
                                Keterangan
                              </Label>
                              <Col sm={7}>
                                <Input
                                className="form-control-alternative"
                                
                                  type="textarea"
                                  name="desc"
                                  rows = "4"
                                  placeholder="Masukan Keterangan"
                                  value={keterangan}
                                  onChange={(e) => {
                                    setKeterangan(e.target.value);
                                  }}
                                />
                              </Col>                             
                            </FormGroup>
                          </Col>
                      </Row>
                    </CardBody>
                </Card>
                <Form onSubmit={handlePembayaran}>
                <Card className="bg-secondary shadow">
                    <CardHeader className="bg-white border-0">
                      <h3>Invoice</h3>
                    </CardHeader>
                        <CardBody>
                          <Table size="sm" responsive>
                            <thead>
                              <tr>
                                <th><b>Kode Invoice</b></th>
                                <th><b>Tagihan</b></th>
                                <th><b>Keterangan</b></th>
                                <th><b>Potongan</b></th>
                                <th><b>Bayar</b></th>
                              </tr>
                            </thead>
                              <tbody>
                              {
                                savedItems.map((savedItem, key) => {
                                  return (
                                    <tr key={key}>
                                      <td>{savedItem.invoice_code}</td>
                                      <td>{formatRupiah(savedItem.payment_total)}</td>
                                      <td>{savedItem.keterangan2}</td>
                                      <td>
                                        <Input
                                          className="form-control-alternative"
                                          placeholder="Rp 0"
                                          type="number"
                                          value={savedItem.potongan}
                                          onChange={(e) => {
                                              handlePotongan(key, e.target.value);
                                          }}/>
                                        </td>
                                      <td>
                                      <FormGroup>
                                                  <Input
                                                      autoComplete="off"
                                                      placeholder="Rp 0"
                                                      invalid={qtyError === "invalid"}
                                                      type="number"
                                                      value={savedItem.bayar}
                                                      onChange={(e) => {
                                                        handleBayar(key, e.target.value);
                                                        if (savedItem.bayar > savedItem.qtysisa) {
                                                          setQtyError("");
                                                        }
                                                      }}
                                                  />
                                                  <FormFeedback>
                                                      Max Bayar {savedItem.qtysisa} 
                                                  </FormFeedback>
                                                </FormGroup>
                                        </td>
                                      <td>
                                        <Button color="danger" onClick={() => deleteItem(savedItem.invoice_code)}><i className="fas fa-trash" /></Button>
                                      </td>
                                    </tr>
                                  )
                                })
                              }
                              </tbody>
                          </Table>
                        </CardBody>
                <CardFooter>
                      <Link className="btn btn-info" to="/admin/bukti-bank-masuk-pesanan-aplikator/page">
                        Kembali
                      </Link>
                      <Button color="danger" type="submit">
                          Simpan
                      </Button>
                </CardFooter>
              </Card>
              </Form>
            </CardBody>
          </div>
        </Row>
    </Container>
    </>
  );
}

export default CreateFinanceBbmPesananAplikator;