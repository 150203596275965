/*eslint-disable*/
import React, { useEffect, useState } from "react";
import {
  
  Card,
  Button,
  Row,
  Col,
  CardBody,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Modal, ModalHeader, ModalBody
  
} from "reactstrap";
import { Link,useHistory } from "react-router-dom";
import axios from "axios";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import Select2 from "react-select2-wrapper";
import SweetAlert from "react-bootstrap-sweetalert";
import Halaman404 from "views/404";

export default function CreateCarousel1(props)  {
    const username = localStorage.username
  const [address,setAddress] = useState("")
  const [berat,setBerat] = useState("")
  const [bukti,setBukti] = useState("")
  const [codetransaction,setCodeTransaction] = useState("")
  const [jarak,setJarak] = useState("")
  const [keterangan,setKeterangan] = useState("")
  const [kurir,setKurir] = useState("")
  const [kurirs,setKurirs] = useState([])
  const [manualaddress,setManualAddress] = useState("")
  const [nominalpajak,setNominalPajak] = useState("")
  const [ongkir,setOngkir] = useState("")
  const [pajakid,setPajakId] = useState("")
  const [partialcode,setPartialCode] = useState("")
  const [paymenttotal,setPaymentTotal] = useState("")
  const [pengiriman,setPengiriman] = useState("")
  const [persenpajak,setPersenPajak] = useState("")
  const [pic1name,setPic1Name] = useState("")
  const [pic1state,setPic1State] = useState("")
  const [pic2name,setPic2Name] = useState("")
  const [pic2state,setPic2State] = useState("")
  const [pricetotal,setPriceTotal] = useState("")
  const [qtytotal,setQtyTotal] = useState("")
  const [sccode,setScCode] = useState("")
  const [sccounter,setScCounter] = useState("")
  const [transactiondate,setTransactionDate] = useState("")
  const [type,setType] = useState("")
  const [usercode,setUserCode] = useState("")
  const [warehouseid,setWarehouseId] = useState("")
  const [alert, setAlert] = React.useState(null);
  const [reasons, setReasons] = useState([])
  const token = localStorage.token;
  const warehouse = localStorage.warehouse;
  let history = useHistory();
  const [customers, setCustomers] = useState([]);
  const [customer, setCustomer] = useState("");
  const [typereq, setTypeReq] = useState([]);
  const [savedItems, setSavedItems] = useState([]);
  const [ppn, setPPN] = useState(0);
  const [grandtotal, setGrandTotal] = useState(0);
  const [pajak, setPajak] = useState("");
  const [lainnya, setLainnya] = useState(0);
  const [alamat, setAlamat] = useState("");
  const [ongkir1, setOngkir1] = useState(0);
    const [ppnnew, setPPNNEW] = useState(0);
  const [totalPrice1, setTotalPrice1] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [a] = useState(0);
  const [b] = useState(0);
	const [qtyTotal, setTotalQty] = useState(0);
  const [codeso, setCodeSo] = useState("")
  const [bankcustomer, setBankCustomer] = useState("")
  const [bankcustomers, setBankCustomers] = useState([])
 
  const saveItemOrUpdate = () => {
      setSavedItems([...savedItems,{
        linkwebsite: "",
        linkmobile: "" ,
      }]);
  }

//   const handleLinkWebsite = (index, value) => {
//     let updateList = savedItems;
//     let qtya = value

//     if(value){
//       updateList[index] = {...updateList[index], linkwebsite: value};
//       setTotalPrice(qtya - qtya)
//     }else{return false}
//   }

//   const handleLinkMobile = (index, value) => {
//     let updateList = savedItems;
//     let qtyb = value

//     if(value){
//       updateList[index] = {...updateList[index], linkmobile: value};
//       setSavedItems(updateList);
//       setTotalPrice( qtyb - qtyb)
//     }else{return false}
//   }

const handleLinkWebsite = (index, value) => {
    let updateList = savedItems;
    let aqtyTotal =  value;
    
    if(value){
      updateList[index] = {...updateList[index], linkwebsite: value};
      setSavedItems(updateList);
      setTotalQty(qtyTotal + aqtyTotal);
    }else{return false}
  }

  const handleLinkMobile = (index, value) => {
    let updateList = savedItems;
    let aqtyTotal =  value;
    
    if(value){
      updateList[index] = {...updateList[index], linkmobile: value};
      setSavedItems(updateList);
      setTotalQty(qtyTotal + aqtyTotal);
    }else{return false}
  }
  
  function CreateData() {
    let dataItems = [];
        savedItems.map((dataItem) => dataItems = [...dataItems, 
            { 
                url_mobile: dataItem.linkmobile,
                url_web: dataItem.linkwebsite,
            }]);
    let data = {
      list : dataItems
    };
    axios
        .post(
          `${process.env.REACT_APP_API_BASE_URL}/iklan-ecommerce/carousel1/save`,
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(function (response) {
          setSuccessAlert(response.data.message);
          setTimeout(() => (history.push("/admin/iklan/master/iklan/carousel-1/page")), 1000);
        })
        .catch(function (error) {
              setGagalAlert(error.response.data.message);
        });
  }

  const setSuccessAlert = (id) => {
    setAlert(
      <SweetAlert
        success
        showConfirm
        confirmBtnText="Ok"
        title={id}
        onCancel={hideAlert}
        onConfirm={hideAlert}
      />
    )
  }

const setGagalAlert = (id) => {
setAlert(
  <SweetAlert
  danger
  showConfirm
  confirmBtnText="Ok"
  title={id}
  onCancel={hideAlert}
  onConfirm={hideAlert}
  />
);
};

const setQuestionAlert = () => {
setAlert(
  <SweetAlert
  warning
  showCancel
  confirmBtnText="Lanjutkan"
  confirmBtnBsStyle="danger"
  title="Apakah Kamu Yakin?"
  onConfirm={CreateData}
  onCancel={hideAlert}
  focusCancelBtn
  />
);
};

  const hideAlert = () => {
    setAlert(null);
  };


const formatRupiah = (money) => {
  return new Intl.NumberFormat('id-ID',
      { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }
  ).format(money);
}  
  

  return (
    <>
    {alert}
    <SimpleHeader name="Tambah Carousel 1" parentName="SO" />
    <Container className="mt--6" fluid>
        <Row>
        <div className="col">
            <CardBody>
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                      <h3>Tambah Carousel 1</h3>
                    </CardHeader>
                    <CardBody>
                          <Table size="sm" responsive>
                            <thead>
                              <tr>
                                <th><b>Link Website</b></th>
                                <th><b>Link Mobile</b></th>
                              </tr>
                            </thead>
                              <tbody>
                              {
                                savedItems.map((savedItem, key) => {
                                  return (
                                    <tr key={key}>
                                        <td>
                                        {/* {savedItem.linkwebsite} */}
                                            <Input
                                                autoComplete="off"
                                                placeholder="Link Website"
                                                type="text"
                                                value={savedItem.linkwebsite}
                                                onChange={(e) => {
                                                    handleLinkWebsite(key, e.target.value);
                                                }}
                                            />
                                        </td>
                                      {/* <td>{savedItem.linkmobile}</td> */}
                                      <td>
                                        {/* {savedItem.linkwebsite} */}
                                            <Input
                                                autoComplete="off"
                                                placeholder="Link Mobile"
                                                type="text"
                                                value={savedItem.linkmobile}
                                                onChange={(e) => {
                                                    handleLinkMobile(key, e.target.value);
                                                }}
                                            />
                                        </td>
                                    </tr>
                                  )
                                })
                              }
                              <tr>
                                <td>
                                <Button color="danger" onClick={()=> saveItemOrUpdate()}>
                                    Tambah
                                </Button>
                                  {/* <><Form onSubmit={handleSubmit}>
                                          <Input
                                            autoComplete="off"
                                            placeholder="Masukan Item Manual"
                                            type="search"
                                            value={queryy}
                                            onChange={(e) => setQueryy(e.target.value)}
                                          />
                                        
                                        {isSearchShoww && queryy && (
                                        <Card className="position-sticky boxShadow" style={{ maxHeight: "15.5rem", overflowY: "auto", paddingTop: "1rem", position: "relative" }}>
                                          <div style={{ position: "absolute", top: "2.5px", right: "1rem", cursor: "pointer", fontSize: "1rem" }}>
                                            <i className="fas fa-window-close text-danger" onClick={() => setIsSearchShoww(false)}></i>
                                          </div>
                                          {allItemm?.response ? (
                                            allItemm.response.map((item) => (
                                              <CardBody key={item.id} style={{ minHeight: "5rem", padding: "1rem" }} className="bgSearch" onClick={() => {saveItemOrUpdate(item);setQueryy('');setIsSearchShoww(false);}}>
                                                <div>
                                                  <b>Nama item:</b> {item.item_name}
                                                </div>
                                              </CardBody>
                                            ))
                                          ) : (
                                            <div className="text-center mb-3 text-danger">Item "{queryy}" tidak ada bosku!</div>
                                          )}
                                        </Card>
                                      )}
                                  </Form></> */}
                                  </td>
                                 </tr>
                              </tbody>
                          </Table>
                        </CardBody>
                <CardFooter>
                      <Link className="btn btn-info" to="/admin/iklan/master/iklan/carousel-1/page">
                        Kembali
                      </Link>
                      <Button color="danger" onClick={()=> setQuestionAlert()}>
                          Simpan
                      </Button>
                </CardFooter>
              </Card>
            </CardBody>
          </div>
        </Row>
    </Container>
    </>
  );
}