/*!

=========================================================
* Argon Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState} from "react";
// react library for routing
import { useLocation, NavLink as NavLinkRRD, Link } from "react-router-dom";
// nodejs library that concatenates classes
import classnames from "classnames";
// nodejs library to set properties for components
import { PropTypes } from "prop-types";
// react library that creates nice scrollbar on windows devices
import PerfectScrollbar from "react-perfect-scrollbar";
// reactstrap components
import { Collapse, NavbarBrand, Navbar, NavItem, NavLink, Nav, Input } from "reactstrap";
import axios from "axios";
// import LaporanInvoiceSoKasir from "views/pages/Laporan/LaporanInvoiceKasir/Index";
import SweetAlert from "react-bootstrap-sweetalert";

function Sidebar({ toggleSidenav, sidenavOpen, routes, logo, rtlActive }) {
const [alert, setAlert] = React.useState(null);

	const [state, setState] = React.useState({});
	const [warehouseList, setWarehouseList] = useState();
	const [warehouseName, setWarehouseName] = useState();
	const location = useLocation();
	const username = localStorage.username
	const token = localStorage.token
	// const allInfo = JSON.parse(localStorage.allinfo);
	
	// Menu Master
// 	const menuMaster = String(allInfo.privileges.filter((i) => i.privilege_name === "Menu Master").map((p) => p.read_access));
// 	const cabang = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Cabang").map((p) => p.read_access));
// 	const lokasiBarang = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Lokasi Barang").map((p) => p.read_access));
// 	const batasWaktu = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Durasi Operasional").map((p) => p.read_access));
// 	const validasiPassword = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Otoritas").map((p) => p.read_access));
// 	const ppn = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Ppn").map((p) => p.read_access));
// 	const jatuhTempo = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Durasi Tempo").map((p) => p.read_access));
// 	const supplier = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Supplier").map((p) => p.read_access));
// 	const customer = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Customer").map((p) => p.read_access));
// 	const satuan = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Satuan").map((p) => p.read_access));
// 	const item = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Item Pusat").map((p) => p.read_access));
// 	const itemRegion = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Item Region").map((p) => p.read_access));
// 	const itemToko = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Item Toko").map((p) => p.read_access));
// 	const katalog = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Katalog").map((p) => p.read_access));
// 	const promoToko = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Promo Toko").map((p) => p.read_access));
// 	const asset = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Asset").map((p) => p.read_access));
// 	const bank = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Bank").map((p) => p.read_access));
// 	const account = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Account").map((p) => p.read_access));
// 	const coa = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Coa").map((p) => p.read_access));
// 	const users = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Users").map((p) => p.read_access));
	
// 	// Menu Inventory
// 	const menuInventory = String(allInfo.privileges.filter((i) => i.privilege_name === "Menu Inventory").map((p) => p.read_access));
// 	const stok = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Stock").map((p) => p.read_access));
// 	const adjustment = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Adjustment").map((p) => p.read_access));
// 	const rakTransferStok = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Transfer Internal").map((p) => p.read_access));
// 	const transferStok = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Transfer Eksternal").map((p) => p.read_access));
// 	const receivingTransferStok = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Receiving TE").map((p) => p.read_access));
// 	const suratJalanTransferStok = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Surat Jalan TE").map((p) => p.read_access));
	
// 	// Menu Pembelian
// 	const menuPembelian = String(allInfo.privileges.filter((i) => i.privilege_name === "Menu Purchase Order").map((p) => p.read_access));
// 	const rfqPusat = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Rfq PO Pusat").map((p) => p.read_access));
// 	const rfq = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Rfq PO").map((p) => p.read_access));
// 	const orderPusat = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub PO Pusat").map((p) => p.read_access));
// 	const order = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub PO Cabang").map((p) => p.read_access));
// 	// const memoKurir = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Memo PO").map((p) => p.read_access));
// 	const receiving = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Receiving PO").map((p) => p.read_access));
// 	const invoice = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Invoice PO").map((p) => p.read_access));
// 	const poRetur = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Retur PO").map((p) => p.read_access));

// 	// Menu Penjualan
// 	const menuPenjualan = String(allInfo.privileges.filter((i) => i.privilege_name === "Menu Sales Order").map((p) => p.read_access));
// 	const posKasir = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Pos Cashier").map((p) => p.read_access));
// 	const posKasirpelunasan = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Pelunasan Cashier").map((p) => p.read_access));
// 	const posKasirsuratjalan = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Surat Jalan Cashier").map((p) => p.read_access));
// 	const ClosingKasir = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Settlement").map((p) => p.read_access));
// 	// const po = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Cetak Kasir").map((p) => p.read_access));
// 	const ReturKasir = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Retur Cashier").map((p) => p.read_access));
// 	const penawaran = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Penawaran SO").map((p) => p.read_access));
// 	const salesOrderPusat = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub SO Pusat").map((p) => p.read_access));
// 	const salesOrder = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub SO").map((p) => p.read_access));
// 	const suratjalanSo = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Surat Jalan SO").map((p) => p.read_access));
// 	const invoiceSo = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Invoice SO").map((p) => p.read_access));
// 	const ReturSO = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Retur SO").map((p) => p.read_access));
	
// 	//Menu Tracking 
// 	const menuTracking = String(allInfo.privileges.filter((i) => i.privilege_name === "Menu Tracking").map((p) => p.read_access));
// 	const kunjunganSales = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Kunjungan Sales").map((p) => p.read_access));
// 	// const lacakDriver = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Lacak Driver").map((p) => p.read_access));
// 	const lacakSales = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Lacak Sales").map((p) => p.read_access));
// 	// const lacakDriver = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Lacak Driver").map((p) => p.read_access));

// 	// Menu HRD
// 	const menuHrd = String(allInfo.privileges.filter((i) => i.privilege_name === "Menu HRD").map((p) => p.read_access));
// 	const karyawan = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Karyawan").map((p) => p.read_access));
// 	const absensi = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Absensi").map((p) => p.read_access));
// 	const Izin = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Ijin").map((p) => p.read_access));

// 	// Menu karyawan
// 	const menuKaryawan = String(allInfo.privileges.filter((i) => i.privilege_name === "Menu Karyawan").map((p) => p.read_access));
// 	const absenkaryawan = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Formulir Absensi").map((p) => p.read_access));
// 	const izinkaryawan = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Formulir Ijin").map((p) => p.read_access));
// 	// const profilkaryawan = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Profil").map((p) => p.read_access));

// 	// Menu Finance
// 	const menuFinance = String(allInfo.privileges.filter((i) => i.privilege_name === "Menu Finance").map((p) => p.read_access));
// 	const jadwalstokopname = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Stok Opname Scheduled").map((p) => p.read_access));
// 	const stokOpname = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Stok Opname").map((p) => p.read_access));
// 	const buktibankKeluar = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Bukti Bank Keluar").map((p) => p.read_access));
// 	const buktiKasKeluar = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Bukti Kas Keluar").map((p) => p.read_access));
// 	const buktireturKeluarjual = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Bukti Keluar Retur Jual").map((p) => p.read_access));
// 	const buktibankMasuk = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Bukti Bank Masuk").map((p) => p.read_access));
// 	const buktiKasMasuk = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Bukti Kas Masuk").map((p) => p.read_access));
// 	const buktireturMasukbeli = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Bukti Masuk Retur Beli").map((p) => p.read_access));
// 	const Rekonsiliasi = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Rekonsiliasi Bank").map((p) => p.read_access));
// 	const Biaya = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Biaya Lain").map((p) => p.read_access));
// 	const Pendapatan = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Pendapatan Lain").map((p) => p.read_access));
	
// 	// Menu Ecommerce
// 	const menuEcommerce = String(allInfo.privileges.filter((i) => i.privilege_name === "Menu Ecommerce").map((p) => p.read_access));
// 	const ecommerceOperasional = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Operasional").map((p) => p.read_access));
// 	// const ecommerceAdminOperasional = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Admin Operasional").map((p) => p.read_access));
// 	const ecommerceFinance = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Finance").map((p) => p.read_access));
// 	const ecommerceAdminFinance = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Admin Finance").map((p) => p.read_access));
// 	// Menu Report 
// 	const menuReport = String(allInfo.privileges.filter((i) => i.privilege_name === "Menu Laporan").map((p) => p.read_access));
// // Menu Report 
// 	const menuMigrasi = String(allInfo.privileges.filter((i) => i.privilege_name === "Menu Migrasi").map((p) => p.read_access));
	
	const successCallback = (position) => {
		// console.log(position);
	  };
	  
	  const errorCallback = (error) => {
		// console.log(error);
	  };
	  
	  navigator.geolocation.getCurrentPosition(successCallback, errorCallback);

	React.useEffect(() => {
		setState(getCollapseStates(routes));
		// getWarehouseList();
		// cekuseraccess();
		// cektoken();
		// eslint-disable-next-line
	}, []);
	//getting warehouse data
	const getWarehouseList = () => {
		axios
			.get(`${process.env.REACT_APP_API_BASE_URL}/warehouse/list/all`)
			.then(({ data }) => {
				setWarehouseList(data.response);
				if (data.response.length === 0) {
					const initalData = data.response[0];
					setWarehouseName(initalData.name);
					localStorage.setItem("warehouse", initalData.id);
				}
			})
			.catch((err) => console.log(err));
	}; 

	// useEffect(() => {
		
	// 	// cekUsername();
	//   }, []);

	const cekuseraccess = async () => {
		let data = {
			username: username,
		  }
		await axios.post(`${process.env.REACT_APP_API_BASE_URL}/users/access-checking`, data, {
			headers: {
			  Authorization: `Bearer ${token}`,
			}
		  })
		  .then(function () {
			console.log("Sudah Login Jancok");
		  })
		  .catch(function (error) {
			setGagalAlert()
			if(error.response.data.status === 400){
				window.location.href = "/auth/login";
				localStorage.removeItem("token");
				localStorage.removeItem("allinfo");
				localStorage.removeItem("authority");
				localStorage.removeItem("email");
				localStorage.removeItem("name");
				localStorage.removeItem("token");
				localStorage.removeItem("usercode");
				localStorage.removeItem("userId");
				localStorage.removeItem("username");
				localStorage.removeItem("usernametoken");
				localStorage.removeItem("warehouse");
				localStorage.removeItem("warehouseId");
				cekUsername();
			}

		  });
	};

	const setGagalAlert = (id) => {
		setAlert(
		  <SweetAlert
		  danger
		  showConfirm
		//   confirmBtnText="Ok"
		  title="Mohon Cek Jaringan Anda. Refresh Halaman ini segera. Terima Kasih"
		  onCancel={hideAlert}
		  onConfirm={hideAlert}
		  />
		);
		};
		
		
		  const hideAlert = () => {
			setAlert(null);
		  };


	const cektoken =() => {
		let data = {
			username : username,
		  };
			axios.post(`${process.env.REACT_APP_API_BASE_URL}/users/token-check`, data, {
			headers: {
			  Authorization: `Bearer ${token}`,
			}
		  })
			.then((data) => {
					console.log("Gaskeun Cok");

			})
			.catch(function (error) {
				if(error.response.data.status === 403){
					cekUsername();
					window.location.href = "/auth/login";
					localStorage.removeItem("token");
					localStorage.removeItem("allinfo");
					localStorage.removeItem("authority");
					localStorage.removeItem("email");
					localStorage.removeItem("name");
					localStorage.removeItem("token");
					localStorage.removeItem("usercode");
					localStorage.removeItem("userId");
					localStorage.removeItem("username");
					localStorage.removeItem("usernametoken");
					localStorage.removeItem("warehouse");
					localStorage.removeItem("warehouseId");
				}
			  });
	};

	const cekUsername = () => {
		let data = {
			username : username,
		  };
		axios
			.post(`${process.env.REACT_APP_API_BASE_URL}/users/logout`, data, { headers: { Authorization: `Bearer ${token}` } })
			.then(function () {
				window.location.href = "/auth/login";
				localStorage.removeItem("token");
				localStorage.removeItem("allinfo");
				localStorage.removeItem("authority");
				localStorage.removeItem("email");
				localStorage.removeItem("name");
				localStorage.removeItem("token");
				localStorage.removeItem("usercode");
				localStorage.removeItem("userId");
				localStorage.removeItem("username");
				localStorage.removeItem("usernametoken");
				localStorage.removeItem("warehouse");
				localStorage.removeItem("warehouseId");
			})
			.catch(function (error) {
				console.log(error);
			});
		};

	//add warehouse into the list
	const addWarehouseList = () => {
		if (!warehouseList) return <option> warehouse not found </option>;
		return warehouseList.map((item) => (
			<option key={item.id} data-id={item.id}>
				{item.name}
			</option>
		));
	};
	//selected change warehouse
	const onWarehouseChange = (event) => {
		const selectedWarehouse = event.target.options.selectedIndex;
		const name = event.target.value;
		setWarehouseName(name);
		const warehouseId = event.target.options[selectedWarehouse].getAttribute("data-id");
		localStorage.setItem("warehouse", warehouseId);
	};

	// verifies if routeName is the one active (in browser input)
	const activeRoute = (routeName) => {
		return location.pathname.indexOf(routeName) > -1 ? "active" : "";
	};
	// makes the sidenav normal on hover (actually when mouse enters on it)
	const onMouseEnterSidenav = () => {
		if (!document.body.classList.contains("g-sidenav-pinned")) {
			document.body.classList.add("g-sidenav-show");
		}
	};
	// makes the sidenav mini on hover (actually when mouse leaves from it)
	const onMouseLeaveSidenav = () => {
		if (!document.body.classList.contains("g-sidenav-pinned")) {
			document.body.classList.remove("g-sidenav-show");
		}
	};
	// this creates the intial state of this component based on the collapse routes
	// that it gets through routes
	const getCollapseStates = (routes) => {
		let initialState = {};
		routes.map((prop, key) => {
			if (prop.collapse) {
				initialState = {
					[prop.state]: getCollapseInitialState(prop.views),
					...getCollapseStates(prop.views),
					...initialState,
				};
			}
			return null;
		});
		return initialState;
	};
	// this verifies if any of the collapses should be default opened on a rerender of this component
	// for example, on the refresh of the page,
	// while on the src/views/forms/RegularForms.js - route /admin/regular-forms
	const getCollapseInitialState = (routes) => {
		for (let i = 0; i < routes.length; i++) {
			if (routes[i].collapse && getCollapseInitialState(routes[i].views)) {
				return true;
			} else if (location.pathname.indexOf(routes[i].path) !== -1) {
				return true;
			}
		}
		return false;
	};
	// this is used on mobile devices, when a user navigates
	// the sidebar will autoclose
	const closeSidenav = () => {
		if (window.innerWidth < 1200) {
			toggleSidenav();
		}
	};
	// this function creates the links and collapses that appear in the sidebar (left menu)
	const createLinks = (routes) => {
		return (
			routes
				// function to filter access rights
				// Menu Master
				// .filter((i) => (menuMaster !== "1" ? i.name !== "Master" : i))
				// .filter((i) => (cabang !== "1" ? i.name !== "Cabang" : i))
				// .filter((i) => (lokasiBarang !== "1" ? i.name !== "Planogram" : i))
				// .filter((i) => (batasWaktu !== "1" ? i.name !== "Batas Operasional" : i))
				// .filter((i) => (validasiPassword !== "1" ? i.name !== "Password Otoritas" : i))
				// .filter((i) => (users !== "1" ? i.name !== "Akses Pengguna" : i))
				// .filter((i) => (supplier !== "1" ? i.name !== "Supplier" : i))
				// .filter((i) => (satuan !== "1" ? i.name !== "Satuan" : i))
				// .filter((i) => (item !== "1" ? i.name !== "Item Pusat" : i))
				// .filter((i) => (itemRegion !== "1" ? i.name !== "Item Region" : i))
				// .filter((i) => (itemToko !== "1" ? i.name !== "Item Toko" : i))
				// .filter((i) => (katalog !== "1" ? i.name !== "Katalog" : i))
				// .filter((i) => (promoToko !== "1" ? i.name !== "Promo" : i))
				// .filter((i) => (customer !== "1" ? i.name !== "Customer" : i))
				// .filter((i) => (ppn !== "1" ? i.name !== "PPN" : i))
				// .filter((i) => (jatuhTempo !== "1" ? i.name !== "Jatuh Tempo" : i))
				// .filter((i) => (asset !== "1" ? i.name !== "Asset" : i))
				// .filter((i) => (bank !== "1" ? i.name !== "Bank" : i))
				// .filter((i) => (account !== "1" ? i.name !== "Akun" : i))
				// .filter((i) => (coa !== "1" ? i.name !== "Coa" : i))
				// // Menu Inventory
				// .filter((i) => (menuInventory !== "1" ? i.name !== "Inventory" : i))
        		// .filter((i) => (stok !== "1" ? i.name !== "Stok" : i))
				// .filter((i) => (adjustment !== "1" ? i.name !== "Adjustment" : i))
				// .filter((i) => (transferStok !== "1" ? i.name !== "Transfer Eksternal" : i))
				// .filter((i) => (receivingTransferStok !== "1" ? i.name !== "BPB Transfer Eksternal" : i))
				// .filter((i) => (suratJalanTransferStok !== "1" ? i.name !== "Surat Jalan Transfer Eksternal" : i))
				// .filter((i) => (rakTransferStok !== "1" ? i.name !== "Transfer Internal" : i))
				// // Menu Pembelian
				// .filter((i) => (menuPembelian !== "1" ? i.name !== "Pembelian" : i))
				// .filter((i) => (rfqPusat !== "1" ? i.name !== "Permintaan Barang Cabang" : i))
				// .filter((i) => (rfq !== "1" ? i.name !== "Permintaan Barang" : i))
				// .filter((i) => (orderPusat !== "1" ? i.name !== "Pembelian Barang Cabang" : i))
				// .filter((i) => (order !== "1" ? i.name !== "Pembelian Barang" : i))
				// // .filter((i) => (memoKurir !== "1" ? i.name !== "Memo Kurir" : i))
				// .filter((i) => (receiving !== "1" ? i.name !== "BPB Pembelian" : i))
				// .filter((i) => (invoice !== "1" ? i.name !== "Faktur Pembelian" : i))
				// .filter((i) => (poRetur !== "1" ? i.name !== "Retur Pembelian" : i))
				// // Menu Penjualan
				// .filter((i) => (menuPenjualan !== "1" ? i.name !== "Penjualan" : i))
				// .filter((i) => (posKasir !== "1" ? i.name !== "Kasir" : i))
				// .filter((i) => (posKasirpelunasan !== "1" ? i.name !== "Pelunasan Kasir" : i))
				// .filter((i) => (posKasirsuratjalan !== "1" ? i.name !== "Surat Jalan Kasir" : i))
				// .filter((i) => (ClosingKasir !== "1" ? i.name !== "Closing Kasir" : i))
				// .filter((i) => (penawaran !== "1" ? i.name !== "Penawaran" : i))
				// .filter((i) => (salesOrderPusat !== "1" ? i.name !== "Penjualan Barang Pusat" : i))
				// .filter((i) => (salesOrder !== "1" ? i.name !== "Penjualan Barang" : i))
				// .filter((i) => (suratjalanSo !== "1" ? i.name !== "Surat Jalan Penjualan" : i))
				// .filter((i) => (invoiceSo !== "1" ? i.name !== "Faktur Penjualan" : i))
				// .filter((i) => (ReturSO !== "1" ? i.name !== "Retur Penjualan" : i))
				// .filter((i) => (ReturKasir !== "1" ? i.name !== "Retur Kasir" : i))
				// // Menu Tracking
				// .filter((i) => (menuTracking !== "1" ? i.name !== "Tracking" : i))
				// .filter((i) => (kunjunganSales !== "1" ? i.name !== "Dokument Canvaser" : i))
				// .filter((i) => (lacakSales !== "1" ? i.name !== "Canvaser" : i))
				// // .filter((i) => (lacakDriver !== "1" ? i.name !== "Kurir" : i))
				// // Menu HRD
				// .filter((i) => (menuHrd !== "1" ? i.name !== "HRD" : i))
				// .filter((i) => (karyawan !== "1" ? i.name !== "Daftar Karyawan" : i))
				// .filter((i) => (absensi !== "1" ? i.name !== "Daftar Absen" : i))
				// .filter((i) => (Izin !== "1" ? i.name !== "Daftar Izin" : i))
				// // Menu Karyawan
				// .filter((i) => (menuKaryawan !== "1" ? i.name !== "Karyawan" : i))
				// .filter((i) => (absenkaryawan !== "1" ? i.name !== "Absensi" : i))
				// .filter((i) => (izinkaryawan !== "1" ? i.name !== "Izin" : i))
				// // Menu Finance
				// .filter((i) => (menuFinance !== "1" ? i.name !== "Finance" : i))
				// .filter((i) => (jadwalstokopname !== "1" ? i.name !== "Jadwal Opname" : i))
				// .filter((i) => (stokOpname !== "1" ? i.name !== "Stok Opname" : i))
				// .filter((i) => (Biaya !== "1" ? i.name !== "Biaya Lain Lain" : i))
				// .filter((i) => (Pendapatan !== "1" ? i.name !== "Pemasukan Lain Lain" : i))
				// .filter((i) => (buktiKasKeluar !== "1" ? i.name !== "BKK Pembelian" : i))
				// .filter((i) => (buktiKasMasuk !== "1" ? i.name !== "BKM Penjualan" : i))
				// .filter((i) => (buktibankKeluar !== "1" ? i.name !== "BBK Pembelian" : i))
				// .filter((i) => (buktibankMasuk !== "1" ? i.name !== "BBM Penjualan" : i))
				// .filter((i) => (buktireturKeluarjual !== "1" ? i.name !== "BK Retur Jual" : i))
				// .filter((i) => (buktireturMasukbeli !== "1" ? i.name !== "BM Retur Beli" : i))
				// .filter((i) => (Rekonsiliasi !== "1" ? i.name !== "Rekonsiliasi Bank" : i))
				
				// // Menu Ecommerce
				// .filter((i) => (menuEcommerce !== "1" ? i.name !== "E-Commerce" : i))
				// .filter((i) => (ecommerceOperasional !== "1" ? i.name !== "" : i))
				// .filter((i) => (ecommerceAdminFinance !== "1" ? i.name !== "" : i))
				// .filter((i) => (ecommerceFinance !== "1" ? i.name !== "" : i))
				// .filter((i) => (ecommerceAdminFinance !== "1" ? i.name !== "" : i))
				// // Menu Report
				// .filter((i) => (menuReport !== "1" ? i.name !== "Laporan" : i))
				// // .filter((i) => (laporanMaster !== "1" ? i.name !== "Laporan Master" : i))

				// // Migrass
				// .filter((i) => (menuMigrasi !== "1" ? i.name !== "Migrasi Data" : i))
				
				.map((prop, key) => {
					if (prop.redirect) {
						return null;
					}
					if (prop.hidden) {
						return null;
					}
					if (prop.collapse) {
						var st = {};
						st[prop["state"]] = !state[prop.state];
						return (
							<NavItem key={key}>
								<NavLink
									href="#pablo"
									data-toggle="collapse"
									aria-expanded={state[prop.state]}
									className={classnames({
										active: getCollapseInitialState(prop.views),
									})}
									onClick={(e) => {
										e.preventDefault();
										setState(st);
									}}>
									{prop.icon ? (
										<>
											<i className={prop.icon} />
											<span className="nav-link-text">{prop.name}</span>
										</>
									) : prop.miniName ? (
										<>
											<span className="sidenav-mini-icon"> {prop.miniName} </span>
											<span className="sidenav-normal"> {prop.name} </span>
										</>
									) : null}
								</NavLink>
								<Collapse isOpen={state[prop.state]}>
									<Nav className="nav-sm flex-column">{createLinks(prop.views)}</Nav>
								</Collapse>
							</NavItem>
						);
					}
					return (
						<NavItem className={activeRoute(prop.layout + prop.path)} key={key}>
							<NavLink to={prop.layout + prop.path} activeClassName="" onClick={closeSidenav} tag={NavLinkRRD}>
								{prop.icon !== undefined ? (
									<>
										<i className={prop.icon} />
										<span className="nav-link-text">{prop.name}</span>
									</>
								) : prop.miniName !== undefined ? (
									<>
										<span className="sidenav-mini-icon"> {prop.miniName} </span>
										<span className="sidenav-normal"> {prop.name} </span>
									</>
								) : (
									prop.name
								)}
							</NavLink>
						</NavItem>
					);
				})
		);
	};

	let navbarBrandProps;
	if (logo && logo.innerLink) {
		navbarBrandProps = {
			to: logo.innerLink,
			tag: Link,
		};
	} else if (logo && logo.outterLink) {
		navbarBrandProps = {
			href: logo.outterLink,
			target: "_blank",
		};
	}
// 	const scrollBarInner = (
// 		<div className="scrollbar-inner">
// 			<div className="sidenav-header d-flex align-items-center">
// 				{logo ? (
// 					<NavbarBrand {...navbarBrandProps}>
// 						<img alt={logo.imgAlt} className="navbar-brand-img" src={logo.imgSrc}/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<font size="3" align="center"><b>{code}</b></font>
// 					</NavbarBrand>	
// 				) : null}
// 				<div className="ml-auto">
					
// 				</div>
// 			</div>

// 			<div className="navbar-inner">
// 				{localStorage.getItem("authority") === "[ROLE_SUPERADMIN]" && (
// 					<Input value={warehouseName} type="select" bsSize="sm" onChange={onWarehouseChange}>
// 						{addWarehouseList()}
// 					</Input>
// 				)}
				
// 				<Collapse navbar isOpen={true}>
// 					<Nav navbar>{createLinks(routes)}</Nav>
// 					{/* <hr className="my-3" /> */}
// 					{/* <h6 className="navbar-heading p-0 text-muted">
//             <span className="docs-normal">Documentation</span>
//             <span className="docs-mini">D</span>
//           </h6> */}
// 					{/* <Nav className="mb-md-3" navbar>
//             <NavItem>
//               <NavLink
//                 href="https://demos.creative-tim.com/argon-dashboard-pro-react/#/documentation/overview?ref=adpr-sidebar"
//                 target="_blank"
//               >
//                 <i className="ni ni-spaceship" />
//                 <span className="nav-link-text">Getting started</span>
//               </NavLink>
//             </NavItem>
//             <NavItem>
//               <NavLink
//                 href="https://demos.creative-tim.com/argon-dashboard-pro-react/#/documentation/colors?ref=adpr-sidebar"
//                 target="_blank"
//               >
//                 <i className="ni ni-palette" />
//                 <span className="nav-link-text">Foundation</span>
//               </NavLink>
//             </NavItem>
//             <NavItem>
//               <NavLink
//                 href="https://demos.creative-tim.com/argon-dashboard-pro-react/#/documentation/alert?ref=adpr-sidebar"
//                 target="_blank"
//               >
//                 <i className="ni ni-ui-04" />
//                 <span className="nav-link-text">Components</span>
//               </NavLink>
//             </NavItem>
//             <NavItem>
//               <NavLink
//                 href="https://demos.creative-tim.com/argon-dashboard-pro-react/#/documentation/charts?ref=adpr-sidebar"
//                 target="_blank"
//               >
//                 <i className="ni ni-chart-pie-35" />
//                 <span className="nav-link-text">Plugins</span>
//               </NavLink>
//             </NavItem>
//           </Nav> */}
// 				</Collapse>
// 			</div>
// 		</div>
// 	);
// 	return (
// 		<Navbar
// 			className={"sidenav navbar-vertical navbar-expand-xs navbar-light bg-white " + (rtlActive ? "" : "fixed-left")}
// 			onMouseEnter={onMouseEnterSidenav}
// 			onMouseLeave={onMouseLeaveSidenav}>
// 			{navigator.platform.indexOf("Win") > -1 ? <PerfectScrollbar>{scrollBarInner}</PerfectScrollbar> : scrollBarInner}
// 		</Navbar>
// 	);
// }

const scrollBarInner = (
    <div className="scrollbar-inner">
      <div className="sidenav-header d-flex align-items-center">
        {logo ? (
          <NavbarBrand {...navbarBrandProps}>
            <img
              alt={logo.imgAlt}
              className="navbar-brand-img"
              src={logo.imgSrc}
            />
          </NavbarBrand>
        ) : null}
        <div className="ml-auto">
          <div
            className={classnames("sidenav-toggler d-none d-xl-block", {
              active: sidenavOpen,
            })}
            onClick={toggleSidenav}
          >
            <div className="sidenav-toggler-inner">
              <i className="sidenav-toggler-line" />
              <i className="sidenav-toggler-line" />
              <i className="sidenav-toggler-line" />
            </div>
          </div>
        </div>
      </div>
      <div className="navbar-inner">
		{/* untuk ganti warehouse di bawah logo cok */}
				{/* {localStorage.getItem("authority") === "[ROLE_SUPERADMIN]" && (
					<Input value={warehouseName} type="select" bsSize="sm" onChange={onWarehouseChange}>
						{addWarehouseList()}
					</Input>
				)} */}
        <Collapse navbar isOpen={true}>
          <Nav navbar>{createLinks(routes)}</Nav>
          {/* <hr className="my-3" /> */}
          {/* <h6 className="navbar-heading p-0 text-muted">
            <span className="docs-normal">Documentation</span>
            <span className="docs-mini">D</span>
          </h6> */}
          {/* <Nav className="mb-md-3" navbar>
            <NavItem>
              <NavLink
                href="https://demos.creative-tim.com/argon-dashboard-pro-react/#/documentation/overview?ref=adpr-sidebar"
                target="_blank"
              >
                <i className="ni ni-spaceship" />
                <span className="nav-link-text">Getting started</span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                href="https://demos.creative-tim.com/argon-dashboard-pro-react/#/documentation/colors?ref=adpr-sidebar"
                target="_blank"
              >
                <i className="ni ni-palette" />
                <span className="nav-link-text">Foundation</span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                href="https://demos.creative-tim.com/argon-dashboard-pro-react/#/documentation/alert?ref=adpr-sidebar"
                target="_blank"
              >
                <i className="ni ni-ui-04" />
                <span className="nav-link-text">Components</span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                href="https://demos.creative-tim.com/argon-dashboard-pro-react/#/documentation/charts?ref=adpr-sidebar"
                target="_blank"
              >
                <i className="ni ni-chart-pie-35" />
                <span className="nav-link-text">Plugins</span>
              </NavLink>
            </NavItem>
          </Nav> */}
        </Collapse>
      </div>
    </div>
  );
  return (
    <Navbar
      className={
        "sidenav navbar-vertical navbar-expand-xs navbar-light bg-white " +
        (rtlActive ? "" : "fixed-left")
      }
      onMouseEnter={onMouseEnterSidenav}
      onMouseLeave={onMouseLeaveSidenav}
    >
	{alert}
      {navigator.platform.indexOf("Win") > -1 ? (
        <PerfectScrollbar>{scrollBarInner}</PerfectScrollbar>
      ) : (
          scrollBarInner
        )}
    </Navbar>
  );
}



Sidebar.defaultProps = {
	routes: [{}],
	toggleSidenav: () => {},
	sidenavOpen: false,
	rtlActive: false,
};

Sidebar.propTypes = {
	// function used to make sidenav mini or normal
	toggleSidenav: PropTypes.func,
	// prop to know if the sidenav is mini or normal
	sidenavOpen: PropTypes.bool,
	// links that will be displayed inside the component
	routes: PropTypes.arrayOf(PropTypes.object),
	// logo
	logo: PropTypes.shape({
		// innerLink is for links that will direct the user within the app
		// it will be rendered as <Link to="...">...</Link> tag
		innerLink: PropTypes.string,
		// outterLink is for links that will direct the user outside the app
		// it will be rendered as simple <a href="...">...</a> tag
		outterLink: PropTypes.string,
		// the image src of the logo
		imgSrc: PropTypes.string.isRequired,
		// the alt for the img
		imgAlt: PropTypes.string.isRequired,
	}),
	// rtl active, this will make the sidebar to stay on the right side
	rtlActive: PropTypes.bool,
};

export default Sidebar;
