/*eslint-disable*/
import React, { useEffect, useState } from "react";
import {
	Card,
	CardBody,
	Label,
	FormGroup,
	Row,
	Input,
	CardHeader,
	CardFooter,
	Col,
	Container,
} from "reactstrap";
import { Link} from "react-router-dom";
import axios from "axios";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import moment from "moment" ;

export default function DetailHrdIzinDinas(props) {
const token = localStorage.token;
	const [description, setDescription] = useState("");
	const [tanggal, setTanggal] = useState("");
    const [name, setName] = useState("")

    useEffect(() => {
        getById();
      }, []);
    
      const getById = () => {
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        };
        axios
          .get(
            `${process.env.REACT_APP_API_BASE_URL}/absensi-dinas/get/${props.match.params.id}`,
            { headers }
          )
          .then((data) => {
            setDescription(data.data.response.keterangan);
            setName(data.data.response.username);
            setTanggal(moment(data.data.response.dinas_date, "YYYY-MM-DD").format("YYYY-MM-DD"));
           
          })
          .catch(function (error) {
            console.log(error);
          });
      };



	return (
		<>
			<div>
				<SimpleHeader name="Detail Dinas Karyawan" parentName="Master" />
				<Container className="mt--6" fluid>
					<Row>
						<div className="col">
							<CardBody>
								<Row md="12">
									<Col md="12">
										<Card className="bg-secondary shadow">
											<CardHeader className="bg-white border-0">
												<h3>Detail Dinas Karyawan</h3>
											</CardHeader>
											<CardBody>
                                                <FormGroup row>
                                                    <Label>Tanggal</Label>
                                                    <Input
                                                    disabled
                                                        className="form-control-alternative"
                                                        autoComplete="off"
                                                        id="dateRequired"
                                                        type="date"
                                                        placeholder="Masukan Tanggal"
                                                        value={tanggal}
                                                        onChange={(e) => {
                                                            setTanggal(e.target.value);
                                                        }}
                                                        />
                                                </FormGroup>  
												<FormGroup row>
													<Label>Nama</Label>
                                                        <Input
                                                        disabled
                                                            autoComplete="off"
                                                            className="form-control-alternative"
                                                            type="text"
                                                            name="deskripsi Item"
                                                            placeholder="Nama"
                                                            value={name}
                                                            onChange={(e) => {
                                                                setName(e.target.value);
                                                            }}
                                                        />
												</FormGroup>
												<FormGroup row>
													<Label>Keterangan Dinas</Label>
                                                        <Input
                                                        disabled
                                                            autoComplete="off"
                                                            className="form-control-alternative"
                                                            rows="13"
                                                            type="textarea"
                                                            name="deskripsi Item"
                                                            placeholder="Masukan Keterangan Izin"
                                                            value={description}
                                                            onChange={(e) => {
                                                                setDescription(e.target.value);
                                                            }}
                                                        />
												</FormGroup>
											</CardBody>
											<CardFooter>
												<Link className="btn btn-info" to="/admin/hrd/dinas">
													Kembali
												</Link>
											</CardFooter>
										</Card>
									</Col>
								</Row>
							</CardBody>
						</div>
					</Row>
				</Container>
			</div>
		</>
	);
}
