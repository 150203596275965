/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import { 
    Card, 
    Button, 
    Row, 
    Col, 
    CardBody, 
    CardHeader,
    Form, 
    Badge,
    Container,
    FormGroup, 
    Label, 
    Input ,
    DropdownItem,DropdownMenu, UncontrolledDropdown, DropdownToggle
} from 'reactstrap';
import { Link } from "react-router-dom";
import axios from 'axios';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import SweetAlert from "react-bootstrap-sweetalert";
import ModalCetakSJFinish from './ModalCetakSJ';
// import ModalCetakSJCeklist from './ModalCetakSJCeklist';
// import ModalCetakPickingList from './ModalCetakPackingList';
// import Halaman404 from 'views/404';

const HistoriSuratJalanPesananAplikatorPage = () => {
  const [alert, setAlert] = React.useState(null);
  const token = localStorage.token;
  const warehouse = localStorage.warehouse
  const [rowIndex, setRowIndex] = useState(0);
  const redirectPrefix = `/admin/surat-jalan-aplikator/histori/detail/`;
  const [allSuratJalanSo, setAllSuratJalanSo] = useState([]);
  const [uomCode, setUomCode] = useState("");
  const [description, setDescription] = useState("");
  const [page, setPage] = useState(1);
  const [perPage, setPerpage] = useState(10);
  const [totalItem, setTotalItem] = useState(0);
  const [currentSort, setCurrentSort] = useState("");
  const [openModalCetak1, setOpenModalCetak1] = useState(false);
  const [dataModalCetak1, setDataModalCetak1] = useState({ id: 0,});
  const toggle1 = () => setOpenModalCetak1(!openModalCetak1);
  const [openModalCetak2, setOpenModalCetak2] = useState(false);
  const [dataModalCetak2, setDataModalCetak2] = useState({ id: 0,});
  const toggle2 = () => setOpenModalCetak2(!openModalCetak2);
//   const allInfo = JSON.parse(localStorage.allinfo);
//   const suratjalanso = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Surat Jalan SO").map((p) => p.read_access));
//   const createButton = String(allInfo.privileges.filter((i) => i.privilege_name === "Tab Surat Jalan SO").map((p) => p.create_access));
//   const updateButton = String(allInfo.privileges.filter((i) => i.privilege_name === "Tab Surat Jalan SO").map((p) => p.update_access));
//   const cetak = String(allInfo.privileges.filter((i) => i.privilege_name === "Tab Surat Jalan SO").map((p) => p.create_access));
 
  const displayModalCetak1 = (id) => {
    setDataModalCetak1({
      id: id,
    });
    setOpenModalCetak1(true);
  };

  // const displayModalCetak2 = (id) => {
  //   setDataModalCetak2({
  //     id: id,
  //   });
  //   setOpenModalCetak2(true);
  // };
  
  let paginationOption = {
    page: page,
    alwaysShowAllBtns: true,
    override: true,
    showTotal: true,
    withFirstAndLast: false,
    sizePerPage: perPage,
    totalSize: totalItem,
    onPageChange: (page) => {
      updateDataTable(page, perPage, currentSort, uomCode, description);
    },
    sizePerPageRenderer: () => (
      <div className="dataTables_length" id="datatable-basic_length">
        <label>
          Show{" "}
          {
            <select
              name="datatable-basic_length"
              aria-controls="datatable-basic"
              className="form-control form-control-sm"
              onChange={(e) => {
                updateDataTable(page, e.target.value, currentSort)
              }}
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="25">25</option>
              <option value="50">50</option>
            </select>
          }{" "}
          entries.
        </label>
      </div>
    ),
  }

  const updateDataTable = (page, perPage, sort, uomCode, description) => {
    getSuratJalan(page, perPage, sort, uomCode, description);
    setPage(page);
    setPerpage(perPage);
    setRowIndex((page - 1) * perPage);
    setCurrentSort(sort);
    setUomCode(uomCode);
    setDescription(description);
  }

  const handleTableChange = (type, { sortField, sortOrder }) => {
    if (type === "sort") {
      let sort = `${sortField} ${sortOrder}`
      updateDataTable(page, perPage, sort,  uomCode, description)
    }
  }

  
  useEffect(() => {
    getSuratJalan(page, perPage, currentSort);
  }, []);

  // fungsi dari ambil data
  const getSuratJalan = (page, perPage, currentSort, sj_code = null, status_sj = null) => {
    
    let filter = { 
      
      page: page, 
      per_page: perPage,
      warehouse_id : parseInt(warehouse),
      
    };
    if (sj_code !== null) {
      filter = Object.assign(filter, { sj_code: sj_code })
    }
    if (status_sj !== null) {
        filter = Object.assign(filter, { status_sj: status_sj })
    }
    const data = filter;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/surat-jalan-apk/page`, data, {
        headers,
      })
      .then((data) => {
        setAllSuratJalanSo(data.data.response);
        setPage(data.data.current_page + 1);
        setPerpage(data.data.per_page);
        setTotalItem(data.data.total_item);
      })
      .catch(function (error) {
        setAllSuratJalanSo(error.response.data.response);
      });
  }

  const setGagalAlert = (id) => {
    setAlert(
      <SweetAlert
        danger
        showConfirm
        confirmBtnText="Ok"
        title={id}
        onCancel={hideAlert}
        onConfirm={hideAlert}
      />
    );
  };

  const hideAlert = () => {
    setAlert(null);
  };

  const reset = () => {
    setUomCode("");
    setDescription("");
    updateDataTable(1, perPage, currentSort, "", "");
  }

  return (
    <div>
      {alert}
      <SimpleHeader name="Histori" parentName="PO" />
        <Container className="mt--6" fluid>
          <Row>
            <div className="col">
            <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div style={{ textAlign: 'left' }}>
                        <Link className="btn btn-link" to="/admin/surat-jalan-aplikator">
                          <i className="fa fa-arrow-circle-left fa-2x" /> 
                        </Link>
                    </div>
                    <div style={{ textAlign: 'right' }}>
                    </div>
                  </div>
                </CardHeader>
                <CardBody>
                        <Form>
                          <Row md="12">
                            <Col md="3">
                              <FormGroup>
                                <Label
                                  className="form-control-label"
                                  htmlFor="exampleFormControlSelect3"
                              >Kode SJ</Label>
                                <Input
                                className="form-control-alternative"
                                  type="text"
                                  placeholder="Masukan Kode SJ"
                                  value={uomCode}
                                  onChange={e => updateDataTable(1, perPage, currentSort, e.target.value, description)}
                                />
                              </FormGroup>
                            </Col>
                            <Col md="3">
                              <FormGroup> 
                            <Label
                                className="form-control-label"
                                htmlFor="exampleFormControlSelect3"
                            >
                                Status
                            </Label>
                            <Input
                                name="account"
                                type="select"
                                value={description}
                                onChange={(e) =>
                                updateDataTable(1, perPage, currentSort,uomCode,e.target.value)
                                }
                            >
                                <option value={""}>Pilih Status</option>
                                <option value={3}>Proses</option>
                                <option value={4}>Tolak</option>
                                <option value={5}>Setuju</option>
                            </Input>
                              </FormGroup>
                          </Col>
                            {/* <Col md="3">
                              <FormGroup>
                                <Label
                                  className="form-control-label"
                                  htmlFor="exampleFormControlSelect3"
                              >Kode So</Label>
                                <Input
                                className="form-control-alternative"
                                  type="text"
                                  placeholder="Masukan Kode So"
                                  value={description}
                                  onChange={e => updateDataTable(1, perPage, currentSort, uomCode, e.target.value)}
                                />
                              </FormGroup>
                            </Col> */}
                          </Row>
                          <Row>
                            <Col>
                              <Button type="button" onClick={reset} color="secondary">Reset</Button>
                            </Col>
                          </Row>
                        </Form>
                        {allSuratJalanSo === null ? (
                        <div>
                          <ToolkitProvider
                                  rowNumber={rowIndex}
                                  data={""}
                                  keyField="id"
                                  columns={[
                                  {
                                    dataField: "no",
                                    text: "#",
                                    sort: true,
                                    page: 1,
                                    formatter: (cell, row, index) => {
                                    let currentRow = ++index;
                                    return currentRow + rowIndex;
                                    },
                                },
                                {
                                  dataField: "",
                                  text: "Tanggal",
                                  sort: true,
                                  },
                                  {
                                    dataField: "",
                                    text: "Kode SJ",
                                    sort: true,
                                },
                                  {
                                    dataField: "",
                                    text: "Status",
                                    sort: true,
                                },
                                  ]}
                              >
                                  {(props) => (
                                  <div className="py-4 table-responsive">
                                      <BootstrapTable
                                      remote
                                      {...props.baseProps}
                                      bootstrap4={true}
                                      bordered={false}
                                      hover={true}
                                      onTableChange={handleTableChange}
                                      />
                                  </div>
                                  )}
                          </ToolkitProvider>
                          <CardHeader>
                              <center><h3>DATA NOT FOUND</h3></center>
                          </CardHeader>
                        </div>
                        ):(
                      <ToolkitProvider
                              rowNumber={rowIndex}
                              data={allSuratJalanSo}
                              keyField="id"
                              columns={[
                              {
                                  dataField: "no",
                                  text: "#",
                                  sort: true,
                                  page: 1,
                                  formatter: (cell, row, index) => {
                                  let currentRow = ++index;
                                  return currentRow + rowIndex;
                                  },
                              },
                              {
                                dataField: "transaction_date",
                                text: "Tanggal",
                                sort: true,
                              },
                              {
                                  dataField: "sj_code",
                                  text: "Kode SJ",
                                  sort: true,
                              },
                              {
                                dataField: "",
                                text: "Status",
                                sort: true,
                                formatter: (cell, row) => {
                                  return row.status_sj === 3
                                    ? 
                                      <Badge color="" className="badge-dot mr-4">
                                        <i className="bg-danger" />
                                        Proses
                                      </Badge>
                                    : row.status_sj === 4
                                    ? <Badge color="" className="badge-dot mr-4">
                                        <i className="bg-warning" />
                                        Tidak Setuju
                                      </Badge>
                                    : <Badge color="" className="badge-dot mr-4">
                                        <i className="bg-success" />
                                        Setuju
                                      </Badge>;
                                },
                            },
                            {
                                dataField: "",
                                text: "",
                                formatter: (cell, row, index) => {
                                  return row.status_sj === 3
                                    ? <UncontrolledDropdown nav>
                                        <DropdownToggle color="danger">
                                            Tindakan
                                          </DropdownToggle>
                                          <DropdownMenu center>
                                                <Link  to={redirectPrefix + row.id}
                                                  id={"tooltip_" + row.id}>
                                                <DropdownItem>
                                                  <i className="fas fa-user-edit" /><span>Detail</span>
                                                </DropdownItem>
                                                </Link>
                                          </DropdownMenu>
                                      </UncontrolledDropdown>
                                    : row.status_sj === 4
                                    ?  <UncontrolledDropdown nav>
                                    <DropdownToggle color="danger">
                                        Tindakan
                                      </DropdownToggle>
                                      <DropdownMenu center>
                                            <Link  to={redirectPrefix + row.id}
                                              id={"tooltip_" + row.id}>
                                            <DropdownItem>
                                              <i className="fas fa-user-edit" /><span>Detail</span>
                                            </DropdownItem>
                                            </Link>
                                      </DropdownMenu>
                                  </UncontrolledDropdown>
                                    : <UncontrolledDropdown nav>
                                    <DropdownToggle color="danger">
                                        Tindakan
                                      </DropdownToggle>
                                      <DropdownMenu center>
                                            {/* <DropdownItem
                                                onClick={() => displayModalCetak2(row.id)}
                                              >
                                                <i className="fas fa-print" />
                                                <span>Picking List</span>
                                            </DropdownItem> */}
                                            <DropdownItem
                                              onClick={() => displayModalCetak1(row.id)}
                                            >
                                              <i className="fas fa-print" />
                                              <span>Cetak</span>
                                            </DropdownItem>
                                            <Link  to={redirectPrefix + row.id}
                                              id={"tooltip_" + row.id}>
                                            <DropdownItem>
                                              <i className="fas fa-user-edit" /><span>Detail</span>
                                            </DropdownItem>
                                            </Link>
                                      </DropdownMenu>
                                  </UncontrolledDropdown>
                                },
                              },
                              ]}
                          >
                              {(props) => (
                              <div className="py-4 table-responsive">
                                  <BootstrapTable
                                  remote
                                  {...props.baseProps}
                                  bootstrap4={true}
                                  bordered={false}
                                  hover={true}
                                  pagination={paginationFactory({ ...paginationOption })}
                                  onTableChange={handleTableChange}
                                  />
                              </div>
                              )}
                      </ToolkitProvider>
                        )}
                </CardBody>
              </Card>
            </div>
          </Row>
           {openModalCetak1 && (
            <ModalCetakSJFinish
              open={openModalCetak1}
              data={dataModalCetak1}
              toggle={toggle1}
            />
          )}
           {/* {openModalCetak2 && (
            <ModalCetakPickingList
              open={openModalCetak2}
              data={dataModalCetak2}
              toggle={toggle2}
            />
          )} */}
        </Container>
    </div>
  );
}

export default HistoriSuratJalanPesananAplikatorPage;