/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import { 
    Card, 
    Button, 
    Row, 
    Col, 
    CardBody, 
    CardHeader,
    Form, 
    Badge,
    FormGroup, 
    Container,
    Label, 
    Input ,
    DropdownItem,DropdownMenu, UncontrolledDropdown, DropdownToggle
} from 'reactstrap';
import { Link } from "react-router-dom";
import axios from 'axios';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import SweetAlert from "react-bootstrap-sweetalert";
import Halaman404 from 'views/404';
import filterFactory from 'react-bootstrap-table2-filter';
import DetailAfiliasi from './Detail';

const AfiliasiPage = () => {
  const [alert, setAlert] = React.useState(null);
  const token = localStorage.token;
  const username = localStorage.username;
  const warehouse = parseInt(localStorage.warehouse);
  const redirectPrefix = `/admin/master/kurir/edit/`;
  const [rowIndex, setRowIndex] = useState(0);
  const [allKurir, setAllKurir] = useState([]);
  const [status, setStatus] = useState("");
  const [kurir, setKurir] = useState("");
  const [page, setPage] = useState(1);
  const [perPage, setPerpage] = useState(10);
  const [totalItem, setTotalItem] = useState(0);
  const [currentSort, setCurrentSort] = useState("");
  const [selectDetailTurunan, setSelectDetailTurunan] = useState(0);

  let paginationOption = {
    page: page,
    alwaysShowAllBtns: true,
    override: true,
    showTotal: true,
    withFirstAndLast: false,
    sizePerPage: perPage,
    totalSize: totalItem,
    onPageChange: (page) => {
      updateDataTable(page, perPage, currentSort, kurir, status);
    },
    sizePerPageRenderer: () => (
      <div className="dataTables_length" id="datatable-basic_length">
        <label>
          Show{" "}
          {
            <select
              name="datatable-basic_length"
              aria-controls="datatable-basic"
              className="form-control form-control-sm"
              onChange={(e) => {
                updateDataTable(page, e.target.value, currentSort)
              }}
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="25">25</option>
              <option value="50">50</option>
            </select>
          }{" "}
          entries.
        </label>
      </div>
    ),
  }

  const updateDataTable = (page, perPage, sort, kurir,status ) => {
    getPenawaranSo(page, perPage, sort,kurir, status );
    setPage(page);
    setPerpage(perPage);
    setRowIndex((page - 1) * perPage);
    setCurrentSort(sort);
    setKurir(kurir);
    setStatus(status);
  }

  const handleTableChange = (type, { sortField, sortOrder }) => {
    if (type === "sort") {
      let sort = `${sortField} ${sortOrder}`
      updateDataTable(page, perPage, sort, kurir, status)
    }
  }

  
  useEffect(() => {
    getPenawaranSo(page, perPage, currentSort);
  }, []);

  // fungsi dari ambil data
  const getPenawaranSo = async (page, perPage, currentSort, name = null, active_flag = null) => {
    
    let filter = { 
      
      page: page, 
      per_page: perPage,
    };
    if (name !== null) {
      filter = Object.assign(filter, { name: name })
    }
    if (active_flag !== null) {
        filter = Object.assign(filter, { active_flag: active_flag })
    }
    const data = filter;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/users/page`, data, {
        headers,
      })
      .then((data) => {
        setAllKurir(data.data.response);
        setPage(data.data.current_page + 1);
        setPerpage(data.data.per_page);
        setTotalItem(data.data.total_item);
      })
      .catch(function (error) {
        setAllKurir(error.response.data.response);
      });
  }

  const setGagalAlert = (id) => {
    setAlert(
      <SweetAlert
        danger
        showConfirm
        confirmBtnText="Ok"
        title={id}
        onCancel={hideAlert}
        onConfirm={hideAlert}
      />
    );
  };

  const hideAlert = () => {
    setAlert(null);
  };

  const reset = () => {
    setKurir("");
    setStatus("");
    updateDataTable(1, perPage, currentSort, "", "");
  }

  const formatRupiah = (money) => {
    return new Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR", minimumFractionDigits: 0 }).format(money);
};

const expandRow = {
    parentClassName: 'expanding-foo',
    onlyOneExpanding: true,
    renderer: (row, rowIndex) => (
      setSelectDetailTurunan(row.id),
    <div>
      <DetailAfiliasi code={selectDetailTurunan} />
    </div>
  ),
  showExpandColumn: true,
  expandByColumnOnly: true
};

  return (
    <div>
      {alert}
        <SimpleHeader name="Afiliasi" parentName="" />
          <Container className="mt--6" fluid>
          <Row>
            <div className="col">
            <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div style={{ textAlign: 'left' }}>
                    </div>
                  </div>
                </CardHeader>
                <CardBody>
                        <Form>
                          <Row md="12">
                              <Col md="3">
                              <FormGroup>
                                <Label
                                  className="form-control-label"
                                  htmlFor="exampleFormControlSelect3"
                              >Nama</Label>
                                <Input
                                className="form-control-alternative"
                                  type="text"
                                  placeholder="Masukan Nama"
                                  value={kurir}
                                  onChange={e => updateDataTable(1, perPage, currentSort, e.target.value, status)}
                                />
                              </FormGroup>
                            </Col>
                            {/* <Col md="3">
                              <FormGroup>
                                <Label
                                  className="form-control-label"
                                  htmlFor="exampleFormControlSelect3"
                              >Status</Label>
                                <Input
                                className="form-control-alternative"
                                  type="select"
                                  placeholder="Masukan Customer"
                                  value={status}
                                  onChange={e => updateDataTable(1, perPage, currentSort, kurir, e.target.value)}
                                >
                                    <option value="">Pilih Status</option>
                                    <option value={1}>Aktif</option>
                                    <option value={2}>Tidak Aktif</option>
                                </Input>
                              </FormGroup>
                            </Col> */}
                          </Row>
                          <Row>
                            <Col>
                              <Button type="button" onClick={reset} color="secondary">Reset</Button>
                            </Col>
                          </Row>
                        </Form>
                        {allKurir === null ? (
                        <div>
                          <ToolkitProvider
                                  rowNumber={rowIndex}
                                  data={""}
                                  keyField="id"
                                  columns={[
                                //   {
                                //     dataField: "no",
                                //     text: "#",
                                //     sort: true,
                                //     page: 1,
                                //     formatter: (cell, row, index) => {
                                //     let currentRow = ++index;
                                //     return currentRow + rowIndex;
                                //     },
                                // },
                                {
                                    dataField: "",
                                    text: "Level",
                                    sort: true,
                                  },
                                  {
                                    dataField: "",
                                    text: "Nama",
                                    sort: true,
                                  },
                                  {
                                    dataField: "",
                                    text: "Code Referal",
                                    sort: true,
                                  },
                                  {
                                    dataField: "",
                                    text: "Saldo",
                                    sort: true,
                                    // formatter: (value) => formatRupiah(value)
                                  },
                                  ]}
                              >
                                  {(props) => (
                                  <div className="py-4 table-responsive">
                                      <BootstrapTable
                                      remote
                                      {...props.baseProps}
                                      bootstrap4={true}
                                      bordered={false}
                                      hover={true}
                                      onTableChange={handleTableChange}
                                      />
                                  </div>
                                  )}
                          </ToolkitProvider>
                          <CardHeader>
                              <center><h3>DATA NOT FOUND</h3></center>
                          </CardHeader>
                        </div>
                        ):(
                      <ToolkitProvider
                              rowNumber={rowIndex}
                              data={allKurir}
                              keyField="id"
                              columns={[
                            //   {
                            //       dataField: "no",
                            //       text: "#",
                            //       sort: true,
                            //       page: 1,
                            //       formatter: (cell, row, index) => {
                            //       let currentRow = ++index;
                            //       return currentRow + rowIndex;
                            //       },
                            //   },
                              {
                                dataField: "member_level",
                                text: "Level",
                                sort: true,
                              },
                              {
                                dataField: "name",
                                text: "Nama",
                                sort: true,
                              },
                              {
                                dataField: "user_code",
                                text: "Code Referal",
                                sort: true,
                              },
                              {
                                dataField: "wallet",
                                text: "Saldo",
                                sort: true,
                                formatter: (value) => formatRupiah(value)
                              },
                            //   {
                            //     dataField: "active_flag",
                            //     text: "Status",
                            //     sort: true,
                            //     formatter: (cell, row) => {
                            //       return row.active_flag === 1
                            //         ? <Badge color="" className="badge-dot mr-4">
                            //             <i className="bg-success" />
                            //             Aktif
                            //           </Badge>
                            //         : <Badge color="" className="badge-dot mr-4">
                            //             <i className="bg-danger" />
                            //             Tidak Aktif
                            //           </Badge>;
                            //     },
                            // },
                            //   {
                            //       dataField: "", text: "", formatter: (cell, row, index) => {
                            //       return (
                            //         <UncontrolledDropdown nav>
                            //         <DropdownToggle className="btn btn-danger" color="" tag="a">
                            //               Tindakan
                            //         </DropdownToggle>
                            //         <DropdownMenu>
                            //               <Link  to={redirectPrefix + row.id}
                            //                 id={"tooltip_" + row.id}>
                            //               <DropdownItem>
                            //                 <i className="fas fa-user-edit" /><span>Detail Afiliasi</span>
                            //               </DropdownItem>
                            //               </Link>
                            //         </DropdownMenu>
                            //     </UncontrolledDropdown>
                            //         )
                            //       }
                            //     },
                              ]}
                          >
                              {(props) => (
                              <div className="py-4 table-responsive">
                                  <BootstrapTable
                                  remote
                                  {...props.baseProps}
                                  bootstrap4={true}
                                  bordered={false}
                                  hover={true}
                                  pagination={paginationFactory({ ...paginationOption })}
                                  onTableChange={handleTableChange}
                                  expandRow={ expandRow }
                                  filter={ filterFactory() }
                                  />
                              </div>
                            )}
                      </ToolkitProvider>
                        )}
                </CardBody>
              </Card>
            </div>
          </Row>
          </Container>
    </div>
  );
}

export default AfiliasiPage;