/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Card,
  CardBody,
  Row,
  Container,
  CardImg,
} from "reactstrap";
import { Link } from "react-router-dom";
import SimpleHeader from "components/Headers/SimpleHeader.js";

export default function MGMPage() {

  return (
    <>
      <SimpleHeader name="Penarikan Komisi" parentName="Report" />
      
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
                <CardBody>
                    <Row>
                        <div className="card-columns">
                            <Card>
                                <Link to="/admin/master/mgm/mgm/page">
                                    <CardImg
                                        alt="..."
                                        src={require("assets/img/theme/penarikan-komisi.png").default}
                                        top
                                    />
                                    
                                </Link>
                            </Card>
                            <Card>
                                <Link to="/admin/master/mgm/finance/page">
                                    <CardImg
                                        alt="..."
                                        src={require("assets/img/theme/validasi-kepala-finance.png").default}
                                        top
                                    />
                                </Link>
                            </Card>
                            <Card>
                                <Link to="/admin/master/mgm/histori/page">
                                    <CardImg
                                        alt="..."
                                        src={require("assets/img/theme/history.png").default}
                                        top
                                    />
                                </Link>
                            </Card>
                        </div>
					</Row>
                </CardBody>
          </div>
        </Row>
      </Container>
      
    </>
  );
}