/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { Card, Button, Row, Col, CardBody, CardHeader, CardFooter, Table, Container, Form,FormFeedback, FormGroup, Label, Input} from "reactstrap";
import { Link,useHistory } from "react-router-dom";
import axios from "axios";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import SweetAlert from "react-bootstrap-sweetalert";
import Halaman404 from "views/404";

const CreateSoReturProyek = () => {
const [alert, setAlert] = React.useState(null);
const token = localStorage.token;
  const username = localStorage.username;
  const warehouse = localStorage.warehouse;
  let history = useHistory();
  const [customers, setCustomers] = useState([]);
  const [customer, setCustomer] = useState("");
  const [codeinvoice,setCodeInvoice ] = useState("");
  const [payment_method,setPaymentMethod] = useState(0);
  const [keterangan, setKeterangan] = useState("");
  const [allJangkaWaktu, setAllJangkaWaktu] = useState([]);
  const [jangkaWaktu,setJangkaWaktu] = useState(1);
  const [totalPrice, setTotalPrice] = useState(0);
  const [savedItems, setSavedItems] = useState([]);
  const [ongkir, setOngkir] = useState(0);
  const [allpajak, setAllPajak] = useState([]);
  const [pajak, setPajak] = useState(1);
  const [ppn, setPPN] = useState(0);
  const [totaldiskon, setTotalDiskon] = useState(0);
  const [grandtotal, setGrandTotal] = useState(0);
  const [diskonglobalnominal, setDiskonGlobalNominal] = useState(0);
  const [diskonglobalpersen, setDiskonGlobalPersen] = useState(0);
  const [ongkir1, setOngkir1] = useState(0);
  const [ppnnew, setPPNNEW] = useState(0);
  const [diskonglobal, setDiskonGLobal] = useState(0);
  const [totalPrice1, setTotalPrice1] = useState(0);
  const [a, setA] = useState(0);
  const [b, setB] = useState(0);
  const [diskonglobalnominal1,setDiskonGlobalNominal1] = useState(0)
  const [totaldiskon2, setTotalDiskon2] = useState(0);
  const [totaldiskon3, setTotalDiskon3] = useState(0);
  const [editable, setEditable] = useState(false);
  const [qtyTotal, setTotalQty] = useState(0);
  const [diskontotal, setDiskonTotal] = useState(0);
  const [isSearchShow, setIsSearchShow] = useState(false);
  const headers = { Authorization: `Bearer ${token}`};
  const [allItem, setAllItem] = useState([]);
  const [query, setQuery] = useState("");
  const [bankcustomer, setBankCustomer] = useState("")
  const [bankcustomers, setBankCustomers] = useState([])
  const [customername, setCustomerName] = useState("")
  const [qtyError, setQtyError] = useState(null);
  const allInfo = JSON.parse(localStorage.allinfo);
  const ReturSoProyeks = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Retur SO").map((p) => p.read_access));
  
    const validateForm = () => {
        let error = false;
        savedItems.map((savedItem, key) => { 
          if (savedItem.qty > savedItem.qtysisa) {
            setQtyError("invalid");
            error = true;
          }
        })
        return error;
    };

    const handleQtySisa = (e) => {
      e.preventDefault();
      if (!validateForm()) {
          setQuestionAlert()
      }
    }

  const searchShow = (item) => {
    AmbilDataCok(item.invoice_code);
    setIsSearchShow(false);
  };

  useEffect(() => {
    const getData = setTimeout(async() => {
      if(query != ""){
        axios
        .post(`${process.env.REACT_APP_API_BASE_URL}/invoice-so/page`, {  
          page: 1,
          per_page: 10,
          status_d:5,
          active_flag : 2,
          invoice_code:  query,
          warehouse_id: parseInt(warehouse)
        },{ headers })
        .then((res) => {
          setAllItem(res.data);
          setIsSearchShow(true);
          
        })
        .catch(function (error) {
          setGagalAlert(error.response.data.message);
        });
      }}, 1000)
      return () => clearTimeout(getData)
  }, [query]);
  
const AmbilDataCok = (id) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/invoice-so/get-by-code/${id}`,
        { headers }
      )
      .then((data) => {
        setCustomer(data.data.response.customer_id);
        setCustomerName(data.data.response.customer_name);
        getbankCustomer(data.data.response.customer_id);
        setBankCustomer(data.data.response.account_number);
        getCustomer(data.data.response.customer_id);
        setCodeInvoice(data.data.response.invoice_code);
        getPajak(data.data.response.pajak_id);
        setOngkir(data.data.response.ongkir);
        setTotalQty(data.data.response.price_total);
        setDiskonTotal(data.data.response.diskon_total);
        setKeterangan(data.data.response.keterangan);
        setTotalPrice(data.data.response.price_total);
        getJangkaWaktu(data.data.response.jangka_waktu);
        setPaymentMethod(data.data.response.payment_method);
        setDiskonGlobalNominal(data.data.response.diskon_global_nominal);
        setDiskonGlobalPersen(data.data.response.diskon_global_persen);
        getItemDataSaved(data.data.response.invoice_code);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getItemDataSaved = (id) => {
    axios.post(`${process.env.REACT_APP_API_BASE_URL}/invoice-so/item-by-code`, {

        code_invoice: id

    }).then(async response => {
        let stateItem = [];
        await Promise.all(response.data.response.map(async (data) => {
        //   console.log(data);
            stateItem = [...stateItem, {
                item_id: data.item_id,
                item_name:data.item_name,
                item_code:data.item_code,
                qtysisa: data.max_qty,
                qty: data.qty,
                satuan:data.satuan,
                harga: data.harga,
                diskon_persen: data.diskon_persen,
                diskon_nominal: data.diskon_nominal,
                data: {
                  item_id: data.item_id,
                  item_name: data.item_name,
                  harga: data.harga,
                  item_code:data.item_code,
                  qtysisa: data.max_qty,
                  qty: data.qty,
                  diskon_persen: data.diskon_persen,
                  diskon_nominal: data.diskon_nominal,
              },
          }];
      }));
      setSavedItems(stateItem);
  })
}

useEffect(() => {
  setDiskonGlobalNominal1(diskonglobalnominal);
}, [diskonglobalnominal]);

  // diskon bayangan
  useEffect(() => {
  setTotalDiskon(diskonglobalnominal1);
}, [diskonglobalnominal1]);

useEffect(() => {
  setTotalDiskon3(diskontotal-a);
}, [diskontotal, a]);

  //diskon tampil
  useEffect(() => {
  setTotalDiskon2(totaldiskon - a);
}, [totaldiskon3, totaldiskon, a]);

//ongkir tampil
useEffect(() => {
  setOngkir1(ongkir - b);
}, [ongkir, b]);


  useEffect(() => {
  setTotalPrice1(qtyTotal);
}, [qtyTotal]);


  // diskonglobalnominal dan persen
  useEffect(() => {
    diskonglobalnominal && diskonglobal === "diskonglobalnominal" && setDiskonGlobalPersen((diskonglobalnominal / qtyTotal ) * 100);
    diskonglobalpersen && diskonglobal === "diskonglobalpersen"  && setDiskonGlobalNominal((qtyTotal) * (diskonglobalpersen/100));
  }, [diskonglobalnominal, diskonglobal, qtyTotal, diskonglobalpersen,diskonglobalpersen]);

  // hasil nominal dari PPN
  useEffect(() => {
      setPPNNEW( (qtyTotal - totaldiskon) * (ppn / 100));
 }, [qtyTotal,totaldiskon,ppn]);

  // hasil grandtotal
useEffect(() => {
  setGrandTotal(( qtyTotal - totaldiskon2) + ppnnew + ongkir1- a );
}, [qtyTotal, totaldiskon2,ppnnew, ongkir1, a]);


	useEffect(() => {
        getById();
	}, [pajak]);

	const getById = () => {
	    const headers = {
	      "Content-Type": "application/json",
	      Authorization: `Bearer ${token}`,
	    };
	    axios
	      .get(
	        `${process.env.REACT_APP_API_BASE_URL}/pajak/${pajak}`,
	        { headers }
	      )
	      .then((data) => {;
	        setPPN(data.data.response.persentase);
	      })
	      .catch(function (error) {
	        console.log(error);
	      });
	  };

    const handleEditQty = (index, value) => {
      let updateList = savedItems;
      let minTotal = (parseInt(updateList[index].harga)- parseInt(updateList[index].diskon_nominal)) * parseInt(updateList[index].qty) ;
      let plusTotal = (parseInt(updateList[index].harga)- parseInt(updateList[index].diskon_nominal)) * value ;
      let aqtyTotala = (parseInt(updateList[index].harga) - parseInt(updateList[index].diskon_nominal))* value ;
      let aqtyTotal = (parseInt(updateList[index].harga) - parseInt(updateList[index].diskon_nominal)) * parseInt(updateList[index].qty) ;
      let diskonnominal1 = parseInt(updateList[index].diskon_nominal) * value ;
      let diskonnominal2 = parseInt(updateList[index].qty) * parseInt(updateList[index].diskon_nominal);
      
      if(!isNaN(value)&&value.length>0){
        updateList[index] = {...updateList[index], qty: value};
        setSavedItems(updateList);
        setTotalPrice(totalPrice+plusTotal-minTotal);
        setTotalQty(qtyTotal + aqtyTotala - aqtyTotal);
        // setDiskonTotal(diskontotal + diskonnominal1 - diskonnominal2)
      }else{return false}
    }
    
    const handleEditHarga = (index, value) => {
      if(!isNaN(value)&&value.length>0){
        let updateList = savedItems;
        let minTotal = (parseInt(updateList[index].harga)- parseInt(updateList[index].diskon_nominal)) * parseInt(updateList[index].qty) ;
        let aqtyTotal = (parseInt(updateList[index].harga) - parseInt(updateList[index].diskon_nominal))* parseInt(updateList[index].qty)  ;
        updateList[index] = {...updateList[index], harga: value};
        setSavedItems(updateList);
        let plusTotal = parseInt(updateList[index].qty) * (value - parseInt(updateList[index].diskon_nominal));
        setTotalPrice(totalPrice + plusTotal - minTotal);
        let aqtyTotala = parseInt(updateList[index].qty) * (value  - parseInt(updateList[index].diskon_nominal));
        setTotalQty(qtyTotal + aqtyTotala - aqtyTotal);
      }else{return false}
    }
    
    const handleEditDiskonNominal = (index, value) => {
      if(!isNaN(value)&&value.length>0){
        let updateList = savedItems;
        let diskonnominal1 = parseInt(updateList[index].qty) * value ;
        let diskonnominal2 = parseInt(updateList[index].qty) * parseInt(updateList[index].diskon_nominal);
        let persentasediskonnya = value / parseInt(updateList[index].harga) * 100;
        let aqtyTotal = (parseInt(updateList[index].harga)- parseInt(updateList[index].diskon_nominal)) * parseInt(updateList[index].qty) ;
        let minTotal = (parseInt(updateList[index].harga)- parseInt(updateList[index].diskon_nominal)) * parseInt(updateList[index].qty) ;
        updateList[index] = {...updateList[index], diskon_nominal: value, diskon_persen: persentasediskonnya};
        setSavedItems(updateList);
        let plusTotal = parseInt(updateList[index].qty) * (parseInt(updateList[index].harga) - parseInt(updateList[index].diskon_nominal));
        setTotalPrice(totalPrice+plusTotal-minTotal);
        let aqtyTotala = (parseInt(updateList[index].harga) - parseInt(updateList[index].diskon_nominal)) * parseInt(updateList[index].qty );
        setTotalQty(qtyTotal + aqtyTotala - aqtyTotal);
        // setDiskonTotal(diskontotal + diskonnominal1 - diskonnominal2)
      }else{return false}
    }
    
    const handleEditDiskonPersen = (index, value) => {
      if(!isNaN(value)&&value.length>0){
        let updateList = savedItems;
        let  nominaldiskonnya = parseInt(updateList[index].harga) * value/100;
        let minTotal = (parseInt(updateList[index].harga) - parseInt(updateList[index].diskon_nominal)) * parseInt(updateList[index].qty) ;
        let aqtyTotal = (parseInt(updateList[index].harga)- parseInt(updateList[index].diskon_nominal)) * parseInt(updateList[index].qty) ;
        updateList[index] = {...updateList[index], diskon_nominal: nominaldiskonnya, diskon_persen: value};
        setSavedItems(updateList);
        let plusTotal = (parseInt(updateList[index].qty)- parseInt(updateList[index].diskon_nominal)) * parseInt(updateList[index].harga) ;
        setTotalPrice(totalPrice+plusTotal-minTotal);
        let qatyTotal = (parseInt(updateList[index].harga) - parseInt(updateList[index].diskon_nominal)) * parseInt(updateList[index].qty)  ;
        setTotalQty(qtyTotal + qatyTotal - aqtyTotal);
        let diskonnominal1 = parseInt(updateList[index].qty) * value ;
        let diskonnominal2 = parseInt(updateList[index].qty) * parseInt(updateList[index].diskon_nominal);
        // setDiskonTotal(diskontotal + diskonnominal1 - diskonnominal2 )
      }else{return false}
    }


  const getJangkaWaktu = (id) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/jwkredit/list`, { headers })
      .then((data) => {
        setAllJangkaWaktu(data.data.response);
        setJangkaWaktu(id);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  
  const getPajak = (id) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `bearer ${token}`,
    };
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/pajak/list`, { headers })
      .then((data) => {
        setAllPajak(data.data.response);
        setPajak(id);
      })
      .catch(function (error) {
        console.log(error);
      });
  };



   function CreateData() {
    let dataItems = [];
        savedItems.map((dataItem) => dataItems = [...dataItems, 
            { 
                item_id: dataItem.item_id, 
                qty: dataItem.qty, 
                harga: dataItem.harga,
                diskon_nominal:dataItem.diskon_nominal,
                diskon_persen:dataItem.diskon_persen,
            }]);
    let data = {
      warehouse_id : parseInt(warehouse),
      username : username,
      code_transaction: codeinvoice,
      pajak_id : parseInt(pajak),
      diskon_global_nominal : parseFloat(diskonglobalnominal),
      diskon_global_persen : parseInt(diskonglobalpersen),
      payment_method: parseInt(payment_method),
      jangka_waktu: parseInt(jangkaWaktu),
      status:2,
      status_ar1:5,
      account_number : bankcustomer,
      status_ar2:3,
      status_d : 3,
      customer_id: parseInt(customer),
      ongkir:parseFloat(ongkir),
      keterangan: keterangan ,
      retur_type: 2,
      items : dataItems,
    };
    axios
        .post(
          `${process.env.REACT_APP_API_BASE_URL}/so-retur/save`,
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(function (response) {
          setSuccessAlert(response.data.message);
				  setTimeout(() => (history.push("/admin/so-retur/page")), 1000);

        })
        .catch(function (error) {
              setGagalAlert(error.response.data.message);
        });
    }
  
    const setSuccessAlert = (id) => {
      setAlert(
        <SweetAlert
          success
          showConfirm
          confirmBtnText="Ok"
          title={id}
          onCancel={hideAlert}
          onConfirm={hideAlert}
        />
      )
    }

  const setGagalAlert = (id) => {
  setAlert(
    <SweetAlert
    danger
    showConfirm
    confirmBtnText="Ok"
    title={id}
    onCancel={hideAlert}
    onConfirm={hideAlert}
    />
  );
  };

  const setQuestionAlert = () => {
  setAlert(
    <SweetAlert
    warning
    showCancel
    confirmBtnText="Lanjutkan"
    confirmBtnBsStyle="danger"
    title="Apakah Kamu Yakin?"
    onConfirm={CreateData}
    onCancel={hideAlert}
    focusCancelBtn
    />
  );
  };
  
    const hideAlert = () => {
      setAlert(null);
    };

const getCustomer = (id) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/customer/list`,
        { headers }
      )
      .then((data) => {
        setCustomers(data.data.response);
        setCustomer(id);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getbankCustomer = (id) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/bank/get-by-customer/${id}`, { headers })
      .then((data) => {
        setBankCustomers(data.data.response);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const deleteItem = (id) => {
    let array = [...savedItems];

    let index = array.findIndex(object => {
      return object.item_id === id;
    });

    if (index !== -1) {
      setTotalPrice(totalPrice - (array[index].harga - array[index].diskon_nominal) * array[index].qty)  ;
      setTotalQty(qtyTotal - (array[index].harga - array[index].diskon_nominal) * array[index].qty) ;
      setDiskonTotal(diskontotal - array[index].diskon_nominal * array[index].qty);
      array.splice(index, 1);
      setSavedItems(array);
    }
  }



const formatRupiah = (money) => {
  return new Intl.NumberFormat('id-ID',
      { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }
  ).format(money);
}

  const handleSubmit = (e) => {
    {
      CreateData();
    }
  };

  return (
    <>
    {alert}
    {ReturSoProyeks && ReturSoProyeks === "1" ? (
      <div>
    <SimpleHeader name="Buat Retur Penjualan" parentName="SO" />
    <Container className="mt--6" fluid>
        <Row>
          <div className="col">
          <Card className="position-sticky bg-secondary BoxShadow" style={{ top: 0, zIndex: "5" }}>
									<CardBody className="ml-3 pb-0">
										<Row md="12">
											<Col md="4">
											</Col>
											<Col md="4">
												<FormGroup row>
													<Col sm={10}>
														<Input
															className="form-control-alternative"
															placeholder="Search Kode Faktur"
															type="search"
															value={query}
															onChange={(e) => setQuery(e.target.value)}
														/>
													</Col>
												</FormGroup>
											</Col>
                      <Col md="4">
                      </Col>
										</Row>
									</CardBody>
								</Card>
									{isSearchShow && query && (
										<Card className="boxShadow" style={{ top: 100, zIndex: "3", maxHeight: "15.5rem", overflowY: "auto", paddingTop: "1rem", position: "sticky" }}>
											<div style={{ position: "absolute", top: "2.5px", right: "1rem", cursor: "pointer", fontSize: "2rem" }}>
												<i className="fas fa-window-close text-danger" onClick={() => setIsSearchShow(false)}></i>
											</div>
											<span className="text-center mb-3">
												<b>Pencarian berdasarkan:</b> {query}
											</span>
											{allItem?.response ? (
												allItem.response.map((item) => (
													<CardBody key={item.id} className="bgSearch border-bottom p-2 mb-2" style={{minHeight: 'auto'}} onClick={() => {searchShow(item); setQuery(''); setIsSearchShow(false);}}>
														<div className=" d-flex align-items-center">
															<div className="ml-3">
																<div>
																	<b>Kode :</b> {item.invoice_code}
																</div>
															</div>
														</div>
													</CardBody>
												))
											) : (
												<div className="text-center mb-3 text-danger">Item "{query}" tidak ada bosku!</div>
											)}
										</Card>
                  )}
          <Card className="bg-secondary shadow">
                <Form onSubmit={handleSubmit}>
                    <CardHeader className="bg-white border-0">
                      <h3></h3>
                    </CardHeader>
                    <CardBody>
                      <Row md="12">
                          <Col md="6">
                            <FormGroup row>
                              <Label
                                for="exampleEmail"
                                sm={4}
                              >
                                Customer
                              </Label>
                              <Col sm={7}>
                                <Input
                                className="form-control-alternative"
                                disabled
                                  type="text"
                                  placeholder="Masukan Customer"
                                  value={customername}
                                  onChange={(e) => {
                                    setCustomerName(e.target.value);
                                  }}
                                />
                              </Col>                             
                            </FormGroup>
                            <FormGroup row>
														<Label for="exampleEmail" sm={4}>
															Bank
														</Label>
														<Col sm={7}>
															<Input
                              disabled
                                className="form-control-alternative"
																type="select"
																value={bankcustomer}
																onChange={(e) => {
																	setBankCustomer(e.target.value);
																}}>
                                <option value="" disabled selected hidden>Pilih Bank</option>
                                  {bankcustomers.map((supplier, key) => {
                                    return (
                                        <option key={key} value={supplier.account_number}>
                                          {supplier.bank_name} {supplier.account_number} a/n {supplier.account_name} 
                                        </option>
                                    );
                                  })}
                                </Input>
														</Col>
													  </FormGroup>
                            <FormGroup row>
                              <Label for="exampleEmail" sm={4}>
                                Diskon
                              </Label>
                              <Col sm={3}>
                                <Input
                                disabled
                                autoComplete="off"
                                  className="form-control-alternative"
                                  type="text"
                                  placeholder="Diskon (%)"
                                  value={diskonglobalpersen}
                                  onChange={(e) => {
                                    setDiskonGlobalPersen(e.target.value);
                                    setDiskonGLobal("diskonglobalpersen")
                                  }}
                                />
                              </Col>
                              <Col sm={4}>
                                <Input
                                disabled
                                  autoComplete="off"
                                  className="form-control-alternative"
                                  type="text"
                                  name="lebar"
                                  placeholder="Diskon (N)"
                                  value={diskonglobalnominal}
                                  onChange={(e) => {
                                    setDiskonGlobalNominal(e.target.value);
                                    setDiskonGLobal("diskonglobalnominal")
                                  }}
                                />
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label for="exampleEmail" sm={4}>
                                    PPN
                                </Label>
                                <Col sm={7}>
                                    <Input
                                        disabled
                                        className="form-control-alternative"
                                        type="select"
                                        value={pajak}
                                        onChange={(e) => {
                                            setPajak(e.target.value);
                                    }}>
                                <option value='' disabled selected hidden>Pilih PPN</option>
                                  {allpajak.map((warehouse2, key) => {
                                    return (
                                        <option key={key} value={warehouse2.id}>
                                          {warehouse2.keterangan}
                                        </option>
                                    );
                                  })}
                                </Input>
                            </Col>
                            </FormGroup>
                          </Col>
                          <Col md="6">
                          <FormGroup row>
                            <Label
                              for="exampleEmail"
                              sm={4}
                            >
                              Metode Pembayaran
                            </Label>
                            <Col sm={7}>
                            <Input
                            disabled
                            className="form-control-alternative"
                                name="Tipe Po"
                                type="select"
                                value={payment_method}
                                onChange={(e) => {
                                    setPaymentMethod(e.target.value);
                                }}
                              >
                                <option value={""}>Pilih Metode Pembayaran</option>
                                <option value={1}>Lunas</option>
                                <option value={2}>Tempo</option>
                                <option value={5}>Dp/Kredit</option>
                              </Input>
                            </Col>
                            </FormGroup>
                            {payment_method >  1  ? (
                              <FormGroup row>
                                <Label
                                  for="exampleEmail"
                                  sm={4}
                                >
                                  Jangka Waktu
                                </Label>
                                <Col sm={4}>
                                  <Input
                                    className="form-control-alternative"
                                    type="select"
                                    name="desc"
                                    value={jangkaWaktu}
                                    onChange={(e) => {
                                      setJangkaWaktu(e.target.value);
                                    }}
                                  >
                                  <option value="">Pilih</option>
                                  {allJangkaWaktu.map((waktu, key) => {
                                    return (
                                      <option key={key} value={waktu.durasi}>
                                        {waktu.durasi}
                                      </option>
                                    );
                                  })}
                                </Input>
                                </Col> 
                                <Label for="exampleEmail" sm={3}>
                                  <b>Hari</b>
                                </Label>
                              </FormGroup>
                            ):(
                              ""
                            )}
                            <FormGroup row>
                              <Label
                                for="exampleEmail"
                                sm={4}
                              >
                                Ongkir
                              </Label>
                              <Col sm={7}>
                                <Input
                                className="form-control-alternative"
                                disabled
                                  type="text"
                                  name="desc"
                                  placeholder="Masukan Ongkir"
                                  value={ongkir}
                                  onChange={(e) => {
                                    setOngkir(e.target.value);
                                  }}
                                />
                              </Col>                             
                            </FormGroup>
                          </Col>
                      </Row>
                    </CardBody>
                </Form>
              </Card>
              <Card className="bg-secondary shadow">
                    <CardHeader className="bg-white border-0">
                      <h3>Keterangan/Alasan</h3>
                    </CardHeader>
                <CardBody>
                  <Row md="12">
                    <Col md="12">
                      <FormGroup row>
                        <Label
                          for="exampleEmail"
                          sm={3}
                        >
                          Keterangan
                        </Label>
                        <Col sm={6}>
                          <Input
                          className="form-control-alternative"
                            type="textarea"
                            name="desc"
                            rows = "4"
                            placeholder="Masukan Keterangan"
                            value={keterangan}
                            onChange={(e) => {
                              setKeterangan(e.target.value);
                            }}
                          />
                        </Col>                             
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card className="bg-secondary shadow">
                <Form onSubmit={handleQtySisa}>
                    <CardHeader className="bg-white border-0">
                      <h3>Item</h3>
                    </CardHeader>
                        <CardBody>
                        <Table size="sm" responsive>
                            <thead>
                              <tr>
                                <th><b>Nama Item</b></th>
                                <th><b>Kode Item</b></th>
                                <th><b>Harga</b></th>
                                <th><b>Qty&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b></th>
                                <th><b>Satuan</b></th>
                                <th><b>Diskon %</b></th>
                                <th><b>Diskon (N)</b></th>
                                <th><b>Sub Total</b></th>
                              </tr>
                            </thead>
                              <tbody>
                              {
                                savedItems.map((savedItem, key) => {
                                  return (
                                    <tr key={key}>
                                      <td>{savedItem.item_name}</td>
                                      <td>{savedItem.item_code}</td>
                                      <td>
                                      
                                      {editable ? (
                                          <Input
                                          className="form-control-alternative"
                                            placeholder="Diskon Persen"
                                            type="number"
                                            value={savedItem.harga}
                                            onChange={(e) => {
                                              handleEditHarga(key, e.target.value);
                                            }}
                                          />
                                        ) : (
                                            <>{formatRupiah(savedItem.harga)}</>
                                        )}
                                      
                                      </td>
                                      <td>
                                      <Input
                                          className="form-control-alternative"
                                          placeholder="qty"
                                          type="number"
                                          invalid={qtyError === "invalid"}
                                          value={savedItem.qty}
                                          onChange={(e) => {
                                            handleEditQty(key, e.target.value);
                                            setDiskonGLobal("diskonglobalpersen")
                                            if (savedItem.qty > savedItem.qtysisa) {
                                              setQtyError("");
                                            }
                                          }}/>
                                           <FormFeedback>
                                                Max Barang {savedItem.qtysisa} 
                                            </FormFeedback>
                                      </td>
                                      <td>{savedItem.satuan}</td>
                                      <td>
                                        {editable ? (
                                          <Input
                                          className="form-control-alternative"
                                            placeholder="Diskon Persen"
                                            type="number"
                                            value={savedItem.diskon_persen}
                                            onChange={(e) => {
                                              handleEditDiskonPersen(key, e.target.value);
                                            }}
                                          />
                                        ) : (
                                            <>{savedItem.diskon_persen}</>
                                        )}
                                      </td>
                                      <td>
                                        {editable ? (
                                          <Input
                                          className="form-control-alternative"
                                            placeholder="Diskon nominal"
                                            type="number"
                                            value={savedItem.diskon_nominal}
                                            onChange={(e) => {
                                              handleEditDiskonNominal(key, e.target.value);
                                            }}
                                          />
                                        ) : (
                                            <>{formatRupiah(savedItem.diskon_nominal)}</>
                                        )}
                                      </td>
                                      <td>
                                        {formatRupiah((savedItem.harga - savedItem.diskon_nominal) * savedItem.qty)}
                                      </td>
                                      <td>
                                        <Button color="danger" onClick={() => deleteItem(savedItem.item_id)}><i className="fas fa-trash" /></Button>
                                      </td>
                                    </tr>
                                  )
                                })
                              }
                              </tbody>
                          </Table>
                        </CardBody>
                        <CardFooter>
                          <Row md="12">
                            <Col md="6">
                            </Col>
                            <Col md="2">
                            </Col>
                            <Col md="4">
                              <FormGroup row>
                              <Label
                                for="exampleEmail"
                                sm={4}
                                size="small"
                              >
                                <b>Total</b>
                              </Label>
                              <Col sm={6}>
                              <Input
                              className="form-control-alternative"
                                disabled
                                style={{fontWeight: 'bold'}}
                                type="text"
                                name="barcode"
                                placeholder="Harga Total"
                                // value={totalPrice1}
                                value={"Rp." + totalPrice1.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") + ",-"}
                                />
                              </Col>
                              </FormGroup>
                              <FormGroup row>
                              <Label
                                for="exampleEmail"
                                sm={4}
                              >
                                <b>Diskon</b>
                              </Label>
                              <Col sm={6}>
                              <Input
                                className="form-control-alternative"
                                style={{fontWeight: 'bold'}}
                                disabled
                                type="text"
                                name="barcode"
                                placeholder="Diskon"
                                value={"Rp." + totaldiskon2.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") + ",-"}
                                />
                              </Col>
                              </FormGroup>
                              <FormGroup row>
                              <Label
                                for="exampleEmail"
                                sm={4}
                              >
                                <b>PPN</b>
                              </Label>
                              <Col sm={6}>
                              <Input
                                className="form-control-alternative"
                                disabled
                                style={{fontWeight: 'bold'}}
                                type="text"
                                name="barcode"
                                placeholder="PPN"
                                value={"Rp." + ppnnew.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") + ",-"}
                                />
                              </Col>
                              </FormGroup>
                              <FormGroup row>
                              <Label
                                for="exampleEmail"
                                sm={4}
                              >
                                <b>Ongkir</b>
                              </Label>
                              <Col sm={6}>
                              <Input
                                className="form-control-alternative"
                                disabled
                                style={{fontWeight: 'bold'}}
                                type="text"
                                name="barcode"
                                placeholder="PPN"
                                value={"Rp." + ongkir1.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") + ",-"}
                                />
                              </Col>
                              </FormGroup>
                              <FormGroup row>
                              <Label
                                for="exampleEmail"
                                sm={4}
                              >
                                <b>Grand Total</b>
                              </Label>
                              <Col sm={6}>
                              <Input
                              className="form-control-alternative"
                                disabled
                                type="text"
                                name="barcode"
                                style={{fontWeight: 'bold'}}
                                placeholder="Grand Total"
                                value={"Rp." + grandtotal.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") + ",-"}
                                />
                              </Col>
                              </FormGroup>
                            </Col>
                          </Row>
                        </CardFooter>
                <CardFooter>
                      <Link className="btn btn-info" to="/admin/so-retur/page">
                        Kembali
                      </Link>
                      <Button color="danger" type="submit">
                          Simpan
                      </Button>
                </CardFooter>
                </Form>
              </Card>
          </div>
        </Row>
    </Container>
    </div>
    ):(
      <Halaman404 />
    )}  
    </>
  );
}

export default CreateSoReturProyek;

