/*eslint-disable*/
import React, { useEffect, useState } from "react";
import {
  Card,
  Button,
  Row,
  Col,
  CardBody,
  CardHeader,
  CardFooter,
  Table,
  Container,
  FormGroup,
  Label,
  Input,

} from "reactstrap";
import { Link,useHistory } from "react-router-dom";
import axios from "axios";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import SweetAlert from "react-bootstrap-sweetalert";

export default function ValidasiInvoicePesananProdukCokDirektur(props) {
    const [alert, setAlert] = React.useState(null);
    const [user_name, setUser_Name] = useState("");
    const [codeSj, setCodeSj] = useState("");
    const [codesc, setCodeSc] = useState("")
    const [persenpajak, setPersenPajak] = useState("");
    const token = localStorage.token;
    const username = localStorage.username;
    const warehouse = localStorage.warehouse;
    let history = useHistory();
    const [statusd, setStatusD] = useState([]);
    const [keterangan, setKeterangan] = useState("");
    const [savedItems, setSavedItems] = useState([]);
    const [grandtotal, setGrandTotal] = useState(0);
    const [ongkir, setOngkir] = useState(0);
    const [ongkir1, setOngkir1] = useState(0);
    const [ppnnew, setPPNNEW] = useState(0);
    const [totalPrice1, setTotalPrice1] = useState(0);
    const a = 0;
    const b = 0;
    const [qtyTotal, setTotalQty] = useState(0);
 
	//ongkir tampil
	useEffect(() => {
		setOngkir1(ongkir - b);
	}, [ongkir, b]);


    useEffect(() => {
		setTotalPrice1(qtyTotal);
	}, [qtyTotal]);

    // hasil nominal dari PPN
    useEffect(() => {
        setPPNNEW( (qtyTotal) * (persenpajak / 100));
   }, [qtyTotal,persenpajak]);

    // hasil grandtotal
	useEffect(() => {
		setGrandTotal((qtyTotal) + ppnnew + ongkir1- a );
	}, [qtyTotal,ppnnew, ongkir1, a]);


  useEffect(() => {
    getById();
    // getItemDataSaved()
  }, []);

  const getById = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/invoice-sjp/get/${props.match.params.id}`,
        { headers }
      )
      .then((data) => {
        setUser_Name(data.data.response.user_name)
        setCodeSj(data.data.response.sj_code)
        setCodeSc(data.data.response.code_sc)
        setOngkir(data.data.response.ongkir)
        setPersenPajak(data.data.response.persen_pajak)
        setKeterangan(data.data.response.keterangan);
        setTotalQty(data.data.response.price_total);
        getItemDataSaved();
       
      })
      .catch(function (error) {
        console.log(error);
      });
  };

 
  const getItemDataSaved = () => {
    const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
    axios.post(`${process.env.REACT_APP_API_BASE_URL}/invoice-sjp/item`, {

        invoice_id: props.match.params.id

    },{headers}).then(async response => {
        let stateItem = [];

        await Promise.all(response.data.response.map(async (data) => {
            stateItem = [...stateItem, {
                item_id: data.item_id,
                item_name:data.item_name,
                item_code : data.item_code,
                satuan : data.satuan,
                qty: data.qty,
                harga: data.harga,
                data: {
                    item_name: data.item_name,
                    harga: data.harga,
                    qty: data.qty,
                },
            }];
        }));
        setSavedItems(stateItem);
    })
}


   function EditData() {
    let dataItems = [];
        savedItems.map((dataItem) => dataItems = [...dataItems, 
            { 
                item_id: dataItem.item_id, 
                qty: dataItem.qty, 
                harga:dataItem.harga,
            }]);
    let data = {
        // warehouse_id : parseInt(warehouse),
        // code_sc: codesc,
        // code_sj : codeSj,
        status_d: parseInt(statusd),
        direktur: username,
        // status_d: 3,
        // ongkir : parseFloat(ongkir),
        // keterangan: keterangan ,
        // items : dataItems
    };
    axios
        .post(
          `${process.env.REACT_APP_API_BASE_URL}/invoice-sjp/update-admin2/${props.match.params.id}`,
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(function (response) {
          setSuccessAlert(response.data.message);
				setTimeout(() => (history.push("/admin/invoice-sjp/validasi-direktur")), 1000);
        })
        .catch(function (error) {
              setGagalAlert(error.response.data.message);
        });
    }
  
    const setSuccessAlert = (id) => {
      setAlert(
        <SweetAlert
          success
          showConfirm
          confirmBtnText="Ok"
          title={id}
          onCancel={hideAlert}
          onConfirm={hideAlert}
        />
      )
    }

  const setGagalAlert = (id) => {
  setAlert(
    <SweetAlert
    danger
    showConfirm
    confirmBtnText="Ok"
    title={id}
    onCancel={hideAlert}
    onConfirm={hideAlert}
    />
  );
  };

  const setQuestionAlert = () => {
  setAlert(
    <SweetAlert
    warning
    showCancel
    confirmBtnText="Lanjutkan"
    confirmBtnBsStyle="danger"
    title="Apakah Kamu Yakin?"
    onConfirm={EditData}
    onCancel={hideAlert}
    focusCancelBtn
    />
  );
  };
  
    const hideAlert = () => {
      setAlert(null);
    };


  const formatRupiah = (money) => {
    return new Intl.NumberFormat('id-ID',
        { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }
    ).format(money);
  }

  return (
    <>
    {alert}
    <SimpleHeader name="Validasi Kepala Finance" parentName="SO" />
    <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <CardBody>
            <Card>
                <CardBody>
                  <Row md="12">
                      <Col md="6">
                        <FormGroup row>
                        <Label
                        className="form-control-label"
                        for="exampleEmail" sm={3}>
                            User
                        </Label>
                        <Col sm={6}>
                            <Input
                                disabled
                                placeholder="Masukan Customer"
                                className="form-control-alternative"
                                type="text"
                                value={user_name}
                                onChange={(e) => {
                                    setUser_Name(e.target.value);
                                }}/>
                        </Col>
                        </FormGroup>
                        <FormGroup row>
                        <Label
                        className="form-control-label"
                        for="exampleEmail" sm={3}>
                          PPN
                        </Label>
                        <Col sm={6}>
                          <Input
                            disabled
                            className="form-control-alternative"
                            type="text"
                            value={persenpajak}
                            onChange={(e) => {
                              setPersenPajak(e.target.value);
                            }}
                            />
                           
                        </Col>
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup row>
                          <Label
className="form-control-label"

                            for="exampleEmail"
                            sm={4}
                          >
                            Ongkir
                          </Label>
                          <Col sm={6}>
                            <Input
                            className="form-control-alternative"
                            disabled
                              type="text"
                              name="desc"
                              placeholder="Masukan Ongkir"
                              value={ongkir}
                              onChange={(e) => {
                                setOngkir(e.target.value);
                              }}
                            />
                          </Col>                             
                        </FormGroup>
                        <FormGroup row>
                          <Label
className="form-control-label"

                            for="exampleEmail"
                            sm={4}
                          >
                            Keterangan
                          </Label>
                          <Col sm={6}>
                            <Input
                            className="form-control-alternative"
                            disabled
                              type="textarea"
                              name="desc"
                              rows = "4"
                              placeholder="Masukan Keterangan"
                              value={keterangan}
                              onChange={(e) => {
                                setKeterangan(e.target.value);
                              }}
                            />
                          </Col>                             
                        </FormGroup>
                        <FormGroup row>
                              <Col
                                for="exampleEmail"
                                sm={4}
                              >
                                <Label
                                className="form-control-label">Validasi</Label>
                                
                              </Col>
                                <Col sm={7}>
                                  <div style={{ display: "flex" }}>
                                    <div className="custom-control custom-radio mb-3">
                                      <Input
                                        className="custom-control-input"
                                        id="customRadio12"
                                        name="custom-radio-5"
                                        type="radio"
                                        value={5}
                                        checked={statusd === 5}
                                        onChange={() => setStatusD(5)}
                                      />
                                      <Label
                                        className="custom-control-label"
                                        htmlFor="customRadio12"
                                      >
                                      </Label>
                                      <b>SETUJU</b>
                                    </div>
                                    <div
                                      className="custom-control custom-radio mb-3"
                                      style={{ marginLeft: "20px" }}
                                    >
                                      <Input
                                        className="custom-control-input"
                                        id="customRadio13"
                                        name="custom-radio-5"
                                        type="radio"
                                        value={4}
                                        checked={statusd === 4}
                                        onChange={() => setStatusD(4)}
                                      />
                                      <Label
                                        className="custom-control-label"
                                        htmlFor="customRadio13"
                                      >
                                      </Label>
                                      <b>TOLAK</b>
                                    </div>
                                  </div>
                                </Col>
                              </FormGroup>
                      </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                      <h3>Item</h3>
                    </CardHeader>
                    <CardBody>
                          <Table size="sm" responsive>
                            <thead>
                              <tr>
                                <th><b>Nama Item</b></th>
                                <th><b>Kode Item</b></th>
                                <th><b>Harga</b></th>
                                <th><b>Qty</b></th>
                                <th><b>Satuan</b></th>
                                <th><b>Sub Total</b></th>
                              </tr>
                            </thead>
                              <tbody>
                              {
                                savedItems.map((savedItem, key) => {
                                  return (
                                    <tr key={key}>
                                      <td>{savedItem.item_name}</td>
                                      <td>{savedItem.item_code}</td>
                                      <td><>{formatRupiah(savedItem.harga)}</></td>
                                      <td><>{savedItem.qty}</></td>
                                      <td>{savedItem.satuan}</td>
                                      <td>{formatRupiah(savedItem.harga * savedItem.qty)}</td>
                                    </tr>
                                  )
                                })
                              }
                              </tbody>
                          </Table>
                        </CardBody>
                        <CardFooter >
                          <Row md="12">
                            <Col md="6">
                            </Col>
                            <Col md="2">
                            </Col>
                            <Col md="4">
                              <FormGroup row>
                              <Label
className="form-control-label"

                                for="exampleEmail"
                                sm={4}
                                size="small"
                              >
                                <b>Total</b>
                              </Label>
                              <Col sm={6}>
                              <Input
                              className="form-control-alternative"
                                disabled
                                style={{fontWeight: 'bold'}}
                                type="text"
                                name="barcode"
                                placeholder="Harga Total"
                                // value={totalPrice1}
                                value={"Rp." + totalPrice1.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") + ",-"}
                                />
                              </Col>
                              </FormGroup>
                              <FormGroup row>
                              <Label
className="form-control-label"

                                for="exampleEmail"
                                sm={4}
                              >
                                <b>PPN</b>
                              </Label>
                              <Col sm={6}>
                              <Input
                                className="form-control-alternative"
                                disabled
                                style={{fontWeight: 'bold'}}
                                type="text"
                                name="barcode"
                                placeholder="PPN"
                                value={"Rp." + ppnnew.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") + ",-"}
                                />
                              </Col>
                              </FormGroup>
                              <FormGroup row>
                              <Label
className="form-control-label"

                                for="exampleEmail"
                                sm={4}
                              >
                                <b>Ongkir</b>
                              </Label>
                              <Col sm={6}>
                              <Input
                                className="form-control-alternative"
                                disabled
                                style={{fontWeight: 'bold'}}
                                type="text"
                                name="barcode"
                                placeholder="PPN"
                                value={"Rp." + ongkir1.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") + ",-"}
                                />
                              </Col>
                              </FormGroup>
                              <FormGroup row>
                              <Label
className="form-control-label"

                                for="exampleEmail"
                                sm={4}
                              >
                                <b>Grand Total</b>
                              </Label>
                              <Col sm={6}>
                              <Input
                              className="form-control-alternative"
                                disabled
                                type="text"
                                name="barcode"
                                style={{fontWeight: 'bold'}}
                                placeholder="Grand Total"
                                value={"Rp." + grandtotal.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") + ",-"}
                                />
                              </Col>
                              </FormGroup>

                            </Col>
                          </Row>
                        </CardFooter>
                <CardFooter>
                      <Link className="btn btn-info" to="/admin/invoice-sjp/validasi-direktur">
                        Kembali
                      </Link>
                      <Button color="danger" onClick={()=>setQuestionAlert()}>
                          Simpan
                      </Button>
                </CardFooter>
              </Card>
            </CardBody>
          </div>
        </Row>
    </Container>
    </>
  );
}