/*eslint-disable*/
import React, { useEffect, useState } from "react";
import {
	Card,
	CardBody,
	Label,
	FormGroup,
	ListGroupItem,
	ListGroup,
	Row,
	Input,
	CardHeader,
	CardFooter,
	CardImg,
	Col,
	Button,
	Table,
	Container,
	CardGroup,
	Modal, ModalHeader, ModalBody
} from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import Barcode from "react-barcode";
import QRCode from "qrcode.react";
import moment from "moment" ;
import SweetAlert from "react-bootstrap-sweetalert";

export default function ValidasiHrdIzinDinasValidasi(props) {
const [alert, setAlert] = React.useState(null);
const token = localStorage.token;
    let history = useHistory();
	const warehouseId = localStorage.warehouse;
	const username = localStorage.username;
	const [isLoading, setLoading] = useState(false);
	const [description, setDescription] = useState("");
	const [modal, setModal] = useState(false);
  	const toggle = () => setModal(!modal);
	const [tanggal, setTanggal] = useState("");
    const [usernamepembuat, setUsernamePembuat] = useState("")
    const [approve, setApprove] = useState("");
    const [name, setName] = useState("")

    useEffect(() => {
        getById();
      }, []);
    
      const getById = () => {
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        };
        axios
          .get(
            `${process.env.REACT_APP_API_BASE_URL}/absensi-dinas/get/${props.match.params.id}`,
            { headers }
          )
          .then((data) => {
            setDescription(data.data.response.keterangan);
            setUsernamePembuat(data.data.response.username);
            setName(data.data.response.username);
            setApprove(data.data.response.approve);
            setTanggal(moment(data.data.response.dinas_date, "YYYY-MM-DD").format("YYYY-MM-DD"));
           
          })
          .catch(function (error) {
            console.log(error);
          });
      };


	const CreateIzinKaryawan = () => {
		setLoading(true);
		const headers = {
			Authorization: `Bearer ${token}`,
		};
        	let data = {
        		username: name,
                warehouse_id : parseInt(warehouseId),
                dinas_date : moment(tanggal).format("YYYY-MM-DD 00:00"),
                keterangan : description,
                approve : parseInt(approve),

        	};
		axios
			.post(`${process.env.REACT_APP_API_BASE_URL}/absensi-dinas/update/${props.match.params.id}`, data, { headers })
			
			.then(function (response) {
				setSuccessAlert(response.data.message);
				setTimeout(() => (history.push("/admin/hrd/dinas")), 1000);
			  })
			  .then((json) => {
				setLoading(false);
			  })
			  .catch(function (error) {
					setGagalAlert(error.response.data.message);
			  });
		}
	  
		const setSuccessAlert = (id) => {
		  setAlert(
			<SweetAlert
			  success
			  showConfirm
			  confirmBtnText="Ok"
			  title={id}
			  onCancel={hideAlert}
			  onConfirm={hideAlert}
			/>
		  )
		}
	  
	  const setGagalAlert = (id) => {
	  setAlert(
		<SweetAlert
		danger
		showConfirm
		confirmBtnText="Ok"
		title={id}
		onCancel={hideAlert}
		onConfirm={hideAlert}
		/>
	  );
	  };
	  
	  const setQuestionAlert = () => {
	  setAlert(
		<SweetAlert
		warning
		showCancel
		confirmBtnText="Lanjutkan"
		confirmBtnBsStyle="danger"
		title="Apakah Kamu Yakin?"
		onConfirm={CreateIzinKaryawan}
		onCancel={hideAlert}
		focusCancelBtn
		/>
	  );
	  };
	  
		const hideAlert = () => {
		  setAlert(null);
		};


    const handleSubmit = () => 
	{
			CreateIzinKaryawan();
	};

	return (
		<>
		{alert}
			<div>
				<SimpleHeader name="Validasi Dinas" parentName="Master" />
				<Container className="mt--6" fluid>
					<Row>
						<div className="col">
							<CardBody>
								<Row md="12">
									<Col md="12">
										<Card className="bg-secondary shadow">
											<CardHeader className="bg-white border-0">
												<h3>Validasi Dinas</h3>
											</CardHeader>
											<CardBody>
                                                <FormGroup row>
                                                    <Label>Tanggal</Label>
                                                    <Input
                                                    disabled
                                                        className="form-control-alternative"
                                                        autoComplete="off"
                                                        id="dateRequired"
                                                        type="date"
                                                        placeholder="Masukan Tanggal"
                                                        value={tanggal}
                                                        onChange={(e) => {
                                                            setTanggal(e.target.value);
                                                        }}
                                                        />
                                                </FormGroup>  
												<FormGroup row>
													<Label>Nama</Label>
                                                        <Input
                                                        disabled
                                                            autoComplete="off"
                                                            className="form-control-alternative"
                                                            type="text"
                                                            name="deskripsi Item"
                                                            placeholder="Nama"
                                                            value={name}
                                                            onChange={(e) => {
                                                                setName(e.target.value);
                                                            }}
                                                        />
												</FormGroup>
												<FormGroup row>
													<Label>Keterangan Dinas</Label>
                                                        <Input
                                                        disabled
                                                            autoComplete="off"
                                                            className="form-control-alternative"
                                                            rows="13"
                                                            type="textarea"
                                                            name="deskripsi Item"
                                                            placeholder="Masukan Keterangan Dinas"
                                                            value={description}
                                                            onChange={(e) => {
                                                                setDescription(e.target.value);
                                                            }}
                                                        />
												</FormGroup>
                                                <Label>
                                                    Validasi
                                                </Label>
                                                <FormGroup row>
                                                        <div className="custom-control custom-radio mb-3">
                                                            <Input
                                                            className="custom-control-input"
                                                            id="customRadio10"
                                                            name="custom-radio-4"
                                                            type="checkbox"
                                                            value={5}
                                                            checked={approve === 5}
                                                            onChange={() => setApprove(5)}
                                                            />
                                                            <Label
                                                            className="custom-control-label"
                                                            htmlFor="customRadio10"
                                                            >
                                                            Disetujui
                                                            </Label>
                                                        </div>
                                                        <div
                                                            className="custom-control custom-radio mb-3"
                                                            style={{ marginLeft: "20px" }}
                                                        >
                                                            <Input
                                                            className="custom-control-input"
                                                            id="customRadio11"
                                                            name="custom-radio-4"
                                                            type="checkbox"
                                                            value={4}
                                                            checked={approve === 4}
                                                            onChange={() => setApprove(4)}
                                                            />
                                                            <Label
                                                            className="custom-control-label"
                                                            htmlFor="customRadio11"
                                                            >
                                                            Ditolak
                                                            </Label>
                                                        </div>
                                                </FormGroup>
											</CardBody>
											<CardFooter>
												<Link className="btn btn-info" to="/admin/hrd/dinas">
													Kembali
												</Link>
                                                <Button color="danger" onClick={()=>setQuestionAlert()}>
													Simpan
												</Button>
											</CardFooter>
										</Card>
									</Col>
								</Row>
							</CardBody>
						</div>
					</Row>
				</Container>
			</div>
		</>
	);
}
