/*eslint-disable*/
import React, { useEffect, useState } from "react";
import axios from "axios";
import { 
    Card,
    Row, 
    Col, 
    CardBody, 
    CardHeader,
    FormGroup, 
    Container,
    Label, 
    Input ,
} from 'reactstrap';
import CurrencyInput from 'react-currency-input-field';

const DetailAfiliasi = (props) => {
  const token = localStorage.token;
  const [periode, setPeriode] = useState("")
  const [totalturunan, setTotalTurunan] = useState("");
  const [komisi, setKomisi] = useState("");

  useEffect(() => {
    getJumlahTurunan();
    getTransaksiTurunan()
  }, [props.code]);

  const getJumlahTurunan = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/user-withdraw/jumlah-turunan/${props.code}`,
        {
          headers,
        }
      )
      .then((data) => {
        setTotalTurunan(data.data.response.jumlah_turunan)
        setPeriode(data.data.response.periode)
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getTransaksiTurunan = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/user-withdraw/transaksi-turunan/${props.code}`,
        {
          headers,
        }
      )
      .then((data) => {
        setKomisi(data.data.response)
      })
      .catch(function (error) {
        // setKomisi(error.data.response.message)
        console.log(error);
      });
  };
  

  return (
        // <ToolkitProvider
        //           rowNumber={rowIndex}
        //           data={namaproyek}
        //           keyField="id"
        //           columns={[
        //             {
        //             //   dataField: "no",
        //             //   text: "#",
        //             //   sort: true,
        //               page: 1,
        //               formatter: (cell, row, index) => {
        //                 let currentRow = ++index;
        //                 return currentRow + rowIndex;
        //               }
        //             },
                    
        //             {
        //               dataField: "account_code",
        //               // text: "Kode Account",
        //             //   sort: true,
        //             },
        //             {
        //               dataField: "account_name",
        //               // text: "Nama Account",
        //             //   sort: true,
        //             },
        //           ]}
        //         >
        //           {(props) => (
        //             <div>
        //               <BootstrapTable
        //                 remote
        //                 {...props.baseProps}
        //                 bootstrap4={true}
        //                 bordered={false}
        //                 hover={true}
        //                 expandRow={ expandRow }
        //                 // rowEvents={rowEvents}
        //                 // pagination={paginationFactory({ ...paginationOption })}
        //                 // onTableChange={handleTableChange}
        //               />
        //             </div>
        //           )}
        // </ToolkitProvider>
        <div>
            <CardBody>
              <Card className="bg-secondary shadow">
                  <CardHeader className="bg-white border-0">
                    <h3>Detail Afiliasi</h3>
                  </CardHeader>
                  <CardBody>
                    <Row md="12">
                      <Col md="6">
                        <FormGroup row>
                          <Label
                          className="form-control-label"
                            for="exampleEmail"
                            sm={4}
                          >
                            Periode
                          </Label>
                          <Col sm={7}>
                            <Input
                            disabled
                              autoComplete="off"
                              className="form-control-alternative"
                              type="text"
                              name="Nama"
                              placeholder="Masukan Hasil Survey"
                              value={periode}
                            //   onChange={(e) => {
                            //     setHasilSurvey(e.target.value);
                            //   }}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label
                          className="form-control-label"
                            for="exampleEmail"
                            sm={4}
                          >
                            Jumlah Afiliasi
                          </Label>
                          <Col sm={7}>
                          <Input
                            disabled
                              autoComplete="off"
                              className="form-control-alternative"
                              type="number"
                              name="Nama"
                              placeholder="Masukan Hasil Survey"
                              value={totalturunan}
                            //   onChange={(e) => {
                            //     setHasilSurvey(e.target.value);
                            //   }}
                            />
                          </Col>
                        </FormGroup>
                        {/* <FormGroup row>
                          <Label
                          className="form-control-label"
                            for="exampleEmail"
                            sm={4}
                          >
                            Per KG
                          </Label>
                          <Col sm={7}>
                            <CurrencyInput
                                
                                id="validation-example-2-field"
                                placeholder="Rp0"
                                allowDecimals={false}
                                value={perkg}
                                onValueChange={priceperkg}
                                className={`form-control`}
                                prefix={'Rp'}
                                step={10}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label
                          className="form-control-label"
                            for="exampleEmail"
                            sm={4}
                          >
                            Per KM
                          </Label>
                          <Col sm={7}>
                          <CurrencyInput
                                
                                id="validation-example-2-field"
                                placeholder="Rp0"
                                allowDecimals={false}
                                value={perkm}
                                onValueChange={priceperkm}
                                className={`form-control`}
                                prefix={'Rp'}
                                step={10}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label
                          className="form-control-label"
                            for="exampleEmail"
                            sm={4}
                          >
                            Packing Cairan
                          </Label>
                          <Col sm={7}>
                          <CurrencyInput
                                
                                id="validation-example-2-field"
                                placeholder="Rp0"
                                allowDecimals={false}
                                value={packingcairan}
                                onValueChange={pricepackingcairan}
                                className={`form-control`}
                                prefix={'Rp'}
                                step={10}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label
                          className="form-control-label"
                            for="exampleEmail"
                            sm={4}
                          >
                            Packing Pecah
                          </Label>
                          <Col sm={7}>
                          <CurrencyInput
                                
                                id="validation-example-2-field"
                                placeholder="Rp0"
                                allowDecimals={false}
                                value={packingpecah}
                                onValueChange={pricepackingpecah}
                                className={`form-control`}
                                prefix={'Rp'}
                                step={10}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label
                          className="form-control-label"
                            for="exampleEmail"
                            sm={4}
                          >
                            Status
                          </Label>
                          <Col sm={7}>
                            <Input
                              autoComplete="off"
                              className="form-control-alternative"
                              type="select"
                              name="Alamat"
                              placeholder="Masukan Alamat"
                              value={status}
                              onChange={(e) => {
                                setStatus(e.target.value);
                              }}
                            >
                                <option value="">Pilih Status</option>
                                <option value={1}>Aktif</option>
                                <option value={2}>Tidak Aktif</option>
                            </Input>
                          </Col>
                        </FormGroup> */}
                      </Col>
                      <Col md="6">
                      <FormGroup row>
                          <Label
                          className="form-control-label"
                            for="exampleEmail"
                            sm={4}
                          >
                            Komisi
                          </Label>
                          <Col sm={7}>
                            {komisi === "" ? 
                            (
                            <Input
                                disabled
                              autoComplete="off"
                              className="form-control-alternative"
                              type="text"
                              name="Nama"
                              placeholder="Komisi"
                              value="Tingkatkan Transaksi Kode Referral"
                            />
                            ):(
                            <CurrencyInput
                            disabled
                                    id="validation-example-2-field"
                                    placeholder="Rp0"
                                    allowDecimals={false}
                                    value={komisi}
                                    className={`form-control`}
                                    prefix={'Rp'}
                                    step={10}
                                />
                            )
                            }
                            
                          </Col>
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                
              </Card>
            </CardBody>
    </div>
  );
};

export default DetailAfiliasi;
