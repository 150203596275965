/*eslint-disable*/
import React, { useEffect, useState } from "react";
import {
  
  Card,
  Button,
  Row,
  Col,
  CardBody,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Modal, ModalHeader, ModalBody
  
} from "reactstrap";
import { Link,useHistory } from "react-router-dom";
import axios from "axios";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import Select2 from "react-select2-wrapper";
import SweetAlert from "react-bootstrap-sweetalert";
import Halaman404 from "views/404";

export default function DetailHistoriPesanan (props)  {
    const username = localStorage.username
  const [address,setAddress] = useState("")
  const [berat,setBerat] = useState("")
  const [bukti,setBukti] = useState("")
  const [codetransaction,setCodeTransaction] = useState("")
  const [jarak,setJarak] = useState("")
  const [keterangan,setKeterangan] = useState("")
  const [kurir,setKurir] = useState("")
  const [kurirs,setKurirs] = useState([])
  const [manualaddress,setManualAddress] = useState("")
  const [nominalpajak,setNominalPajak] = useState("")
  const [ongkir,setOngkir] = useState("")
  const [pajakid,setPajakId] = useState("")
  const [partialcode,setPartialCode] = useState("")
  const [paymenttotal,setPaymentTotal] = useState("")
  const [pengiriman,setPengiriman] = useState("")
  const [persenpajak,setPersenPajak] = useState("")
  const [pic1name,setPic1Name] = useState("")
  const [pic1state,setPic1State] = useState("")
  const [pic2name,setPic2Name] = useState("")
  const [pic2state,setPic2State] = useState("")
  const [pricetotal,setPriceTotal] = useState("")
  const [qtytotal,setQtyTotal] = useState("")
  const [sccode,setScCode] = useState("")
  const [sccounter,setScCounter] = useState("")
  const [transactiondate,setTransactionDate] = useState("")
  const [type,setType] = useState("")
  const [usercode,setUserCode] = useState("")
  const [warehouseid,setWarehouseId] = useState("")
  const [alert, setAlert] = React.useState(null);
  const [reasons, setReasons] = useState([])
  const token = localStorage.token;
  const warehouse = localStorage.warehouse;
  let history = useHistory();
  const [customers, setCustomers] = useState([]);
  const [customer, setCustomer] = useState("");
  const [typereq, setTypeReq] = useState([]);
  const [savedItems, setSavedItems] = useState([]);
  const [ppn, setPPN] = useState(0);
  const [grandtotal, setGrandTotal] = useState(0);
  const [pajak, setPajak] = useState("");
  const [lainnya, setLainnya] = useState(0);
  const [alamat, setAlamat] = useState("");
  const [ongkir1, setOngkir1] = useState(0);
    const [ppnnew, setPPNNEW] = useState(0);
  const [totalPrice1, setTotalPrice1] = useState(0);
  const [a] = useState(0);
  const [b] = useState(0);
	const [qtyTotal, setTotalQty] = useState(0);
  const [codeso, setCodeSo] = useState("")
  const [bankcustomer, setBankCustomer] = useState("")
  const [bankcustomers, setBankCustomers] = useState([])
 
	
	//ongkir tampil
	useEffect(() => {
		setOngkir1(ongkir - b);
	}, [ongkir, b]);

    // hasil nominal dari PPN
    useEffect(() => {
        setPPNNEW( qtyTotal * (ppn / 100));
   }, [qtyTotal,ppn]);

    // hasil grandtotal
	useEffect(() => {
		setGrandTotal(( qtyTotal ) + ppnnew + ongkir1- a );
	}, [qtyTotal,ppnnew, ongkir1, a]);
 

  useEffect(() => {
    getByIdss();
  }, []);

  const getByIdss = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/so-commerce/get/${props.match.params.id}`,
        { headers }
      )
      .then((data) => {
        getItemDataSaved();
        getKurirList(data.data.response.kurir_id)
        setTotalQty(data.data.response.price_total);
        setTypeReq(data.data.response.type);
        setPPN(data.data.response.persen_pajak);
        setAddress(data.data.response.address_id)
        setBerat(data.data.response.berat)
        setBukti(data.data.response.bukti)
        setCodeTransaction(data.data.response.code_transaction)
        setJarak(data.data.response.jarak)
        setKeterangan(data.data.response.keterangan)
        setManualAddress(data.data.response.manual_address)
        setNominalPajak(data.data.response.nominal_pajak)
        setOngkir(data.data.response.ongkir)
        setPajakId(data.data.response.pajak_id)
        setPartialCode(data.data.response.partial_code)
        setPaymentTotal(data.data.response.payment_total)
        setPengiriman(data.data.response.pengiriman)
        setPersenPajak(data.data.response.persen_pajak)
        setPic1Name(data.data.response.pic1_name)
        setPic1State(data.data.response.pic1_state)
        setPic2Name(data.data.response.pic2_name)
        setPic2State(data.data.response.pic2_state)
        setPriceTotal(data.data.response.price_total)
        setQtyTotal(data.data.response.qty_total)
        setScCode(data.data.response.sc_code)
        setScCounter(data.data.response.sc_counter)
        setTransactionDate(data.data.response.transaction_date)
        setType(data.data.response.type)
        setUserCode(data.data.response.user_code)
        setWarehouseId(data.data.response.warehouse_id)
       
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getItemDataSaved = () => {
    let data = {
        sc_id: props.match.params.id
    }
    axios.post(`${process.env.REACT_APP_API_BASE_URL}/so-commerce/item`, data, {headers: {
        Authorization: `Bearer ${token}`,
      }}
    ).then(async response => {
        let stateItem = [];
        await Promise.all(response.data.response.map(async (data) => {
            stateItem = [...stateItem, {
                item_id: data.item_id,
                item_name:data.item_name,
                item_code : data.item_code,
                satuan : data.satuan,
                qtysisa: data.max_qty,
                qty: data.qty,
                harga: data.harga,
                data: {
                    item_name: data.item_name,
                    harga: data.harga,
                    qtysisa: data.max_qty,
                  qty: data.qty,
                },
            }];
        }));
        setSavedItems(stateItem);
    })
}

const getKurirList = (id) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/kurir/aktif`,
        { headers }
      )
      .then((data) => {
        setKurirs(data.data.response);
        setKurir(id);
      })
      .catch(function (error) {
        console.log(error);
      });
  };


const formatRupiah = (money) => {
  return new Intl.NumberFormat('id-ID',
      { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }
  ).format(money);
}  
  

  return (
    <>
    {alert}
    <SimpleHeader name="Detail Pesanan" parentName="SO" />
    <Container className="mt--6" fluid>
        <Row>
        <div className="col">
            <CardBody>
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                      <h3>Detail Pesanan</h3>
                    </CardHeader>
                    <CardBody>
                        <Row md="12">
                          <Col md="6">
                              <FormGroup row>
                                <Label
                                 className="form-control-label"
                                  for="exampleEmail"
                                  sm={3}
                                >
                                  Kode
                                </Label>
                                <Col md="6">
                                      <Input
                                      disabled
                                        className="form-control-alternative"
                                        type="text"
                                        name="desc"
                                        placeholder="Masukan Keterangan"
                                        value={codetransaction}
                                        onChange={(e) => {
                                          setCodeTransaction(e.target.value);
                                        }}
                                      />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label
                                 className="form-control-label"
                                  for="exampleEmail"
                                  sm={3}
                                >
                                  Kode User
                                </Label>
                                <Col md="6">
                                      <Input
                                      disabled
                                        className="form-control-alternative"
                                        type="text"
                                        name="desc"
                                        placeholder="Masukan Keterangan"
                                        value={usercode}
                                        onChange={(e) => {
                                          setSuccessAlert(e.target.value);
                                        }}
                                      />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                              <Label
                               className="form-control-label"
                                for="exampleEmail"
                                sm={3}
                              >
                                Alamat
                              </Label>
                              <Col sm={6}>
                                <Input
                                disabled
                                className="form-control-alternative"
                                  type="textarea"
                                  name="keterangan"
                                  rows = "4"
                                  placeholder="Masukan Alamat"
                                  value={manualaddress}
                                  onChange={(e) => {
                                    setManualAddress(e.target.value);
                                  }}
                                />
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                              <Label
                               className="form-control-label" for="exampleEmail" sm={3}>
                                Kurir
                              </Label>
                              <Col sm={6}>
                                <Input
                                disabled
                                  className="form-control-alternative"
                                    type="select"
                                    value={kurir}
                                    onChange={(e) => {
                                      setKurir(e.target.value);
                                    }}>
                                    <option value='' disabled selected hidden>Pilih Kurir</option>
                                      {kurirs.map((warehouse2, key) => {
                                        return (
                                            <option key={key} value={warehouse2.id}>
                                              {warehouse2.name}
                                            </option>
                                        );
                                      })}
                                </Input>
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                              <Label
                               className="form-control-label"
                                for="exampleEmail"
                                sm={3}
                              >
                                Berat
                              </Label>
                              <Col sm={6}>
                                <Input
                                disabled
                                className="form-control-alternative"
                                  type="number"
                                  name="desc"
                                  placeholder="Masukan Ongkir"
                                  value={berat}
                                  onChange={(e) => {
                                    setBerat(e.target.value);
                                  }}
                                />
                              </Col>                             
                            </FormGroup>
                            <FormGroup row>
                              <Label
                               className="form-control-label"
                                for="exampleEmail"
                                sm={3}
                              >
                                Jarak
                              </Label>
                              <Col sm={6}>
                                <Input
                                disabled
                                className="form-control-alternative"
                                  type="number"
                                  name="desc"
                                  placeholder="Masukan Ongkir"
                                  value={jarak}
                                  onChange={(e) => {
                                    setJarak(e.target.value);
                                  }}
                                />
                              </Col>                             
                            </FormGroup>
                            
                            {/* <FormGroup row>
                              <Label
                               className="form-control-label" for="exampleEmail" sm={3}>
                                PPN
                              </Label>
                              <Col sm={6}>
                                <Input
                                  className="form-control-alternative"
                                    type="select"
                                    value={pajak}
                                    onChange={(e) => {
                                      setPajak(e.target.value);
                                      getByPajak(e.target.value);
                                    }}>
                                    <option value='' disabled selected hidden>Pilih PPN</option>
                                      {allpajak.map((warehouse2, key) => {
                                        return (
                                            <option key={key} value={warehouse2.id}>
                                              {warehouse2.keterangan}
                                            </option>
                                        );
                                      })}
                                </Input>
                              </Col>
                            </FormGroup> */}
                             <FormGroup row>
                              <Label
                               className="form-control-label"
                                for="exampleEmail"
                                sm={3}
                              >
                                PPN
                              </Label>
                              <Col sm={6}>
                                <Input
                                disabled
                                className="form-control-alternative"
                                  type="number"
                                  name="desc"
                                  placeholder="Masukan Ongkir"
                                  value={ppn}
                                  onChange={(e) => {
                                    setPPN(e.target.value);
                                  }}
                                />
                              </Col>                             
                            </FormGroup>
                          </Col>
                          <Col md="6">
                          <FormGroup row>
                            <Label
                             className="form-control-label"
                              for="exampleEmail"
                              sm={4}
                            >
                              Tipe Pesanan
                            </Label>
                            <Col sm={6}>
                            <Input
                            disabled
                            className="form-control-alternative"
                                name="Tipe Request"
                                type="select"
                                value={typereq}
                                onChange={(e) => {
                                  setTypeReq(e.target.value);
                                }}
                              >
                                <option value="" disabled hidden selected>Pilih Tipe Pesanan</option>
                                <option value={1}>Ready</option>
                                <option value={2}>Indent</option>
                              </Input>
                            </Col>
                            </FormGroup>
                            <FormGroup row>
                            <Label
                             className="form-control-label"
                              for="exampleEmail"
                              sm={4}
                            >
                              Pengiriman
                            </Label>
                            <Col sm={6}>
                            <Input
                            disabled
                            className="form-control-alternative"
                                name="Tipe Request"
                                type="select"
                                value={pengiriman}
                                onChange={(e) => {
                                  setPengiriman(e.target.value);
                                }}
                              >
                                <option value="" disabled hidden selected>Pilih Pengiriman</option>
                                <option value={1}>Ambil Sendiri</option>
                                <option value={2}>Delivery</option>
                                <option value={3}>Kurir</option>
                              </Input>
                            </Col>
                            </FormGroup>
                            {pengiriman >  1  ? (
                            <FormGroup row>
                              <Label
                               className="form-control-label"
                                for="exampleEmail"
                                sm={4}
                              >
                                Ongkir
                              </Label>
                              <Col sm={6}>
                                <Input
                                disabled
                                className="form-control-alternative"
                                  type="number"
                                  name="desc"
                                  placeholder="Masukan Ongkir"
                                  value={ongkir}
                                  onChange={(e) => {
                                    setOngkir(e.target.value);
                                  }}
                                />
                              </Col>                             
                            </FormGroup>
                            ):(
                              ""
                            )}
                                {/* <FormGroup row>
                              <Col
                                for="exampleEmail"
                                sm={4}
                              >
                                Validasi
                              </Col>
                                <Col sm={7}>
                                  <div style={{ display: "flex" }}>
                                    <div className="custom-control custom-radio mb-3">
                                      <Input
                                        className="custom-control-input"
                                        id="customRadio12"
                                        name="custom-radio-5"
                                        type="radio"
                                        value={5}
                                        checked={pic1state === 5}
                                        onChange={() => setPic1State(5)}
                                      />
                                      <Label
                                       className="form-control-label"
                                        className="custom-control-label"
                                        htmlFor="customRadio12"
                                      >
                                      </Label>
                                      Setuju
                                    </div>
                                    <div
                                      className="custom-control custom-radio mb-3"
                                      style={{ marginLeft: "20px" }}
                                    >
                                      <Input
                                        className="custom-control-input"
                                        id="customRadio13"
                                        name="custom-radio-5"
                                        type="radio"
                                        value={4}
                                        checked={pic1state === 4}
                                        onChange={() => setPic1State(4)}
                                      />
                                      <Label
                                       className="form-control-label"
                                        className="custom-control-label"
                                        htmlFor="customRadio13"
                                      >
                                      </Label>
                                      Tolak
                                    </div>
                                  </div>
                                </Col>
                              </FormGroup> */}
                               <FormGroup row>
                            <Label
                             className="form-control-label"
                              for="exampleEmail"
                              sm={4}
                            >
                              Status Barang
                            </Label>
                            <Col sm={6}>
                            <Input
                            disabled
                            className="form-control-alternative"
                                name="Tipe Request"
                                type="select"
                                value={pic2state}
                                onChange={(e) => {
                                  setPic2State(e.target.value);
                                }}
                              >
                                <option value="" disabled hidden selected>Pilih Pengiriman</option>
                                <option value={0}>Konfirmasi Pembayaran</option>
                                <option value={1}>Di Batalkan Customer</option>
                                <option value={2}>Di Batalkan Cabang</option>
                                <option value={3}>Dalam Pesanan</option>
                                <option value={4}>Tiba Di Gudang</option>
                                <option value={5}>Tiba Di Toko</option>
                                <option value={6}>Menuju Alamat Pengiriman</option>
                                <option value={7}>Telah Sampai</option>
                                <option value={8}>Pengembalian</option>
                                <option value={9}>Selesai</option>
                                <option value={10}>Mengalami Kendala</option>

                              </Input>
                            </Col>
                            </FormGroup>
                             <FormGroup row>
                              <Label
                               className="form-control-label"
                                for="exampleEmail"
                                sm={4}
                              >
                                Keterangan
                              </Label>
                              <Col sm={6}>
                                <Input
                            disabled
                                className="form-control-alternative"
                                  type="textarea"
                                  name="keterangan"
                                  rows = "4"
                                  placeholder="Masukan Keterangan"
                                  value={keterangan}
                                  onChange={(e) => {
                                    setKeterangan(e.target.value);
                                  }}
                                />
                              </Col>
                            </FormGroup>
                          </Col>
                      </Row>
                    </CardBody>
              </Card>
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                      <h3>Item</h3>
                    </CardHeader>
                    <CardBody>
                          <Table size="sm" responsive>
                            <thead>
                              <tr>
                                <th><b>Nama Item</b></th>
                                <th><b>Kode Item</b></th>
                                <th><b>Harga</b></th>
                                <th><b>Qty</b></th>
                                <th><b>Satuan</b></th>
                                <th><b>Sub Total</b></th>
                              </tr>
                            </thead>
                              <tbody>
                              {
                                savedItems.map((savedItem, key) => {
                                  return (
                                    <tr key={key}>
                                      <td>{savedItem.item_name}</td>
                                      <td>{savedItem.item_code}</td>
                                      <td><>{formatRupiah(savedItem.harga)}</></td>
                                      <td>{savedItem.qty}</td>
                                      <td>{savedItem.satuan}</td>
                                      <td>{formatRupiah(savedItem.harga  * savedItem.qty)}</td>
                                      
                                    </tr>
                                  )
                                })
                              }
                              </tbody>
                          </Table>
                        </CardBody>
                        <CardFooter>
                          <Row md="12">
                            <Col md="6">
                            </Col>
                            <Col md="2">
                            </Col>
                            <Col md="4">
                              <FormGroup row>
                              <Label
                               className="form-control-label"
                                for="exampleEmail"
                                sm={4}
                                size="small"
                              >
                                <b>Total</b>
                              </Label>
                              <Col sm={6}>
                              <Input
                              className="form-control-alternative"
                                disabled
                                style={{fontWeight: 'bold'}}
                                type="text"
                                name="barcode"
                                placeholder="Harga Total"
                                value={"Rp." + totalPrice1.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") + ",-"}
                                />
                              </Col>
                              </FormGroup>
                              <FormGroup row>
                              <Label
                               className="form-control-label"
                                for="exampleEmail"
                                sm={4}
                              >
                                <b>PPN</b>
                              </Label>
                              <Col sm={6}>
                              <Input
                                className="form-control-alternative"
                                disabled
                                style={{fontWeight: 'bold'}}
                                type="text"
                                name="barcode"
                                placeholder="PPN"
                                value={"Rp." + ppnnew.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") + ",-"}
                                />
                              </Col>
                              </FormGroup>
                              <FormGroup row>
                              <Label
                               className="form-control-label"
                                for="exampleEmail"
                                sm={4}
                              >
                                <b>Ongkir</b>
                              </Label>
                              <Col sm={6}>
                              <Input
                                className="form-control-alternative"
                                disabled
                                style={{fontWeight: 'bold'}}
                                type="text"
                                name="barcode"
                                placeholder="PPN"
                                value={"Rp." + ongkir1.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") + ",-"}
                                />
                              </Col>
                              </FormGroup>
                              <FormGroup row>
                              <Label
                               className="form-control-label"
                                for="exampleEmail"
                                sm={4}
                              >
                                <b>Grand Total</b>
                              </Label>
                              <Col sm={6}>
                              <Input
                              className="form-control-alternative"
                                disabled
                                type="text"
                                name="barcode"
                                style={{fontWeight: 'bold'}}
                                placeholder="Grand Total"
                                value={"Rp." + grandtotal.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") + ",-"}
                                />
                              </Col>
                              </FormGroup>

                            </Col>
                          </Row>
                        </CardFooter>
                <CardFooter>
                      <Link className="btn btn-info" to="/admin/penjualan/so/penjualan/histori">
                        Kembali
                      </Link>
                </CardFooter>
              </Card>
            </CardBody>
          </div>
        </Row>
    </Container>
    </>
  );
}