/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import { 
    Card, 
    Button, 
    Row, 
    Col, 
    CardBody, 
    CardHeader, 
    Badge,
    Form, 
    Container,
    FormGroup, 
    Label, 
    Input ,
    DropdownToggle,
    UncontrolledDropdown,
    DropdownMenu,
    DropdownItem,
} from 'reactstrap';
import { Link } from "react-router-dom";
import axios from 'axios';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import SweetAlert from "react-bootstrap-sweetalert";
import ModalCetakFinanceBbmPesananAplikator from './ModalCetakBankMasukPP';
import ModalDetailFinanceBbmPesananAplikator from './ModalDetail';
// import Halaman404 from 'views/404';

const HistoriBuktiBankMasukPesananAplikator = () => {
const [alert, setAlert] = React.useState(null);
const token = localStorage.token;
  const warehouse = parseInt(localStorage.warehouse);
//   const redirectPrefix = `/admin/bbm-finance/detail/`;
  const [rowIndex, setRowIndex] = useState(0);
  const [allBuktiKasKeluar, setAllBuktiKasKeluar] = useState([]);
  const [uomCode, setUomCode] = useState("");
  const [description, setDescription] = useState("");
  const [page, setPage] = useState(1);
  const [perPage, setPerpage] = useState(10);
  const [totalItem, setTotalItem] = useState(0);
  const [currentSort, setCurrentSort] = useState("");
  
  const [openModalCetak, setOpenModalCetak] = useState(false);
  const [dataModalCetak, setDataModalCetak] = useState({
    id: 0,
  });
  const toggleModal = () => setOpenModalCetak(!openModalCetak);

  const [openModalCetak1, setOpenModalCetak1] = useState(false);
  const [dataModalCetak1, setDataModalCetak1] = useState({
    id: 0,
  });
  const toggleModal1 = () => setOpenModalCetak1(!openModalCetak1);

  const displayModalCetak = (id) => {
    setDataModalCetak({
      id: id,
    });
    setOpenModalCetak(true);
  };
  
  const displayModalCetak1 = (id) => {
    setDataModalCetak1({
      id: id,
    });
    setOpenModalCetak1(true);
  };
  let paginationOption = {
    page: page,
    alwaysShowAllBtns: true,
    override: true,
    showTotal: true,
    withFirstAndLast: false,
    sizePerPage: perPage,
    totalSize: totalItem,
    onPageChange: (page) => {
      updateDataTable(page, perPage, currentSort, uomCode, description);
    },
    sizePerPageRenderer: () => (
      <div className="dataTables_length" id="datatable-basic_length">
        <label className="form-control-label">
          Show{" "}
          {
            <select
              name="datatable-basic_length"
              aria-controls="datatable-basic"
              className="form-control form-control-sm"
              onChange={(e) => {
                updateDataTable(page, e.target.value, currentSort)
              }}
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="25">25</option>
              <option value="50">50</option>
            </select>
          }{" "}
          entries.
        </label>
      </div>
    ),
  }

  const updateDataTable = (page, perPage, sort, uomCode, description) => {
    getBuktiKasKeluar(page, perPage, sort, uomCode, description);
    setPage(page);
    setPerpage(perPage);
    setRowIndex((page - 1) * perPage);
    setCurrentSort(sort);
    setUomCode(uomCode);
    setDescription(description);
  }

  const handleTableChange = (type, { sortField, sortOrder }) => {
    if (type === "sort") {
      let sort = `${sortField} ${sortOrder}`
      updateDataTable(page, perPage, sort,  uomCode, description)
    }
  }

  
  useEffect(() => {
    getBuktiKasKeluar(page, perPage, currentSort);
  }, []);

  // fungsi dari ambil data
  const getBuktiKasKeluar = (page, perPage, currentSort,transaction_date=null, bbm_code=null) => {
    
    let filter = { 
      page: page, 
      per_page: perPage,
      warehouse_id : parseInt(warehouse)
    };
    if (transaction_date !== null) {
        filter = Object.assign(filter, { transaction_date: transaction_date });
      }
      if (bbm_code !== null) {
        filter = Object.assign(filter, { bbm_code: bbm_code });
      }
    const data = filter;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/bbm-apk/page`, data, {
        headers,
      })
      .then((data) => {
        setAllBuktiKasKeluar(data.data.response);
        setPage(data.data.current_page + 1);
        setPerpage(data.data.per_page);
        setTotalItem(data.data.total_item);
      })
      .catch(function (error) {
        setAllBuktiKasKeluar(error.response.data.response);
      });
}

const setGagalAlert = (id) => {
setAlert(
<SweetAlert
danger
showConfirm
confirmBtnText="Ok"
title={id}
onCancel={hideAlert}
onConfirm={hideAlert}
/>
);
};

const hideAlert = () => {
  setAlert(null);
};

  const reset = () => {
    setUomCode("");
    setDescription("");
    updateDataTable(1, perPage, currentSort, "", "");
  }

  const formatRupiah = (money) => {
    return new Intl.NumberFormat('id-ID',
        { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }
    ).format(money);
  }

  return (
    <div>  
    {alert}
      <SimpleHeader name="Histori" parentName="PO" />
        <Container className="mt--6" fluid>
        <Row>
          <div className="col">
          <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ textAlign: 'left' }}>
                      <Link className="btn btn-link" to="/admin/bukti-bank-masuk-pesanan-aplikator">
                        <i className="fa fa-arrow-circle-left fa-2x" /> 
                      </Link>
                  </div>
                </div>
              </CardHeader>
              <CardBody>
                      <Form>
                      <Row md="12">
                            <Col md="3">
                              <FormGroup>
                                <Label className="form-control-label" htmlFor="exampleFormControlSelect3">Tanggal</Label>
                                <Input
                                className="form-control-alternative"
                                  type="date"
                                  placeholder="Masukan Kode BKK"
                                  value={uomCode}
                                  onChange={e => updateDataTable(1, perPage, currentSort, e.target.value, description)}
                                />
                              </FormGroup>
                            </Col>
                            <Col md="3">
                              <FormGroup>
                                <Label className="form-control-label" htmlFor="exampleFormControlSelect3">Kode BBM</Label>
                                <Input
                                className="form-control-alternative"
                                  type="text"
                                  placeholder="Masukan kode"
                                  value={description}
                                  onChange={e => updateDataTable(1, perPage, currentSort, uomCode, e.target.value)}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        <Row>
                          <Col>
                            <Button type="button" onClick={reset} color="secondary">Reset</Button>
                          </Col>
                        </Row>
                      </Form>
                      {allBuktiKasKeluar === null ? (
                        <div>
                          <ToolkitProvider
                                  rowNumber={rowIndex}
                                  data={""}
                                  keyField="id"
                                  columns={[
                                  {
                                      dataField: "no",
                                      text: "#",
                                      sort: true,
                                      page: 1,
                                      formatter: (cell, row, index) => {
                                      let currentRow = ++index;
                                      return currentRow + rowIndex;
                                      },
                                  },
                                  {
                                    dataField: "",
                                    text: "Tanggal",
                                    sort: true,
                                },
                                {
                                    dataField: "",
                                    text: "Kode BBM",
                                    sort: true,
                                },
                                {
                                    dataField: "",
                                    text: "Tagihan",
                                    sort: true,
                                },
                                {
                                  dataField: "",
                                  text: "Status",
                                  sort: true,
                              },
                           
                                  ]}
                              >
                                  {(props) => (
                                  <div className="py-4 table-responsive">
                                      <BootstrapTable
                                      remote
                                      {...props.baseProps}
                                      bootstrap4={true}
                                      bordered={false}
                                      hover={true}
                                      onTableChange={handleTableChange}
                                      />
                                  </div>
                                  )}
                          </ToolkitProvider>
                          <CardHeader>
                              <center><h3>DATA NOT FOUND</h3></center>
                          </CardHeader>
                        </div>
                        ):(
                    <ToolkitProvider
                            rowNumber={rowIndex}
                            data={allBuktiKasKeluar}
                            keyField="id"
                            columns={[
                            {
                                dataField: "no",
                                text: "#",
                                sort: true,
                                page: 1,
                                formatter: (cell, row, index) => {
                                let currentRow = ++index;
                                return currentRow + rowIndex;
                                },
                            },
                            {
                              dataField: "transaction_date",
                              text: "Tanggal",
                              sort: true,
                          },
                            {
                                dataField: "bbm_code",
                                text: "Kode BBM",
                                sort: true,
                            },
                            {
                                dataField: "payment_total",
                                text: "Tagihan",
                                sort: true,
                                formatter: (value) => formatRupiah(value)
                            },
                            {
                              dataField: "",
                              text: "Status",
                              sort: true,
                              formatter: (cell, row) => {
                                return row.status_d === 3
                                  ? 
                                    <Badge color="" className="badge-dot mr-4">
                                      <i className="bg-danger" />
                                      Proses
                                    </Badge>
                                  : row.status_d === 4
                                  ? <Badge color="" className="badge-dot mr-4">
                                      <i className="bg-warning" />
                                      Tidak Setuju
                                    </Badge>
                                  : <Badge color="" className="badge-dot mr-4">
                                      <i className="bg-success" />
                                      Setuju
                                    </Badge>;
                              },
                          },
                          {
                            dataField: "",
                            text: "",
                            formatter: (cell, row, index) => {
                              return row.status_d === 3
                                ? <UncontrolledDropdown nav>
                                    <DropdownToggle color="danger">
                                        Tindakan
                                      </DropdownToggle>
                                      <DropdownMenu center>
                                            {/* <Link  to={redirectPrefix + row.id}
                                              id={"tooltip_" + row.id}>
                                            <DropdownItem>
                                              <i className="fas fa-user-edit" /><span>Detail</span>
                                            </DropdownItem>
                                            </Link> */}
                                            <Link  onClick={() => displayModalCetak1(row.id)}>
                                            <DropdownItem>
                                              <i className="fas fa-user-edit" /> <span>Detail</span>
                                            </DropdownItem>
                                        </Link>
                                      </DropdownMenu>
                                  </UncontrolledDropdown>
                                : row.status_d === 4
                                ?  <UncontrolledDropdown nav>
                                <DropdownToggle color="danger">
                                    Tindakan
                                  </DropdownToggle>
                                  <DropdownMenu center>
                                        {/* <Link  to={redirectPrefix + row.id}
                                          id={"tooltip_" + row.id}>
                                        <DropdownItem>
                                          <i className="fas fa-user-edit" /><span>Detail</span>
                                        </DropdownItem>
                                        </Link> */}
                                        <Link  onClick={() => displayModalCetak1(row.id)}>
                                            <DropdownItem>
                                              <i className="fas fa-user-edit" /> <span>Detail</span>
                                            </DropdownItem>
                                        </Link>
                                  </DropdownMenu>
                              </UncontrolledDropdown>
                                : <UncontrolledDropdown nav>
                                <DropdownToggle color="danger">
                                    Tindakan
                                  </DropdownToggle>
                                  <DropdownMenu center>
                                        {/* <Link  to={redirectPrefix + row.id}
                                          id={"tooltip_" + row.id}>
                                        <DropdownItem>
                                          <i className="fas fa-user-edit" /><span>Detail</span>
                                        </DropdownItem>
                                        </Link> */}
                                         <Link  onClick={() => displayModalCetak1(row.id)}>
                                            <DropdownItem>
                                              <i className="fas fa-user-edit" /> <span>Detail</span>
                                            </DropdownItem>
                                        </Link>
                                        <Link  onClick={() => displayModalCetak(row.id)}>
                                            <DropdownItem>
                                              <i className="fas fa-print" /> <span>Cetak</span>
                                            </DropdownItem>
                                        </Link>
                                  </DropdownMenu>
                              </UncontrolledDropdown>
                            },
                          },
                            ]}
                        >
                            {(props) => (
                            <div className="py-4 table-responsive">
                                <BootstrapTable
                                remote
                                {...props.baseProps}
                                bootstrap4={true}
                                bordered={false}
                                hover={true}
                                pagination={paginationFactory({ ...paginationOption })}
                                onTableChange={handleTableChange}
                                />
                            </div>
                            )}
                    </ToolkitProvider>
                        )}
              </CardBody>
            </Card>
          </div>
        </Row>
        </Container>
        {openModalCetak && (
        <ModalCetakFinanceBbmPesananAplikator
          open={openModalCetak}
          data={dataModalCetak}
          toggle={toggleModal}
        />
      )}
      {openModalCetak1 && (
        <ModalDetailFinanceBbmPesananAplikator
          open={openModalCetak1}
          data={dataModalCetak1}
          toggle={toggleModal1}
        />
      )}
    </div>
  );
}

export default HistoriBuktiBankMasukPesananAplikator;
