/*eslint-disable*/
import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Label,
  FormGroup,
  Row,
  Input,
  Container,
  CardHeader,
  CardFooter,
  Button,
  Form,
  FormFeedback,
} from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import SweetAlert from "react-bootstrap-sweetalert";
import Halaman404 from "views/404";

export default function PajakPage() {
  const token = localStorage.token;
  const warehouse = localStorage.warehouse;
  let history = useHistory();
  const [persentase, setPersentase] = useState("");
  const [persentaseError, setPersentaseError] = useState(null);
  const [open, setOpen] = useState(false)
  const [id, setId] = useState("")
  const [alert, setAlert] = React.useState(null);
 

  const validateForm = () => {
    let error = false;
    if (persentase === "") {
      setPersentaseError("invalid");
      error = true;
    }
    return error;
  };

  useEffect(() => {
    getPajak();
  }, []);

  const getPajak = async (page, perPage ) => {
    let filter = { 
      page: page, 
      per_page: perPage 
    }
    const data = filter;
    const headers = {
      'Authorization': `Bearer ${token}`,
    };
    const res = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/pajak-ecommerce/page`, data, { headers })
    setPersentase(res.data.response.persen_pajak);
    setId(res.data.response.id);

    }


  function EditData() {
    let data = {
      persen_pajak: parseFloat(persentase),
    };
    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/pajak-ecommerce/update/${id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (response) {
        setSuccessAlert(response.data.message);
        setOpen(false)
        getPajak()
        })
        .catch(function (error) {
          setGagalAlert(error.response.data.message);
        });
  }

  const setSuccessAlert = (id) => {
    setAlert(
      <SweetAlert
        success
        timeout={1000}
        showConfirm
        confirmBtnText="Ok"
        title={id}
        onCancel={hideAlert}
        onConfirm={hideAlert}
      />
    )
  }

    const setGagalAlert = (id) => {
    setAlert(
        <SweetAlert
        danger
        showConfirm
        confirmBtnText="Ok"
        title={id}
        onCancel={hideAlert}
        onConfirm={hideAlert}
        />
    );
    };

    const setQuestionAlert = () => {
    setAlert(
        <SweetAlert
        warning
        showCancel
        confirmBtnText="Lanjutkan"
        confirmBtnBsStyle="danger"
        title="Apakah Kamu Yakin?"
        onConfirm={EditData}
        onCancel={hideAlert}
        focusCancelBtn
        />
    );
    };

  const hideAlert = () => {
    setAlert(null);
  }

  return (
    <>
    {alert}
      <SimpleHeader name="Pajak" parentName="Admin" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <CardBody>
              <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div style={{ textAlign: 'left' }}>
                    </div>
                    <div style={{ textAlign: 'right' }}>
                    <Button hidden={open === true} color="warning" onClick={() => setOpen(true)}>Edit</Button>
                    <Button hidden={open === false} color="info" onClick={() => setQuestionAlert()}>Update</Button>
                     
                    </div>
                  </div>
                </CardHeader>
                  <CardBody>
                    <FormGroup>
                      <Label
                        className="form-control-label"
                        htmlFor="exampleFormControlInput1"
                      >
                        Persentase
                        {/* <span className="text-danger">*</span> */}
                      </Label>
                      <Input
                        disabled = {open === false}
                        autoComplete="off"
                        type="text"
                        name="persentase"
                        placeholder="Masukan Persentase"
                        value={persentase}
                        invalid={persentaseError === "invalid"}
                        onChange={(e) => {
                          setPersentase(e.target.value);
                          if (e.target.value !== "") {
                            setPersentaseError("");
                          }
                        }}
                      />
                      <FormFeedback>Persentase tidak boleh kosong</FormFeedback>
                    </FormGroup>
                  </CardBody>
              </Card>
            </CardBody>
          </div>
        </Row>
      </Container>
    </>
  );
}
