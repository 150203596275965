/*eslint-disable*/
import React, { useEffect, useState } from "react";
import {
  
  Card,
  Button,
  Row,
  Col,
  CardBody,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Form,
  FormGroup,
  FormFeedback,
  Label,
  Input,
  
} from "reactstrap";
import { Link,useHistory } from "react-router-dom";
import axios from "axios";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import SweetAlert from "react-bootstrap-sweetalert";
import Halaman404 from "views/404";

export default function ValidasiSuratJalanAddendumKepalaGudang(props) {
  const token = localStorage.token;
  const [alert, setAlert] = React.useState(null);
  const username = localStorage.username;
  const warehouse = localStorage.warehouse;
  let history = useHistory();
  const [name, setName] = useState("")
  const [customers, setCustomers] = useState([]);
  const [customer, setCustomer] = useState("");
  const [status,setStatus] = useState("")
  const [pengiriman, setPengiriman] = useState([]);
  const [keterangan, setKeterangan] = useState("");
  const [editingItem, setEditingitem] = useState([]);
  const [savedItems, setSavedItems] = useState([]);
  const [codeso, setCodeSo] = useState("");
  const [codeSc, setCodeSc] = useState("");
  const [driver, setDriver] = useState("");
  const [helper, setHelper] = useState("");
  const [nopol, setNopol] = useState("");
  const [qtyError, setQtyError] = useState(null);
 
  const validateForm = () => {
    let error = false;
    savedItems.map((savedItem, key) => { 
      if (savedItem.qty > savedItem.qtysisa) {
        setQtyError("invalid");
        error = true;
      }
    })
    return error;
};

const handleformvalidasi = (e) => {
  e.preventDefault();
  if (!validateForm()) {
      setQuestionAlert();
  }
}

  useEffect(() => {
    getById();
  }, []);

  const getById = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/surat-jalan-addendum/get/${props.match.params.id}`,
        { headers }
      )
      .then((data) => {
        getItemDataSaved();
        // getCustomer(data.data.response.customer_id);
        setName(data.data.response.user_name)
        setKeterangan(data.data.response.keterangan);
        setDriver(data.data.response.driver);
        setHelper(data.data.response.helper);
        setStatus(data.data.response.status_sj);
        setNopol(data.data.response.nopol);
        setCodeSc(data.data.response.code_sj);
        setCodeSo(data.data.response.code_addendum);
        setPengiriman(data.data.response.pengiriman);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  console.log(codeSc);

  const getItemDataSaved = () => {
    axios.post(`${process.env.REACT_APP_API_BASE_URL}/surat-jalan-addendum/item`, {

        sjadd_id: props.match.params.id

      },{headers: {
        Authorization: `Bearer ${token}`
      },}).then(async response => {
          let stateItem = [];
          let stateEditing = [];
          await Promise.all(response.data.response.map(async (data) => {
              stateItem = [...stateItem, {
                item_id: data.item_id,
                item_name:data.item_name,
                item_code : data.item_code,
                qty: data.qty,
                harga: data.harga,
                qtysisa : data.max_qty,
                satuan : data.satuan,
                data: {
                    item_name: data.item_name,
                    qtysisa : data.max_qty,
                    item_code : data.item_code,
                    qty: data.qty,
                    satuan: data.satuan,
                },
            }]; 
              stateEditing = [...stateEditing, {
              editing: false
          }];
      }));
      setEditingitem(stateEditing);
      setSavedItems(stateItem);
    })
}

const getCustomer = (id) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/customer/list`,
        { headers }
      )
      .then((data) => {
        setCustomers(data.data.response);
        setCustomer(id);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

   function EditData() {
    let dataItems = [];
        savedItems.map((dataItem) => dataItems = [...dataItems, 
            { 
                item_id: dataItem.item_id, 
                qty: dataItem.qty, 
                harga:dataItem.harga,
            }]);
    let data = {
        warehouse_id : parseInt(warehouse),
        admin_gudang : username,
        code_addendum: codeso,
        code_sc : codeSc,
        status_sj: status,
        user_name : name,
        pengiriman: parseInt(pengiriman),
        keterangan: keterangan ,
        driver : driver,
        helper : helper,
        nopol : nopol,
        items : dataItems
    };
    axios
        .post(
          `${process.env.REACT_APP_API_BASE_URL}/surat-jalan-addendum/update/${props.match.params.id}`,
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(function (response) {
          setSuccessAlert(response.data.message)
				setTimeout(() => (history.push("/admin/surat-jalan-addendum/validasi-kepala-gudang")), 1000);

        })
        .catch(function (error) {
              setGagalAlert(error.response.data.message);
        });
    }
  
    const setSuccessAlert = (id) => {
      setAlert(
        <SweetAlert
          success
          showConfirm
          confirmBtnText="Ok"
          title={id}
          onCancel={hideAlert}
          onConfirm={hideAlert}
        />
      )
    }

  const setGagalAlert = (id) => {
  setAlert(
    <SweetAlert
    danger
    showConfirm
    confirmBtnText="Ok"
    title={id}
    onCancel={hideAlert}
    onConfirm={hideAlert}
    />
  );
  };

  const setQuestionAlert = () => {
  setAlert(
    <SweetAlert
    warning
    showCancel
    confirmBtnText="Lanjutkan"
    confirmBtnBsStyle="danger"
    title="Apakah Kamu Yakin?"
    onConfirm={EditData}
    onCancel={hideAlert}
    focusCancelBtn
    />
  );
  };
  
    const hideAlert = () => {
      setAlert(null);
    };
  

const handleSubmit = (e) => {
    {
      EditData();
    }
  };

  const changePriceStatus = (index, status) => {
    setEditingitem([
        ...editingItem.slice(0, index),
        Object.assign({}, editingItem[index], { editing: status }),
        ...editingItem.slice(index + 1)
    ]);
  }
  
  const changeItemDataTable = async (arg) => {
      setSavedItems([
          ...savedItems.slice(0, arg.index),
          Object.assign({}, savedItems[arg.index], {
              data: {
                  item_name: arg.itemName,
                  item_code : arg.item_code,
                  satuan : arg.satuan,
                  qty:savedItems[arg.index].qty,
              }
          }),
          ...savedItems.slice(arg.index + 1)
      ]);
  
      changePriceStatus(arg.index, false);
  }

  return (
    <>
    {alert}
    <SimpleHeader name="Validasi Kepala Gudang" parentName="Penjualan" />
    <Container className="mt--6" fluid>
        <Row>
        <div className="col">
            <CardBody>
              <Card className="bg-secondary shadow">
                    <CardHeader className="bg-white border-0">
                      <h3>Validasi Kepala Gudang</h3>
                    </CardHeader>
                    <CardBody>
                      <Row md="12">
                          <Col md="6">
                          <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={3}
                              >
                                Kode
                              </Label>
                              <Col sm={6}>
                              <Input
                                  className="form-control-alternative"
                                  disabled
                                  name="customer"
                                  type="text"
                                  value={codeso}
                                  onChange={(e) => {
                                    setCodeSo(e.target.value);
                                  }}
                                />
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={3}
                              >
                                User
                              </Label>
                              <Col sm={6}>
                                  <Input
                                className="form-control-alternative"
                                disabled
                                  type="text"
                                  name="Alamat Kirim"
                                  placeholder="Masukan Nama"
                                  value={name}
                                  onChange={(e) => {
                                    setName(e.target.value);
                                  }}
                                />
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={3}
                              >
                                Driver
                              </Label>
                              <Col sm={6}>
                                <Input
                                disabled
                                    autoComplete="off"
                                    className="form-control-alternative"
                                    placeholder="Masukan Driver"
                                    name="customer"
                                    type="text"
                                    value={driver}
                                    onChange={(e) => {
                                      setDriver(e.target.value);
                                    }}
                                  >
                                </Input>
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={3}
                              >
                                Helper
                              </Label>
                              <Col sm={6}>
                                <Input
                                disabled
                                    autoComplete="off"
                                    className="form-control-alternative"
                                    placeholder="Masukan Helper"
                                    name="customer"
                                    type="text"
                                    value={helper}
                                    onChange={(e) => {
                                      setHelper(e.target.value);
                                    }}
                                  >
                                </Input>
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={3}
                              >
                                Nopol
                              </Label>
                              <Col sm={6}>
                                <Input
                                disabled
                                    autoComplete="off"
                                    className="form-control-alternative"
                                    placeholder="Masukan Nopol"
                                    name="customer"
                                    type="text"
                                    value={nopol}
                                    onChange={(e) => {
                                      setNopol(e.target.value);
                                    }}
                                  >
                                </Input>
                              </Col>
                            </FormGroup>
                          </Col>
                          <Col md="6">
                          <FormGroup row>
                            <Label
                            className="form-control-label"
                              for="exampleEmail"
                              sm={3}
                            >
                              Pengiriman
                            </Label>
                            <Col sm={6}>
                            <Input
                                className="form-control-alternative"
                                disabled
                                name="Tipe Request"
                                type="select"
                                value={pengiriman}
                                onChange={(e) => {
                                  setPengiriman(e.target.value);
                                }}
                              >
                                <option value="">Pilih Request</option>
                                <option value={1}>Ambil Sendiri</option>
                                <option value={2}>Delivery</option>
                                <option value={3}>Kurir</option>
                              </Input>
                            </Col>
                            </FormGroup>
                          <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={3}
                              >
                                Alamat Kirim
                              </Label>
                              <Col sm={6}>
                                <Input
                                className="form-control-alternative"
                                disabled
                                  type="textarea"
                                  name="Alamat Kirim"
                                  rows = "6"
                                  placeholder="Masukan Alamat Kirim"
                                  value={keterangan}
                                  onChange={(e) => {
                                    setKeterangan(e.target.value);
                                  }}
                                />
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                            <Col
                              for="exampleEmail"
                              sm={3}
                            >
                              <Label className="form-control-label">Validasi</Label>
                            </Col>
                              <Col sm={6}>
                                <div style={{ display: "flex" }}>
                                  <div className="custom-control custom-radio mb-3">
                                    <Input
                                      className="custom-control-input"
                                      id="customRadio12"
                                      name="custom-radio-5"
                                      type="radio"
                                      value={5}
                                      checked={status === 5}
                                      onChange={() => setStatus(5)}
                                    />
                                    <Label
                                      className="custom-control-label"
                                      htmlFor="customRadio12"
                                    >
                                    </Label>
                                    <b>SETUJU</b>

                                  </div>
                                  <div
                                    className="custom-control custom-radio mb-3"
                                    style={{ marginLeft: "20px" }}
                                  >
                                    <Input
                                      className="custom-control-input"
                                      id="customRadio13"
                                      name="custom-radio-5"
                                      type="radio"
                                      value={4}
                                      checked={status === 4}
                                      onChange={() => setStatus(4)}
                                    />
                                    <Label
                                      className="custom-control-label"
                                      htmlFor="customRadio13"
                                    >
                                    </Label>
                                    <b>TOLAK</b>

                                  </div>
                                </div>
                              </Col>
                          </FormGroup>
                          </Col>
                      </Row>
                    </CardBody>
              </Card>
              <Card className="bg-secondary shadow">
                <Form onSubmit={handleformvalidasi}>
                    <CardHeader className="bg-white border-0">
                      <h3>Item</h3>
                    </CardHeader>
                    <CardBody>
                      <Table size="sm" responsive>
                        <thead>
                        <tr>
                            <th><b>Nama Item</b></th>
                            <th><b>Kode Item</b></th>
                            <th><b>Qty</b></th>
                            <th><b>Satuan</b></th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            savedItems.map((savedItem, key) => {
                                return (
                                    <tr key={key}>
                                        <td>{savedItem.data.item_name}</td>
                                        <td>{savedItem.data.item_code}</td>
                                        <td>{savedItem.data.qty}</td>
                                        <td>{savedItem.data.satuan}</td>
                                    </tr>
                                )
                            })
                        }
                        </tbody>
                      </Table>
                    </CardBody>
                    <CardFooter>
                          <Link className="btn btn-info" to="/admin/surat-jalan-addendum/validasi-kepala-gudang">
                            Kembali
                          </Link>
                          <Button color="danger" type="submit">
                              Simpan
                          </Button>
                    </CardFooter>
                </Form>
              </Card>
            </CardBody>
          </div>
        </Row>
    </Container>
    </>
  );
}