/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import { 
    Card, 
    Row, 
    Col, 
    CardBody, 
    CardHeader, 
    Container,
    Badge,
    Form, 
    FormGroup,
    Label, 
    Input,UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem
} from 'reactstrap';
import { Link } from "react-router-dom";
import axios from 'axios';
import SimpleHeader from "components/Headers/SimpleHeader.js";
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import SweetAlert from "react-bootstrap-sweetalert";


import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

// import {PDFDownloadLink} from '@react-pdf/renderer';
import ModalCetakReturSo from './ModalCetakReturSo';
import Halaman404 from 'views/404';
// import PdfReportSo from './Pdf';

const HistoriSoRetur = () => {
const [alert, setAlert] = React.useState(null);
  const token = localStorage.token;
  const warehouse = parseInt(localStorage.warehouse);
  const redirectPrefix = `/admin/so-retur/histori/detail/`;
  // const redirectPrefix1 = `/admin/so-retur/payment/`;
  const [rowIndex, setRowIndex] = useState(0);
  const [allPenawaranSo, setAllPenawaranSo] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerpage] = useState(1000000);
  const [totalItem, setTotalItem] = useState(0);
  const [currentSort, setCurrentSort] = useState("");
  const [status,setStatus] = useState("");
  const [openModalCetak, setOpenModalCetak] = useState(false);
  const [dataModalCetak, setDataModalCetak] = useState({ id: 0,});
  const toggle = () => setOpenModalCetak(!openModalCetak);
  const allInfo = JSON.parse(localStorage.allinfo);
  const ReturSoProyeks = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Retur SO").map((p) => p.read_access));
  const cetak = String(allInfo.privileges.filter((i) => i.privilege_name === "Tab Cetak Retur SO").map((p) => p.create_access));
  
  let paginationOption = {
    page: page,
    alwaysShowAllBtns: true,
    override: true,
    showTotal: true,
    withFirstAndLast: false,
    sizePerPage: perPage,
    totalSize: totalItem,
    onPageChange: (page) => {
      updateDataTable(page, perPage, currentSort, status);
    },
    sizePerPageRenderer: () => (
      <div className="dataTables_length" id="datatable-basic_length">
        <label>
          Show{" "}
          {
            <select
              name="datatable-basic_length"
              aria-controls="datatable-basic"
              className="form-control form-control-sm"
              onChange={(e) => {
                updateDataTable(page, e.target.value, currentSort)
              }}
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="25">25</option>
              <option value="50">50</option>
            </select>
          }{" "}
          entries.
        </label>
      </div>
    ),
  }

  const updateDataTable = (page, perPage, sort,status) => {
    setPage(page);
    setPerpage(perPage);
    setRowIndex((page - 1) * perPage);
    setCurrentSort(sort);
    setStatus(status);
    getPenawaranSo(page, perPage, sort, status);
  }

  const handleTableChange = (type, { sortField, sortOrder }) => {
    if (type === "sort") {
      let sort = `${sortField} ${sortOrder}`
      updateDataTable(page, perPage, sort, status)
    }
  }

  
  useEffect(() => {
    getPenawaranSo(page, perPage, currentSort);
  }, []);

  // fungsi dari ambil data
  const getPenawaranSo = async (page, perPage, currentSort, retur_code='') => {
    
    let filter = { 
      page: page, 
      per_page: perPage,
      // status_ar2:5,
      // status:2,
      // status_d:5,
      warehouse_id : parseInt(warehouse)
    };
    if (retur_code !== '') {
      filter = Object.assign(filter, { retur_code: retur_code })
    }
    const data = filter;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/so-retur/page`, data, {
        headers,
      })
      .then((data) => {
        setAllPenawaranSo(data.data.response);
        setPage(data.data.current_page + 1);
        setPerpage(data.data.per_page);
        setTotalItem(data.data.total_item);
      })
      .catch(function (error) {
        setAllPenawaranSo(error.response.data.response);
      });
  }


const setGagalAlert = (id) => {
setAlert(
  <SweetAlert
  danger
  showConfirm
  confirmBtnText="Ok"
  title={id}
  onCancel={hideAlert}
  onConfirm={hideAlert}
  />
);
};

const hideAlert = () => {
  setAlert(null);
};

const displayModalCetak = (id) => {
  setDataModalCetak({
    id: id,
  });
  setOpenModalCetak(true);
};

  return (
    <>
    {alert}
    {ReturSoProyeks && ReturSoProyeks === "1" ? (
      <div>
       <SimpleHeader name="Histori" parentName="PO" />
        <Container className="mt--6" fluid>
          <Row>
            <div className="col">
            <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div style={{ textAlign: 'left' }}>
                        <Link className="btn btn-link" to="/admin/so-retur">
                          <i className="fa fa-arrow-circle-left fa-2x" /> 
                        </Link>
                    </div>
                  </div>
                </CardHeader>
                <CardBody>
                        <Form>
                          <Row md="12">
                          <Col md="3">
                            <FormGroup>
                              <Label
                                className="form-control-label"
                                htmlFor="exampleFormControlSelect3"
                            >Kode Retur</Label>
                              <Input
                                className="form-control-alternative"
                                placeholder="Masukan Kode Retur"
                                type="text"
                                value={status}
                                onChange={e => updateDataTable(1, perPage, currentSort, e.target.value)}
                              >
                              </Input>
                            </FormGroup>
                          </Col>
                          </Row>
                        </Form>
                        {allPenawaranSo === null ? (
                        <div>
                          <ToolkitProvider
                                  rowNumber={rowIndex}
                                  data={""}
                                  keyField="id"
                                  columns={[
                                  {
                                    dataField: "no",
                                    text: "#",
                                    sort: true,
                                    page: 1,
                                    formatter: (cell, row, index) => {
                                    let currentRow = ++index;
                                    return currentRow + rowIndex;
                                    },
                                },
                                {
                                  dataField: "",
                                  text: "Tanggal",
                                  sort: true,
                                  },
                                  {
                                    dataField: "",
                                    text: "Kode Retur",
                                    sort: true,
                                },
                                {
                                    dataField: "",
                                    text: "Kode Transaksi",
                                    sort: true,
                                },
                                  {
                                    dataField: "",
                                    text: "Status",
                                    sort: true,
                                },
                                  ]}
                              >
                                  {(props) => (
                                  <div className="py-4 table-responsive">
                                      <BootstrapTable
                                      remote
                                      {...props.baseProps}
                                      bootstrap4={true}
                                      bordered={false}
                                      hover={true}
                                      onTableChange={handleTableChange}
                                      />
                                  </div>
                                  )}
                          </ToolkitProvider>
                          <CardHeader>
                              <center><h3>DATA NOT FOUND</h3></center>
                          </CardHeader>
                        </div>
                        ):(
                        <ToolkitProvider
                              rowNumber={rowIndex}
                              data={allPenawaranSo}
                              keyField="id"
                              columns={[
                              {
                                  dataField: "no",
                                  text: "#",
                                  sort: true,
                                  page: 1,
                                  formatter: (cell, row, index) => {
                                  let currentRow = ++index;
                                  return currentRow + rowIndex;
                                  },
                              },
                              {
                                  dataField: "transaction_date",
                                  text: "Tanggal",
                                  sort: true,
                                },
                                {
                                    dataField: "retur_code",
                                    text: "Kode Retur",
                                    sort: true,
                                },
                                {
                                    dataField: "code_transaction",
                                    text: "Kode Transaksi",
                                    sort: true,
                                },
                                {
                                  dataField: "status_d",
                                  text: "Status",
                                  sort: true,
                                  formatter: (cell, row) => {
                                    return row.status_d === 3
                                      ? 
                                        <Badge color="" className="badge-dot mr-4">
                                          <i className="bg-danger" />
                                          Proses
                                        </Badge>
                                      : row.status_d === 4
                                      ? <Badge color="" className="badge-dot mr-4">
                                          <i className="bg-warning" />
                                          Tolak
                                        </Badge>
                                      : <Badge color="" className="badge-dot mr-4">
                                          <i className="bg-success" />
                                          Setuju
                                        </Badge>;
                                  },
                              },
                              {
                                dataField: "",
                                text: "",
                                formatter: (cell, row, index) => {
                                  return row.status_d === 3
                                    ? <UncontrolledDropdown nav>
                                        <DropdownToggle color="danger">
                                            Tindakan
                                          </DropdownToggle>
                                          <DropdownMenu center>
                                                <Link  to={redirectPrefix + row.id}
                                                  id={"tooltip_" + row.id}>
                                                <DropdownItem>
                                                  <i className="fas fa-user-edit" /><span>Detail</span>
                                                </DropdownItem>
                                                </Link>
                                          </DropdownMenu>
                                      </UncontrolledDropdown>
                                    : row.status_d === 4
                                    ?  <UncontrolledDropdown nav>
                                    <DropdownToggle color="danger">
                                        Tindakan
                                      </DropdownToggle>
                                      <DropdownMenu center>
                                            <Link  to={redirectPrefix + row.id}
                                              id={"tooltip_" + row.id}>
                                            <DropdownItem>
                                              <i className="fas fa-user-edit" /><span>Detail</span>
                                            </DropdownItem>
                                            </Link>
                                      </DropdownMenu>
                                  </UncontrolledDropdown>
                                    : <UncontrolledDropdown nav>
                                    <DropdownToggle color="danger">
                                        Tindakan
                                      </DropdownToggle>
                                      <DropdownMenu center>
                                            {/* <Link  to={redirectPrefix1 + row.id}
                                                id={"tooltip_" + row.id}>
                                              <DropdownItem>
                                                <i className="fas fa-book" /><span>Payment</span>
                                              </DropdownItem>
                                            </Link> */}
                                            {cetak && cetak === "1" && (
                                            <DropdownItem
                                              onClick={() => displayModalCetak(row.id)}
                                            >
                                              <i className="fas fa-print" />
                                              <span>Cetak</span>
                                            </DropdownItem>
                                            )}
                                      </DropdownMenu>
                                  </UncontrolledDropdown>
                                },
                              },
                              ]}
                          >
                              {(props) => (
                              <div className="py-4 table-responsive">
                                  <BootstrapTable
                                  remote
                                  {...props.baseProps}
                                  bootstrap4={true}
                                  bordered={false}
                                  hover={true}
                                  pagination={paginationFactory({ ...paginationOption })}
                                  onTableChange={handleTableChange}
                                  />
                              </div>
                            )}
                      </ToolkitProvider>
                        )} 
                </CardBody>
              </Card>
            </div>
          </Row>
        </Container>
        </div>
        ):(
          <Halaman404 />
        )}
        {openModalCetak && (
  <ModalCetakReturSo
    open={openModalCetak}
    data={dataModalCetak}
    toggle={toggle}
  />
)}
    </>
  );
}

export default HistoriSoRetur;