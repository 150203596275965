/*eslint-disable*/
import React, { useEffect, useState } from "react";
import {
  Card,
  Button,
  Row,
  Col,
  CardBody,
  CardHeader,
  CardFooter,
  FormGroup,
  Label,
  Input,
  Container,
} from "reactstrap";

import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import SweetAlert from "react-bootstrap-sweetalert";

const CreateIklan = () => {
  const token = localStorage.token;
  const history = useHistory();
  const [videowebsite, setVideoWebsite] = useState("");
  const [videomobile, setVideoMobile] = useState("");
  const [posterwebsite1, setPosterWebsite1] = useState("");
  const [postermobile1, setPosterMobile1] = useState("");
  const [posterwebsite2, setPosterWebsite2] = useState("");
  const [postermobile2, setPosterMobile2] = useState("");
  const [open, setOpen] = useState(false)
  const [id, setId] = useState("")
  const [alert, setAlert] = React.useState(null);

  const EditIklan = () => {
    const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
    };

    const data = {
        video_web : videowebsite, 
        video_mobile: videomobile, 
        poster1_web: posterwebsite1, 
        poster1_mobile: postermobile1, 
        poster2_web: posterwebsite2, 
        poster2_mobile: postermobile2
    }
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/iklan-ecommerce/save`, data, { headers })
      .then(function (response) {
        setSuccessAlert(response.data.message);
        setOpen(false);
        getIklan();
        // setTimeout(() => (history.push("/admin/iklan/master/iklan/page")), 1000);
      })
      .catch(function (error) {
          setGagalAlert(error.response.data.message);
      });
  };
  const setSuccessAlert = (id) => {
    setAlert(
      <SweetAlert
        success
        showConfirm
        confirmBtnText="Ok"
        title={id}
        timeout={1000}
        onCancel={hideAlert}
        onConfirm={hideAlert}
      />
    )
  }

  const setGagalAlert = (id) => {
    setAlert(
      <SweetAlert
        danger
        showConfirm
        confirmBtnText="Ok"
        title={id}
        onCancel={hideAlert}
        onConfirm={hideAlert}
      />
    );
  };

  const setQuestionAlert = () => {
    setAlert(
      <SweetAlert
        warning
        showCancel
        confirmBtnText="Lanjutkan"
        confirmBtnBsStyle="danger"
        title="Apakah Kamu Yakin?"
        onConfirm={EditIklan}
        onCancel={hideAlert}
        focusCancelBtn
      />
    );
  };

  const hideAlert = () => {
    setAlert(null);
  }

  useEffect(() => {
    getIklan();
  }, []);

  // fungsi dari ambil data
  const getIklan = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/iklan-ecommerce/page`, {
        headers,
      })
      .then((data) => {
        setId(data.data.response.id)
        setPosterMobile1(data.data.response.poster1_mobile)
        setPosterMobile2(data.data.response.poster2_mobile)
        setPosterWebsite1(data.data.response.poster1_web)
        setPosterWebsite2(data.data.response.poster2_web)
        setVideoMobile(data.data.response.video_mobile)
        setVideoWebsite(data.data.response.video_web)
      })
      .catch(function (error) {
        // setAllIklan(error.response.data.response);
      });
  }
 

  return (
    <div>
      {alert}
      <SimpleHeader name="Iklan" parentName="Master" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <CardBody>
              <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div style={{ textAlign: 'left' }}>
                        <Link className="btn btn-link" to="/admin/iklan/master">
                        <i className="fa fa-arrow-circle-left fa-2x" /> 
                      </Link>
                    </div>
                    <div style={{ textAlign: 'right' }}>
                    <Button hidden={open === true} color="warning" onClick={() => setOpen(true)}>Edit</Button>
                    <Button hidden={open === false} color="info" onClick={() => setQuestionAlert()}>Update</Button>
                      {/* <Link className="btn btn-danger" to="/admin/iklan/master/iklan/create">
                      <i className="fas fa-plus" /> Tambah
                      </Link> */}
                    </div>
                  </div>
                </CardHeader>
                  <CardBody>
                    <Row md="12">
                      <Col md="6">
                        <FormGroup row>
                          <Label
                          className="form-control-label"
                            for="exampleEmail"
                            sm={4}
                          >
                            Video Website
                          </Label>
                          <Col sm={7}>
                            <Input
                            disabled= {open === false}
                              autoComplete="off"
                              className="form-control-alternative"
                              type="text"
                              name="Nama"
                              placeholder="Masukan Link Video Website"
                              value={videowebsite}
                              onChange={(e) => {
                                setVideoWebsite(e.target.value);
                              }}
                            />
                          </Col>
                        </FormGroup>
                       
                        <FormGroup row>
                          <Label
                          className="form-control-label"
                            for="exampleEmail"
                            sm={4}
                          >
                            Poster Website 1
                          </Label>
                          <Col sm={7}>
                          <Input
                            disabled= {open === false}
                              autoComplete="off"
                              className="form-control-alternative"
                              type="text"
                              name="Nama"
                              placeholder="Masukan Link Poster Website 1"
                              value={posterwebsite1}
                              onChange={(e) => {
                                setPosterWebsite1(e.target.value);
                              }}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label
                          className="form-control-label"
                            for="exampleEmail"
                            sm={4}
                          >
                            Poster Website 2
                          </Label>
                          <Col sm={7}>
                          <Input
                          disabled= {open === false}
                              autoComplete="off"
                              className="form-control-alternative"
                              type="text"
                              name="Nama"
                              placeholder="Masukan Link Poster Website 2"
                              value={posterwebsite2}
                              onChange={(e) => {
                                setPosterWebsite2(e.target.value);
                              }}
                            />
                          </Col>
                        </FormGroup>
                        
                      </Col>
                      <Col md="6">
                      <FormGroup row>
                          <Label
                          className="form-control-label"
                            for="exampleEmail"
                            sm={4}
                          >
                            Video Mobile
                          </Label>
                          <Col sm={7}>
                          <Input
                          disabled= {open === false}
                              autoComplete="off"
                              className="form-control-alternative"
                              type="text"
                              name="Nama"
                              placeholder="Masukan Link Video Mobile"
                              value={videomobile}
                              onChange={(e) => {
                                setVideoMobile(e.target.value);
                              }}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label
                          className="form-control-label"
                            for="exampleEmail"
                            sm={4}
                          >
                            Poster Mobile 1
                          </Label>
                          <Col sm={7}>
                          <Input
                          disabled= {open === false}
                              autoComplete="off"
                              className="form-control-alternative"
                              type="text"
                              name="Nama"
                              placeholder="Masukan Link Poster Mobile 1"
                              value={postermobile1}
                              onChange={(e) => {
                                setPosterMobile1(e.target.value);
                              }}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label
                          className="form-control-label"
                            for="exampleEmail"
                            sm={4}
                          >
                            Poster Mobile 2
                          </Label>
                          <Col sm={7}>
                          <Input
                          disabled= {open === false}
                              autoComplete="off"
                              className="form-control-alternative"
                              type="text"
                              name="Nama"
                              placeholder="Masukan Link Poster Mobile 2"
                              value={postermobile2}
                              onChange={(e) => {
                                setPosterMobile2(e.target.value);
                              }}
                            />
                          </Col>
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
              </Card>
            </CardBody>
          </div>
        </Row>
      </Container>
    </div>

  );
};

export default CreateIklan;