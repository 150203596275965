import React, { useEffect, useState } from "react";
import {
  Button,
  Row,
  Col,
  CardBody,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import KopSurat from "views/components/KopSurat";
import axios from "axios";
import * as FileSaver from "file-saver";
import SweetAlert from "react-bootstrap-sweetalert";

const ModalCetakSJFinish = ({ open, toggle, data }) => {
    const token = localStorage.token;
    const username = localStorage.username;
    const [savedItems, setSavedItems] = useState([]);
    const [download, setDownload] = useState("")
    const [alert, setAlert] = React.useState(null);
    const [codepo, setCodePo] = useState("");
    const [keterangan, setKeterangan] = useState("");
    const [supplier, setSupplier] = useState("");
    const [warehouserfq, setWarehouseRfq] = useState("");
    const [waktu,setWaktu] = useState([]);
    const [address, setAddress] = useState("")
    const [phone, setPhone] = useState("")
    const [validasi,setValidasi] = useState("")
    const [driver,setDriver] = useState("")
    const [helper,setHelper] = useState("")
    const [nopol,setNopol] = useState("")

  useEffect(() => {
    getById();
    getItemDataSaved();
  }, []);

  const getById = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/surat-jalan-commerce/cetak-admin/${data.id}`,
        { headers }
      )
      .then((data) => {  
        setAddress(data.data.response.sj.address)
        setPhone(data.data.response.sj.phone)
        setSupplier(data.data.response.sj.person);
        setDownload(data.data.response.sj.counter);
        setWaktu(data.data.response.sj.created)
        setWarehouseRfq(data.data.response.sj.warehouse);
        setKeterangan(data.data.response.sj.keterangan);
        setCodePo(data.data.response.sj.sj_code);
        setSupplier(data.data.response.sj.customer);
        setWaktu(data.data.response.sj.created)
        setWarehouseRfq(data.data.response.sj.warehouse);
        setKeterangan(data.data.response.sj.keterangan);
        setDriver(data.data.response.sj.driver)
        setHelper(data.data.response.sj.helper)
        setNopol(data.data.response.sj.nopol)
        setValidasi(data.data.response.sj.validator1)
       
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const HitCounter = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/surat-jalan-commerce/hit-counter-admin/${data.id}`,
        { headers }
      )
      .then((data) => {  
        console.log("suskes Cuk");
      })
      .catch(function (error) {
        console.log(error);
      });
  };


  const getItemDataSaved = () => {
    const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
    axios.get(`${process.env.REACT_APP_API_BASE_URL}/surat-jalan-commerce/cetak-admin/${data.id}`,{ headers }
    
    ).then(async response => {
        let stateItem = [];
        await Promise.all(response.data.response.list.map(async (data) => {
            stateItem = [...stateItem, {
                item_id: data.item_id,
                item_name:data.item_name,
                item_code:data.item_code,
                qty: data.qty,
                harga: data.harga,
                satuan: data.satuan,
                diskon_nominal: data.diskon_nominal,
                diskon_persen : data.diskon_persen,
                sub_total : data.sub_total,
                data: {
                    item_name: data.item_name,
                    harga: data.harga
                },
            }];
        }));

        setSavedItems(stateItem);
    })
}

const downloadExcel = async ()=> {
    const J = "Cek-Barang-SJ"
    const fileName = J;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/surat-jalan-so/cetak-admin/${data.id}`,{headers})
      .then((data) => {
        setAddress(data.data.response.sj.address)
        setPhone(data.data.response.sj.phone)
        setSupplier(data.data.response.sj.person);
        setDownload(data.data.response.sj.counter);
        setWaktu(data.data.response.sj.created)
        setWarehouseRfq(data.data.response.sj.warehouse);
        setKeterangan(data.data.response.sj.keterangan);
        setCodePo(data.data.response.sj.sj_code);
        setSupplier(data.data.response.sj.customer);
        setWaktu(data.data.response.sj.created)
        setWarehouseRfq(data.data.response.sj.warehouse);
        setKeterangan(data.data.response.sj.keterangan);
        var apiPo = data.data.response.list;
        // var tglcetak = data.data.response.download_date;
        var tableToExcel = (function() {
          var uri = 'data:application/vnd.ms-excel;base64,',
            template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--><meta http-equiv="content-type" content="text/plain; charset=UTF-8"/></head><body><table>{table}</table></body></html>',
            base64 = function(s) {
              return window.btoa(unescape(encodeURIComponent(s)))
            },
            format = function(s, c) {
              return s.replace(/{(\w+)}/g, function(m, p) {
                return c[p];
              })
            }
          return function(table, name) {
            var heading = 'Cek Barang SJ' ;
            var imgsrc1 = 'https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEhAVKx5R3RdjeXQuRdKan2RNLsZn2U4qXYOgU4jqILz6u6MLSzlvzY1b5x9Xiz4sKHhM0UJ1NKKoFVx6ZEI8JqgANlrZ8KwCJ2j9pOmJN-e50-HzVhTFRvEahjCJB51O4oMmJ25V2yQtYOGfxV2b7C2aT9VKBruh0_znTbORz66pu9P47DMB5aP4SuF/s320/Hokky1.png';
            var po = '';
            var a ;
            for(a=0; a < apiPo.length; a++){
              po += `
              <tr style="border: 1px solid black">
                  <td>${apiPo[a].item_name}</td>
                  <td>${apiPo[a].item_code}</td>
                  <td></td>
                  <td>${apiPo[a].satuan}</td>
              </tr>
              `
            }
            var table = `
            <table id="account_table">
                <tbody>
                <tr>
                    <td><img src="https://i.ibb.co/jkFYDqg/150x75.png"/> </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>Hokky Bangunan</td>
                </tr>
                <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>Jl Menganti Karangan No.562</td>
                </tr>
                <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>Wiyung surabaya barat - Jawa Timur </td>
                </tr>
                <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>Telp: 081 217 85 3300 </td>
                </tr>
                <tr>
                    <td colspan="8"><center><b><h1>SURAT JALAN ECOMMERCE</h1></b><center></td>
                </tr>
                <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td>Tanggal</td>
                    <td>: ${waktu}</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>Customer : ${supplier}</td>
                </tr>
                <tr>
                    <td>Kode SJ</td>
                    <td>: ${codepo}</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>Alamat : ${address}</td>
                <tr>
                <tr>
                    <td>Ket</td>
                    <td rowspan="2">: ${keterangan}</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>Phone : ${phone}</td>
                <tr>
                <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
                  <tr style="border: 1px solid black">
                      <th>Nama Item</th>
                      <th>Kode Item</th>
                      <th>Qty</th>
                      <th>Satuan</th>
                    </tr>
                        ${po}
                </tbody>
            </table>
            `;
            var ctx = {
                worksheet: name || 'WorkBook',
                imgsrc1: imgsrc1,
                heading: heading,
                table: table
            }
            var blob = new Blob([format(template, ctx)]);
            return blob;
            }
        })()
        var blobURL = tableToExcel('account_table', 'Cek Barang Surat Jalan');
        FileSaver.saveAs(blobURL, fileName+'.xls');
        
        })
      .catch(function (error) {
            setGagalAlert();
      });
  }
  
  const setGagalAlert = (id) => {
    setAlert(
      <SweetAlert
        danger
        showConfirm
        confirmBtnText="Ok"
        title="Gagal Cok"
        onCancel={hideAlert}
        onConfirm={hideAlert}
      />
    );
  };
  
  const hideAlert = () => {
    setAlert(null);
  };


  function print() {
    var printContents = document.getElementById("targetContent").innerHTML;
    var originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
  }
  
  return (
    <div>
        {alert}
        <Modal isOpen={open} toggle={toggle} style={{ minWidth: "70%", top:"-15%" }}>
      <ModalHeader toggle={toggle} align="center">
      <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div style={{ textAlign: 'left' }}>
                    </div>
                    <div style={{ textAlign: 'right' }}>
                        <Button color="info" onClick={() => (print(),HitCounter(),setTimeout(() => (window.location.reload("/admin/surat-jalan-so/page")), 1000))}>Print</Button>
                        <Button color="success" onClick={()=> (downloadExcel(),HitCounter())}>Excel</Button>
                    </div>
                </div>
      </ModalHeader>
      <ModalBody>
        <CardBody
          className="border"
          id="targetContent"
          style={{ width: "100%" }}
        >
          <div className="col">
            <KopSurat 
            warehouseName={warehouserfq}
            // user={username}
             />
          </div>
          <div className="w-100" style={{ border: "1px solid #b3b3b3" }}></div>
                        <Row md="12">
                            <Col md="12">
                                <h3><b><center>SURAT JALAN ECOMMERCE</center></b></h3>
                            </Col>
                        </Row>
                        <div className="w-100" style={{ border: "1px solid #b3b3b3" }}></div>
                        <br></br>
                        <Row md="12">
                            <Col md="5">
                            <div className="row align-items-center mb-1">
                                <div className="col-3">Tanggal</div>
                                <div className="col-1 text-center">:</div>
                                <div className="col-5 ">
                                    <b>{waktu}</b>
                                </div>
                            </div>
                            <div className="row align-items-center mb-1">
                                <div className="col-3">Kode SJ</div>
                                <div className="col-1 text-center">:</div>
                                <div className="col-5 ">
                                    <b>{codepo}</b>
                                </div>
                            </div>
                             <div className="row align-items-center mb-1">
                                <div className="col-3">Nopol</div>
                                <div className="col-1 text-center">:</div>
                                <div className="col-5 ">
                                    <b>{nopol}</b>
                                </div>
                            </div>
                            </Col>
                            <Col md="1">
                            </Col>
                            <Col md="6">
                            <div className="row align-items-center mb-1">
                                <div className="col-3">Customer</div>
                                <div className="col-1 text-center">:</div>
                                <div className="col-7 ">
                                    <b>{supplier}</b>
                                </div>
                            </div>
                            <div className="row align-items-center mb-1">
                                <div className="col-3">Alamat</div>
                                <div className="col-1 text-center">:</div>
                                <div className="col-7 ">
                                    <b>{address}</b>
                                </div>
                            </div>
                            <div className="row align-items-center mb-1">
                                <div className="col-3">Kontak</div>
                                <div className="col-1 text-center">:</div>
                                <div className="col-7 ">
                                    <b>{phone}</b>
                                </div>
                            </div>
                            </Col>
                        </Row>
                    <CardBody>
                      <br></br>
                      <Table size="sm" responsive>
                        <thead>
                        <tr>
                            <th>
                            <b>Nama Item</b>
                            </th>
                            <th>
                            <b>Kode Item</b>
                            </th>
                            <th>
                            <b>Qty</b>
                            </th>
                            <th>
                            <b>Satuan</b>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            savedItems.map((savedItem, key) => {
                                return (
                                    <tr key={key}>
                                        <td>{savedItem.data.item_name}</td>
                                        <td>{savedItem.item_code}</td>
                                        <td>{savedItem.qty}</td>
                                        <td>{savedItem.satuan}</td>
                                    </tr>
                                )
                            })
                        }
                        </tbody>
                      </Table>
                    </CardBody>
                    <div className="w-100" style={{ border: "0.5px solid #b3b3b3" }}></div>
                        <center>Terms of Price, delivery & shipping required</center>
                        <br></br>
                                <Row md="20">
                                    <Col md="4">
                                    <div className="row align-items-center mb-3">
                                        <div className="col-5">Kepala Gudang</div>
                                        <div className="col-1 text-center">:</div>
                                        <div className="col-5 ">
                                            <b>{validasi}</b>
                                        </div>
                                    </div>
                                    <div className="row align-items-center mb-3">
                                        <div className="col-5">Signature</div>
                                        <div className="col-1 text-center">:</div>
                                        <div className="col-4 ">
                                            <b>------------</b>
                                        </div>
                                    </div>
                                    </Col>
                                    <Col md="4">
                                    <div className="row align-items-center mb-3">
                                        <div className="col-4">Driver</div>
                                        <div className="col-1 text-center">:</div>
                                        <div className="col-4 ">
                                            <b>{driver}</b>
                                        </div>
                                    </div>
                                    <div className="row align-items-center mb-3">
                                        <div className="col-4">Signature</div>
                                        <div className="col-1 text-center">:</div>
                                        <div className="col-4 ">
                                            <b>------------</b>
                                        </div>
                                    </div>
                                    </Col>
                                    <Col md="4">
                                    <div className="row align-items-center mb-3">
                                        <div className="col-4">Helper</div>
                                        <div className="col-1 text-center">:</div>
                                        <div className="col-4 ">
                                            <b>{helper}</b>
                                        </div>
                                    </div>
                                    <div className="row align-items-center mb-3">
                                        <div className="col-4">Signature</div>
                                        <div className="col-1 text-center">:</div>
                                        <div className="col-4 ">
                                            <b>------------</b>
                                        </div>
                                    </div>
                                    </Col>
                                </Row>
                                <div className="w-100" style={{ border: "0.5px solid #b3b3b3" }}></div>
                      {download === 0 ? (
                        <div className="row align-items-center mb-5">
                        <div className="col-4"><i>Download Original oleh {username}</i></div>
                        </div>
                      ):(
                      <div className="row align-items-center mb-5">
                      <div className="col-4"><i>Di Download</i> {download} Kali Oleh {username}</div>
                    </div>
                      )
                      
                      }
        </CardBody>
      </ModalBody>
    </Modal>
    </div>
    
  );
};

export default ModalCetakSJFinish;
