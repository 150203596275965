import React, { useState } from "react";
import { Card, CardBody, Row, Col, Container, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";

import SimpleHeader from "components/Headers/SimpleHeader.js";
import classnames from "classnames";

// import PenawaranPo from "views/pages/po/PenawaranPo/Index.js";
// import ValidatorPo from "views/pages/po/ValidatorPo/Index.js";
// import AdminApprovePo from "views/pages/po/AdminApprove/Index.js";
// import ValidasiPo from "views/pages/po/ValidasiPo/Index.js";
// import CetakPo from "views/pages/po/CetakPo/Index";

// import PenawaranPoPusat from "./PermintaanPo/Index2";
// import ValidasiPenawaranPoPusat from "./ValidasiPermintaanPo/Index";
// import ValidasiAdminPoPusat from "./ValidasiAdminPo/Index";
// import ValidasiDirekturPoPusat from "./ValidasiDirektur/Index";
// import ValidasiDirekturPrevNext from "./Prevnext/Index";
// import CetakPO from "./Cetak/Index";
import ValidasiRiwayatIzin from "./RiwayatIzin/Index";
import ValidasiHrdIzin from "./Izin/Index";

export default function IndexIzinHrd() {
	const [activeTab, setActiveTab] = useState("1");
	// const penawaranPO = String(allInfo.privileges.filter((i) => i.privilege_name === "Tab PO Pusat").map((p) => p.read_access));
	// const validasiPenawaranPO = String(allInfo.privileges.filter((i) => i.privilege_name === "Tab Validasi PO Pusat").map((p) => p.read_access));
	// const validasiAdminPO = String(allInfo.privileges.filter((i) => i.privilege_name === "Tab Validasi PO Admin Pusat").map((p) => p.read_access));
	// const validasiDirektur = String(allInfo.privileges.filter((i) => i.privilege_name === "Tab Validasi PO Direktur Pusat").map((p) => p.read_access));
	// const cetakPO = String(allInfo.privileges.filter((i) => i.privilege_name === "Tab Cetak PO Pusat").map((p) => p.read_access));

	const toggle = (tab) => {
		if (activeTab !== tab) setActiveTab(tab);
	};

	return (
		<>
			<SimpleHeader name="Izin" parentName="Admin" />
			<Container className="mt--6" fluid>
				<Row>
					<div className="col">
						<Card>
							<CardBody>
								<Nav tabs>
									{/* {penawaranPO && penawaranPO === "1" && ( */}
										<NavItem className="pointer">
											<NavLink
												className={classnames({ active: activeTab === "1" })}
												onClick={() => {
													toggle("1");
												}}>
												Izin
											</NavLink>
										</NavItem>
									{/* )}
									{validasiPenawaranPO && validasiPenawaranPO === "1" && ( */}
										<NavItem className="pointer">
											<NavLink
												className={classnames({ active: activeTab === "2" })}
												onClick={() => {
													toggle("2");
												}}>
                                                    Histori
											</NavLink>
										</NavItem>
									{/* )} */}
								</Nav>
								<TabContent activeTab={activeTab}>
								{/* {penawaranPO && penawaranPO === "1" && ( */}
									<TabPane tabId="1">
										<Row>
											<Col md="12">
												<ValidasiHrdIzin />
											</Col>
										</Row>
									</TabPane>
								{/* )}
								{validasiPenawaranPO && validasiPenawaranPO === "1" && ( */}
									<TabPane tabId="2">
										<Row>
											<Col md="12">
												<ValidasiRiwayatIzin />
											</Col>
										</Row>
									</TabPane>
								{/* )} */}
								</TabContent>
							</CardBody>
						</Card>
					</div>
				</Row>
			</Container>
		</>
	);
}
