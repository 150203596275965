/*eslint-disable*/
import React, { useEffect, useState } from "react";
import {
  Card,
  Button,
  Row,
  Col,
  CardBody,
  CardHeader,
  CardFooter,
  Table,
  Badge,
  Form ,
  Container,
  FormGroup,
  Label,
  Input,
  FormFeedback,

} from "reactstrap";
import { Link,useHistory } from "react-router-dom";
import axios from "axios";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import SweetAlert from "react-bootstrap-sweetalert";
import SelectFile from "components/SelectedFile/index";
import CurrencyInput from 'react-currency-input-field';

export default function DetailMgm (props) {
const [alert, setAlert] = React.useState(null);
const token = localStorage.token;
  const username = localStorage.username;
  let history = useHistory();
  const [tanggal,setTanggal] = useState("");
  const [codeuser,setCodeUser] = useState("");
  const [nameuser,setNameUser] = useState("");
  const [bankname,setBankName] = useState("");
  const [banknames,setBankNames] = useState([]);
  const [accountname,setAccountName] = useState("");
  const [accountnumber,setAccountNumber] = useState("");
  const [nominal,setNominal] = useState("");
  const [status1,setStatus1] = useState("");
  const [status2,setStatus2] = useState("");
  const [validator1,setValidator1] = useState("");
  const [validator2,setValidator2] = useState("");
  const [update1,setUpdate1] = useState("");
  const [update2,setUpdate2] = useState("");
  const [lunas, setLunas] = useState("")
  const [image, setImage] = useState("")
  const [imageerror, setImageError] = useState(null)

  const validateForm = () => {
    let error = false;
    if (image === "") {
      setImageError("invalid");
      error = true;
    }
    return error;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateForm()) {
          setQuestionAlert();
    }
  }

  useEffect(() => {
    getById();
  }, []);

  const getById = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/user-withdraw/get/${props.match.params.id}`,
        { headers }
      )
      .then((data) => {
        // console.log(data);
        getBank(data.data.response.bank_name)
        setTanggal(data.data.response.transaction_date)
        setCodeUser(data.data.response.user_code)
        setNameUser(data.data.response.user_name)
        setAccountName(data.data.response.account_name)
        setAccountNumber(data.data.response.account_number)
        setNominal(data.data.response.nominal)
        setStatus1(data.data.response.status1)
        setStatus2(data.data.response.status2)
        setValidator1(data.data.response.validator1)
        setValidator2(data.data.response.validator2)
        setUpdate1(data.data.response.update1)
        setUpdate2(data.data.response.update2)
        setImage(data.data.response.bukti_transfer)
        setLunas(data.data.response.active_flag)
       
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getBank = (id) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/daftar-bank/list`, { headers })
      .then((data) => {
        setBankNames(data.data.response);
        setBankName(id)
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <>
    {alert}
    <SimpleHeader name="Detail MGM" parentName="SO" />
    <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <CardBody>
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                      <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <h3>Detail MGM</h3>
                          <div style={{ textAlign: 'right' }}>
                            <h1>
                              <Badge color="success">
                                {lunas}
                              </Badge>
                            </h1>
                          </div>
                      </div>
                    </CardHeader>
                    <Form onSubmit={handleSubmit}>
                    <CardBody>
                      <Row md="12">
                          <Col md="6">
                          <FormGroup row>
                            <Label className="form-control-label" for="exampleEmail" sm={3}>
                                Tanggal
                            </Label>
                            <Col sm={6}>
                                <Input
                                disabled
                                placeholder="Masukan Customer"
                                className="form-control-alternative"
                                    type="text"
                                    value={tanggal}
                                    onChange={(e) => {
                                        setTanggal(e.target.value);
                                    }}/>
                            </Col>
                            </FormGroup>
                            <FormGroup row>
                            <Label className="form-control-label" for="exampleEmail" sm={3}>
                                Kode User
                            </Label>
                            <Col sm={6}>
                                <Input
                                disabled
                                placeholder="Masukan Customer"
                                className="form-control-alternative"
                                    type="text"
                                    value={codeuser}
                                    onChange={(e) => {
                                        setCodeUser(e.target.value);
                                    }}/>
                            </Col>
                            </FormGroup>
                            <FormGroup row>
                            <Label className="form-control-label" for="exampleEmail" sm={3}>
                                Nama
                            </Label>
                            <Col sm={6}>
                                <Input
                                disabled
                                placeholder="Masukan Customer"
                                className="form-control-alternative"
                                    type="text"
                                    value={nameuser}
                                    onChange={(e) => {
                                        setNameUser(e.target.value);
                                    }}/>
                            </Col>
                            </FormGroup>
                            <FormGroup row>
                              <Label className="form-control-label"
                                for="exampleEmail"
                                sm={3}
                              >
                                Nominal
                              </Label>
                              <Col sm={6}>
                                <Input
                                className="form-control-alternative"
                                disabled
                                  type="text"
                                  name="desc"
                                  placeholder="Masukan Ongkir"
                                  value={nominal}
                                  onChange={(e) => {
                                    setNominal(e.target.value);
                                  }}
                                />
                              </Col>                             
                            </FormGroup>
                          </Col>
                          <Col md="6">
                          <FormGroup row>
                            <Label className="form-control-label" for="exampleEmail" sm={4}>
                                Bank
                            </Label>
                            <Col sm={6}>
                                <Input
                                disabled
                                className="form-control-alternative"
                                type="select"
                                value={bankname}
                                onChange={(e) => {
                                    setBankName(e.target.value);
                                }}>
                                <option value="" disabled selected hidden>Pilih Bank</option>
                                  {banknames.map((supplier, key) => {
                                    return (
                                        <option key={key} value={supplier.bank_name}>
                                          {supplier.bank_name}
                                        </option>
                                    );
                                  })}
                                </Input>
                            </Col>
                            </FormGroup>
                            <FormGroup row>
                            <Label className="form-control-label" for="exampleEmail" sm={4}>
                                Pemilik Bank
                            </Label>
                            <Col sm={6}>
                                <Input
                                disabled
                                placeholder="Masukan Customer"
                                className="form-control-alternative"
                                    type="text"
                                    value={accountname}
                                    onChange={(e) => {
                                        setAccountName(e.target.value);
                                    }}/>
                            </Col>
                            </FormGroup>
                            <FormGroup row>
                            <Label className="form-control-label" for="exampleEmail" sm={4}>
                                Rekening
                            </Label>
                            <Col sm={6}>
                                <Input
                                disabled
                                placeholder="Masukan Customer"
                                className="form-control-alternative"
                                    type="text"
                                    value={accountnumber}
                                    onChange={(e) => {
                                        setAccountNumber(e.target.value);
                                    }}/>
                            </Col>
                            </FormGroup>
                            <FormGroup row>
                              <Label className="form-control-label"
                                for="exampleEmail"
                                sm={4}
                              >
                                Bukti Transfer
                              </Label>
                              <Col sm={7}>
                                {/* <label className="form-control-label" htmlFor="formId" >
                                  <input
                                    hidden
                                    id="formId"
                                    name="file"
                                    type="file"
                                    accept="image/*"
                                    invalid={imageerror === "invalid"}
                                    onChange={(event) => {
                                      setImage(event.target.files[0]);
                                      if (event.target.files[0] !== "") {
                                        setImageError("");
                                      }
                                    }}
                                  />
                                  <SelectFile
                                    onclick={() => { }}
                                    imageSrc={image != "" && image != undefined ? URL.createObjectURL(image) : null}
                                  />
                                </label> */}
                                <img
                                    alt=""
                                    src={image}
                                    width="100%"
                                    />
                              </Col>
                            </FormGroup>
                          </Col>
                      </Row>
                    </CardBody>
                    <CardFooter>
                          <Link className="btn btn-info" to="/admin/master/mgm/histori/page">
                            Kembali
                          </Link>
                    </CardFooter>
                </Form>
              </Card>
            </CardBody>
          </div>
        </Row>
    </Container>
    </>
  );
}